export const ENV = process.env.REACT_APP_ENV
export const VERSION = process.env.REACT_APP_VERSION

export const MOCKEND_ALL_DISABLED = process.env.REACT_APP_MOCKEND_ALL_DISABLED === 'true'
export const MOCKEND_ALL_ENABLED = process.env.REACT_APP_MOCKEND_ALL_ENABLED === 'true'
export const MOCKEND_TIMING = 800

export const CONSOLE_LOG_ENABLED = true

export const HOSTNAME = process.env.REACT_APP_HOSTNAME
export const API_NAME = process.env.REACT_APP_API_NAME || ''
export const ENDPOINT = process.env.REACT_APP_ENDPOINT
export const REGION = process.env.REACT_APP_REGION
export const SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY
export const FACEBOOK_PIXEL_KEY = process.env.REACT_APP_FACEBOOK_PIXEL_KEY
export const GOOGLE_ADS_KEY = process.env.REACT_APP_GOOGLE_ADS_KEY
export const GOOGLE_ADS_SEND_KEY = process.env.REACT_APP_GOOGLE_ADS_SEND_KEY
export const DEFAULT_AGENCY_CODE = process.env.REACT_APP_DEFAULT_AGENCY_CODE

export const INPUT_DIGITS_KEY_CODES = {
  BACKSPACE: 8,
  ARROW_LEFT: 37,
  ARROW_RIGHT: 39,
}

export const MAX_FILE_MB = 10
export const MAX_FILE = 1024 ** MAX_FILE_MB

export const PERIOD_MAPPER: Record<string, string> = {
  true_false: 'giornaliero_base',
  true_true: 'stagionale_base',
  false_false: 'giornaliero_plus',
  false_true: 'stagionale_plus',
}

export const COVERAGE_TYPE_DAYS: Record<string, string> = {
  '1': 'id_daily',
  '2': 'id_seasonal',
  '3': 'id_period',
}
