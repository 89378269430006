import { Trans } from 'react-i18next'
import { ScrollTopRoot } from 'src/roots'
import { DataEntryStep1Props, typedMemo } from 'src/types'
import { InputDate, InputRadio, InputSelect, InputText } from 'src/ui/widgets'

import { useDataEntryStep1 } from './data-entry-step1.hook'

export const DataEntryStep1 = typedMemo((props: DataEntryStep1Props) => {
  const {
    daysOptions,
    insuredLimit,
    formik: { values, errors, touched, setFieldValue, handleChange, handleBlur, setFieldTouched },
    validationSchema,
  } = props

  const {
    t,
    agency,
    coveragesOptions,
    closePickerTrigger,
    coverageStartDateDisabledFrom,
    coverageStartDateDisabledTo,
    coverageEndDateDisabledFrom,
    coverageEndDateDisabledTo,
    toStructureOptions,
  } = useDataEntryStep1(props)

  const PeriodTypeDates = (
    <div>
      <div className="col-sm-12 p-0 m-0">
        <InputDate
          name="coverage_start_date"
          handleChange={handleChange}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          validationSchema={validationSchema}
          touched={touched.coverage_start_date}
          value={values.coverage_start_date}
          error={errors.coverage_start_date}
          label={t('data_entry_step_1.coverage_start_date')}
          placeholder={t('data_entry_step_1.coverage_start_date_placeholder')}
          disabled={values.insured_days === 'id_seasonal'}
          disabledFrom={coverageStartDateDisabledFrom}
          disabledTo={coverageStartDateDisabledTo}
          classes="m-0 p-0"
          closePickerTrigger={closePickerTrigger}
        />
      </div>

      <div className="col-sm-12 p-0 m-0">
        <InputDate
          name="coverage_end_date"
          handleChange={handleChange}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          validationSchema={validationSchema}
          touched={touched.coverage_end_date}
          value={values.coverage_end_date}
          error={errors.coverage_end_date}
          label={t('data_entry_step_1.coverage_end_date')}
          placeholder={t('data_entry_step_1.coverage_end_date_placeholder')}
          disabled={values.insured_days !== 'id_period'}
          disabledFrom={coverageEndDateDisabledFrom}
          disabledTo={coverageEndDateDisabledTo}
          classes="m-0 p-0"
          closePickerTrigger={closePickerTrigger}
        />
      </div>
    </div>
  )

  const PeriodTypeConvention = (
    <div>
      {agency?.structures && agency?.structures?.length > 0 && (
        <div className="col-sm-12 m-0 mt-2 font-15">
          <InputSelect
            name="convention"
            validationSchema={validationSchema}
            setFieldValue={setFieldValue}
            options={toStructureOptions(agency?.structures)}
            touched={touched.convention}
            value={values.convention}
            error={errors.convention}
            label={t('data_entry_step_1.convention')}
            placeholder={t('data_entry_step_1.convention_placeholder')}
          />
        </div>
      )}
    </div>
  )

  const InsuredDays = (
    <>
      <div className="col-12 d-md-flex">
        <div className="col-12 p-0 m-0">
          <InputSelect
            name="insured_days"
            validationSchema={validationSchema}
            setFieldValue={setFieldValue}
            options={daysOptions}
            touched={touched.insured_days}
            value={values.insured_days}
            error={errors.insured_days}
            label={t('data_entry_step_1.coverage_insured_days')}
          />
        </div>
      </div>
      {(values?.insured_days === 'id_period' ||
        Number(values?.insured_number) + Number(values?.insured_number_under14) >=
          insuredLimit) && <div className="col-12 col-md-12 p-0 m-0 pb-3">{PeriodTypeDates}</div>}
      <div className="col-12 col-md-12 p-0 m-0 pb-3">{PeriodTypeConvention}</div>
    </>
  )

  const CoverageInsuredNumber = (
    <div className="col-12 p-0 m-0">
      <InputText
        name="insured_number"
        handleChange={handleChange}
        handleBlur={handleBlur}
        validationSchema={validationSchema}
        touched={touched.insured_number}
        value={values.insured_number}
        error={errors.insured_number}
        type="number"
        label={t('data_entry_step_1.coverage_insured_number')}
        placeholder={t('data_entry_step_1.coverage_insured_number_placeholder')}
        classesError="font-15 m-0 c-862633"
        classesLabel="font-15"
      />
    </div>
  )

  const CoverageInsuredNumberUnder14 = (
    <div className="col-12 p-0 m-0">
      <InputText
        name="insured_number_under14"
        handleChange={handleChange}
        handleBlur={handleBlur}
        validationSchema={validationSchema}
        touched={touched.insured_number_under14}
        value={values.insured_number_under14}
        error={errors.insured_number_under14}
        type="number"
        label={t('data_entry_step_1.coverage_insured_number_under14')}
        placeholder={t('data_entry_step_1.coverage_insured_number_placeholder')}
        classesError="font-15 m-0 c-862633"
        classesLabel="font-15"
      />
    </div>
  )

  const PeriodType = (
    <div className="pl-md-3 mb-2 mb-md-1 font-14">
      {CoverageInsuredNumber}
      {CoverageInsuredNumberUnder14}
      {InsuredDays}
    </div>
  )

  const CoverageType = (
    <div className="pl-md-3 mb-5 mb-md-4 font-14">
      <InputRadio
        name="coverage_type_radios"
        handleChange={handleChange}
        handleBlur={handleBlur}
        options={coveragesOptions}
        validationSchema={validationSchema}
        touched={touched.coverage_type_radios}
        value={values.coverage_type_radios}
        error={
          touched.coverage_type_radios && errors.coverage_type_radios
            ? errors.coverage_type_radios
            : ''
        }
        label={t('data_entry_step_1.coverage_type_title')}
        classes="m-0 p-0"
        classesLabel="m-0 p-0 font-16 d-flex align-items-center mb-3"
        vertically
      />
    </div>
  )

  const InputsSection = (
    <div className="col-12 col-md-6 p-0 px-md-3 pl-md-3 mb-2 mb-md-1 font-14">
      {CoverageType}
      {PeriodType}
    </div>
  )

  const LabelsSection = (
    <div className="col-12 col-md-6 p-0 px-md-3 pl-md-3 font-16 mb-5 mb-md-4 me-md-2 mt-md-4 pt-md-3">
      <div className="mb-4">
        <Trans i18nKey="data_entry_step_1.coverage_description_start" />
      </div>
      <ul>
        <li>
          <Trans i18nKey="data_entry_step_1.coverage_description_bullet_1" />
        </li>
        <li>
          <Trans i18nKey="data_entry_step_1.coverage_description_bullet_2" />
        </li>
        <li>
          <Trans i18nKey="data_entry_step_1.coverage_description_bullet_3" />
        </li>
        <li>
          <Trans i18nKey="data_entry_step_1.coverage_description_bullet_4" />
        </li>
        <li>
          <Trans i18nKey="data_entry_step_1.coverage_description_bullet_5" />
        </li>
      </ul>
    </div>
  )

  return (
    <div>
      <ScrollTopRoot />
      <div className="d-md-flex">
        {InputsSection}
        {LabelsSection}
      </div>
    </div>
  )
})
