import { useAws } from 'src/networking/aws'
import { AwsGateCallbacks, AwsGateRequest } from 'src/types'
import { PostGetGroup } from 'src/types/networking/terminals/group.types'

export const usePostGetGroup = <
  T extends PostGetGroup['request'],
  D extends PostGetGroup['response']
>() => {
  const hook = useAws<T, D>('postGetGroup')
  return {
    ...hook,
    crossGate: (request: AwsGateRequest<T>, callbacks: AwsGateCallbacks<D>) => {
      return hook.crossGate(
        {
          method: 'post',
          path: '/get-group',
          ...request,
        },
        callbacks
      )
    },
  }
}
