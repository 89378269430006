import { FieldArray, FieldArrayRenderProps, FormikProvider } from 'formik'
import { ChangeEvent } from 'react'
import { ProgressBar } from 'react-bootstrap'
import { Image } from 'react-component-library'
import Select, { components, NoticeProps } from 'react-select'
import { MinusIcon, PlusIcon } from 'src/assets'
import { lowerLanguage } from 'src/i18n'
import { ScrollTopRoot } from 'src/roots'
import { reactSelectStyles } from 'src/styles/react-select.styles'
import { ReactSelectOption } from 'src/types'
import { Button, InputRadio, InputText, Loader } from 'src/ui/widgets'

import { UseClaimReportRcInjuryReportInjuryStep2 } from './claim-report-rc-injury-report-injury-step2.hook'
import { OptionsOutsideSelect } from './options-outside-select'

export const ClaimReportRcInjuryReportInjuryStep2 = () => {
  const {
    t,
    formik,
    validationSchema,
    isInjurySectionsEmpty,
    optionExtentDamage,
    optionNatures,
    optionBodyParts,
    progressBar,
    pendingGetInjuriesClaim,
    goBack,
    handleSelectChange,
  } = UseClaimReportRcInjuryReportInjuryStep2()

  const { values, touched, setFieldValue, handleChange, handleBlur, handleSubmit } = formik

  const Topbar = (
    <Button
      flavor="transparentAccentBack"
      text={t('commons.back')}
      classes="px-0 pt-0"
      classesText="text-uppercase font-14-semibold"
      onPress={goBack}
    />
  )

  const Title = (
    <div className="font-28-semibold c-292929">
      {t('claim_report_rc_injury_report_injury_step2.title')}
    </div>
  )

  const Subtitle = (
    <div className="mt-2 font-14-semibold c-292929 text-uppercase">
      {t('claim_report_rc_injury_report_injury_step2.subtitle')}
    </div>
  )

  const RadioForm = (
    <div>
      <InputRadio
        name="extentDamage"
        handleChange={(event: ChangeEvent<HTMLInputElement>) => {
          setFieldValue('extentDamage', event?.target?.value)
          handleChange(event)
        }}
        handleBlur={handleBlur}
        options={optionExtentDamage || []}
        validationSchema={validationSchema}
        touched={touched.extentDamage}
        value={values.extentDamage}
        error={''}
        label={t('claim_report_rc_injury_report_injury_step2.extent_damage')}
        classes="m-0 p-0 mt-4"
        classesLabel="m-0 p-0 font-12 d-flex align-items-center mb-3"
        classesOption="pb-3"
      />
    </div>
  )

  const InputForm = (
    <div>
      <InputText
        name="damageDescription"
        handleChange={handleChange}
        handleBlur={handleBlur}
        textarea
        validationSchema={validationSchema}
        touched={touched.damageDescription}
        value={values.damageDescription}
        label={t('claim_report_rc_injury_report_injury_step2.damage_description')}
        placeholder={t('claim_report_rc_injury_report_injury_step2.damage_description_placeholder')}
      />
    </div>
  )

  const NoOptionsMessage = (props: NoticeProps) => {
    return (
      <components.NoOptionsMessage {...props}>
        {t('claim_report_rc_injury_report_injury_step2.select_no_options')}
      </components.NoOptionsMessage>
    )
  }
  const SelectForm = ({ index }: { index: number }) => (
    <div className="mt-3 d-md-flex col-12">
      <div className="col-md-6 pe-md-2">
        <div className="font-12">
          {t('claim_report_rc_injury_report_injury_step2.select_nature_label')}
        </div>
        <Select
          name={`injurySections.${index}.nature`}
          className="mb-4"
          options={optionNatures}
          defaultValue={values?.injurySections?.[index]?.nature}
          isClearable
          styles={reactSelectStyles}
          components={{ NoOptionsMessage }}
          placeholder={t('claim_report_rc_injury_report_injury_step2.select_nature_placeholder')}
          onChange={(e) => {
            setFieldValue(`injurySections.${index}.nature`, e as ReactSelectOption)

            const allBodies = optionBodyParts[(e as ReactSelectOption)?.value] || []
            const bodyParts_: any = []

            allBodies?.map((item) =>
              bodyParts_?.push({
                value: item?.value,
                label: item?.language[lowerLanguage],
              })
            )

            setFieldValue(`injurySections.${index}.optionBodyParts`, bodyParts_)
          }}
        />
      </div>

      <div className="col-md-6 ps-md-2">
        <div className="font-12">
          {t('claim_report_rc_injury_report_injury_step2.select_body_parts_label')}
        </div>
        <OptionsOutsideSelect
          name={`injurySections.${index}.bodyParts`}
          id={index}
          onChange={(e: any) => {
            handleSelectChange(e)
            setFieldValue(`injurySections.${index}.bodyParts`, e as ReactSelectOption)
          }}
          isMulti
          placeholder={t(
            'claim_report_rc_injury_report_injury_step2.select_body_parts_placeholder'
          )}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          options={values?.injurySections?.[index]?.optionBodyParts}
          outsideValues={values?.injurySections?.[index]?.bodyParts}
        />
      </div>
    </div>
  )

  const ButtonForm = (
    <div className="mt-5 pt-2 d-flex justify-content-md-end">
      <div className="col-12 col-md-4">
        <Button
          flavor="accentNext"
          text={t('commons.continue')}
          type="submit"
          disabled={
            !formik?.values?.extentDamage ||
            !formik?.values?.damageDescription ||
            !formik?.values?.injurySections?.length ||
            isInjurySectionsEmpty
          }
          classes="ps-4 ps-md-3 pe-4 pe-md-3 m-auto m-md-0 w-100 d-flex justify-content-between"
          classesText="font-14-semibold text-uppercase"
        />
      </div>
    </div>
  )

  return (
    <div className="mb-md-5 pb-md-5 ps-md-3 pe-md-3 d-md-flex justify-content-md-center container-fluid">
      <div className="col-12 col-lg-7">
        <div className="mt-4">
          <ScrollTopRoot />
          {Topbar}
          <div className="claim-progress">
            <ProgressBar now={progressBar} className="claim-progress-bar" />
          </div>
          <div className="p-4 ps-3 pe-3 p-md-5 mb-5 bg-ffffff">
            {Title}
            {Subtitle}
            <form onSubmit={handleSubmit}>
              {RadioForm}
              {InputForm}
              <FormikProvider value={formik}>
                <FieldArray
                  name="injurySections"
                  render={(fieldArray: FieldArrayRenderProps) => (
                    <div>
                      {values?.injurySections?.map((_, index) => (
                        <div key={index}>
                          <SelectForm index={index} />
                          {index !== values?.injurySections?.length - 1 && (
                            <div className="mt-2 mb-2 mt-md-4 mb-md-4 claim-border-bottom-green" />
                          )}
                        </div>
                      ))}

                      <div className="mt-4 d-flex align-items-center">
                        <div
                          className="font-14-semibold c-059881 cursor-pointer"
                          onClick={() => {
                            fieldArray.push({
                              nature: undefined,
                              bodyParts: [],
                              showMenu: false,
                              showAccordion: false,
                            })
                          }}
                        >
                          <Image
                            src={PlusIcon}
                            width={16}
                            height={16}
                            className="filter-059881 me-2"
                          />
                          {t(
                            'claim_report_rc_injury_report_injury_step2.add_injury'
                          )?.toUpperCase()}
                        </div>
                      </div>

                      {values?.injurySections?.length > 1 && (
                        <div className="mt-4 d-flex align-items-center">
                          <div
                            className="font-14-semibold c-059881 cursor-pointer"
                            onClick={() => {
                              fieldArray.pop()
                            }}
                          >
                            <Image
                              src={MinusIcon}
                              width={16}
                              height={16}
                              className="filter-059881 me-2"
                            />
                            {t(
                              'claim_report_rc_injury_report_injury_step2.remove_injury'
                            )?.toUpperCase()}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                />
              </FormikProvider>
              <div className="pt-5">{ButtonForm}</div>
            </form>
          </div>
        </div>
      </div>
      {pendingGetInjuriesClaim && <Loader overlay />}
    </div>
  )
}
