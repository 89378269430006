import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useRecoilState, useRecoilValue } from 'recoil'
import { Paths } from 'src/navigation'
import { atomClaimData, atomClaimQuote, atomProgressBar } from 'src/storage'
import { FormikClaimRequestReason, InputRadioOption } from 'src/types'
import { yupStrRequired } from 'src/utils'
import * as Yup from 'yup'

import { PROGRESS_BAR_STEP } from '../../progress-bar-step'

export const UseClaimReportRcInjuryRequestReason = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const claimQuote = useRecoilValue(atomClaimQuote)

  const optionItems: InputRadioOption[] = [
    ...(claimQuote?.coverage === '2'
      ? [
          {
            id: 'injury',
            value: t('claim_report_rc_injury_request_reason.radio_item_injury'),
          },
        ]
      : []),
    {
      id: 'damage',
      value: t('claim_report_rc_injury_request_reason.radio_item_damage'),
    },
    {
      id: 'refound',
      value: t('claim_report_rc_injury_request_reason.radio_item_refound'),
    },
  ]

  const [progressBar, setProgressBar] = useRecoilState(atomProgressBar)
  const [claimData, setClaimData] = useRecoilState(atomClaimData)

  const [initialValues] = useState<FormikClaimRequestReason>({
    request: claimData?.other?.requestType ? claimData?.other?.requestType : '',
  })

  const validationSchema = Yup.object().shape({
    request: yupStrRequired,
  })

  //////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    setTimeout(() => {
      setProgressBar(PROGRESS_BAR_STEP.claim_report_rc_injury_request_reason)
    }, 100)
  }, [])

  useEffect(() => {
    if (!claimQuote) {
      navigate(Paths.ClaimReport)
    }
  }, [claimQuote])

  //////////////////////////////////////////////////////////////////////////////

  const formik = useFormik<FormikClaimRequestReason>({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setClaimData({
        ...claimData,
        other: {
          ...claimData?.other,
          requestType: values?.request,
        },
      })
      if (values?.request === 'injury') {
        navigate(Paths.ClaimReportRcInjuryReportInjuryStep1)
      } else if (values?.request === 'damage') {
        navigate(Paths.ClaimReportRcInjuryReportDamageStep1)
      } else if (values?.request === 'refound') {
        navigate(Paths.ClaimReportRcInjuryRefound)
      }
    },
  })

  //////////////////////////////////////////////////////////////////////////////

  const goBack = () => {
    navigate(Paths.ClaimReportRcInjuryPersonalData)
  }

  return {
    t,
    formik,
    optionItems,
    validationSchema,
    progressBar,
    goBack,
  }
}
