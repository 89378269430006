import { Trans } from 'react-i18next'
import { PropsClaimReportCode } from 'src/types/pages/claim.types'
import { ClaimCode } from 'src/ui/fragments'
import { Button, Loader } from 'src/ui/widgets'

import { useClaimReportCode } from './claim-report-code.hook'

export const ClaimReportCode = ({ setShowClaimSummary }: PropsClaimReportCode) => {
  const { t, email, error, disabled, successCode, code, setCode } = useClaimReportCode()

  const Title = (
    <div className="font-28-semibold white-space-pre text-md-center">
      {t('claim_report_code.title')}
    </div>
  )

  const SubTitle = (
    <div className="mt-3 mt-md-5 font-16">
      <Trans i18nKey="claim_report_code.subtitle" />
    </div>
  )

  const CodeForm = (
    <div className="mt-0 mt-md-4 mb-0 mb-md-5 d-flex justify-content-center">
      <div className="mt-4 p-3 pt-4 pt-md-2 pb-4 col-12 claim-card-bg">
        <div className="font-14 d-inline-block w-100 text-md-center">
          <div className="display-contents">
            <Trans i18nKey="claim_report_code.code_title" />
          </div>
          <div
            className="cursor-pointer display-contents font-14-semibold"
            onClick={() => window.open(`mailto:${email}`)}
          >
            {email}
          </div>
          <div>
            <ClaimCode
              code={code}
              error={error}
              disabled={disabled}
              successCode={successCode}
              setCode={setCode}
            />
          </div>
        </div>
      </div>
    </div>
  )

  const ButtonForm = (
    <div className="mt-4 mt-md-5 pt-2 pt-md-5 d-flex justify-content-md-end">
      <div className="col-12 col-md-4">
        <Button
          flavor="accentNext"
          text={t('claim_report_code.button_start')}
          classes="ps-4 ps-md-3 pe-4 pe-md-3 m-auto m-md-0 w-100 d-flex justify-content-between"
          classesText="font-14-semibold text-uppercase"
          disabled={disabled || !successCode}
          onPress={() => setShowClaimSummary(true)}
        />
      </div>
    </div>
  )

  return (
    <div className="mb-md-5 pb-md-5 ps-md-3 pe-md-3 d-md-flex justify-content-md-center container-fluid">
      <div className="col-12 col-lg-7">
        <div className="mt-4 p-4 ps-3 ps-md-4 pe-3 pe-md-4 bg-ffffff">
          {Title}
          {SubTitle}
          {CodeForm}
          {ButtonForm}
          {disabled && (
            <div className="d-flex justify-content-center mb-3">
              <Loader />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
