import { useTranslation } from 'react-i18next'
import { CountryData } from 'react-phone-input-2'
import { regexAccents, regexTaxCode } from 'src/regex'
import {
  checkFiscalCode,
  compareDates,
  isValidDate,
  replaceDotPhoneFormat,
  reverseDate,
  yupBirthDate,
  yupBool,
  yupBoolRequired,
  yupEmailRequired,
  yupStr,
  yupStrRequired,
} from 'src/utils'
import * as Yup from 'yup'

export const useValidationSchemasGroup = () => {
  const { t } = useTranslation()

  const stepGroup2 = Yup.object().shape({
    mainInsured: Yup.object().shape({
      name: yupStrRequired.matches(regexAccents, t('commons.error_is_wrong')),
      surname: yupStrRequired.matches(regexAccents, t('commons.error_is_wrong')),
      email: yupEmailRequired,
      emailConfirm: yupEmailRequired.test(
        'check_email',
        t('data_entry_step_2.error_equal_email'),
        (value, field) => {
          return field.parent.email?.toLowerCase()?.trim() === value?.toLowerCase()?.trim()
        }
      ),
      phone: Yup.object().shape({
        formattedPhone: yupStr.test(
          'validate-insured-phone',
          t('commons.error_is_wrong'),
          function () {
            const { country, value } = this.parent

            const formattedValue = value?.replace(/\D/g, '')

            if (
              formattedValue?.length > 0 &&
              formattedValue !== (country as CountryData)?.dialCode &&
              replaceDotPhoneFormat((country as CountryData)?.format, formattedValue).includes(
                'X'
              ) &&
              formattedValue?.length < (country as CountryData)?.dialCode.length + 9
            ) {
              return false
            }

            return true
          }
        ),
      }),
      cap: yupStrRequired.test('check_validation_cap', t('commons.error_is_wrong'), (value) => {
        return value && value?.length <= 10 ? true : false
      }),
      nationality: yupStr,
      fiscalCodeQuestion: yupStrRequired,
      birthDate: yupBirthDate
        .when('fiscalCodeQuestion', (item, schema) => {
          return item === 'no_id' ? schema.required(t('commons.required')) : schema
        })
        .test('check_date', t('data_entry_step_2.error_date'), (value) => {
          return compareDates(new Date(reverseDate(value)), new Date()) <= 0
        })
        .test('check_validation', t('data_entry_step_2.error_date'), (value, field) => {
          return field.schema.exclusiveTests.required === true
            ? isValidDate(reverseDate(value))
            : true
        }),
      birthNation: yupStr
        .when('fiscalCodeQuestion', (item, schema) => {
          return item === 'no_id' ? schema.required(t('commons.required')) : schema
        })
        .matches(regexAccents, t('commons.error_is_wrong')),
      birthPlace: yupStr
        .when('fiscalCodeQuestion', (item, schema) => {
          return item === 'no_id' ? schema.required(t('commons.required')) : schema
        })
        .matches(regexAccents, t('commons.error_is_wrong')),
      gender: yupStr.when('fiscalCodeQuestion', (item, schema) => {
        return item === 'no_id' ? schema.required(t('commons.required')) : schema
      }),
      fiscalCode: yupStr
        .when('fiscalCodeQuestion', (item, schema) => {
          return item === 'yes_id' ? schema.required(t('commons.required')) : schema
        })
        .matches(regexTaxCode, t('commons.error_is_wrong'))
        .test('check_validation', t('commons.fiscal_code_mismatch'), (value, field) => {
          if (field?.parent?.fiscalCodeQuestion === 'yes_id') {
            return (
              checkFiscalCode(field?.parent?.name, field?.parent?.surname, value || '') || false
            )
          }
          return true
        }),
      consentPrivacy: yupBool
        .required(t('commons.consent_required'))
        .oneOf([true], t('commons.consent_required')),
      consentMarketing: yupBool,
      consentProfiling: yupBool,
      consentCommunication: yupBool,
    }),
  })

  const stepGroup3 = Yup.object().shape({
    mainInsured: Yup.object().shape({
      informativeVision: yupBoolRequired,
      consentTd: yupBoolRequired,
    }),
  })

  const stepGroup4 = Yup.object()

  return [Yup.object(), Yup.object(), stepGroup2, stepGroup3, stepGroup4]
}

export const useValidationSchemasGroup1 = () => {
  const { t } = useTranslation()

  return Yup.object().shape({
    name: yupStrRequired.matches(regexAccents, t('commons.error_is_wrong')),
    surname: yupStrRequired.matches(regexAccents, t('commons.error_is_wrong')),
    nationality: yupStrRequired,
    fiscalCodeQuestion: yupStrRequired,
    birthDate: yupBirthDate
      .when('fiscalCodeQuestion', (item, schema) => {
        return item === 'no_id' ? schema.required(t('commons.required')) : schema
      })
      .test('check_date', t('data_entry_step_2.error_date'), (value) => {
        return compareDates(new Date(reverseDate(value)), new Date()) <= 0
      })
      .test('check_validation', t('data_entry_step_2.error_date'), (value, field) => {
        return field.schema.exclusiveTests.required === true
          ? isValidDate(reverseDate(value))
          : true
      }),
    birthNation: yupStr
      .when('fiscalCodeQuestion', (item, schema) => {
        return item === 'no_id' ? schema.required(t('commons.required')) : schema
      })
      .matches(regexAccents, t('commons.error_is_wrong')),
    birthPlace: yupStr
      .when('fiscalCodeQuestion', (item, schema) => {
        return item === 'no_id' ? schema.required(t('commons.required')) : schema
      })
      .matches(regexAccents, t('commons.error_is_wrong')),
    gender: yupStr.when('fiscalCodeQuestion', (item, schema) => {
      return item === 'no_id' ? schema.required(t('commons.required')) : schema
    }),
    fiscalCode: yupStr
      .when('fiscalCodeQuestion', (item, schema) => {
        return item === 'yes_id' ? schema.required(t('commons.required')) : schema
      })
      .matches(regexTaxCode, t('commons.error_is_wrong'))
      .test('check_validation', t('commons.fiscal_code_mismatch'), (value, field) => {
        if (field?.parent?.fiscalCodeQuestion === 'yes_id') {
          return checkFiscalCode(field?.parent?.name, field?.parent?.surname, value || '') || false
        }
        return true
      }),
  })
}
