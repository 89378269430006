import { useTranslation } from 'react-i18next'
import { useRecoilValue } from 'recoil'
import { lowerLanguage } from 'src/i18n'
import { selectorDocuments } from 'src/storage'

export const useAccessibility = () => {
  const { t } = useTranslation()
  const documents = useRecoilValue(selectorDocuments)
  const accessibilityLink = documents?.find(({ id }) => id === '9')?.file?.[lowerLanguage]

  return { t, accessibilityLink }
}
