import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useRecoilState, useRecoilValue } from 'recoil'
import { Paths } from 'src/navigation'
import { atomClaimData, atomClaimQuote, atomProgressBar } from 'src/storage'
import { yupBoolRequired } from 'src/utils'
import * as Yup from 'yup'

import { PROGRESS_BAR_STEP } from '../../progress-bar-step'

export const UseClaimReportWeatherDeclaration = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const claimQuote = useRecoilValue(atomClaimQuote)

  const [claimData, setClaimData] = useRecoilState(atomClaimData)
  const [progressBar, setProgressBar] = useRecoilState(atomProgressBar)

  const [initialValues] = useState<{ isDeclarationChecked: boolean }>({
    isDeclarationChecked: claimData?.weather?.isDeclarationChecked || false,
  })

  const validationSchema = Yup.object().shape({
    isDeclarationChecked: yupBoolRequired,
  })

  //////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    setTimeout(() => {
      setProgressBar(PROGRESS_BAR_STEP.claim_report_weather_declaration)
    }, 100)
  }, [])

  useEffect(() => {
    if (!claimQuote) {
      navigate(Paths.ClaimReport)
    }
  }, [claimQuote])

  //////////////////////////////////////////////////////////////////////////////

  const formik = useFormik<{ isDeclarationChecked: boolean }>({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (claimQuote?.assets?.length === 0) {
        setClaimData({
          ...claimData,
          weather: {
            ...claimData?.weather,
            isDeclarationChecked: values?.isDeclarationChecked,
            insuredType: 'contractor',
          },
        })
        navigate(Paths.ClaimReportWeatherInformation)
      } else {
        setClaimData({
          ...claimData,
          weather: {
            ...claimData?.weather,
            isDeclarationChecked: values?.isDeclarationChecked,
          },
        })
        navigate(Paths.ClaimReportWeatherInsuredPerson)
      }
    },
  })

  //////////////////////////////////////////////////////////////////////////////

  const goBack = () => {
    navigate(Paths.ClaimReportType)
    setClaimData({
      ...claimData,
      weather: {},
    })
  }

  return {
    t,
    progressBar,
    formik,
    validationSchema,
    goBack,
  }
}
