import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { lowerLanguage } from 'src/i18n'
import { Paths } from 'src/navigation'
import { usePostGetInjuriesClaim } from 'src/networking/terminals/claim/claim.gates'
import { atomClaimBackPath, atomClaimData, atomClaimQuote, atomProgressBar } from 'src/storage'
import {
  ClaimReportInjuryStep2Sections,
  FormikClaimReportInjuryStep2,
  InputRadioOption,
  ReactSelectMultilanguageOption,
  ReactSelectOption,
} from 'src/types'
import { yupArrayRequired, yupStrRequired } from 'src/utils'
import * as Yup from 'yup'

import { PROGRESS_BAR_STEP } from '../../../progress-bar-step'

export const UseClaimReportRcInjuryReportInjuryStep2 = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const optionExtentDamage: InputRadioOption[] = [
    {
      id: 'yes_id',
      value: t('claim_report_rc_injury_report_injury_step2.extent_damage_yes'),
    },
    {
      id: 'no_id',
      value: t('claim_report_rc_injury_report_injury_step2.extent_damage_no'),
    },
  ]

  const [optionNatures, setOptionNatures] = useState<ReactSelectOption[]>([])

  const [optionBodyParts, setOptionBodyParts] = useState<
    Record<string, ReactSelectMultilanguageOption[]>
  >({})

  const claimQuote = useRecoilValue(atomClaimQuote)
  const setClaimBackPath = useSetRecoilState(atomClaimBackPath)
  const [claimData, setClaimData] = useRecoilState(atomClaimData)
  const [progressBar, setProgressBar] = useRecoilState(atomProgressBar)

  const [showExceedOptions, setShowExceedOptions] = useState(false)
  const [isInjurySectionsEmpty, setIsInjurySectionsEmpty] = useState(true)

  const { pending: pendingGetInjuriesClaim, crossGate: postGetInjuriesClaim } =
    usePostGetInjuriesClaim()

  const getInjurySections = () => {
    const sections_: ClaimReportInjuryStep2Sections[] = []
    if (
      claimData?.other?.request?.injury?.injurySections &&
      claimData?.other?.request?.injury?.injurySections?.length > 0
    ) {
      claimData?.other?.request?.injury?.injurySections?.map((item) => {
        sections_.push({
          nature: item?.nature,
          bodyParts: item?.bodyParts || [],
          optionBodyParts: item?.optionBodyParts || [],
          showMenu: item?.showMenu || false,
          showAccordion: item?.showAccordion || false,
        })
      })
    } else {
      sections_.push({
        nature: undefined,
        bodyParts: [],
        optionBodyParts: [],
        showMenu: false,
        showAccordion: false,
      })
    }

    return sections_
  }

  const [initialValues] = useState<FormikClaimReportInjuryStep2>({
    extentDamage: claimData?.other?.request?.injury?.extentDamage || '',
    damageDescription: claimData?.other?.request?.injury?.damageDescription || '',
    injurySections: getInjurySections() || [],
  })

  const validationSchema = Yup.object().shape({
    extentDamage: yupStrRequired,
    damageDescription: yupStrRequired,
    injurySections: yupArrayRequired,
  })

  const [bodyPartsSelected, setBodyPartsSelected] = useState<ReactSelectOption[]>([])

  //////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    setTimeout(() => {
      setProgressBar(PROGRESS_BAR_STEP.claim_report_rc_injury_report_injury)
    }, 100)
  }, [])

  useEffect(() => {
    postGetInjuriesClaim(
      {},
      {
        onSuccess: (response) => {
          if (response) {
            const nature_: ReactSelectOption[] = []

            response?.nature?.map((item) =>
              nature_?.push({
                value: item?.value,
                label: item?.language[lowerLanguage],
              })
            )
            setOptionNatures(nature_)
            // setOptionBodyParts
            setOptionBodyParts(response?.injuries || {})
          }
        },
        onFailure: () => {
          navigate(Paths.ClaimReportError)
        },
        onPending: () => {},
      }
    )
  }, [])

  useEffect(() => {
    if (!claimQuote) {
      navigate(Paths.ClaimReport)
    }
  }, [claimQuote])

  //////////////////////////////////////////////////////////////////////////////

  const formik = useFormik<FormikClaimReportInjuryStep2>({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setClaimData({
        ...claimData,
        other: {
          ...claimData?.other,
          request: {
            ...claimData?.other?.request,
            injury: {
              ...claimData?.other?.request?.injury,
              damageDescription: values?.damageDescription,
              extentDamage: values?.extentDamage,
              injurySections: values?.injurySections,
            },
          },
        },
      })

      setClaimBackPath(Paths.ClaimReportRcInjuryReportInjuryStep2)
      navigate(Paths.ClaimReportOpen)
    },
  })

  useEffect(() => {
    if (formik.values?.injurySections) {
      const injuries_ = formik.values?.injurySections?.filter(
        (injury) => !injury?.nature || injury?.bodyParts?.length === 0
      )
      setIsInjurySectionsEmpty(injuries_?.length > 0)
    }
  }, [formik])

  //////////////////////////////////////////////////////////////////////////////

  const goBack = () => {
    navigate(Paths.ClaimReportRcInjuryReportInjuryStep1)
  }

  const handleSelectChange = (e: ReactSelectOption[]) => {
    setBodyPartsSelected(e)
  }

  const showMore = (index: string) => {
    formik.setFieldValue(`injurySections.${index}.showAccordion`, false)
  }

  const showLess = (index: string) => {
    formik.setFieldValue(`injurySections.${index}.showAccordion`, true)
  }

  return {
    t,
    formik,
    validationSchema,
    isInjurySectionsEmpty,
    optionExtentDamage,
    optionNatures,
    optionBodyParts,
    bodyPartsSelected,
    showExceedOptions,
    progressBar,
    pendingGetInjuriesClaim,
    goBack,
    showMore,
    showLess,
    setShowExceedOptions,
    handleSelectChange,
  }
}
