import { useEffect, useState } from 'react'
import { InputDateProps } from 'src/types'

import { easyInsertion, toDate, toDateString } from './input-date.utils'

export const useInputDate = ({
  name,
  value: datestr = '',
  setFieldValue,
  setFieldTouched,
  closePickerTrigger,
}: InputDateProps) => {
  const [days, setDays] = useState<Date[]>([])
  const [expandedPicker, setExpandedPicker] = useState(false)
  const [currentDate, setCurrentDate] = useState(new Date())

  const fullYear = currentDate.getFullYear()
  const fullMonth = currentDate.getMonth()

  const firstDayCurrMonth = new Date(fullYear, fullMonth, 1)
  const firstDayNextMonth = new Date(fullYear, fullMonth + 1, 1)

  const dayOfWeek = (firstDayCurrMonth.getDay() + 6) % 7

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    setExpandedPicker(false)
  }, [closePickerTrigger])

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    const days_ = []
    for (const day = firstDayCurrMonth; day < firstDayNextMonth; day.setDate(day.getDate() + 1)) {
      days_.push(new Date(day))
    }
    setDays(days_)
  }, [currentDate])

  useEffect(() => {
    setFieldValue?.(name, datestr)
    updatePicker()
    if (datestr && datestr.length >= 10) {
      setFieldTouched?.(name, true)
    }
  }, [datestr])

  useEffect(() => {
    setFieldValue?.(name, easyInsertion(datestr))
  }, [datestr])

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  const updateCurrentDate = (newDate: Date) => {
    if (!isNaN(newDate.getTime())) {
      setCurrentDate(newDate)
    }
  }

  const updatePicker = () => {
    if (datestr) {
      updateCurrentDate(toDate(datestr))
    }
  }

  const updateDate = (date: Date) => {
    setFieldValue?.(name, toDateString(date))
    updatePicker()
    setExpandedPicker(false)
  }

  const togglePicker = () => {
    setExpandedPicker((x) => !x)
    updatePicker()
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  const prevYear = () => updateCurrentDate(new Date(currentDate.setFullYear(fullYear - 1)))
  const nextYear = () => updateCurrentDate(new Date(currentDate.setFullYear(fullYear + 1)))

  const prevTenYear = () => updateCurrentDate(new Date(currentDate.setFullYear(fullYear - 10)))
  const nextTenYear = () => updateCurrentDate(new Date(currentDate.setFullYear(fullYear + 10)))

  const prevMonth = () => updateCurrentDate(new Date(currentDate.setMonth(fullMonth - 1)))
  const nextMonth = () => updateCurrentDate(new Date(currentDate.setMonth(fullMonth + 1)))

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  return {
    days,
    expandedPicker,
    currentDate,
    fullYear,
    fullMonth,
    dayOfWeek,
    updateDate,
    togglePicker,
    prevYear,
    nextYear,
    prevTenYear,
    nextTenYear,
    prevMonth,
    nextMonth,
  }
}
