import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useRecoilState, useRecoilValue } from 'recoil'
import { MAX_FILE, MAX_FILE_MB } from 'src/config'
import { Paths } from 'src/navigation'
import { usePostUploadFileClaim } from 'src/networking/terminals/claim/claim.gates'
import { regexPhone } from 'src/regex'
import { atomClaimBackPath, atomClaimData, atomClaimQuote, atomProgressBar } from 'src/storage'
import { FormikClaimOpen, InputRadioOption } from 'src/types'
import { ClaimFile, ClaimOpenContact } from 'src/types/pages/claim.types'
import { deepCopy, yupDate, yupEmail, yupStr, yupStrRequired } from 'src/utils'
import * as Yup from 'yup'

import { PROGRESS_BAR_STEP } from '../../progress-bar-step'

// eslint-disable-next-line complexity
export const UseClaimReportOpen = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const inputFileAccept = '.pdf,.jpg,.png,.tif'

  const genderOptions: InputRadioOption[] = [
    {
      id: 'male',
      value: t('claim_report_open.male'),
    },
    {
      id: 'female',
      value: t('claim_report_open.female'),
    },
  ]

  const nationalityOptions: InputRadioOption[] = [
    {
      id: 'IT',
      value: t('claim_report_open.nationality_it'),
    },
    {
      id: 'EXT',
      value: t('claim_report_open.nationality_foreign'),
    },
  ]

  const claimQuote = useRecoilValue(atomClaimQuote)

  const claimBackPath = useRecoilValue(atomClaimBackPath)
  const [claimData, setClaimData] = useRecoilState(atomClaimData)
  const [progressBar, setProgressBar] = useRecoilState(atomProgressBar)
  const { crossGate: postUploadFileClaim } = usePostUploadFileClaim()

  const [updateInsured, setUpdateInsured] = useState(false)
  const [isMobile, setIsMobile] = useState(window.innerWidth < 810)
  const [showUploadFileModal, setShowUploadFileModal] = useState(false)
  const [isOutOfSizeFile, setIsOutOfSizeFile] = useState(false)
  const [loadingFile, setLoadingFile] = useState(false)

  const [insuredOptions, setInsuredOptions] = useState<InputRadioOption[]>()

  const getResidence = (): string => {
    if (claimData?.selectedType === 'other') {
      return claimData?.other?.personalData?.residence || ''
    }
    return claimData?.openInformations?.residence || ''
  }

  const [initialValues, setInitialValues] = useState<FormikClaimOpen>({
    insuredType: claimData?.openInformations?.insuredType || '',
    name: claimData?.openInformations?.name || '',
    surname: claimData?.openInformations?.surname || '',
    nationality: claimData?.openInformations?.nationality || '',
    birth: claimData?.openInformations?.birth || '',
    birthNation: claimData?.openInformations?.birthNation || '',
    birthPlace: claimData?.openInformations?.birthPlace || '',
    gender: claimData?.openInformations?.gender || '',
    fiscalCode: claimData?.openInformations?.fiscalCode || '',
    residence: getResidence() || '',
    contact: claimData?.openInformations?.contact?.type || '',
    contactMobile: claimData?.openInformations?.contact?.mobileValue || '',
    contactEmail: claimData?.openInformations?.contact?.emailValue || '',
    contactConfirmEmail: claimData?.openInformations?.contact?.confirmEmailValue || '',
    otherInformation: claimData?.openInformations?.otherInformation || '',
    optionalFiles: claimData?.openInformations?.optionalFiles || {},
  })

  const [timeSlots, setTimeSlot] = useState(
    claimData?.openInformations?.contact?.timeSlots || [
      [
        { value: '8:00 - 9:00', isSelected: false },
        { value: '10:00 - 11:00', isSelected: false },
        { value: '11:00 - 12:00', isSelected: false },
      ],
      [
        { value: '12:00 - 13:00', isSelected: false },
        { value: '13:00 - 14:00', isSelected: false },
        { value: '15:00 - 16:00', isSelected: false },
      ],
      [
        { value: '16:00 - 17:00', isSelected: false },
        { value: '17:00 - 18:00', isSelected: false },
        { value: '18:00 - 19:00', isSelected: false },
      ],
    ]
  )

  const validationSchema = Yup.object().shape({
    insuredType: yupStrRequired,
    name: yupStrRequired,
    surname: yupStrRequired,
    nationality: yupStrRequired,
    birth: yupDate.when('nationality', (item, schema) => {
      return item === 'EXT' ? schema.required(t('commons.required')) : schema
    }),
    birthNation: yupStr.when('nationality', (item, schema) => {
      return item === 'EXT' ? schema.required(t('commons.required')) : schema
    }),
    birthPlace: yupStr.when('nationality', (item, schema) => {
      return item === 'EXT' ? schema.required(t('commons.required')) : schema
    }),
    gender: yupStr.when('nationality', (item, schema) => {
      return item === 'EXT' ? schema.required(t('commons.required')) : schema
    }),
    fiscalCode: yupStr.when('nationality', (item, schema) => {
      return item === 'IT' ? schema.required(t('commons.required')) : schema
    }),
    residence: yupStrRequired,
    contact: yupStrRequired,
    contactMobile: yupStr.when('contact', (item, schema) => {
      return item === 'mobile'
        ? schema.matches(regexPhone, t('commons.error_is_wrong')).required(t('commons.required'))
        : schema
    }),
    contactEmail: yupEmail.when('contact', (item, schema) => {
      return item === 'email' ? schema.required(t('commons.required')) : schema
    }),
    contactConfirmEmail: yupEmail
      .when('contact', (item, schema) => {
        return item === 'email' ? schema.required(t('commons.required')) : schema
      })
      .test('contactEmail', t('claim_report_open.error_equal_email'), (value, field) => {
        return field.parent.contactEmail?.toLowerCase()?.trim() === value?.toLowerCase()?.trim()
      }),
    otherInformation: yupStr,
  })

  //////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    setTimeout(() => {
      setProgressBar(PROGRESS_BAR_STEP.claim_report_open)
    }, 100)
  }, [])

  useEffect(() => {
    let initialValues_ = initialValues

    const isMainContractor = claimQuote?.assets?.length === 0

    if (
      !claimData?.openInformations?.name &&
      !claimData?.openInformations?.surname &&
      isMainContractor
    ) {
      initialValues_ = {
        ...initialValues,
        insuredType: 'contractor',
        name: claimQuote?.contractor?.name || '',
        surname: claimQuote?.contractor?.surname || '',
        nationality: claimQuote?.contractor?.nationality || '',
        fiscalCode: claimQuote?.contractor?.fiscalCode || '',
        residence: getResidence(),
      }
    }

    setInitialValues(initialValues_)
  }, [])

  useEffect(() => {
    // eslint-disable-next-line no-negated-condition
    if (!claimQuote) {
      navigate(Paths.ClaimReport)
    } else {
      let assets: InputRadioOption[] = []

      let deathPerson = ''

      if (
        claimData?.other?.request?.injury?.type &&
        claimData?.other?.request?.injury?.type === '0'
      ) {
        deathPerson = claimData?.other?.insuredType || ''
      }

      assets?.push({
        id: 'contractor',
        value: `${claimQuote?.contractor?.surname || ''} ${claimQuote?.contractor?.name || ''}`,
      })

      claimQuote?.assets?.map((asset, index) => {
        assets?.push({
          id: `asset_${index}`,
          value: `${asset?.surname} ${asset?.name}`,
        })
      })

      assets?.push({
        id: 'third_person',
        value: t('claim_report_open.third_person'),
      })

      if (deathPerson !== '') {
        const filteredAssets = assets?.filter((item) => item?.id !== deathPerson)
        assets = filteredAssets
      }

      setInsuredOptions(assets)
    }
  }, [claimQuote])

  useEffect(() => {
    window.addEventListener(
      'resize',
      () => {
        const ismobile = window.innerWidth < 810
        if (ismobile !== isMobile) setIsMobile(ismobile)
      },
      false
    )
  }, [isMobile])

  //////////////////////////////////////////////////////////////////////////////

  const formik = useFormik<FormikClaimOpen>({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    // eslint-disable-next-line complexity
    onSubmit: async (values) => {
      const contact_: ClaimOpenContact = {
        type: values?.contact,
        mobileValue: values?.contact === 'mobile' ? values?.contactMobile : undefined,
        timeSlots: values?.contact === 'mobile' ? timeSlots : undefined,
        emailValue:
          values?.contact === 'email'
            ? values?.contactEmail
            : claimQuote?.contractor?.mainContact?.value
            ? claimQuote?.contractor?.mainContact?.value
            : undefined,
        confirmEmailValue: values?.contact === 'email' ? values?.contactConfirmEmail : undefined,
      }

      let optionalFiles_ = values?.optionalFiles || {}
      let continue_ = false

      if (Object?.keys(optionalFiles_)?.length > 0) {
        setLoadingFile(true)
        await Promise.all(
          Object?.entries(optionalFiles_)?.map(async ([key, file]) => {
            await postUploadFileClaim(
              {
                body: {
                  shopId: claimQuote?.contractId || '',
                  ...file,
                },
              },
              {
                onSuccess: (response) => {
                  if (response?.fileName) {
                    optionalFiles_ = {
                      ...optionalFiles_,
                      [key]: {
                        ...optionalFiles_[key],
                        reference: response?.fileName,
                      },
                    }
                  }
                  continue_ = true
                },
                onFailure: () => {
                  setLoadingFile(false)
                  navigate(Paths.ClaimReportError)
                },
                onPending: () => {},
              }
            )
          })
        )
        setLoadingFile(false)
      } else {
        continue_ = true
      }

      if (continue_) {
        setClaimData({
          ...claimData,
          openInformations: {
            ...claimData?.openInformations,
            insuredType: claimQuote?.assets?.length === 0 ? 'contractor' : values?.insuredType,
            name: values?.name,
            surname: values?.surname,
            fiscalCode: values?.fiscalCode,
            nationality: values?.nationality,
            birth: values?.birth,
            birthNation: values?.birthNation,
            birthPlace: values?.birthPlace,
            gender: values?.gender,
            residence: values?.residence,
            contact: contact_,
            otherInformation: values?.otherInformation || '',
            optionalFiles: optionalFiles_,
          },
        })

        navigate(Paths?.ClaimOpenSummary)
      }
    },
  })

  // eslint-disable-next-line complexity
  useEffect(() => {
    if (updateInsured) {
      setUpdateInsured(false)
      let initialValues_ = formik?.values

      if (formik?.values?.insuredType === 'contractor') {
        initialValues_ = {
          ...formik?.values,
          name: claimQuote?.contractor?.name || '',
          surname: claimQuote?.contractor?.surname || '',
          nationality: claimQuote?.contractor?.nationality || '',
          fiscalCode: claimQuote?.contractor?.fiscalCode || '',
          birth: claimQuote?.contractor?.birthDate || '',
          birthNation: claimQuote?.contractor?.birthNation || '',
          birthPlace: claimQuote?.contractor?.birthPlace || '',
          gender: claimQuote?.contractor?.gender || '',
          residence: '',
          contactMobile:
            claimQuote?.contractor?.otherContacts?.find((contact) => contact?.type === 'mobile')
              ?.value || '',
          contactEmail: claimQuote?.contractor?.mainContact?.value || '',
          contactConfirmEmail: claimQuote?.contractor?.mainContact?.value || '',
        }
      } else {
        const asset = formik?.values?.insuredType?.split('_')[1]

        if (asset) {
          const insured_ = claimQuote?.assets?.[Number(asset)]

          initialValues_ = {
            ...formik?.values,
            name: insured_?.name || '',
            surname: insured_?.surname || '',
            nationality: insured_?.nationality || '',
            fiscalCode: insured_?.fiscalCode || '',
            birth: insured_?.birthDate || '',
            birthNation: insured_?.birthNation || '',
            birthPlace: insured_?.birthPlace || '',
            gender: insured_?.gender || '',
            residence: '',
            contactMobile: '',
            contactEmail: '',
            contactConfirmEmail: '',
          }
        }
      }
      setInitialValues(initialValues_)
    }
  }, [formik?.values?.insuredType, updateInsured])

  useEffect(() => {
    if (formik?.values?.contact) {
      if (formik?.values?.insuredType === 'contractor') {
        if (formik?.values?.contact === 'mobile') {
          const mobile_ =
            claimQuote?.contractor?.otherContacts?.find((contact) => contact?.type === 'mobile')
              ?.value || ''

          setInitialValues({
            ...formik?.values,
            contact: formik?.values?.contact,
            contactMobile: mobile_,
            contactEmail: '',
            contactConfirmEmail: '',
          })
        } else if (formik?.values?.contact === 'email') {
          const email_ = claimQuote?.contractor?.mainContact?.value || ''
          setInitialValues({
            ...formik?.values,
            contact: formik?.values?.contact,
            contactMobile: '',
            contactEmail: email_,
            contactConfirmEmail: email_,
          })
        }
      }
    }
  }, [formik?.values?.contact])

  //////////////////////////////////////////////////////////////////////////////

  const selectTimeSlot = (rowIndex: number, colIndex: number) => {
    if (timeSlots) {
      const newTimeSlots = deepCopy(timeSlots)
      newTimeSlots[rowIndex][colIndex].isSelected = !newTimeSlots[rowIndex][colIndex]?.isSelected
      setTimeSlot(newTimeSlots)
    }
  }

  const formatFile = async (file: File): Promise<Record<string, ClaimFile>> => {
    const formattedFile: Record<string, ClaimFile> = await new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = async () => {
        resolve({
          [`${file?.name?.replaceAll(' ', '')}`]: {
            name: file?.name || '',
            size: file?.size || 0,
            data: reader.result?.toString() || '',
            type: file?.type || '',
            lastModified: file?.lastModified || 0,
          },
        })
      }
      reader.onerror = reject
      reader.readAsDataURL(file)
    })

    return formattedFile
  }

  const uploadDocuments = async (files: FileList) => {
    let optionalFiles_ = initialValues?.optionalFiles || {}

    const isOutDimensions = Object?.entries(files)?.find(
      // eslint-disable-next-line no-unused-vars
      ([_, file]) => file.size / MAX_FILE > MAX_FILE_MB
    )

    // eslint-disable-next-line no-negated-condition
    if (!isOutDimensions) {
      setShowUploadFileModal(false)
      await Promise.all(
        // eslint-disable-next-line no-unused-vars
        Object?.entries(files)?.map(async ([_, file]) => {
          const newFileKey = file?.name?.replaceAll(' ', '')
          const check = Object.keys(optionalFiles_)?.find((fileKey) => fileKey === newFileKey)

          if (!check && file) {
            const file_ = await formatFile(file)
            optionalFiles_ = {
              ...optionalFiles_,
              ...file_,
            }
          }
        })
      )

      setInitialValues((currentClaimFiles) => ({
        ...currentClaimFiles,
        ...formik?.values,
        optionalFiles: optionalFiles_,
      }))
    } else {
      setIsOutOfSizeFile(true)
      setShowUploadFileModal(false)
    }
  }

  const deleteDocument = (key: string) => {
    let optionalFiles_: Record<string, ClaimFile> = {}
    if (Object.keys(initialValues?.optionalFiles)?.length > 0) {
      Object.entries(initialValues?.optionalFiles)?.map(([fileKey, fileValue]) => {
        if (fileKey !== key) {
          optionalFiles_ = {
            ...optionalFiles_,
            [fileKey?.replaceAll(' ', '')]: fileValue,
          }
        }
      })
    }

    setInitialValues((currentClaimFiles) => ({
      ...currentClaimFiles,
      optionalFiles: { ...optionalFiles_ },
    }))
  }

  const goBack = () => {
    if (claimBackPath) {
      navigate(claimBackPath)
    } else {
      navigate(-1)
    }
  }

  return {
    t,
    progressBar,
    formik,
    validationSchema,
    isMobile,
    timeSlots,
    claimQuote,
    genderOptions,
    insuredOptions,
    nationalityOptions,
    inputFileAccept,
    isOutOfSizeFile,
    showUploadFileModal,
    loadingFile,
    selectTimeSlot,
    deleteDocument,
    uploadDocuments,
    setUpdateInsured,
    setIsOutOfSizeFile,
    setShowUploadFileModal,
    goBack,
  }
}
