import './section3.scss'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Paths } from 'src/navigation'

export const useSection3 = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  /**
   * Functions
   */
  const toInsertPage = () => {
    navigate({
      pathname: Paths.DataEntry1,
      search: `?${new URLSearchParams({
        landing: 'true',
        base: 'true',
        seasonal: 'false',
      }).toString()}`,
    })
  }

  return {
    t,
    toInsertPage,
  }
}
