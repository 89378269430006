import './input-text.scss'

import { Image } from 'react-bootstrap'
import { ValidIcon } from 'src/assets'
import { InputTextProps, typedMemo } from 'src/types'
import { formatLabel } from 'src/utils'

import { useInputText } from './input-text.hook'

export const InputText = typedMemo((props: InputTextProps) => {
  const {
    name,
    label,
    validationSchema,
    error,
    value,
    readOnly,
    handleChange,
    handleBlur,
    textarea,
    warning,
    icon,
    iconValid = ValidIcon,
    datalist,
    classes = '',
    onPaste,
    onCopy,
  } = props

  const {
    onPressEnabledIcon,
    allClassesLabel,
    allClassesField,
    allClassesError,
    allClassesWarning,
    allClassesIcon,
    inputProps,
  } = useInputText(props)

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  const Label = (
    <label htmlFor={name} className={allClassesLabel}>
      {formatLabel(validationSchema, name, label)}
    </label>
  )

  const InputNormal = (
    <input
      list={`${name}-datalist`}
      onChange={handleChange}
      onBlur={handleBlur}
      onPaste={onPaste}
      onCopy={onCopy}
      readOnly={readOnly}
      {...inputProps}
    />
  )

  const InputTextArea = <textarea {...inputProps} onChange={handleChange} />

  const Field = (
    <div className={allClassesField}>
      {textarea ? InputTextArea : InputNormal}
      {icon && (
        <Image width={36} src={icon} className={allClassesIcon} onClick={onPressEnabledIcon} />
      )}
      {iconValid && !error && (value || value?.toString() === '0') && (
        <Image width={36} src={iconValid} className="input-text--icon-valid" />
      )}
    </div>
  )

  const Error = <p className={allClassesError}>{`${error} `}</p>

  const Warning = <p className={allClassesWarning}>{`${warning} `}</p>

  const Datalist = (
    <datalist id={`${name}-datalist`}>
      {datalist?.map((data, index) => (
        <option key={index} value={data} />
      ))}
    </datalist>
  )

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  return (
    <main id={`input-${name}`} className={classes}>
      <div className="d-flex flex-column">
        {Label}
        {Field}
        {warning ? Warning : Error}
        {datalist && Datalist}
      </div>
    </main>
  )
})
