import { Loader } from 'src/ui/widgets'

import { useClaimOpening } from './claim-opening.hook'

export const ClaimOpening = () => {
  const { t, pendingOpeningClaim } = useClaimOpening()

  return (
    <div className="col-12 font-27 mx-auto my-auto text-center white-space-pre p-4">
      <div className="mb-3 font-27">{t('claim_report_opening.title')}</div>
      {pendingOpeningClaim && (
        <div className="d-flex justify-content-center mb-3">
          <Loader />
        </div>
      )}
    </div>
  )
}
