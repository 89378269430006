import { useState } from 'react'
import { useRecoilValue } from 'recoil'
import { atomClaimQuote } from 'src/storage'

export const useClaimReport = () => {
  const claimQuote = useRecoilValue(atomClaimQuote)
  const [showClaimSummary, setShowClaimSummary] = useState(!!claimQuote || false)

  return {
    showClaimSummary,
    setShowClaimSummary,
  }
}
