/* eslint-disable simple-import-sort/imports */
import { Base64 } from 'js-base64'
import { FiscalCode, Optional } from 'src/types'

import * as FiscalCodeUtils from '@marketto/codice-fiscale-utils'

const { Parser, Validator } = FiscalCodeUtils

export const isValorized = <T>(thing: T) => thing !== undefined && thing !== null

export const isString = (value: unknown) => typeof value === 'string' || value instanceof String

export const isVoidObject = <T extends object>(object: T) => {
  return Object.keys(object).length === 0
}

export const deepCopy = <T>(thing: T): T => {
  try {
    return JSON.parse(JSON.stringify(thing))
  } catch (e) {
    return thing
  }
}

export const deepCompare = <T>(thing1: T, thing2: T) => {
  try {
    return JSON.stringify(thing1) === JSON.stringify(thing2)
  } catch (e) {
    return false
  }
}

export const deleteKey = <T extends Record<string, unknown>>(record: T, key = ''): T => {
  const recordCopy = deepCopy(record)
  delete recordCopy?.[key]
  return recordCopy
}

export const deleteEmptyKeys = <T extends Record<string, unknown>>(record: T): T => {
  const recordCopy = deepCopy(record)
  Object.entries(recordCopy).forEach(([key, value]) => {
    if (value === null || value === undefined || value === '') {
      delete recordCopy?.[key]
    }
  })
  return recordCopy
}

export const delay = (ms: number) => new Promise((res) => setTimeout(res, ms))

export const split = (value: string, chunkSize: number) => {
  return value.match(new RegExp(`.{1,${chunkSize}}`, 'g'))
}
export const range = (start: number, end: number) => {
  return Array.from({ length: end - start + 1 }, (_, i) => i)
}

export const getQueryParams = (url = '') => {
  return new URLSearchParams(
    url
      ?.split('?')
      ?.filter((_, i) => i > 0)
      ?.join('?')
  )
}

export const concatClasses = (...classes: Optional<string>[]) => {
  return classes?.filter((x) => x)?.join(' ')
}

export const formatPrice = (price: number) => {
  return !isNaN(price) && price > 0 ? `${price.toFixed(2).replace('.', ',')} €` : '0,00 €'
}

export const checkFiscalCode = (name: string, surname: string, fiscalCode: string): boolean => {
  return (
    (Validator.codiceFiscale(fiscalCode).matchFirstName(name?.replaceAll(' ', '')) &&
      Validator.codiceFiscale(fiscalCode).matchLastName(surname?.replaceAll(' ', ''))) ||
    false
  )
}

export const generateFiscalCode = ({
  name,
  surname,
  birthDate,
  gender,
  birthPlace,
}: FiscalCode): string => {
  return (
    Parser.encodeCf({
      lastName: surname?.trim(),
      firstName: name?.trim(),
      date: new Date(birthDate?.split('/')?.reverse()?.join('/')),
      gender: gender,
      place: birthPlace,
    }) || ''
  )
}

export const getDateFromFiscalCode = (fiscalCode: string) => {
  const date = new Date(Parser.cfDecode(fiscalCode)?.date || '')
  const day = String(date.getDate()).padStart(2, '0')
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const year = date.getFullYear()

  return `${year}/${month}/${day}`
}

export const fromBase64ToBlob = (base64String: string) => {
  const base64WithoutPrefix = base64String?.replace('data:application/pdf;base64,', '')

  const bytes = Base64.atob(base64WithoutPrefix || '')
  let length = bytes.length
  const out = new Uint8Array(length)

  while (length--) {
    out[length] = bytes.charCodeAt(length)
  }

  const blob = new Blob([out], { type: 'application/pdf' })

  return blob
}

export function capitalizeCase(str: string) {
  if (str.length === 0) return str
  const firstLetter = str.charAt(0).toUpperCase()
  const restOfWord = str.slice(1).toLowerCase()
  return firstLetter + restOfWord
}
