import { Image } from 'react-component-library'
import { ConfirmedIcon } from 'src/assets'

import { useClaimIsOpen } from './claim-is-open.hook'

export const ClaimIsOpen = () => {
  const { t, name, contact } = useClaimIsOpen()

  return (
    <div className="mb-md-5 pb-md-5 ps-md-3 pe-md-3 d-md-flex justify-content-md-center container-fluid">
      <div className="col-12 col-lg-7">
        <div className="mt-4">
          <div className="mb-4 p-4 ps-3 pe-3 p-md-5 bg-ffffff vh-100">
            <div className="mt-5 pt-5 d-flex justify-content-center">
              <Image src={ConfirmedIcon} width={130} height={130} />
            </div>
            <div className="mt-3 p-3">
              <div className="font-34-semibold d-md-flex align-items-center justify-content-center white-space-pre">
                {t('claim_report_is_open.greetings')}
                {name}!
              </div>
              <div className="d-md-flex justify-content-center">
                <div className="mt-4 font-16 col-md-4 text-md-center d-inline-block">
                  <div className="display-contents">{t('claim_report_is_open.subtitle')}</div>
                  {contact?.type === 'mobile' && (
                    <div className="display-contents">
                      {t('claim_report_is_open.contact_mobile')} {contact?.value}
                    </div>
                  )}
                  {contact?.type === 'email' && (
                    <div className="display-contents">
                      {t('claim_report_is_open.contact_email')} {contact?.value}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
