import { useAws } from 'src/networking/aws'
import {
  AwsGateCallbacks,
  AwsGateRequest,
  PostCheckClaimCode,
  PostCreateClaim,
  PostInjuryClaim,
  PostOpenClaim,
  PostSendEmailClaim,
  PostUploadFileClaim,
} from 'src/types'

export const usePostCheckClaimCode = <
  T extends PostCheckClaimCode['request'],
  D extends PostCheckClaimCode['response']
>() => {
  const hook = useAws<T, D>('postCheckClaimCode')
  return {
    ...hook,
    crossGate: (request: AwsGateRequest<T>, callbacks: AwsGateCallbacks<D>) => {
      return hook.crossGate(
        {
          method: 'post',
          path: '/check-code',
          ...request,
        },
        callbacks
      )
    },
  }
}

export const usePostCreateClaim = <
  T extends PostCreateClaim['request'],
  D extends PostCreateClaim['response']
>() => {
  const hook = useAws<T, D>('postCreateClaim')
  return {
    ...hook,
    crossGate: (request: AwsGateRequest<T>, callbacks: AwsGateCallbacks<D>) => {
      return hook.crossGate(
        {
          method: 'post',
          path: '/create-claim',
          ...request,
        },
        callbacks
      )
    },
  }
}

export const usePostOpenClaim = <
  T extends PostOpenClaim['request'],
  D extends PostOpenClaim['response']
>() => {
  const hook = useAws<T, D>('postOpenClaim')
  return {
    ...hook,
    crossGate: (request: AwsGateRequest<T>, callbacks: AwsGateCallbacks<D>) => {
      return hook.crossGate(
        {
          method: 'post',
          path: '/open-claim',
          ...request,
        },
        callbacks
      )
    },
  }
}

export const usePostGetInjuriesClaim = <
  T extends PostInjuryClaim['request'],
  D extends PostInjuryClaim['response']
>() => {
  const hook = useAws<T, D>('postInjuryClaim')
  return {
    ...hook,
    crossGate: (request: AwsGateRequest<T>, callbacks: AwsGateCallbacks<D>) => {
      return hook.crossGate(
        {
          method: 'post',
          path: '/get-injury',
          ...request,
        },
        callbacks
      )
    },
  }
}

export const usePostSendEmailClaim = <
  T extends PostSendEmailClaim['request'],
  D extends PostSendEmailClaim['response']
>() => {
  const hook = useAws<T, D>('postSendEmailClaim')
  return {
    ...hook,
    crossGate: (request: AwsGateRequest<T>, callbacks: AwsGateCallbacks<D>) => {
      return hook.crossGate(
        {
          method: 'post',
          path: '/send-claim-email',
          ...request,
        },
        callbacks
      )
    },
  }
}

export const usePostUploadFileClaim = <
  T extends PostUploadFileClaim['request'],
  D extends PostUploadFileClaim['response']
>() => {
  const hook = useAws<T, D>('postUploadFileClaim')
  return {
    ...hook,
    crossGate: (request: AwsGateRequest<T>, callbacks: AwsGateCallbacks<D>) => {
      return hook.crossGate(
        {
          method: 'post',
          path: '/upload-claim-file',
          ...request,
        },
        callbacks
      )
    },
  }
}
