import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useRecoilState, useRecoilValue } from 'recoil'
import { Paths } from 'src/navigation'
import { atomClaimData, atomClaimQuote, atomProgressBar } from 'src/storage'
import { FormikClaimInsuredPerson, InputRadioOption } from 'src/types'
import { yupStrRequired } from 'src/utils'
import * as Yup from 'yup'

import { PROGRESS_BAR_STEP } from '../../progress-bar-step'

export const UseClaimReportWeatherInsuredPerson = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const claimQuote = useRecoilValue(atomClaimQuote)
  const [progressBar, setProgressBar] = useRecoilState(atomProgressBar)
  const [claimData, setClaimData] = useRecoilState(atomClaimData)

  const [insuredOptions, setInsuredOptions] = useState<InputRadioOption[]>()

  const [initialValues] = useState<FormikClaimInsuredPerson>({
    insured: claimData?.weather?.insuredType || '',
  })

  const validationSchema = Yup.object().shape({
    insured: yupStrRequired,
  })

  //////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    setTimeout(() => {
      setProgressBar(PROGRESS_BAR_STEP.claim_report_weather_insured_person)
    }, 100)
  }, [])

  useEffect(() => {
    // eslint-disable-next-line no-negated-condition
    if (!claimQuote) {
      navigate(Paths.ClaimReport)
    } else {
      const assets: InputRadioOption[] = []

      assets?.push({
        id: 'contractor',
        value: `${claimQuote?.contractor?.surname || ''} ${claimQuote?.contractor?.name || ''}`,
      })

      claimQuote?.assets?.map((asset, index) => {
        assets?.push({
          id: `asset_${index}`,
          value: `${asset?.surname} ${asset?.name}`,
        })
      })

      setInsuredOptions(assets)
    }
  }, [claimQuote])

  //////////////////////////////////////////////////////////////////////////////

  const formik = useFormik<FormikClaimInsuredPerson>({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setClaimData({
        ...claimData,
        weather: {
          ...claimData?.weather,
          insuredType: values?.insured,
        },
      })
      navigate(Paths.ClaimReportWeatherInformation)
    },
  })

  //////////////////////////////////////////////////////////////////////////////

  const goBack = () => {
    navigate(Paths.ClaimReportWeatherDeclaration)
  }

  return {
    t,
    formik,
    insuredOptions,
    validationSchema,
    progressBar,
    goBack,
  }
}
