import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router'
import { useSetRecoilState } from 'recoil'
import { Paths } from 'src/navigation'
import { usePostCheckClaimCode } from 'src/networking/terminals/claim/claim.gates'
import { atomClaimId, atomClaimQuote } from 'src/storage'

export const useClaimReportCode = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const data = new URLSearchParams(useLocation().search).get('id')?.toString() || ''
  const email = new URLSearchParams(useLocation().search).get('email')?.toString() || ''

  const { crossGate: postCheckClaimCode } = usePostCheckClaimCode()
  const saveQuote = useSetRecoilState(atomClaimQuote)
  const saveClaimId = useSetRecoilState(atomClaimId)

  const [code, setCode] = useState('')
  const [error, setError] = useState('')
  const [disabled, setDisabled] = useState(false)
  const [successCode, setSuccessCode] = useState(false)

  //////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (code?.length === 8) {
      setError('')
      setDisabled(true)
      setSuccessCode(false)

      postCheckClaimCode(
        {
          body: {
            code: code,
            data: data,
          },
        },
        {
          onSuccess: (response) => {
            setDisabled(false)
            if (Object?.keys(response)?.length > 0 && response?.quote?.contractId) {
              setSuccessCode(true)
              saveClaimId(response?.claimId)
              saveQuote(response?.quote)
            } else {
              setError('claim_report_code.error_code')
            }
          },
          onFailure: () => {
            setDisabled(false)
            navigate(Paths.ClaimReportError)
          },
          onPending: () => {},
        }
      )
    } else {
      setError('')
    }
  }, [code, data])

  return {
    t,
    error,
    disabled,
    email,
    successCode,
    code,
    setCode,
  }
}
