/* eslint-disable complexity */
import { ChangeEvent } from 'react'
import { Image } from 'react-component-library'
import { AlertCircleIcon, DownloadIcon, EditIcon } from 'src/assets'
import { ScrollTopRoot } from 'src/roots'
import { Formik, typedMemo } from 'src/types'
import { FormikMainInsuredGroup } from 'src/types/networking/terminals/group.types'
import { Button, InputDate, InputRadio, InputText, Modal, Tooltip } from 'src/ui/widgets'
import { InputUploadFile } from 'src/ui/widgets/input-upload-file/input-upload-file'

import { useEntryGroupStep2 } from './entry-group-step2.hook'

export const EntryGroupStep2 = typedMemo(
  ({ formikFather }: { formikFather: Formik<FormikMainInsuredGroup> }) => {
    const {
      t,
      filesGroup,
      formikModal,
      genderOptions,
      isModalVisible,
      validationSchema,
      fcQuestionOptions,
      nationalityOptions,
      hideModal,
      onRemoveFile,
      setFilesGroup,
      onDownloadFile,
      onShowModalEditPerson,
    } = useEntryGroupStep2({ formikFather })

    const { fileNames, filesStatus, group } = formikFather.values

    const {
      values,
      errors,
      touched,
      handleBlur,
      handleChange,
      setFieldValue,
      setFieldTouched,
      handleSubmit,
    } = formikModal

    const Title = (
      <div className="col-12 col-md-6 p-0 px-md-3 pl-md-3 mb-2 mb-md-1 font-20-bold">
        {t('entry_group_step_2.title')}
      </div>
    )

    const UploadInfo = (
      <div className="col-12 col-md-6 p-0 px-md-3 pl-md-3 mb-2 mb-md-1">
        <div className="d-flex align-items-center">
          <div className="font-14-semibold me-4">{t('entry_group_step_2.upload_file_title')}</div>
          <Tooltip placement="top" iconSize={15}>
            <div className="font-14 c-292929 d-flex flex-column align-items-start">
              <div>{t('entry_group_step_2.tooltip_info_file_1')}</div>
              <div>{t('entry_group_step_2.tooltip_info_file_2')}</div>
            </div>
          </Tooltip>
        </div>
        <div className="font-14">{t('entry_group_step_2.upload_file_subtitle')}</div>
        <div className="d-flex align-items-center mt-4">
          <Button
            text={t('entry_group_step_2.download_example_title')}
            iconRight={DownloadIcon}
            iconSize={16}
            flavor="transparentAccent"
            classes="p-0 d-flex align-items-center"
            classesText="font-15-semibold text-underline"
            classesIconRight="ms-2"
            onPress={() => onDownloadFile()}
          />
        </div>
      </div>
    )

    const Upload = (
      <div className="d-flex align-items-center justify-content-center px-md-3 mt-4 mb-md-4">
        <div className="col-md-6">
          <InputUploadFile
            selectedFiles={filesGroup}
            onFilesSelected={setFilesGroup}
            onRemove={onRemoveFile}
            selectedFilesNames={fileNames}
            fileStatus={filesStatus}
            multiple={false}
            showAlert={true}
          />
        </div>
      </div>
    )

    const GroupInfoDesktop = (
      <div className="d-flex align-items-center justify-content-center px-md-3 mt-4 mb-4">
        <div className="col-md-10">
          <div className="d-flex align-items-center">
            <div className="font-14">{t('entry_group_step_2.group_number_1')}</div>
            <div className="font-14-bold ms-2">
              {group?.length}
              {t('entry_group_step_2.group_number_2')}
            </div>
          </div>
          <div className="mt-3 mb-3 border-top" />
          <div className="col-md-12 d-flex align-items-center mb-4">
            <div className="font-14-semibold col-md-4 text-uppercase c-7f7f7f">
              {t('entry_group_step_2.group_column_1')}
            </div>
            <div className="font-14-semibold col-md-3 text-uppercase c-7f7f7f">
              {t('entry_group_step_2.group_column_2')}
            </div>
            <div className="font-14-semibold col-md-3 text-uppercase c-7f7f7f">
              {t('entry_group_step_2.group_column_3')}
            </div>

            <div className="col-md-2" />
          </div>
          {group?.map((group, index) => (
            <div key={index}>
              <div className="col-md-12 d-flex align-items-center">
                <div className="font-15 col-md-4">
                  {group?.name} {group?.surname}
                </div>
                <div className="font-15 col-md-3">
                  {group?.birthPlace && group?.birthDate ? (
                    <div>
                      {group?.birthPlace}, {group?.birthDate}
                    </div>
                  ) : (
                    <div>-</div>
                  )}
                </div>
                <div className="font-15 col-md-3">
                  {group?.fiscalCode ? <div>{group?.fiscalCode}</div> : <div>-</div>}
                </div>
                {group?.errors && (
                  <div className="font-15 col-md-2 d-flex align-items-center justify-content-center">
                    <Image
                      src={EditIcon}
                      width={20}
                      height={20}
                      className="ms-md-3 cursor-pointer"
                      onClick={() => onShowModalEditPerson(index)}
                    />
                    <Image
                      src={AlertCircleIcon}
                      width={20}
                      height={20}
                      className="ms-md-3 filter-A90433"
                    />
                  </div>
                )}
              </div>
              <div className="mt-2 mb-2 border-top" />
            </div>
          ))}
        </div>
      </div>
    )

    const GroupInfoMobile = (
      <div className="d-flex align-items-center justify-content-center px-md-3 mt-4 mb-4">
        <div className="col-md-10">
          <div className="d-flex align-items-center">
            <div className="font-14">{t('entry_group_step_2.group_number_1')}</div>
            <div className="font-14-bold ms-2">
              {group?.length}
              {t('entry_group_step_2.group_number_2')}
            </div>
          </div>
          <div className="mt-3 mb-3 border-top" />
          <div className="col-md-12 d-flex align-items-center">
            <div className="col-md-2" />
          </div>
          {group?.map((groupItem, index) => (
            <div key={index} onClick={() => onShowModalEditPerson(index)}>
              <div className={`p-3 ${groupItem?.errors ? 'border-error' : ''}`}>
                <div className="d-flex align-items-center col-12 mb-2">
                  <div className="font-15-semibold col-6 c-7f7f7f pe-4">
                    {t('entry_group_step_2.group_column_1')}
                  </div>
                  <div className="font-15 col-6">
                    {groupItem?.name} {groupItem?.surname}
                  </div>
                </div>

                <div className="d-flex align-items-center col-12 mb-2">
                  <div className="font-15-semibold col-6 c-7f7f7f pe-4">
                    {t('entry_group_step_2.group_column_2')}
                  </div>
                  <div className="font-15 col-6">
                    {groupItem?.birthPlace && groupItem?.birthDate ? (
                      <div>
                        {groupItem?.birthPlace}, {groupItem?.birthDate}
                      </div>
                    ) : (
                      <div>-</div>
                    )}
                  </div>
                </div>

                <div className="d-flex align-items-center col-12">
                  <div className="font-15-semibold col-6 c-7f7f7f pe-4">
                    {t('entry_group_step_2.group_column_3')}
                  </div>
                  <div className="font-15 col-6">
                    {groupItem?.fiscalCode ? <div>{groupItem?.fiscalCode}</div> : <div>-</div>}
                  </div>
                </div>
              </div>
              {index !== group?.length - 1 && <div className="mt-2 mb-2 border-top" />}
            </div>
          ))}
        </div>
      </div>
    )

    const ModalEditPerson = (
      <Modal visible={isModalVisible} onClose={() => hideModal()}>
        <div className="font-16-semibold pb-3 col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3">
          {t('entry_group_step_2.modal_edit_title')}
        </div>
        <form onSubmit={handleSubmit} className="row justify-content-center m-0 p-0">
          <div>
            <div
              className={`${
                !touched.name || !errors.name ? 'pb-3' : ''
              } col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3`}
            >
              <InputText
                name="name"
                handleChange={handleChange}
                handleBlur={handleBlur}
                validationSchema={validationSchema}
                touched={true}
                value={values.name}
                error={touched.name && errors.name ? errors.name : ''}
                label={t('data_entry_step_2.insured_name')}
                placeholder={t('data_entry_step_2.insured_name_placeholder')}
              />
            </div>

            <div
              className={`${
                !touched.surname || !errors.surname ? 'pb-3' : ''
              } col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3`}
            >
              <InputText
                name="surname"
                handleChange={handleChange}
                handleBlur={handleBlur}
                validationSchema={validationSchema}
                touched={true}
                value={values.surname}
                error={touched.surname && errors.surname ? errors.surname : ''}
                label={t('data_entry_step_2.insured_surname')}
                placeholder={t('data_entry_step_2.insured_surname_placeholder')}
              />
            </div>

            <div className="d-block d-md-flex">
              <div className="col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3">
                <InputRadio
                  name="nationality"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  options={nationalityOptions}
                  validationSchema={validationSchema}
                  touched={true}
                  value={values.nationality}
                  error={errors.nationality}
                  label={t('data_entry_step_2.insured_nationality')}
                  classes="m-0 p-0"
                  classesLabel="m-0 p-0 font-16 d-flex align-items-center mb-3"
                />
              </div>
            </div>

            <div className="d-block d-md-flex">
              <div className="col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3">
                <InputRadio
                  name="fiscalCodeQuestion"
                  handleChange={(event: ChangeEvent<HTMLInputElement>) => {
                    handleChange(event)
                    if (event?.target?.value === 'no_id') {
                      setFieldValue('fiscalCode', '')
                    } else if (event?.target?.value === 'yes_id') {
                      setFieldValue('birthDate', '')
                      setFieldValue('birthNation', '')
                      setFieldValue('birthPlace', '')
                      setFieldValue('gender', '')
                    }
                  }}
                  handleBlur={handleBlur}
                  options={fcQuestionOptions}
                  validationSchema={validationSchema}
                  touched={true}
                  value={values.fiscalCodeQuestion}
                  error={
                    errors.fiscalCodeQuestion && touched.fiscalCodeQuestion
                      ? errors.fiscalCodeQuestion
                      : ''
                  }
                  label={t('data_entry_step_2.insured_fc_question')}
                  classesLabel="m-0 p-0 font-16 d-flex align-items-center mb-3"
                  classes="m-0 p-0 mb-3"
                />
              </div>
            </div>

            {values.fiscalCodeQuestion === 'yes_id' && (
              <div className="d-block d-md-flex">
                <div className="col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3">
                  <InputText
                    name="fiscalCode"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    validationSchema={validationSchema}
                    touched={true}
                    value={values.fiscalCode?.toUpperCase()}
                    error={errors.fiscalCode}
                    label={`${t('data_entry_step_2.insured_fc')}*`}
                    placeholder={t('data_entry_step_2.insured_fc_placeholder')}
                  />
                </div>
              </div>
            )}

            {values.fiscalCodeQuestion === 'no_id' && (
              <div>
                <div className="d-block d-md-flex">
                  <div className="col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3">
                    <InputDate
                      name="birthDate"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      validationSchema={validationSchema}
                      touched={true}
                      value={values.birthDate}
                      error={errors.birthDate}
                      label={`${t('data_entry_step_2.insured_birth')}*`}
                      placeholder={t('data_entry_step_2.insured_birth_placeholder')}
                      disabledTo={new Date()}
                      classes="m-0 p-0"
                    />
                  </div>

                  <div className="col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3">
                    <InputText
                      name="birthNation"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      validationSchema={validationSchema}
                      touched={true}
                      value={values.birthNation}
                      error={errors.birthNation}
                      label={`${t('data_entry_step_2.insured_birth_nation')}*`}
                      placeholder={t('data_entry_step_2.insured_birth_nation_placeholder')}
                    />
                  </div>
                </div>

                <div className="d-block d-md-flex">
                  <div className="col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3">
                    <InputText
                      name="birthPlace"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      validationSchema={validationSchema}
                      touched={true}
                      value={values.birthPlace}
                      error={errors.birthPlace}
                      label={`${t('data_entry_step_2.insured_birth_place')}*`}
                      placeholder={t('data_entry_step_2.insured_birth_place_placeholder')}
                    />
                  </div>

                  <div className="col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3">
                    <InputRadio
                      name="gender"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      options={genderOptions}
                      validationSchema={validationSchema}
                      touched={true}
                      value={values.gender}
                      error={errors.gender}
                      label={`${t('data_entry_step_2.insured_gender')}*`}
                      classesLabel="m-0 p-0 font-16 d-flex align-items-center mb-3"
                      classes="m-0 p-0 mb-3"
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="col-sm-12 p-0 px-md-3 pl-md-3 pr-md-3 d-flex align-items-center justify-content-center">
              <Button
                text={t('commons.confirm')}
                flavor="accent"
                type="submit"
                classes="mt-4 pt-2 pb-2 ps-3 pe-3 d-flex align-items-center"
                classesText="font-15-semibold"
                classesIconRight="ms-2"
              />
            </div>
          </div>
        </form>
      </Modal>
    )

    return (
      <div>
        <ScrollTopRoot />
        <div>
          {Title}
          {UploadInfo}
          {Upload}
          {group?.length > 0 && (
            <div>
              <div className="d-none d-md-block">{GroupInfoDesktop}</div>
              <div className="d-block d-md-none">{GroupInfoMobile}</div>
            </div>
          )}
          {ModalEditPerson}
        </div>
      </div>
    )
  }
)
