import { useRootOutcomes } from 'src/storage'
import { ModalFailure } from 'src/ui/widgets'

export const OutcomeRoot = () => {
  const { topRootOutcome, removeTopRootOutcome } = useRootOutcomes()

  return (
    <ModalFailure
      visible={!!topRootOutcome && !topRootOutcome?.success}
      description={topRootOutcome?.awsGateError?.errorDescription}
      onClose={removeTopRootOutcome}
    />
  )
}
