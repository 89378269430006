import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { usePostCertificate } from 'src/networking/terminals'
import { atomGoogleEcommerce, atomPaymentData } from 'src/storage'
import { GoogleWindow } from 'src/types'
import { submitCaptcha } from 'src/ui/widgets'

import { usePdf } from './handlers/pdf.hook'

export const usePaymentOk = () => {
  const { t } = useTranslation()

  const urlSearchParams = new URLSearchParams(useLocation().search)
  const contractId = urlSearchParams.get('contractId')
  const securityHash = urlSearchParams.get('securityHash')
  const email = urlSearchParams.get('email')

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  const ecommerce = useRecoilValue(atomGoogleEcommerce)

  const [loading, setLoading] = useState(false)

  const { crossGate: postCertificate } = usePostCertificate()

  const { showPdf } = usePdf()

  const setPaymentData = useSetRecoilState(atomPaymentData)

  useEffect(() => {
    setPaymentData(undefined)
  }, [])

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  const doPostCertificate = () => {
    if (contractId && securityHash) {
      postCertificate(
        {
          headers: { 'Content-Type': 'application/json' },
          params: { contractId, securityHash },
        },
        {
          onSuccess: showPdf,
          onPending: () => {},
          onIdle: () => setLoading(false),
        }
      )
    }
  }

  const generateRecaptcha = async () => {
    await submitCaptcha().then(() => {
      setLoading(true)
      setTimeout(doPostCertificate, 7000)
    })
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    const googleWindow: GoogleWindow = window
    googleWindow?.gtag?.('event', 'conversion', {
      send_to: 'AW-1020646686/gicXCNei5K8DEJ6q1-YD',
      value: 1.0,
      currency: 'EUR',
      transaction_id: '',
    })
    googleWindow.dataLayer = googleWindow?.dataLayer || []
    googleWindow.dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.
    googleWindow.dataLayer.push({
      event: 'purchase',
      ecommerce: {
        transaction_id: contractId,
        ...ecommerce,
      },
    })
  }, [])

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  return { t, contractId, securityHash, email, loading, generateRecaptcha }
}
