import './data-entry-step3.scss'

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import { postQuoteCodes, toQuoteRequest, usePostQuote } from 'src/networking/terminals'
import { atomProduct, atomQuote, selectorAgency, selectorCoverages } from 'src/storage'
import { DataEntryStepProps } from 'src/types'
import { getDifferenceInDays, useBoolean } from 'src/utils'

import { usePrice } from '../handlers/price.hook'

export const useDataEntryStep3 = ({
  formik: { values },
  discountError = '',
  setDiscountError,
}: DataEntryStepProps) => {
  const { t } = useTranslation()

  const product = useRecoilValue(atomProduct)
  const [quote, saveQuote] = useRecoilState(atomQuote)
  const resetQuote = useResetRecoilState(atomQuote)
  const agency = useRecoilValue(selectorAgency)
  const coverages = useRecoilValue(selectorCoverages)

  const [quoting, turnOnQuoting, turnOffQuoting] = useBoolean()

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  const price = usePrice(discountError, values?.discount)

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  const oldQuote = quote?.oldQuoteValue?.replace('.', ',')
  const newQuote = quote?.quoteValue?.replace('.', ',')
  const coverage = coverages?.[values.coverage_type_radios || '']

  const seasonalBase = product?.configuration?.coverages?.['1']?.seasonalBasePremium
  const seasonalPlus = product?.configuration?.coverages?.['2']?.seasonalBasePremium
  const quoteValue = Number(quote?.quoteValue?.replace(',', '.'))

  const warning =
    values.insured_days === 'id_period' &&
    ((seasonalBase && values.coverage_type_radios === '1' && quoteValue >= seasonalBase) ||
      (seasonalPlus && values.coverage_type_radios === '2' && quoteValue >= seasonalPlus))

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  const [policyCode, setPolicyCode] = useState(coverage?.policyCode || '')

  const { crossGate: postQuote } = usePostQuote()

  useEffect(() => {
    if (agency?.isCustomNow) {
      if (values.coverage_type_radios === '1') {
        setPolicyCode(agency?.policyNumberBase || '')
      } else if (values.coverage_type_radios === '2') {
        setPolicyCode(agency?.policyNumberPlus || '')
      }
    }
  }, [])

  const doPostQuote = () => {
    postQuote(
      {
        body: toQuoteRequest(values, agency, quote?.quoteId),
      },
      {
        onSuccess: saveQuote,
        onFailure: (error) => {
          const code = error?.errorCode || ''
          const desc = error?.errorDescription || ''
          if (code in postQuoteCodes) {
            setDiscountError(desc)
          }
        },
        onPending: turnOnQuoting,
        onIdle: turnOffQuoting,
      }
    )
  }

  const updateTotalQuote = () => {
    doPostQuote()
    setDiscountError('')
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (!quote) {
      doPostQuote()
    }
  }, [quote])

  useEffect(() => {
    if (values.discount === '') {
      setDiscountError('')
      resetQuote()
    }
  }, [values.discount])

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  const isMultiDays =
    getDifferenceInDays(values.coverage_start_date, values.coverage_end_date) + 1 >= 3

  return {
    t,
    discountError,
    quoting,
    quote,
    agency,
    oldQuote,
    newQuote,
    price,
    coverages,
    coverage,
    policyCode,
    warning,
    isMultiDays,
    updateTotalQuote,
  }
}
