import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { Paths } from 'src/navigation'

export const UseClaimReportRcInjury = () => {
  const navigate = useNavigate()

  useEffect(() => {
    navigate(Paths?.ClaimReportRcInjuryDocument)
  }, [])

  return {}
}
