import { ChangeEvent } from 'react'
import { ProgressBar } from 'react-bootstrap'
import { Trans } from 'react-i18next'
import { ScrollTopRoot } from 'src/roots'
import { Button, InputRadio, InputText } from 'src/ui/widgets'

import { UseClaimReportRcInjuryReportDamageStep1 } from './claim-report-rc-injury-report-damage-step1.hook'

export const ClaimReportRcInjuryReportDamageStep1 = () => {
  const { t, progressBar, formik, validationSchema, isMobile, optionPersonaDataDamage, goBack } =
    UseClaimReportRcInjuryReportDamageStep1()

  const { values, errors, touched, handleBlur, handleChange, setFieldValue, handleSubmit } = formik

  const Topbar = (
    <Button
      flavor="transparentAccentBack"
      text={t('commons.back')}
      classes="px-0 pt-0"
      classesText="text-uppercase font-14-semibold"
      onPress={goBack}
    />
  )

  const Title = (
    <div className="font-28-semibold c-292929">
      {t('claim_report_rc_injury_report_damage_step1.title')}
    </div>
  )

  const Subtitle = (
    <div className="mt-2 font-14-semibold c-292929 text-uppercase">
      {t('claim_report_rc_injury_report_damage_step1.subtitle')}
    </div>
  )

  const RadioForm = (
    <div>
      <InputRadio
        name="injuredPersonKnown"
        handleChange={(event: ChangeEvent<HTMLInputElement>) => {
          setFieldValue('injuredPersonKnown', event?.target?.value)
          handleChange(event)
        }}
        handleBlur={handleBlur}
        options={optionPersonaDataDamage || []}
        validationSchema={validationSchema}
        touched={touched.injuredPersonKnown}
        value={values.injuredPersonKnown}
        error={''}
        vertically={!isMobile}
        label={t('claim_report_rc_injury_report_damage_step1.question_personal_data')}
        classes="m-0 p-0 mt-4"
        classesLabel="m-0 p-0 font-12 d-flex align-items-center mb-3"
        classesOption="pb-3"
      />
    </div>
  )

  const AlertLabel = (
    <div className="font-16 mb-4">
      <Trans i18nKey={'claim_report_rc_injury_report_damage_step1.alert_label'} />
    </div>
  )

  const NewPersonForm = (
    <div className="mt-2 col-12">
      {AlertLabel}
      <div className="d-block d-md-flex">
        <div className="col-md-6 ps-md-0 pe-md-4">
          <InputText
            name="name"
            handleChange={handleChange}
            handleBlur={handleBlur}
            validationSchema={validationSchema}
            touched={touched.name}
            value={values.name}
            error={touched.name && errors.name ? errors.name : undefined}
            label={t('claim_report_rc_injury_report_damage_step1.name')}
            placeholder={t('claim_report_rc_injury_report_damage_step1.name_placeholder')}
          />
        </div>
        <div className="col-md-6 pe-md-0 ps-md-4">
          <InputText
            name="surname"
            handleChange={handleChange}
            handleBlur={handleBlur}
            validationSchema={validationSchema}
            touched={touched.surname}
            value={values.surname}
            error={touched.surname && errors.surname ? errors.surname : undefined}
            label={t('claim_report_rc_injury_report_damage_step1.surname')}
            placeholder={t('claim_report_rc_injury_report_damage_step1.surname_placeholder')}
          />
        </div>
      </div>

      <div className="d-block d-md-flex">
        <div className="col-md-6 ps-md-0 pe-md-4">
          <InputText
            name="fiscalCode"
            handleChange={handleChange}
            handleBlur={handleBlur}
            validationSchema={validationSchema}
            touched={touched.fiscalCode}
            value={values.fiscalCode?.toUpperCase()}
            error={errors.fiscalCode}
            label={t('claim_report_rc_injury_report_damage_step1.fiscal_code')}
            placeholder={t('claim_report_rc_injury_report_damage_step1.fiscal_code_placeholder')}
          />
        </div>
        <div className="col-md-6 pe-md-0 ps-md-4">
          <InputText
            name="residence"
            handleChange={handleChange}
            handleBlur={handleBlur}
            validationSchema={validationSchema}
            touched={touched.residence}
            value={values.residence}
            error={errors.residence}
            label={t('claim_report_rc_injury_report_damage_step1.residence')}
            placeholder={t('claim_report_rc_injury_report_damage_step1.residence_placeholder')}
          />
        </div>
      </div>

      <div className="d-block d-md-flex">
        <div className="col-md-6 ps-md-0 pe-md-4">
          <InputText
            name="mobile"
            handleChange={handleChange}
            handleBlur={handleBlur}
            validationSchema={validationSchema}
            touched={touched.mobile}
            value={values.mobile}
            error={errors.mobile}
            label={t('claim_report_rc_injury_report_damage_step1.mobile')}
            placeholder={t('claim_report_rc_injury_report_damage_step1.mobile_placeholder')}
          />
        </div>
        <div className="col-md-6 pe-md-0 ps-md-4">
          <InputText
            name="email"
            handleChange={handleChange}
            handleBlur={handleBlur}
            validationSchema={validationSchema}
            touched={touched.email}
            value={values.email}
            error={errors.email}
            label={t('claim_report_rc_injury_report_damage_step1.email')}
            placeholder={t('claim_report_rc_injury_report_damage_step1.email_placeholder')}
          />
        </div>
      </div>
    </div>
  )

  const ButtonForm = (
    <div className="mt-5 pt-2 d-flex justify-content-md-end">
      <div className="col-12 col-md-4">
        <Button
          flavor="accentNext"
          text={t('commons.continue')}
          type="submit"
          disabled={!values.injuredPersonKnown}
          classes="ps-4 ps-md-3 pe-4 pe-md-3 m-auto m-md-0 w-100 d-flex justify-content-between"
          classesText="font-14-semibold text-uppercase"
        />
      </div>
    </div>
  )

  return (
    <div className="mb-md-5 pb-md-5 ps-md-3 pe-md-3 d-md-flex justify-content-md-center container-fluid">
      <div className="col-12 col-lg-7">
        <div className="mt-4">
          <ScrollTopRoot />
          {Topbar}
          <div className="claim-progress">
            <ProgressBar now={progressBar} className="claim-progress-bar" />
          </div>
          <div className="mb-4 p-4 ps-3 pe-3 p-md-5 bg-ffffff">
            {Title}
            {Subtitle}
            <form onSubmit={handleSubmit}>
              {RadioForm}
              {values.injuredPersonKnown === 'yes_id' && NewPersonForm}
              <div className="pt-5">{ButtonForm}</div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
