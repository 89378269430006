import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useRecoilState, useRecoilValue } from 'recoil'
import { Paths } from 'src/navigation'
import { regexPhone, regexTaxCode } from 'src/regex'
import { atomClaimData, atomClaimQuote, atomProgressBar } from 'src/storage'
import { FormikClaimPersonalData } from 'src/types'
import { checkFiscalCode, yupEmail, yupStr, yupStrRequired } from 'src/utils'
import * as Yup from 'yup'

import { PROGRESS_BAR_STEP } from '../../progress-bar-step'

export const UseClaimReportRcInjuryPersonalData = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const claimQuote = useRecoilValue(atomClaimQuote)
  const [progressBar, setProgressBar] = useRecoilState(atomProgressBar)
  const [claimData, setClaimData] = useRecoilState(atomClaimData)

  const [initialValues] = useState<FormikClaimPersonalData>({
    name: '',
    surname: '',
    fiscalCode: '',
    residence: '',
    mobile: '',
    email: '',
  })

  const validationSchema = Yup.object().shape({
    name: yupStrRequired,
    surname: yupStrRequired,
    fiscalCode: yupStrRequired
      .matches(regexTaxCode, t('commons.error_is_wrong'))
      .test('check_validation', t('commons.fiscal_code_mismatch'), (value, field) => {
        return checkFiscalCode(field?.parent?.name, field?.parent?.surname, value || '') || false
      }),
    residence: yupStrRequired,
    mobile: yupStr.matches(regexPhone, t('commons.error_is_wrong')),
    email: yupEmail,
  })

  //////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    setTimeout(() => {
      setProgressBar(PROGRESS_BAR_STEP.claim_report_rc_injury_personal_data)
    }, 100)
  }, [])

  useEffect(() => {
    if (!claimQuote) {
      navigate(Paths.ClaimReport)
    }
  }, [claimQuote])

  //////////////////////////////////////////////////////////////////////////////

  // eslint-disable-next-line complexity
  const fillInitialValues = () => {
    let initialValues_ = initialValues

    if (claimData?.other?.personalData?.name && claimData?.other?.personalData?.surname) {
      initialValues_ = {
        name: claimData?.other?.personalData?.name || '',
        surname: claimData?.other?.personalData?.surname || '',
        fiscalCode: claimData?.other?.personalData?.fiscalCode || '',
        residence: claimData?.other?.personalData?.residence || '',
        mobile: claimData?.other?.personalData?.mobile || '',
        email: claimData?.other?.personalData?.email || '',
      }
    } else if (claimData?.other?.insuredType) {
      if (claimData?.other?.insuredType === 'contractor') {
        const mobile_ =
          claimQuote?.contractor?.otherContacts?.find((contact) => contact?.type === 'mobile')
            ?.value || ''

        initialValues_ = {
          name: claimQuote?.contractor?.name || '',
          surname: claimQuote?.contractor?.surname || '',
          fiscalCode: claimQuote?.contractor?.fiscalCode || '',
          residence: '',
          mobile: mobile_,
          email: claimQuote?.contractor?.mainContact?.value || '',
        }
      } else {
        const asset = claimData?.other?.insuredType?.split('_')[1]

        if (asset) {
          const insured_ = claimQuote?.assets?.[Number(asset)]

          initialValues_ = {
            name: insured_?.name || '',
            surname: insured_?.surname || '',
            fiscalCode: insured_?.fiscalCode || '',
            residence: '',
            mobile: '',
            email: '',
          }
        }
      }
    }

    return initialValues_
  }

  const formik = useFormik<FormikClaimPersonalData>({
    initialValues: fillInitialValues(),
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setClaimData({
        ...claimData,
        other: {
          ...claimData?.other,
          personalData: {
            name: values?.name,
            surname: values?.surname,
            fiscalCode: values?.fiscalCode,
            residence: values?.residence,
            mobile: values?.mobile,
            email: values?.email,
          },
        },
      })

      navigate(Paths.ClaimReportRcInjuryRequestReason)
    },
  })

  //////////////////////////////////////////////////////////////////////////////

  const goBack = () => {
    setClaimData({
      ...claimData,
      other: {
        ...claimData?.other,
        personalData: undefined,
      },
    })
    if (claimQuote?.assets?.length === 0) {
      navigate(Paths.ClaimReportRcInjuryDateHour)
    } else {
      navigate(Paths.ClaimReportRcInjuryInsuredPerson)
    }
  }

  return {
    t,
    formik,
    validationSchema,
    progressBar,
    goBack,
  }
}
