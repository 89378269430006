import { useEffect } from 'react'
import { Formik, FormikMainInsured } from 'src/types'
import { FormikMainInsuredGroup } from 'src/types/networking/terminals/group.types'
import { useBoolean } from 'src/utils'

export const useScrollToError = ({ errors }: Formik<FormikMainInsured>) => {
  const [toCheck, startCheck, stopCheck] = useBoolean()

  useEffect(() => {
    if (toCheck) {
      stopCheck()
      const err = Object.keys(errors)[0]
      const error = err === 'insured_list' ? Object.keys(errors.insured_list?.[0] || {})[0] : err
      if (error) {
        if (error === 'insured_days') {
          window.scroll(0, 0)
        }
        document?.getElementById(`input-${error}`)?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      }
    }
  }, [errors, toCheck])

  const triggerFormikCheck = startCheck

  return { triggerFormikCheck }
}

export const useScrollToErrorGroup = ({ errors }: Formik<FormikMainInsuredGroup>) => {
  const [toCheck, startCheck, stopCheck] = useBoolean()

  useEffect(() => {
    if (toCheck) {
      stopCheck()
      const err = Object.keys(errors)[0]
      const error = err
      if (error) {
        if (error === 'insured_days') {
          window.scroll(0, 0)
        }
        document?.getElementById(`input-${error}`)?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      }
    }
  }, [errors, toCheck])

  const triggerFormikCheck = startCheck

  return { triggerFormikCheck }
}
