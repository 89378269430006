import { PropsClaimCode } from 'src/types/pages/claim.types'
import { Digit } from 'src/ui/widgets/input-digits/digit'
import { InputDigits } from 'src/ui/widgets/input-digits/input-digits'

import { useClaimCode } from './claim-code.hook'

export const ClaimCode = ({ code, error, disabled, successCode, setCode }: PropsClaimCode) => {
  const { handleDigitInputsChange } = useClaimCode({ code, setCode })

  const CodeForm = (
    <div>
      <InputDigits
        className={'mt-4 d-flex flex-wrap justify-content-evenly'}
        hidden={false}
        success={successCode}
        successText={successCode ? 'claim_report_code.success_code' : ''}
        error={error}
        disabled={disabled}
        onDigitsChange={handleDigitInputsChange}
      >
        <Digit
          style={{ width: '56px', height: '64px', border: 'unset' }}
          defaultValue={code[0]}
          className={'mb-3 bg-f5e9da font-32 text-center'}
        />
        <Digit
          style={{ width: '56px', height: '64px', border: 'unset' }}
          defaultValue={code[1]}
          className={'mb-3 bg-f5e9da font-32 text-center'}
        />
        <Digit
          style={{ width: '56px', height: '64px', border: 'unset' }}
          defaultValue={code[2]}
          className={'mb-3 bg-f5e9da font-32 text-center'}
        />
        <Digit
          style={{ width: '56px', height: '64px', border: 'unset' }}
          defaultValue={code[3]}
          className={'mb-3 bg-f5e9da font-32 text-center'}
        />
        <Digit
          style={{ width: '56px', height: '64px', border: 'unset' }}
          defaultValue={code[4]}
          className={'mb-3 bg-f5e9da font-32 text-center'}
        />
        <Digit
          style={{ width: '56px', height: '64px', border: 'unset' }}
          defaultValue={code[5]}
          className={'mb-3 bg-f5e9da font-32 text-center'}
        />
        <Digit
          style={{ width: '56px', height: '64px', border: 'unset' }}
          defaultValue={code[6]}
          className={'mb-3 bg-f5e9da font-32 text-center'}
        />
        <Digit
          style={{ width: '56px', height: '64px', border: 'unset' }}
          defaultValue={code[7]}
          className={'mb-3 bg-f5e9da font-32 text-center'}
        />
      </InputDigits>
    </div>
  )

  return <div>{CodeForm}</div>
}
