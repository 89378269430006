import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useRecoilValue } from 'recoil'
import { Paths } from 'src/navigation'
import { atomClaimData, atomClaimQuote } from 'src/storage'

export const useClaimOpenPending = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const claimQuote = useRecoilValue(atomClaimQuote)
  const claimData = useRecoilValue(atomClaimData)
  const [email, setEmail] = useState('')

  useEffect(() => {
    if (claimData && claimQuote) {
      const email_ =
        (claimData?.openInformations?.contact?.emailValue
          ? claimData?.openInformations?.contact?.emailValue
          : claimQuote?.contractor?.mainContact?.value) || ''
      setEmail(email_)
    } else {
      navigate(Paths.ClaimReport)
    }
  }, [])
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  return { t, email }
}
