import { Image } from 'react-component-library'
import { ItasEmailIcon, ItasErrorMan, ItasPhoneIcon } from 'src/assets'

import { useClaimReportError } from './claim-report-error.hook'

export const ClaimReportError = () => {
  const { t } = useClaimReportError()

  const ImageSection = (
    <div>
      <Image src={ItasErrorMan} width={350} height={150} />
    </div>
  )

  const Title = (
    <div className="mt-4 font-28-semibold white-space-pre">{t('claim_report_error.title')}</div>
  )

  const SubTitle = <div className="mt-3 font-14">{t('claim_report_error.subtitle')}</div>

  const Email = (
    <div className="mt-3 font-14 d-flex align-items-center">
      <div className="me-2">
        <Image src={ItasEmailIcon} width={24} height={24} />
      </div>
      <div
        className="cursor-pointer display-contents font-14 c-0067de"
        onClick={() => window.open(`mailto:${t('claim_report_error.email')}`)}
      >
        <div className="text-underline">{t('claim_report_error.email')}</div>
      </div>
    </div>
  )

  const Phone = (
    <div className="mt-2 font-14 d-flex align-items-center">
      <div className="me-2">
        <Image src={ItasPhoneIcon} width={24} height={24} />
      </div>
      <div
        className="cursor-pointer display-contents font-14"
        onClick={() => window.open(`tel:${t('claim_report_error.phone')}`)}
      >
        {t('claim_report_error.phone_title')} {t('claim_report_error.phone')}
      </div>
    </div>
  )

  return (
    <div className="m-4 p-4 ps-3 pe-3 vh-100 bg-ffffff d-md-flex flex-column justify-content-center align-items-center">
      <div className="d-flex justify-content-center align-items-center">{ImageSection}</div>
      {Title}
      {SubTitle}
      <div>
        {Email}
        {Phone}
      </div>
    </div>
  )
}
