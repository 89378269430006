import { useSetRecoilState } from 'recoil'
import { atomFromApp } from 'src/storage'
import { isString } from 'src/utils'

export const FromAppRoot = () => {
  const saveFromApp = useSetRecoilState(atomFromApp)

  window.addEventListener('message', (message) => {
    const data = message?.data || ''
    if (isString(data) && data.startsWith('FROM_APP')) {
      saveFromApp(true)
    }
  })

  return <></>
}
