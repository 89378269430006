import './documents.scss'

import { useTranslation } from 'react-i18next'
import { useRecoilValue } from 'recoil'
import { selectorLandingDocuments } from 'src/storage'

export const useDocuments = () => {
  const { t } = useTranslation()

  const documents = useRecoilValue(selectorLandingDocuments)

  const openDocument = (link = '') => {
    if (link) {
      window.open(link?.replaceAll('"', '').toString(), '_blank')
    }
  }

  return {
    t,
    documents,
    openDocument,
  }
}
