/* eslint-disable complexity */
import './header.scss'

import React from 'react'
import { Image } from 'react-bootstrap'
import { useLocation } from 'react-router'
import { ItasNowBlue, RightArrowIcon } from 'src/assets'
import { IsClaimsPage, NotLandingPaths, Paths } from 'src/navigation'
import { typedMemo } from 'src/types'
import { Button } from 'src/ui/widgets'
import { concatClasses } from 'src/utils'

import { useHeader } from './header.hook'

export const Header = typedMemo(() => {
  const { t, radioLanguage, setIT, setDE, setEN, goToDataEntry, goToLanding } = useHeader()
  const { pathname } = useLocation()

  const isNotLanding = NotLandingPaths.includes(pathname) && pathname !== Paths.Faqs
  const isFaq = NotLandingPaths.includes(pathname) && pathname === Paths.Faqs
  const showLanguage = IsClaimsPage.includes(pathname)

  const getHeaderClass = () => {
    return isNotLanding
      ? 'header-language-radio'
      : isFaq
      ? 'header-language-radio-faq'
      : 'header-language-radio-landing'
  }

  const getTextClass = (language: string) => {
    if (radioLanguage === language) {
      if (isNotLanding) return 'c-141414 text-decoration-underline'
      if (isFaq) return 'c-000000 text-decoration-underline'
      return 'c-2727ab text-decoration-underline'
    }
    if (isFaq) return 'c-7f7f7f'
    if (!isNotLanding) return 'c-2727ab'
    return 'c-141414 opacity-50'
  }

  const getSeparatorClass = () => {
    return isNotLanding ? 'c-141414' : isFaq ? 'c-000000' : 'c-2727ab'
  }

  const Logo = (
    <Image
      className={'header-logo'}
      src={ItasNowBlue}
      width="auto"
      onClick={() => goToLanding()}
      height={40}
    />
  )

  const LanguageButtons = (
    <div className="header-radios align-items-center">
      <Button
        text={t('language.it')}
        classes={concatClasses(getHeaderClass(), getTextClass('it'))}
        classesText="text-uppercase font-14-ag-400"
        onPress={setIT}
      />

      {!showLanguage && (
        <>
          <div className="header-radios align-items-center">
            <div className={`header-separator pb-1 ${getSeparatorClass()}`}>|</div>

            <Button
              text={t('language.en')}
              classes={concatClasses(getHeaderClass(), getTextClass('en'))}
              classesText="text-uppercase font-14-ag-400"
              onPress={setEN}
            />

            <div className={`header-separator pb-1 ${getSeparatorClass()}`}>|</div>

            <Button
              text={t('language.de')}
              classes={concatClasses(getHeaderClass(), getTextClass('de'))}
              classesText="text-uppercase font-14-ag-400"
              onPress={setDE}
            />
          </div>
          {!isNotLanding && (
            <Button
              text={t('commons.buy_now_button')}
              iconRight={RightArrowIcon}
              classes={concatClasses(
                'bg-2727ab header-button p-2 ps-3 pe-3 d-flex justify-content-center'
              )}
              classesText={concatClasses(isFaq ? 'c-ffffff' : '', 'font-14-ag-500 c-ffffff')}
              classesIconRight={concatClasses('ms-3 filter-ffffff')}
              onPress={goToDataEntry}
            />
          )}
        </>
      )}
    </div>
  )

  return (
    <div>
      <header className={isNotLanding ? 'header-navbar' : 'header-navbar-landing'}>
        {Logo}
        {LanguageButtons}
      </header>
    </div>
  )
})
