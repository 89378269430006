import { ProgressBar } from 'react-bootstrap'
import { ScrollTopRoot } from 'src/roots'
import {
  ClaimUploadDocumentInfoFile,
  ClaimUploadDocumentModalError,
  ClaimUploadDocumentModalUpload,
  ClaimUploadDocumentTooltipInfo,
  ClaimUploadDocumentUploadFile,
} from 'src/ui/fragments/claim-upload-document/claim-upload-document'
import { Button, InputCheckbox, InputText, Loader } from 'src/ui/widgets'

import { UseClaimReportRcInjuryReportDamageStep2 } from './claim-report-rc-injury-report-damage-step2.hook'

// eslint-disable-next-line complexity
export const ClaimReportRcInjuryReportDamageStep2 = () => {
  const {
    t,
    progressBar,
    formik,
    validationSchema,
    file,
    isMobile,
    showUploadModal,
    isOutOfSizeFile,
    inputFileAccept,
    loadingFile,
    goBack,
    uploadDocument,
    setIsOutOfSizeFile,
    setShowUploadModal,
    deleteDocument,
    checkDisabled,
  } = UseClaimReportRcInjuryReportDamageStep2()

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } = formik

  const Topbar = (
    <Button
      flavor="transparentAccentBack"
      text={t('commons.back')}
      classes="px-0 pt-0"
      classesText="text-uppercase font-14-semibold"
      onPress={goBack}
    />
  )

  const Title = (
    <div className="font-28-semibold c-292929">
      {t('claim_report_rc_injury_report_damage_step2.title')}
    </div>
  )

  const Subtitle = (
    <div className="mt-2 font-14-semibold c-292929 text-uppercase">
      {t('claim_report_rc_injury_report_damage_step2.subtitle')}
    </div>
  )

  const CheckBoxForm = (
    <div className="mt-4">
      <div className="mb-2 font-12">
        {t('claim_report_rc_injury_report_damage_step2.damage_done_type')}
      </div>
      <InputCheckbox
        name="damageDoneInjury"
        handleChange={handleChange}
        handleBlur={handleBlur}
        validationSchema={validationSchema}
        touched={touched.damageDoneInjury}
        value={values?.damageDoneInjury}
        label={t('claim_report_rc_injury_report_damage_step2.damage_done_injury')}
        classesLabel="m-0 mb-2 font-14 d-flex align-items-center"
      />
      <InputCheckbox
        name="damageDoneThings"
        handleChange={handleChange}
        handleBlur={handleBlur}
        validationSchema={validationSchema}
        touched={touched.damageDoneThings}
        value={values?.damageDoneThings}
        label={t('claim_report_rc_injury_report_damage_step2.damage_done_things')}
        classesLabel="m-0 font-14 d-flex align-items-center"
      />
    </div>
  )

  const InputForm = (
    <div>
      <div className="mt-3 mb-3 border-bottom-green" />
      <InputText
        name="whatDamage"
        handleChange={handleChange}
        handleBlur={handleBlur}
        textarea
        validationSchema={validationSchema}
        touched={touched.whatDamage}
        value={values.whatDamage}
        error={touched.whatDamage && errors.whatDamage ? errors.whatDamage : undefined}
        classesLabel="mb-2 font-12"
        label={t('claim_report_rc_injury_report_damage_step2.what_damage_label')}
        placeholder={t('claim_report_rc_injury_report_damage_step2.what_damage_placeholder')}
      />
      <InputText
        name="damageValue"
        handleChange={handleChange}
        handleBlur={handleBlur}
        textarea
        validationSchema={validationSchema}
        touched={touched.damageValue}
        value={values.damageValue}
        error={touched.damageValue && errors.damageValue ? errors.damageValue : undefined}
        classesLabel="mb-2 font-12 white-space-pre"
        label={t('claim_report_rc_injury_report_damage_step2.damage_value_label')}
        placeholder={t('claim_report_rc_injury_report_damage_step2.damage_value_placeholder')}
      />
    </div>
  )

  const UploadForm = (
    <div className="p-2 mt-3 bg-f5e9da">
      <ClaimUploadDocumentTooltipInfo
        t={t}
        tooltipLabel={t('claim_report_rc_injury_report_damage_step2.tooltip_label')}
      />
      <ClaimUploadDocumentUploadFile
        t={t}
        id={'upload'}
        isMobile={isMobile}
        file={file}
        inputFileAccept={inputFileAccept}
        uploadDocument={uploadDocument}
        setShowUploadModal={setShowUploadModal}
      />
      {file && <ClaimUploadDocumentInfoFile file={file} deleteDocument={deleteDocument} />}
    </div>
  )

  const ButtonForm = (
    <div className="mt-5 pt-2 d-flex justify-content-md-end">
      <div className="col-12 col-md-4">
        <Button
          flavor="accentNext"
          text={t('commons.continue')}
          type="submit"
          disabled={checkDisabled()}
          classes="ps-4 ps-md-3 pe-4 pe-md-3 m-auto m-md-0 w-100 d-flex justify-content-between"
          classesText="font-14-semibold text-uppercase"
        />
      </div>
    </div>
  )

  return (
    <div className="mb-md-5 pb-md-5 ps-md-3 pe-md-3 d-md-flex justify-content-md-center container-fluid">
      <div className="col-12 col-lg-7">
        <div className="mt-4">
          <ScrollTopRoot />
          {Topbar}
          <div className="claim-progress">
            <ProgressBar now={progressBar} className="claim-progress-bar" />
          </div>
          <div className="mb-4 p-4 ps-3 pe-3 p-md-5 bg-ffffff">
            {Title}
            {Subtitle}
            <form onSubmit={handleSubmit}>
              {CheckBoxForm}
              {values?.damageDoneThings && (
                <div>
                  {InputForm} {UploadForm}
                </div>
              )}
              <div className="pt-5">{ButtonForm}</div>
            </form>
            {showUploadModal && (
              <ClaimUploadDocumentModalUpload
                t={t}
                id={'damage_upload_file'}
                inputFileAccept={inputFileAccept}
                uploadDocument={uploadDocument}
                setShowUploadModal={setShowUploadModal}
              />
            )}
            {isOutOfSizeFile && (
              <ClaimUploadDocumentModalError
                t={t}
                isOutOfSizeFile={isOutOfSizeFile}
                setIsOutOfSizeFile={setIsOutOfSizeFile}
              />
            )}
          </div>
        </div>
      </div>
      {loadingFile && <Loader overlay />}
    </div>
  )
}
