import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useRecoilValue } from 'recoil'
import { selectorFaqs } from 'src/storage'

export const useFaqs = () => {
  const { t } = useTranslation()
  const faqs = useRecoilValue(selectorFaqs)

  const navigate = useNavigate()

  return { t, faqs, navigate }
}
