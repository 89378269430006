import { Container } from 'react-bootstrap'
import { DownloadIcon } from 'src/assets'
import { Button } from 'src/ui/widgets'

import { useAccessibility } from './accessibility.hook'

export const Accessibility = () => {
  const { t, accessibilityLink } = useAccessibility()

  return (
    <Container fluid className="ps-4 pe-4">
      <div className="mt-5" />
      <div className="c-862633 white-space-pre font-20-semibold">{t('accessibility.title_1')}</div>
      <div className="white-space-pre font-16">{t('accessibility.subtitle_1')}</div>
      <div className="c-862633 white-space-pre font-20-semibold">{t('accessibility.title_2')}</div>
      <div className="white-space-pre font-16">{t('accessibility.subtitle_2')}</div>
      <div className="c-862633 white-space-pre font-20-semibold">{t('accessibility.title_3')}</div>
      <div className="white-space-pre font-16 display-contents">
        {t('accessibility.subtitle_3')}
      </div>
      <div
        className="c-862633 cursor-pointer font-16 display-contents"
        onClick={() =>
          window.open(`mailto:${t('accessibility.subtitle_3_email').replaceAll(' ', '')}`)
        }
      >
        {t('accessibility.subtitle_3_email')}
      </div>
      <div className="white-space-pre font-16 display-contents">
        {t('accessibility.subtitle_3_2')}
      </div>
      <div className="white-space-pre font-16 display-contents">
        {t('accessibility.subtitle_3_specification')}
      </div>
      <div className="white-space-pre font-16">
        <div className="font-16 display-contents">{t('accessibility.privacy_1')}</div>
        <div
          className="c-862633 cursor-pointer font-16 display-contents"
          onClick={() =>
            window.open(`mailto:${t('accessibility.privacy_email').replaceAll(' ', '')}`)
          }
        >
          {t('accessibility.privacy_email')}
        </div>
        <div className="font-16 display-contents">{t('accessibility.privacy_2')}</div>
        <div
          className="c-862633 cursor-pointer font-16 display-contents"
          onClick={() => window.open(t('accessibility.privacy_link'))}
        >
          {t('accessibility.privacy_link')}
        </div>
        <div className="font-16 display-contents">{t('accessibility.privacy_3')}</div>
      </div>

      <div className="mt-4 col-md-4 d-flex align-items-center justify-content-between">
        <div className="font-16">{t('accessibility.title_4')}</div>
        <Button
          flavor="transparentPrimary"
          text={t('commons.download')}
          classes="m-0 p-0 text-start"
          classesText="font-16 c-862633 text-uppercase"
          classesIconRight="filter-862633 ms-2"
          iconRight={DownloadIcon}
          iconSize={20}
          onPress={() => {
            if (accessibilityLink) {
              window.open(accessibilityLink, '_blank')
            }
          }}
        />
      </div>
      <div className="mt-5 mb-5" />
    </Container>
  )
}
