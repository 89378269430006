export const PROGRESS_BAR_STEP = {
  claim_report_summary: 7,
  claim_report_type: 14,
  claim_report_weather: 21,
  claim_report_weather_declaration: 28,
  claim_report_weather_insured_person: 35,
  claim_report_weather_information: 42,
  claim_report_weather_document: 49,
  claim_report_rc_injury_document: 21,
  claim_report_rc_injury_date_hour: 28,
  claim_report_rc_injury_insured_person: 35,
  claim_report_rc_injury_personal_data: 42,
  claim_report_rc_injury_request_reason: 49,
  claim_report_rc_injury_report_injury: 56,
  claim_report_rc_injury_report_damage_step1: 56,
  claim_report_rc_injury_report_damage_step2: 63,
  claim_report_rc_injury_refound: 56,
  claim_report_open: 70,
  claim_report_open_summary: 100,
}
