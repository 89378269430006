/* eslint-disable complexity */

import { typedMemo } from 'src/types'

import { useDataEntryStep4 } from './data-entry-step4.hook'

export const DataEntryStep4 = typedMemo(() => {
  const {} = useDataEntryStep4()

  return (
    <div className="ps-md-3 pe-md-3">
      <div className="d-flex justify-content-center">
        <div id="my-axepta-sdk-pg" />
      </div>
    </div>
  )
})
