import { Dispatch, SetStateAction } from 'react'
import { Image } from 'react-component-library'
import { TFunction } from 'react-i18next'
import {
  ConfirmedBoldIcon,
  DeleteIcon,
  FailureIcon,
  ItasCamera,
  ItasGallery,
  Upload,
} from 'src/assets'
import { Button, Modal, Tooltip } from 'src/ui/widgets'

type ClaimTranslation = {
  t: TFunction<'translation', undefined>
}

type ClaimUploadDocumentModalErrorProps = ClaimTranslation & {
  isOutOfSizeFile: boolean
  setIsOutOfSizeFile: Dispatch<SetStateAction<boolean>>
}

type ClaimUploadDocumentTooltipInfoProps = ClaimTranslation & {
  tooltipLabel: string
}

type ClaimUploadDocumentModalUploadProps = ClaimTranslation & {
  id: string
  inputFileAccept: string
  uploadDocument: (file: File) => void
  setShowUploadModal: Dispatch<SetStateAction<boolean>>
}

type ClaimUploadDocumentUploadFromDesktopProps = ClaimTranslation & {
  id: string
  file: File | undefined
  inputFileAccept: string
  uploadDocument: (file: File) => void
}

type ClaimUploadDocumentUploadFromMobileProps = ClaimTranslation & {
  file: File | undefined
  id: string
  setShowUploadModal: Dispatch<SetStateAction<boolean>>
  setIdFile?: Dispatch<SetStateAction<string>>
}

type ClaimUploadDocumentUploadFileProps = ClaimTranslation & {
  id: string
  isMobile: boolean
  file: File | undefined
  inputFileAccept: string
  uploadDocument: (file: File) => void
  setShowUploadModal: Dispatch<SetStateAction<boolean>>
  setIdFile?: Dispatch<SetStateAction<string>>
}

type ClaimUploadDocumentInfoFileProps = {
  file: File | undefined
  deleteDocument: () => void
}

export const ClaimUploadDocumentModalError = (props: ClaimUploadDocumentModalErrorProps) => {
  const { t, isOutOfSizeFile, setIsOutOfSizeFile } = props
  return (
    <Modal
      visible={isOutOfSizeFile}
      onClose={() => setIsOutOfSizeFile(false)}
      classes="d-flex justify-content-center"
    >
      <div>
        <div className="d-flex align-items-center justify-content-center">
          <Image src={FailureIcon} width={64} height={64} />
        </div>
        <div className="m-3 font-14">{t('commons.max_exceeded')}</div>
      </div>
    </Modal>
  )
}

export const ClaimUploadDocumentTooltipInfo = (props: ClaimUploadDocumentTooltipInfoProps) => {
  const { t, tooltipLabel } = props
  return (
    <div className="d-flex align-items-center">
      <div className="d-flex">
        <div className="font-12 c-7f7f7f">{tooltipLabel}</div>
      </div>
      <div className="ps-2">
        <Tooltip placement="top" iconSize={15}>
          <div className="font-14 c-292929 d-flex flex-column align-items-start">
            <div>{t('commons.tooltip_info_file_1')}</div>
            <div>{t('commons.tooltip_info_file_2')}</div>
          </div>
        </Tooltip>
      </div>
    </div>
  )
}

export const ClaimUploadDocumentModalUpload = (props: ClaimUploadDocumentModalUploadProps) => {
  const { t, id, inputFileAccept, uploadDocument, setShowUploadModal } = props

  return (
    <div key={`key-id-${id}`}>
      <div className="claim-modal-backgroud-upload" />
      <div className="claim-modal-upload">
        <div className="p-3 bg-ffffff h-100">
          <div className="d-flex justify-content-center align-items-center font-16-semibold">
            {t('commons.upload_title')}
          </div>

          <div className="mt-4">
            <label htmlFor={`upload-input-gallery-${id}`}>
              <div className="d-flex align-items-center">
                <Image src={ItasGallery} width={24} height={24} />
                <div className="ms-3 font-16 c-7f7f7f">{t('commons.upload_gallery')}</div>
              </div>
            </label>
            <input
              onChange={(e) => {
                if (e?.target?.files) uploadDocument(e?.target?.files[0])
              }}
              type="file"
              accept={inputFileAccept}
              capture="environment"
              className="d-none"
              id={`upload-input-gallery-${id}`}
            />
          </div>

          <div className="mt-4">
            <label htmlFor={`upload-input-photo-${id}`}>
              <div className="d-flex align-items-center">
                <Image src={ItasCamera} width={24} height={24} />
                <div className="ms-3 font-16 c-7f7f7f">{t('commons.upload_photo')}</div>
              </div>
            </label>
            <input
              onChange={(e) => {
                if (e?.target?.files) uploadDocument(e?.target?.files[0])
              }}
              type="file"
              accept={inputFileAccept}
              capture="environment"
              className="d-none"
              id={`upload-input-photo-${id}`}
            />
          </div>

          <Button
            flavor="accent"
            text={t('commons.cancel')}
            classes="mt-4 ps-4 pe-4 d-flex justify-content-center m-auto w-100"
            classesText="font-14-semibold text-uppercase"
            onPress={() => setShowUploadModal(false)}
          />
        </div>
      </div>
    </div>
  )
}

export const ClaimUploadDocumentUploadFromDesktop = (
  props: ClaimUploadDocumentUploadFromDesktopProps
) => {
  const { t, id, file, inputFileAccept, uploadDocument } = props

  return (
    <div className="ms-2">
      <div>
        {!file && (
          <input
            onChange={(e) => {
              if (e?.target?.files) uploadDocument(e?.target?.files[0])
            }}
            type="file"
            accept={inputFileAccept}
            className="d-none"
            id={`upload-input-${id}`}
          />
        )}
      </div>
      <label
        htmlFor={`upload-input-${id}`}
        className={`font-14-semibold c-7c2d35 text-underline ${!file && 'cursor-pointer'}`}
      >
        {t('commons.upload_document')?.toUpperCase()}
      </label>
    </div>
  )
}

export const ClaimUploadDocumentUploadFromMobile = (
  props: ClaimUploadDocumentUploadFromMobileProps
) => {
  const { t, file, id, setIdFile, setShowUploadModal } = props
  return (
    <div
      className="ms-2 font-14-semibold text-underline c-7c2d35"
      onClick={() => {
        !file && setShowUploadModal(true)
        setIdFile?.(id)
      }}
    >
      {t('commons.upload_document')?.toUpperCase()}
    </div>
  )
}

export const ClaimUploadDocumentUploadFile = (props: ClaimUploadDocumentUploadFileProps) => {
  const { t, id, isMobile, file, inputFileAccept, setIdFile, uploadDocument, setShowUploadModal } =
    props
  return (
    <div className="mt-2 d-flex align-items-center">
      <Image src={Upload} width={20} height={20} />

      {isMobile ? (
        <ClaimUploadDocumentUploadFromMobile
          t={t}
          id={id}
          file={file}
          setShowUploadModal={setShowUploadModal}
          setIdFile={setIdFile}
        />
      ) : (
        <ClaimUploadDocumentUploadFromDesktop
          t={t}
          id={id}
          file={file}
          inputFileAccept={inputFileAccept}
          uploadDocument={uploadDocument}
        />
      )}
    </div>
  )
}

export const ClaimUploadDocumentInfoFile = (props: ClaimUploadDocumentInfoFileProps) => {
  const { file, deleteDocument } = props
  return (
    <div className="mt-4 bg-f5e9da col-12 col-md-5 d-flex align-items-center">
      <Image src={ConfirmedBoldIcon} width={20} height={20} />
      <div className="ps-3 col-10 font-16-semibold">{file?.name}</div>
      <div className="col-1 d-md-flex justify-content-end cursor-pointer">
        <Image src={DeleteIcon} width={20} height={20} onClick={deleteDocument} />
      </div>
    </div>
  )
}
