import i18n from 'src/i18n'
import { Asset, ClaimQuote, Contractor, Optional, ReactSelectOption } from 'src/types'
import {
  ClaimData,
  ClaimDataInsured,
  ClaimDetailsSection,
  ClaimOpen,
  ClaimOpeningConfirmationSection,
  ClaimOtherData,
  ClaimWeatherData,
  CostFileRefounds,
  DamageDetailsSetions,
  DamageRequest,
  InjuryPersonalDataSection,
} from 'src/types/pages/claim.types'

import { getOpenInformations } from './format-open-informations'
import { getOtherRequest } from './format-other-request'
import { getWeatherRequest } from './format-weather-request'

const getInsured = (insuredType: string, claimQuote: Optional<ClaimQuote>) => {
  if (!insuredType) return undefined

  let insured_: Contractor | Asset | undefined = undefined

  if (insuredType === 'contractor') {
    insured_ = claimQuote?.contractor
  } else {
    const indexAssests = Number(insuredType?.split('_')[1])

    insured_ = claimQuote?.assets?.[indexAssests]
  }

  return {
    name: insured_?.name || '-',
    surname: insured_?.surname || '-',
  }
}

const getMotivation = (claimRequestObject: ClaimOtherData, selectedType: string): string => {
  if (selectedType === 'weather') {
    return i18n.t('claim_report_open_summary.motivations.weather')
  } else if (selectedType === 'other') {
    return i18n.t(`claim_report_open_summary.motivations.${claimRequestObject?.requestType}`)
  }
  return '-'
}

export const getClaimDetails = (
  claimRequestObject: ClaimOtherData | ClaimWeatherData,
  selectedType: string,
  claimQuote: Optional<ClaimQuote>
): ClaimDetailsSection | undefined => {
  const insured_ = getInsured(claimRequestObject?.insuredType || '', claimQuote)
  const motivation_ = getMotivation(claimRequestObject as ClaimOtherData, selectedType)
  const skiResort_ =
    selectedType === 'weather' ? (claimRequestObject as ClaimWeatherData)?.skiResort : undefined

  return {
    date: claimRequestObject?.date || '-',
    hour: claimRequestObject?.hour || '-',
    name: insured_?.name || '-',
    surname: insured_?.surname || '-',
    motivation: motivation_ || '-',
    skyResort: skiResort_,
  }
}

export const getInjuryPersonalData = (
  damageRequestObject: DamageRequest | undefined
): InjuryPersonalDataSection | undefined => {
  if (!damageRequestObject) return undefined

  const personalData: ClaimDataInsured | undefined = damageRequestObject?.personalData

  return {
    isAvailable: damageRequestObject?.injuredPersonKnown === 'yes_id' || false,
    name: personalData?.name || undefined,
    surname: personalData?.surname || undefined,
    fiscalCode: personalData?.fiscalCode || undefined,
    email: personalData?.email || undefined,
    mobile: personalData?.mobile || undefined,
    residence: personalData?.residence || undefined,
  }
}

// eslint-disable-next-line complexity
export const getDamageDetails = (
  claimRequestObject: ClaimOtherData
): DamageDetailsSetions | undefined => {
  if (!claimRequestObject) return undefined

  const requestType = claimRequestObject?.requestType

  if (!requestType || requestType === 'refound') return undefined

  let damageDetails: DamageDetailsSetions | undefined = undefined

  if (requestType === 'injury') {
    const injuryObject = claimRequestObject?.request?.injury

    damageDetails = {
      type: i18n.t(`claim_report_open_summary.injury_type.item_${injuryObject?.type}`),
      damageCaused: [i18n.t('claim_report_open_summary.injury_damage_caused_personal')],
      extentDamage: injuryObject?.extentDamage
        ? injuryObject?.extentDamage === 'yes_id'
          ? i18n.t('claim_report_open_summary.extent_high')
          : injuryObject?.extentDamage === 'no_id'
          ? i18n.t('claim_report_open_summary.extent_low')
          : undefined
        : undefined,
      injurySections: injuryObject?.injurySections || undefined,
      damageDescription: injuryObject?.damageDescription || undefined,
    }
  } else if (requestType === 'damage') {
    const mapperDamageCaused: Record<string, string> = {
      damage_injury_id: i18n.t('claim_report_open_summary.damage_injury_id'),
      damage_things_id: i18n.t('claim_report_open_summary.damage_things_id'),
    }

    const damageObject = claimRequestObject?.request?.damage

    damageDetails = {
      damageCaused:
        damageObject?.damageDoneType?.map((type) => mapperDamageCaused[type]) || undefined,
      damageObject: damageObject?.damageRequestThings?.whatDamage || undefined,
      damageEstimation: damageObject?.damageRequestThings?.damageValue || undefined,
    }
  }

  return damageDetails
}

export const getRequestDetails = (
  refoundRequestObject: CostFileRefounds[] | undefined
): ReactSelectOption[] | undefined => {
  if (!refoundRequestObject || refoundRequestObject?.length === 0) return undefined

  const requestObject: ReactSelectOption[] = []

  refoundRequestObject?.map((item) => {
    if (item?.cost) {
      requestObject?.push(item?.cost)
    }
  })

  return requestObject
}

// eslint-disable-next-line complexity
export const getClaimOpeningConfirmation = (
  openingObject: ClaimOpen | undefined,
  quoteEmail: string
): ClaimOpeningConfirmationSection | undefined => {
  if (!openingObject) return undefined

  const contacts_ =
    openingObject?.contact?.type === 'email'
      ? `${i18n.t('claim_report_open_summary.email')} - ${openingObject?.contact?.emailValue}`
      : openingObject?.contact?.type === 'mobile'
      ? `${i18n.t('claim_report_open_summary.mobile')} - ${openingObject?.contact?.mobileValue}`
      : ''

  const timeSlots_: string[] = []
  if (openingObject?.contact?.timeSlots && openingObject?.contact?.timeSlots?.length > 0) {
    openingObject?.contact?.timeSlots?.map((item) => {
      if (item?.length > 0) {
        item?.map((timeSlot) => {
          if (timeSlot?.isSelected) {
            timeSlots_.push(timeSlot?.value)
          }
        })
      }
    })
  }

  return {
    reportingData:
      openingObject?.insuredType === 'third_person'
        ? i18n.t('claim_report_open.third_person')
        : `${openingObject?.name} ${openingObject?.surname}`,
    name: openingObject?.insuredType === 'third_person' ? openingObject?.name : undefined,
    surname: openingObject?.insuredType === 'third_person' ? openingObject?.surname : undefined,
    birth: openingObject?.birth,
    gender: openingObject?.gender
      ? i18n.t(`claim_report_open_summary.${openingObject?.gender}`)
      : undefined,
    nationality: openingObject?.nationality || undefined,
    birthNation: openingObject?.birthNation || undefined,
    birthPlace: openingObject?.birthPlace || undefined,
    residence: openingObject?.residence || undefined,
    fiscalCode: openingObject?.fiscalCode || undefined,
    contact: contacts_,
    timeSlots: timeSlots_,
    otherInformation: openingObject?.otherInformation || undefined,
    email:
      (openingObject?.contact?.emailValue ? openingObject?.contact?.emailValue : quoteEmail) ||
      undefined,
  }
}

export const formatClaimPostData = (claimData: Optional<ClaimData>): ClaimData => {
  let formatedClaimData: ClaimData = {}
  formatedClaimData = {
    openInformations: getOpenInformations(claimData?.openInformations || undefined),
    other:
      claimData?.selectedType === 'other'
        ? getOtherRequest(claimData?.other || undefined)
        : undefined,
    selectedType: claimData?.selectedType,
    weather:
      claimData?.selectedType === 'weather'
        ? getWeatherRequest(claimData?.weather || undefined)
        : undefined,
  }
  return formatedClaimData
}
