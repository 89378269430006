import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useRecoilState, useRecoilValue } from 'recoil'
import { Paths } from 'src/navigation'
import { regexPhone } from 'src/regex'
import { atomClaimData, atomClaimQuote, atomProgressBar } from 'src/storage'
import { FormikClaimReportDamageStep1, InputRadioOption } from 'src/types'
import { yupStr, yupStrRequired } from 'src/utils'
import * as Yup from 'yup'

import { PROGRESS_BAR_STEP } from '../../../progress-bar-step'

export const UseClaimReportRcInjuryReportDamageStep1 = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const optionPersonaDataDamage: InputRadioOption[] = [
    {
      id: 'yes_id',
      value: t('claim_report_rc_injury_report_damage_step1.answer_personal_data_yes'),
    },
    {
      id: 'no_id',
      value: t('claim_report_rc_injury_report_damage_step1.answer_personal_data_no'),
    },
  ]

  const claimQuote = useRecoilValue(atomClaimQuote)
  const [claimData, setClaimData] = useRecoilState(atomClaimData)
  const [progressBar, setProgressBar] = useRecoilState(atomProgressBar)

  const [isMobile, setIsMobile] = useState(window.innerWidth < 810)
  const [initialValues] = useState<FormikClaimReportDamageStep1>({
    injuredPersonKnown: claimData?.other?.request?.damage?.injuredPersonKnown || '',
    name: claimData?.other?.request?.damage?.personalData?.name || '',
    surname: claimData?.other?.request?.damage?.personalData?.surname || '',
    fiscalCode: claimData?.other?.request?.damage?.personalData?.fiscalCode || '',
    residence: claimData?.other?.request?.damage?.personalData?.residence || '',
    mobile: claimData?.other?.request?.damage?.personalData?.mobile || '',
    email: claimData?.other?.request?.damage?.personalData?.email || '',
  })

  const validationSchema = Yup.object().shape({
    injuredPersonKnown: yupStrRequired,
    name: yupStr.when('injuredPersonKnown', (item, schema) => {
      return item === 'yes_id' ? schema.required(t('commons.required')) : schema
    }),
    surname: yupStr.when('injuredPersonKnown', (item, schema) => {
      return item === 'yes_id' ? schema.required(t('commons.required')) : schema
    }),
    fiscalCode: yupStr,
    residence: yupStr,
    mobile: yupStr.matches(regexPhone, t('commons.error_is_wrong')),
    email: yupStr,
  })

  //////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    setTimeout(() => {
      setProgressBar(PROGRESS_BAR_STEP.claim_report_rc_injury_report_damage_step1)
    }, 100)
  }, [])

  useEffect(() => {
    if (!claimQuote) {
      navigate(Paths.ClaimReport)
    }
  }, [claimQuote])

  useEffect(() => {
    window.addEventListener(
      'resize',
      () => {
        const ismobile = window.innerWidth < 810
        if (ismobile !== isMobile) setIsMobile(ismobile)
      },
      false
    )
  }, [isMobile])

  //////////////////////////////////////////////////////////////////////////////

  const formik = useFormik<FormikClaimReportDamageStep1>({
    initialValues: initialValues,
    validationSchema: validationSchema,

    onSubmit: (values) => {
      setClaimData({
        ...claimData,
        other: {
          ...claimData?.other,
          request: {
            ...claimData?.other?.request,
            damage: {
              ...claimData?.other?.request?.damage,
              injuredPersonKnown: values?.injuredPersonKnown,
              ...(values?.injuredPersonKnown === 'yes_id'
                ? {
                    personalData: {
                      name: values?.name,
                      surname: values?.surname,
                      fiscalCode: values?.fiscalCode,
                      residence: values?.residence,
                      mobile: values?.mobile,
                      email: values?.email,
                    },
                  }
                : { personalData: undefined }),
            },
          },
        },
      })
      navigate(Paths.ClaimReportRcInjuryReportDamageStep2)
    },
  })

  //////////////////////////////////////////////////////////////////////////////

  const goBack = () => {
    navigate(Paths.ClaimReportRcInjuryRequestReason)
    setClaimData({
      ...claimData,
      other: {
        ...claimData?.other,
        request: {},
      },
    })
  }

  return {
    t,
    progressBar,
    formik,
    validationSchema,
    isMobile,
    optionPersonaDataDamage,
    goBack,
  }
}
