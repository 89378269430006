import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router'
import { Paths } from 'src/navigation'
import { usePostOpenClaim } from 'src/networking/terminals/claim/claim.gates'

export const useClaimOpening = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const data = new URLSearchParams(useLocation().search).get('data')?.toString() || ''
  const { pending: pendingOpeningClaim, crossGate: postOpenClaim } = usePostOpenClaim()

  useEffect(() => {
    if (data) {
      postOpenClaim(
        {
          body: {
            claimCode: data,
          },
        },
        {
          onSuccess: (response) => {
            if (response.outcome) {
              navigate(
                `${Paths.ClaimIsOpen}?name=${response.name}&code=${response.code}&open=${response.isOpen}&contact=${response.contact}`
              )
            }
          },
          onFailure: () => {
            navigate(Paths.ClaimReportError)
          },
          onPending: () => {},
        }
      )
    }
  }, [])
  return { t, pendingOpeningClaim }
}
