import { Image } from 'react-component-library'
import {
  FamilyLanding,
  FamilyLandingMobile,
  SeasonalLanding,
  SeasonalLandingMobile,
} from 'src/assets'

import { useSection2Special } from './section2-special.hook'

export const Section2Special = () => {
  const { t, product } = useSection2Special()

  const SeasonalCard = (
    <div className="ms-md-3 me-md-2 p-4 section2-card-special d-md-flex">
      <div>
        <div className="mb-4 text-uppercase section2-special-dynamic-size-title text-center text-md-start">
          {t('landing.section_2_special_seasonal_title')}
        </div>

        <div className="pe-md-5 me-md-5 section2-special-dynamic-size-subtitle text-center text-md-start">
          <div className="d-inline-block">
            <div className="py-md-2 display-contents">
              {t('landing.section_2_special_seasonal_subtitle_1')}
            </div>
            <div className="py-md-2 display-contents bold c-2727ab">
              {t('landing.section_2_special_seasonal_subtitle_2')}
            </div>
            <div className="py-md-2 display-contents">
              {t('landing.section_2_special_seasonal_subtitle_3')}
            </div>
            <div className="py-md-2 display-contents bold c-2727ab">
              {t('landing.section_2_special_seasonal_subtitle_4')}
            </div>
            <div className="py-md-2 display-contents">
              {t('landing.section_2_special_seasonal_subtitle_5')}
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-4">
        <Image
          src={SeasonalLanding}
          alt="SeasonalLanding"
          width="100%"
          height="100%"
          className="image-radius d-none d-md-block"
        />
        <Image
          src={SeasonalLandingMobile}
          alt="SeasonalLandingMobile"
          width="100%"
          height="100%"
          className="mt-4 image-radius d-block d-md-none"
        />
      </div>
    </div>
  )

  const FamilyCard = (
    <div className="ms-md-3 me-md-2 p-4 section2-card-special">
      <div className="d-flex justify-content-center">
        <div className="mb-4 section2-title-news">
          <div className="text-center font-18-bold text-uppercase c-2727ab">
            {t('landing.section_2_special_news')}
          </div>
        </div>
      </div>

      <div className="d-md-flex">
        <div>
          <div className="mb-4 text-uppercase section2-special-dynamic-size-title text-center text-md-start">
            {t('landing.section_2_special_family_title')}
          </div>

          <div className="pe-md-5 me-md-5 section2-special-dynamic-size-subtitle text-center text-md-start">
            <div className="d-inline-block">
              <div className="py-md-2 display-contents">
                {t('landing.section_2_special_family_subtitle_1')}
              </div>
              <div className="py-md-2 display-contents bold c-2727ab">
                {t('landing.section_2_special_family_subtitle_2')}
              </div>
              <div className="py-md-2 display-contents">
                {t('landing.section_2_special_family_subtitle_3')}
              </div>
              <div className="py-md-2 display-contents bold c-2727ab">
                {t('landing.section_2_special_family_subtitle_4')}
              </div>
              <div className="py-md-2 display-contents">
                {t('landing.section_2_special_family_subtitle_5')}
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <Image
            src={FamilyLanding}
            alt="FamilyLandinge"
            width="100%"
            height="100%"
            className="image-radius d-none d-md-block"
          />
          <Image
            src={FamilyLandingMobile}
            alt="FamilyLandingMobile"
            width="100%"
            height="100%"
            className="mt-4 image-radius d-block d-md-none"
          />
        </div>
      </div>
    </div>
  )

  return (
    <>
      {product?.configuration?.landingCard && (
        <div className="ms-md-4 me-md-4">
          {product?.configuration?.landingCard === 'seasonal' && SeasonalCard}
          {product?.configuration?.landingCard === 'family' && FamilyCard}
        </div>
      )}
    </>
  )
}
