import { Children, cloneElement } from 'react'
import { Trans } from 'react-i18next'

import styles from './Digit.module.css'
import { useInputDigits } from './input-digits.hook'

export function InputDigits({
  className,
  error,
  success = false,
  disabled,
  successText,
  onDigitsChange,
  hidden,
  children,
}: any) {
  const { values, isMobile, focusedIndex, handleDigitChange, changeFocus } = useInputDigits({
    onDigitsChange,
  })

  const Digits = Children.map(children, (digit, index) =>
    cloneElement(digit, {
      index: index,
      error: error,
      success: success,
      disabled: disabled,
      value: digit?.props?.defaultValue
        ? digit?.props?.defaultValue
        : values[index]
        ? values[index]
        : null,
      focused: index === focusedIndex,
      hidden: hidden || false,
      onDigitChange: handleDigitChange,
      changeFocus,
    })
  )

  return (
    <div>
      <div className={`${isMobile ? 'd-flex justify-content-center' : ''}`}>
        <div
          className={`${isMobile ? `${`${className} ${styles?.max_digits_width}`}` : className} `}
        >
          {Digits}
        </div>
      </div>
      {error && (
        <div className="ps-1 mt-2 font-14 c-7c2d35">
          <Trans i18nKey={error} />
        </div>
      )}
      {success && (
        <div className="ps-1 mt-2 font-14 c-059881">
          <Trans i18nKey={successText} />
        </div>
      )}
    </div>
  )
}
