import './section6.scss'

import { useTranslation } from 'react-i18next'
import { useRecoilValue } from 'recoil'
import { selectorLogos } from 'src/storage'
export const useSection6 = () => {
  const { t } = useTranslation()

  const logos = useRecoilValue(selectorLogos)

  const contactUs = () => {
    window.open(`mailto:${t('footer.email')}`)
  }

  return {
    t,
    logos,
    contactUs,
  }
}
