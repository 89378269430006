/* eslint-disable complexity */

import { ProgressBar } from 'react-bootstrap'
import { Image } from 'react-component-library'
import { Trans } from 'react-i18next'
import {
  ConfirmedBoldIcon,
  DeleteIcon,
  FailureIcon,
  ItasCamera,
  ItasGallery,
  Upload,
} from 'src/assets'
import { ScrollTopRoot } from 'src/roots'
import { Button, Loader, Modal, Tooltip } from 'src/ui/widgets'

import { UseClaimReportRcInjuryDocument } from './claim-report-rc-injury-document.hook'

export const ClaimReportRcInjuryDocument = () => {
  const {
    t,
    file,
    showUploadModal,
    isOutOfSizeFile,
    inputFileAccept,
    progressBar,
    isMobile,
    isLoaded,
    loadingFile,
    goBack,
    goNext,
    uploadDocument,
    setIsOutOfSizeFile,
    setShowUploadModal,
    deleteDocument,
  } = UseClaimReportRcInjuryDocument()

  const Topbar = (
    <Button
      flavor="transparentAccentBack"
      text={t('commons.back')}
      classes="px-0 pt-0"
      classesText="text-uppercase font-14-semibold"
      onPress={goBack}
    />
  )

  const Title = (
    <div className="font-28-semibold c-292929">{t('claim_report_rc_injury_document.title')}</div>
  )

  const Subtitle = (
    <div className="mt-2 font-14-semibold c-292929 text-uppercase">
      {t('claim_report_rc_injury_document.subtitle')}
    </div>
  )

  const InsertLabel = (
    <div className="mt-3 font-16 c-292929">
      <Trans i18nKey={'claim_report_rc_injury_document.insert_label'} />
    </div>
  )

  const UploadFromDesktop = (
    <div className="mt-4 d-flex justify-content-center align-items-center">
      <div
        className={`${file ? 'custom-input-upload' : 'custom-input-upload custom-label-pointer'}`}
      >
        <label htmlFor="upload-input">
          <Image src={Upload} width={100} height={100} />
        </label>
        {!file && (
          <input
            onChange={(e) => {
              if (e?.target?.files) uploadDocument(e?.target?.files[0])
            }}
            type="file"
            accept={inputFileAccept}
            className="d-none"
            id="upload-input"
          />
        )}
      </div>
    </div>
  )

  const ModalUpload = (
    <div>
      <div className="claim-modal-backgroud-upload" />
      <div className="claim-modal-upload">
        <div className="p-3 bg-ffffff h-100">
          <div className="d-flex justify-content-center align-items-center font-16-semibold">
            {t('claim_report_rc_injury_document.upload_title')}
          </div>

          <div
            className={`"mt-4 ${
              file ? 'custom-input-upload custom-label-pointer' : 'custom-input-upload'
            }`}
          >
            <label htmlFor="upload-input-gallery">
              <div className="d-flex align-items-center">
                <Image src={ItasGallery} width={24} height={24} />
                <div className="ms-3 font-16 c-7f7f7f">
                  {t('claim_report_rc_injury_document.upload_gallery')}
                </div>
              </div>
            </label>
            <input
              onChange={(e) => {
                if (e?.target?.files) uploadDocument(e?.target?.files[0])
              }}
              type="file"
              accept={inputFileAccept}
              capture="environment"
              className="d-none"
              id="upload-input-gallery"
            />
          </div>

          <div
            className={`"mt-4 ${
              file ? 'custom-input-upload custom-label-pointer' : 'custom-input-upload'
            }`}
          >
            <label htmlFor="upload-input-photo">
              <div className="d-flex align-items-center">
                <Image src={ItasCamera} width={24} height={24} />
                <div className="ms-3 font-16 c-7f7f7f">
                  {t('claim_report_rc_injury_document.upload_photo')}
                </div>
              </div>
            </label>
            <input
              onChange={(e) => {
                if (e?.target?.files) uploadDocument(e?.target?.files[0])
              }}
              type="file"
              accept={inputFileAccept}
              capture="environment"
              className="d-none"
              id="upload-input-photo"
            />
          </div>

          <Button
            flavor="accent"
            text={t('commons.cancel')}
            classes="mt-4 ps-4 pe-4 d-flex justify-content-center m-auto w-100"
            classesText="font-14-semibold text-uppercase"
            onPress={() => setShowUploadModal(false)}
          />
        </div>
      </div>
    </div>
  )

  const UploadFromMobile = (
    <div className="mt-4 d-flex justify-content-center align-items-center">
      <div onClick={() => !file && setShowUploadModal(true)}>
        <Image src={Upload} width={100} height={100} />
      </div>
    </div>
  )

  const TooltipInfo = (
    <div className="font-14 c-292929 d-flex flex-column align-items-start">
      <div>{t('commons.tooltip_info_file_1')}</div>
      <div>{t('commons.tooltip_info_file_2')}</div>
    </div>
  )

  const UploadLabel = (
    <div className="mt-3 d-flex align-items-center justify-content-center">
      <div className="font-14 c-7f7f7f">{t('claim_report_rc_injury_document.upload_label')}</div>
      <div className="ps-1">
        <Tooltip placement="top" iconSize={15}>
          {TooltipInfo}
        </Tooltip>
      </div>
    </div>
  )

  const InfoFile = (
    <div className="d-flex justify-content-center align-items-center">
      <div className="p-2 mt-3 bg-f5e9da col-12 col-md-8 d-flex align-items-center">
        <div className="col-1 d-md-flex justify-content-start">
          <Image src={ConfirmedBoldIcon} width={20} height={20} />
        </div>
        <div className="ps-3 col-10 font-16-semibold">{file?.name}</div>
        <div className="col-1 d-md-flex justify-content-end cursor-pointer">
          <Image src={DeleteIcon} width={20} height={20} onClick={deleteDocument} />
        </div>
      </div>
    </div>
  )

  const ButtonForm = (
    <div className={`${file ? 'mt-2 pt-1' : 'mt-5 pt-2'} d-flex justify-content-md-end`}>
      <div className="col-12 col-md-4">
        <Button
          flavor="accentNext"
          text={t('commons.continue')}
          classes="ps-4 ps-md-3 pe-4 pe-md-3 m-auto m-md-0 w-100 d-flex justify-content-between"
          classesText="font-14-semibold text-uppercase"
          disabled={!isLoaded && !file}
          onPress={goNext}
        />
      </div>
    </div>
  )

  const ModalError = (
    <Modal
      visible={isOutOfSizeFile}
      onClose={() => setIsOutOfSizeFile(false)}
      classes="d-flex justify-content-center"
    >
      <div>
        <div className="d-flex align-items-center justify-content-center">
          <Image src={FailureIcon} width={64} height={64} />
        </div>
        <div className="m-3 font-14">{t('claim_report_rc_injury_document.max_exceeded')}</div>
      </div>
    </Modal>
  )

  return (
    <div className="mb-md-5 pb-md-5 ps-md-3 pe-md-3 d-md-flex justify-content-md-center container-fluid">
      <div className="col-12 col-lg-7">
        <div className="mt-4">
          <ScrollTopRoot />
          {Topbar}
          <div className="claim-progress">
            <ProgressBar now={progressBar} className="claim-progress-bar" />
          </div>
          <div className="p-4 ps-3 pe-3 p-md-5 bg-ffffff">
            {Title}
            {Subtitle}
            {InsertLabel}
            {isMobile ? UploadFromMobile : UploadFromDesktop}
            {UploadLabel}
            {file && InfoFile}
            <div className="pt-5">{ButtonForm}</div>
          </div>
          {showUploadModal && ModalUpload}
          {isOutOfSizeFile && ModalError}
        </div>
        {loadingFile && <Loader overlay />}
      </div>
    </div>
  )
}
