/* eslint-disable complexity */
import './input-upload-file.scss'

import { Image } from 'react-component-library'
import { AlertCircleIcon, DeleteIcon, FailureIcon, SuccessCircleIcon, Upload } from 'src/assets'
import { InputUploadFileProps } from 'src/types'
import { Button, Modal } from 'src/ui/widgets'

import { useInputUploadFile } from './input-upload-file.hook'

const ACCEPTED_FILE_TYPES = '.xlsx'

export const InputUploadFile = (props: InputUploadFileProps) => {
  const { selectedFilesNames, width, height, multiple, showAlert = false, fileStatus } = props

  const {
    t,
    isShowAlert,
    isOutOfSizeFile,
    handleDrop,
    confirmAction,
    setIsShowAlert,
    handleFileChange,
    setIsOutOfSizeFile,
  } = useInputUploadFile(props)

  const StatusUpload = (
    <div>
      {selectedFilesNames && selectedFilesNames?.length > 0 && fileStatus && fileStatus === 'OK' && (
        <div className="font-14-bold success-box">
          {t('upload_file.upload_success')}
          <Image src={SuccessCircleIcon} width={20} height={20} className="ms-2 ms-md-3" />
        </div>
      )}
      {selectedFilesNames &&
        selectedFilesNames?.length > 0 &&
        fileStatus &&
        fileStatus === 'PARTIAL_ERRORS' && (
          <div className="font-14-bold errors-box">
            {t('upload_file.upload_partial_errors')}
            <Image src={AlertCircleIcon} width={20} height={20} className="ms-2 ms-md-3" />
          </div>
        )}
      {selectedFilesNames &&
        selectedFilesNames?.length > 0 &&
        fileStatus &&
        fileStatus === 'ERRORS' && (
          <div className="errors-box d-flex align-items-center">
            <div className="ps-3 ps-md-none">
              <div className="font-14-bold">{t('upload_file.upload_errors_title')}</div>
              <div className="font-14">{t('upload_file.upload_errors_subtitle')}</div>
            </div>
            <Image src={AlertCircleIcon} width={20} height={20} className="ms-md-3" />
          </div>
        )}
    </div>
  )

  const UploadBox = (
    <div className="drag-drop" style={{ width: width, height: height }}>
      <div
        className={`document-uploader ${
          selectedFilesNames && selectedFilesNames.length > 0 ? 'upload-box active' : 'upload-box'
        } ${
          fileStatus === 'OK'
            ? 'border-ok'
            : fileStatus === 'PARTIAL_ERRORS'
            ? 'border-partial-errors'
            : fileStatus === 'ERRORS'
            ? 'border-errors'
            : 'border-neutral'
        }`}
        onDrop={handleDrop}
        onDragOver={(event) => event.preventDefault()}
      >
        <div
          className={`${
            selectedFilesNames && selectedFilesNames.length > 0 ? 'd-none' : 'internal-box'
          }`}
        >
          <>
            <div className="text-center font-14 d-none d-md-block">
              <div>{t('upload_file.title')}</div>
            </div>
            <input
              type="file"
              hidden
              id="browse"
              onChange={handleFileChange}
              accept={ACCEPTED_FILE_TYPES}
              multiple={multiple}
            />
            <Image src={Upload} width={100} height={100} className="filter-4145e5 mt-3 mb-3" />
            <label
              htmlFor="browse"
              className="font-15-semibold text-underline browse-btn d-none d-md-block"
            >
              {t('upload_file.upload_label_computer')}
            </label>
            <label
              htmlFor="browse"
              className="font-15-semibold text-underline browse-btn d-block d-md-none"
            >
              {t('upload_file.upload_label_mobile')}
            </label>
          </>
        </div>

        {StatusUpload}

        {selectedFilesNames && selectedFilesNames?.length > 0 && (
          <div className="file-list">
            {selectedFilesNames?.map((name, index) => (
              <div className="file-item" key={index}>
                <div className="font-15-semibold pe-2 pe-md-5 col-md-11">{name}</div>
                <Image
                  className="pointer"
                  src={DeleteIcon}
                  width={20}
                  height={20}
                  onClick={() => setIsShowAlert(index)}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )

  const ModalErrorMaxSize = (
    <Modal
      visible={isOutOfSizeFile}
      onClose={() => setIsOutOfSizeFile(false)}
      classes="d-flex justify-content-center"
    >
      <div>
        <div className="d-flex align-items-center justify-content-center">
          <Image src={FailureIcon} width={64} height={64} />
        </div>
        <div className="m-3 font-14">{t('commons.max_exceeded')}</div>
      </div>
    </Modal>
  )

  const ModalShowAlert = (
    <Modal
      visible={true}
      onClose={() => {
        setIsOutOfSizeFile(false), setIsShowAlert(undefined)
      }}
      classes="d-flex justify-content-center"
    >
      <div>
        <div className="d-flex align-items-center justify-content-center">
          <Image src={FailureIcon} width={64} height={64} />
        </div>
        <div className="m-3 font-14">{t('entry_group_step_2.alert_delete_file')}</div>
        <div className="d-flex align-items-center justify-content-center">
          <Button
            text={t('commons.confirm')}
            flavor="accent"
            classes="pt-2 pb-2 ps-3 pe-3 d-flex align-items-center"
            classesText="font-15-semibold"
            classesIconRight="ms-2"
            onPress={() => confirmAction()}
          />
        </div>
      </div>
    </Modal>
  )

  return (
    <main id={`file-${name}`}>
      {UploadBox}
      <div>{ModalErrorMaxSize}</div>
      <div>{showAlert && isShowAlert !== undefined && ModalShowAlert}</div>
    </main>
  )
}
