import { Trans } from 'react-i18next'
import { RightArrowIcon } from 'src/assets'
import { LandingCardProps, typedMemo } from 'src/types'
import { Button } from 'src/ui/widgets'

import { useLandingCard } from './landing-card.hook'

export const LandingCard = typedMemo((props: LandingCardProps) => {
  const {
    t,
    basePrice,
    plusPrice,
    seasonalBasePrice,
    seasonalPlusPrice,
    buttonDisabled,
    goToDataEntry,
  } = useLandingCard(props)

  const { base, seasonal } = props

  const CardTitle = () => (
    <h2 className="font-grotesk-48 font-weight-700 text-center">
      {seasonal ? (
        <>
          {base
            ? t('landing.section_4_card_title_seasonal_base')
            : t('landing.section_4_card_title_seasonal_plus')}
        </>
      ) : (
        <>
          {base ? t('landing.section_4_card_title_base') : t('landing.section_4_card_title_plus')}
        </>
      )}
    </h2>
  )

  const CardSubtitle = () => (
    <div className="d-flex font-grotesk-24 font-weight-700">
      {seasonal ? (
        <>
          <div>{base ? seasonalBasePrice : seasonalPlusPrice} €</div>
          <div className="ms-2">{t('landing.section_4_card_seasonal')}</div>
        </>
      ) : (
        <>
          <div>
            {`${
              base
                ? basePrice?.toString()?.replace('.', ',')
                : plusPrice?.toString()?.replace('.', ',')
            }
             €`}
          </div>
          <div className="ms-2">{t('landing.section_4_card_daily')}</div>
        </>
      )}
    </div>
  )

  const CardDescription = () => (
    <h2 className="font-grotesk-24 font-weight-400 text-center white-space-pre mt-4">
      {seasonal ? (
        <>
          {base ? (
            <Trans i18nKey="landing.section_4_card_description_seasonal_base" />
          ) : (
            <Trans i18nKey="landing.section_4_card_description_seasonal_plus" />
          )}
        </>
      ) : (
        <>
          {base ? (
            <Trans i18nKey="landing.section_4_card_description_base" />
          ) : (
            <Trans i18nKey="landing.section_4_card_description_plus" />
          )}
        </>
      )}
    </h2>
  )

  const CardButton = () => (
    <div className="mt-5">
      <Button
        text={t('commons.buy_now')}
        iconRight={RightArrowIcon}
        classes="ps-3 pe-3 d-flex justify-content-center m-auto m-md-0"
        classesText="font-grotesk-12 font-weight-700 text-uppercase"
        disabled={buttonDisabled}
        onPress={goToDataEntry}
      />
    </div>
  )

  return (
    <div
      className={`${
        seasonal
          ? base
            ? 'bg-4642e4 c-ffffff'
            : 'bg-aae0ff'
          : base
          ? 'bg-4642e4 c-ffffff'
          : 'bg-aae0ff'
      } landing-card border rounded-4 col-md-6 mt-3 mb-4 mb-md-3 ms-md-3 me-md-3 pb-1 pt-md-0 pb-md-0`}
    >
      <div className="d-flex flex-column align-items-center p-4">
        <CardTitle />
        <CardSubtitle />
        <CardDescription />
        <CardButton />
      </div>
    </div>
  )
})
