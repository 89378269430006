import YouTube from 'react-youtube'

import { useSection4 } from './section4.hook'

export const Section4 = () => {
  const { t } = useSection4()

  const Title = (
    <h2 className=" mb-4 ps-1 pe-1 section4-dynamic-size-title d-flex align-item-center justify-content-center white-space-pre text-uppercase">
      <div className="d-inline-block text-center">
        <div className="display-contents">{t('landing.section_4_title_1')}</div>
        <div className="ms-2 c-2727ab">{t('landing.section_4_title_2')}</div>
      </div>
    </h2>
  )

  const Subtitle = (
    <div className="mb-5 pb-md-5 section4-dynamic-size-subtitle d-flex align-items-center justify-content-center white-space-pre-subtitle text-center">
      {t('landing.section_4_subtitle')}
    </div>
  )

  const Video = (
    <div className="ms-md-4 me-md-4 ps-md-4 pe-md-4">
      <div className="d-none d-md-flex align-items-center justify-content-center video-container">
        <YouTube
          videoId="z3NdnHq3Sq8"
          opts={{
            width: '560',
            height: '315',
            playerVars: {
              autoplay: 1,
              mute: 1,
              playsinline: 1,
              volume: 1,
              loop: 1,
              controls: 0,
              showinfo: 0,
              disablekb: 1,
              modestbranding: 1,
              rel: 0,
              origin: window.location.origin,
            },
          }}
          onReady={(event) => {
            event.target.setLoop(true)
            event.target.playVideo()
          }}
          onEnd={(event) => {
            event.target.playVideo()
          }}
        />
      </div>
      <div className="d-block d-md-none video-container">
        <YouTube
          videoId="z3NdnHq3Sq8"
          opts={{
            width: '100%',
            height: '212',
            playerVars: {
              autoplay: 1,
              mute: 1,
              playsinline: 1,
              volume: 1,
              loop: 1,
              controls: 0,
              showinfo: 0,
              disablekb: 1,
              modestbranding: 1,
              rel: 0,
              origin: window.location.origin,
            },
          }}
          onReady={(event) => {
            event.target.setLoop(true)
            event.target.playVideo()
          }}
          onEnd={(event) => {
            event.target.playVideo()
          }}
        />
      </div>
    </div>
  )

  return (
    <div>
      {Title}
      {Subtitle}
      {Video}
    </div>
  )
}
