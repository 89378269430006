import { CSSObjectWithLabel, GroupBase, StylesConfig } from 'react-select'

export const reactSelectStyles: StylesConfig<unknown, false, GroupBase<unknown>> | undefined = {
  control: (styles: CSSObjectWithLabel) => {
    return {
      ...styles,
      borderRadius: '0',
      minHeight: '46px',
      fontFamily: 'SofiaPro-Medium',
      fontSize: '0.9rem',
      border: '1px solid #c2c2c2',
      borderColor: '#c2c2c2',
      boxShadow: 'none',
      ':hover': {
        border: '1px solid #c2c2c2',
        borderColor: '#c2c2c2',
      },
    }
  },
  menu: (styles: CSSObjectWithLabel) => {
    return {
      ...styles,
      borderRadius: '0',
    }
  },
  option: (styles: CSSObjectWithLabel) => {
    return {
      ...styles,
      backgroundColor: 'white',
      color: 'black',
      borderTop: '1px solid #c2c2c2',
      ':active': {
        backgroundColor: 'white',
      },
      ':hover': {
        backgroundColor: '#deebff',
      },
    }
  },
}
