import { useClaimReport } from './claim-report.hook'
import { ClaimReportCode, ClaimReportSummary } from './sections'

export const ClaimReport = () => {
  const { showClaimSummary, setShowClaimSummary } = useClaimReport()

  return (
    <div>
      {showClaimSummary ? (
        <ClaimReportSummary />
      ) : (
        <ClaimReportCode setShowClaimSummary={setShowClaimSummary} />
      )}
    </div>
  )
}
