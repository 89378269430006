import { ProgressBar } from 'react-bootstrap'
import { Image } from 'react-component-library'
import { ItasOther, Weather } from 'src/assets'
import { ScrollTopRoot } from 'src/roots'
import { Button } from 'src/ui/widgets'

import { UseClaimReportType } from './claim-report-type.hook'

export const ClaimReportType = () => {
  const {
    t,
    CARD_TYPE,
    COVERAGE_RC_CARD_TYPE,
    claimQuote,
    progressBar,
    selectedCard,
    selectCard,
    goBack,
    goNext,
  } = UseClaimReportType()

  const Topbar = (
    <Button
      flavor="transparentAccentBack"
      text={t('commons.back')}
      classes="px-0 pt-0"
      classesText="text-uppercase font-14-semibold"
      onPress={goBack}
    />
  )

  const Title = <div className="font-28-semibold c-292929">{t('claim_report_type.title')}</div>

  const Subtitle = (
    <div className="mt-2 font-14-semibold c-292929 text-uppercase">
      {t('claim_report_type.subtitle')}
    </div>
  )

  const Select = <div className="mt-4 font-16 c-292929">{t('claim_report_type.select')}</div>

  const Card = ({ title, cardKey }: { title: string; cardKey: string }) => (
    <div key={cardKey} onClick={() => selectCard(cardKey)}>
      <div
        className={`h-100 p-2 ${
          selectedCard === cardKey ? 'selected-claim-card' : 'claim-card shadow'
        }`}
      >
        <div className="d-flex justify-content-center">
          <Image src={cardKey === 'weather' ? Weather : ItasOther} width={80} height={80} />
        </div>
        <div className="mt-3 font-12-semibold c-7c2d35 text-uppercase text-center white-space-pre">
          {title}
        </div>
      </div>
    </div>
  )

  const Cards = (
    <div className="mt-4 d-flex justify-content-between justify-content-md-evenly">
      <Card title={COVERAGE_RC_CARD_TYPE[claimQuote?.coverage || '-']} cardKey={CARD_TYPE[1]} />
      <Card title={t('claim_report_type.card_0_title')} cardKey={CARD_TYPE[0]} />
    </div>
  )

  const ButtonForm = (
    <div className="mt-5 pt-2 d-flex justify-content-md-end">
      <div className="col-12 col-md-4">
        <Button
          flavor="accentNext"
          text={t('commons.continue')}
          classes="ps-4 ps-md-3 pe-4 pe-md-3 m-auto m-md-0 w-100 d-flex justify-content-between"
          classesText="font-14-semibold text-uppercase"
          disabled={!selectedCard}
          onPress={goNext}
        />
      </div>
    </div>
  )

  return (
    <div className="mb-md-5 pb-md-5 ps-md-3 pe-md-3 d-md-flex justify-content-md-center container-fluid">
      <div className="col-12 col-lg-7">
        <div className="mt-4">
          <ScrollTopRoot />
          {Topbar}
          <div className="claim-progress">
            <ProgressBar now={progressBar} className="claim-progress-bar" />
          </div>
          <div className="p-4 ps-3 pe-3 p-md-5 bg-ffffff">
            {Title}
            {Subtitle}
            {Select}
            {Cards}
            <div className="pt-5">{ButtonForm}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
