/* eslint-disable complexity */
import { Dispatch, SetStateAction } from 'react'
import { lowerLanguage } from 'src/i18n'
import { ScrollTopRoot } from 'src/roots'
import { Formik, FormikMainInsured, typedMemo } from 'src/types'
import { FormikMainInsuredGroup } from 'src/types/networking/terminals/group.types'
import { Button, InputCheckbox, InputText, Loader, Recaptcha } from 'src/ui/widgets'
import { AnyObjectSchema } from 'yup'

import { useEntryGroupStep4 } from './entry-group-step4.hook'

export const EntryGroupStep4 = typedMemo(
  ({
    formikFather,
    formikMainInsured,
    validationSchema,
    discountError,
    setDiscountError,
  }: {
    formikFather: Formik<FormikMainInsuredGroup>
    formikMainInsured: FormikMainInsured
    validationSchema: AnyObjectSchema
    discountError?: string
    setDiscountError: Dispatch<SetStateAction<string>>
  }) => {
    const {
      t,
      quote,
      price,
      agency,
      warning,
      quoting,
      coverage,
      newQuote,
      oldQuote,
      coverages,
      policyCode,
      isMultiDays,
      updateTotalQuote,
    } = useEntryGroupStep4({ formikFather, formikMainInsured, setDiscountError })

    const { errors, touched, handleChange, handleBlur } = formikFather
    const { group, mainInsured } = formikFather?.values ?? {}

    const CoverageTypeBaseExtra = (
      <>
        {coverages?.['1']?.extraTypes &&
          coverages?.['1']?.extraTypes?.map((item, index: number) => (
            <div key={index + 1} className="font-14">
              - {item?.[lowerLanguage]}
            </div>
          ))}
      </>
    )

    const CoverageTypeBase = (
      <>
        {coverages?.['1']?.types &&
          coverages?.['1']?.types?.map((item, index: number) => (
            <div key={index + 1} className="font-14">
              - {item?.[lowerLanguage]}
            </div>
          ))}
      </>
    )

    const CoverageTypePlus = (
      <>
        {coverages?.['2']?.types &&
          coverages?.['2']?.types?.map((item, index: number) => (
            <div key={index + 1} className="font-14">
              - {item?.[lowerLanguage]}
            </div>
          ))}
      </>
    )

    const MainInsured = (
      <div>
        <div className="d-block d-md-flex mb-3">
          <div className="col-12 col-md-6 mb-3 mb-md-0">
            <div className="font-12-semibold text-uppercase c-7f7f7f">
              {t('data_entry_step_3.coverage_start_date')}
            </div>
            <div className="font-14">{formikMainInsured.coverage_start_date}</div>
          </div>
          <div className="col-12 col-md-5">
            <div className="font-12-semibold text-uppercase c-7f7f7f">
              {t('data_entry_step_3.coverage_end_date')}
            </div>
            <div className="font-14">{formikMainInsured.coverage_end_date}</div>
          </div>
        </div>

        {coverage && (
          <div className="d-block d-md-flex mb-3">
            <div className="col-12 col-md-6 mb-3 mb-md-0">
              <div className="font-12-semibold text-uppercase c-7f7f7f">
                {t('data_entry_step_3.coverage_type_title')}
              </div>
              <div className="font-14">
                {isMultiDays
                  ? coverage?.multiDaysName?.[lowerLanguage] || ''
                  : coverage?.name?.[lowerLanguage] || ''}
              </div>
            </div>
          </div>
        )}

        <div className="d-block d-md-flex mb-3 mt-3">
          <div className="col-12 mb-md-0">
            <div className="font-12-semibold text-uppercase c-7f7f7f">
              {t('data_entry_step_3.insurance_title')}
            </div>
            {CoverageTypeBase}
            {formikMainInsured.coverage_type_radios === '2' && CoverageTypePlus}
            {isMultiDays && CoverageTypeBaseExtra}
          </div>
        </div>

        <div className="d-block d-md-flex">
          <div className="col-12 col-md-6 mb-3 mb-md-0">
            <div className="font-12-semibold text-uppercase c-7f7f7f">
              {t('data_entry_step_3.email')}
            </div>
            <div className="font-14">{mainInsured.email?.toLowerCase()}</div>
          </div>
        </div>
      </div>
    )

    const OtherInsured = (index: number) => (
      <div key={index}>
        <div className="font-14-semibold text-uppercase mb-2">
          {t('data_entry_step_3.insured_title')}{' '}
          {mainInsured?.isInsured === 'id_yes' ? index + 2 : index + 1}
        </div>

        <div className="d-block d-md-flex mb-3">
          {group?.[index]?.name && (
            <div className="col-12 col-md-6 mb-3 mb-md-0">
              <div className="font-12-semibold text-uppercase c-7f7f7f">
                {t('data_entry_step_3.insured_name')}
              </div>
              <div className="font-14">{group?.[index]?.name}</div>
            </div>
          )}
          {group?.[index]?.surname && (
            <div className="col-5">
              <div className="font-12-semibold text-uppercase c-7f7f7f">
                {t('data_entry_step_3.insured_surname')}
              </div>
              <div className="font-14">{group?.[index]?.surname}</div>
            </div>
          )}
        </div>

        {group?.[index]?.fiscalCodeQuestion === 'yes_id' && (
          <div className="d-block d-md-flex mb-3">
            {group?.[index]?.fiscalCode && (
              <div className="col-12 col-md-6 mb-4 mb-md-0">
                <div className="font-12-semibold text-uppercase c-7f7f7f">
                  {t('data_entry_step_3.insured_fc')}
                </div>
                <div className="font-14">{group?.[index]?.fiscalCode?.toUpperCase()}</div>
              </div>
            )}
          </div>
        )}

        {group?.[index]?.fiscalCodeQuestion === 'no_id' && (
          <div>
            <div className="d-block d-md-flex mb-3">
              {group?.[index]?.birthDate && (
                <div className="col-12 col-md-6 mb-4 mb-md-0">
                  <div className="font-12-semibold text-uppercase c-7f7f7f">
                    {t('data_entry_step_3.insured_birth')}
                  </div>
                  <div className="font-14">{group?.[index]?.birthDate}</div>
                </div>
              )}
              {group?.[index]?.birthNation && (
                <div className="col-5">
                  <div className="font-12-semibold text-uppercase c-7f7f7f">
                    {t('data_entry_step_3.insured_birth_nation')}
                  </div>
                  <div className="font-14">{group?.[index]?.birthNation}</div>
                </div>
              )}
            </div>
            <div className="d-block d-md-flex mb-3">
              {group?.[index]?.birthPlace && (
                <div className="col-12 col-md-6 mb-4 mb-md-0">
                  <div className="font-12-semibold text-uppercase c-7f7f7f">
                    {t('data_entry_step_3.insured_birth_place')}
                  </div>
                  <div className="font-14">{group?.[index]?.birthPlace}</div>
                </div>
              )}

              {group?.[index]?.gender && (
                <div className="col-12 col-md-6 mb-4 mb-md-0">
                  <div className="font-12-semibold text-uppercase c-7f7f7f">
                    {t('data_entry_step_3.insured_gender')}
                  </div>
                  <div className="font-14">
                    {group?.[index]?.gender === 'M'
                      ? t('data_entry_step_3.insured_gender_male')
                      : group?.[index]?.gender === 'F'
                      ? t('data_entry_step_3.insured_gender_female')
                      : ''}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        {group && index < group?.length - 1 && <div className="mt-3 mb-3 border-top" />}
      </div>
    )

    const OtherInsureds = (
      <div className="col-12">
        <div>
          <div className="font-14-semibold text-uppercase mb-2">
            {mainInsured?.isInsured === 'id_yes'
              ? `${t('data_entry_step_3.insured_title')} 1`
              : t('data_entry_step_3.group_first_insured')}
          </div>
          <div className="d-block d-md-flex mb-3">
            <div className="col-12 col-md-6 mb-3 mb-md-0">
              <div className="font-12-semibold text-uppercase c-7f7f7f">
                {t('data_entry_step_3.insured_name')}
              </div>
              <div className="font-14">{mainInsured?.name}</div>
            </div>
            <div className="col-5">
              <div className="font-12-semibold text-uppercase c-7f7f7f">
                {t('data_entry_step_3.insured_surname')}
              </div>
              <div className="font-14">{mainInsured.surname}</div>
            </div>
          </div>
          {mainInsured.fiscalCodeQuestion === 'no_id' && (
            <div>
              <div className="d-block d-md-flex mb-3">
                {mainInsured.birthDate && (
                  <div className="col-12 col-md-6 mb-4 mb-md-0">
                    <div className="font-12-semibold text-uppercase c-7f7f7f">
                      {t('data_entry_step_3.insured_birth')}
                    </div>
                    <div className="font-14">{mainInsured.birthDate}</div>
                  </div>
                )}
                {mainInsured.birthNation && (
                  <div className="col-5">
                    <div className="font-12-semibold text-uppercase c-7f7f7f">
                      {t('data_entry_step_3.insured_birth_nation')}
                    </div>
                    <div className="font-14">{mainInsured.birthNation}</div>
                  </div>
                )}
              </div>
              <div className="d-block d-md-flex mb-3">
                {mainInsured.birthPlace && (
                  <div className="col-12 col-md-6 mb-4 mb-md-0">
                    <div className="font-12-semibold text-uppercase c-7f7f7f">
                      {t('data_entry_step_3.insured_birth_place')}
                    </div>
                    <div className="font-14">{mainInsured.birthPlace}</div>
                  </div>
                )}

                {mainInsured.gender && (
                  <div className="col-12 col-md-6 mb-4 mb-md-0">
                    <div className="font-12-semibold text-uppercase c-7f7f7f">
                      {t('data_entry_step_3.insured_gender')}
                    </div>
                    <div className="font-14">
                      {mainInsured.gender === 'M'
                        ? t('data_entry_step_3.insured_gender_male')
                        : mainInsured.gender === 'F'
                        ? t('data_entry_step_3.insured_gender_female')
                        : ''}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          <div className="d-block d-md-flex mb-3">
            <div className="col-12 col-md-6 mb-4 mb-md-0">
              <div className="font-12-semibold text-uppercase c-7f7f7f">
                {t('data_entry_step_3.insured_phone')}
              </div>
              <div className="font-14">
                {mainInsured.phone?.formattedPhone ? mainInsured.phone?.formattedPhone : '-'}
              </div>
            </div>

            {mainInsured.fiscalCode && mainInsured.fiscalCodeQuestion === 'yes_id' && (
              <div className="col-12 col-md-6 mb-4 mb-md-0">
                <div className="font-12-semibold text-uppercase c-7f7f7f">
                  {t('data_entry_step_3.insured_fc')}
                </div>
                <div className="font-14">{mainInsured.fiscalCode?.toUpperCase()}</div>
              </div>
            )}
          </div>
          <div className="d-block d-md-flex mb-3">
            <div className="col-12 col-md-6 mb-4 mb-md-0">
              <div className="font-12-semibold text-uppercase c-7f7f7f">
                {t('data_entry_step_3.insured_cap')}
              </div>
              <div className="font-14">{mainInsured.cap ? mainInsured.cap : '-'}</div>
            </div>
          </div>
        </div>

        {group && group?.length !== 0 && <div className="mt-3 mb-3 border-top" />}

        {group?.map((_, index) => OtherInsured(index))}
      </div>
    )

    const InfoCompany = (
      <div>
        <div className="d-block d-md-flex mb-3">
          <div className="col-12 col-md-6 mb-3 mb-md-0">
            <div className="font-12-semibold text-uppercase c-7f7f7f">
              {t('data_entry_step_3.product_label')}
            </div>
            <div className="font-14">{t('data_entry_step_3.product_value')}</div>
          </div>
          <div className="col-12 col-md-5">
            <div className="font-12-semibold text-uppercase c-7f7f7f">
              {t('data_entry_step_3.company_title')}
            </div>
            <div className="font-14">{t('data_entry_step_3.company')}</div>
          </div>
        </div>
        <div className="d-block d-md-flex mb-3">
          {policyCode && (
            <div className="col-12 col-md-6 mb-3 mb-md-0">
              <div className="font-12-semibold text-uppercase c-7f7f7f">
                {t('data_entry_step_3.policy_label')}
              </div>
              <div className="font-14">{policyCode || ''}</div>
            </div>
          )}
          <div className="col-12 col-md-5">
            <div className="font-12-semibold text-uppercase c-7f7f7f">
              {t('data_entry_step_3.contractor_label')}
            </div>
            <div className="font-14">
              {agency?.denomination
                ? agency?.denomination
                : t('data_entry_step_3.contractor_value')}
            </div>
          </div>
        </div>
      </div>
    )

    const Conditions = (
      <div>
        <div className="col-md-12 d-md-flex">
          <div className="mb-3 col-md-6">
            <InputCheckbox
              name="mainInsured.informativeVision"
              handleChange={handleChange}
              handleBlur={handleBlur}
              validationSchema={validationSchema}
              touched={touched?.mainInsured?.informativeVision}
              value={mainInsured?.informativeVision}
              error={
                errors?.mainInsured?.informativeVision && touched?.mainInsured?.informativeVision
                  ? errors?.mainInsured?.informativeVision
                  : ''
              }
              label={t('data_entry_step_3.informative_vision')}
              classesLabel="m-0 font-16 d-flex align-items-baseline"
            />
          </div>

          <div className="mb-3 col-md-6">
            <InputCheckbox
              name="mainInsured.consentTd"
              handleChange={handleChange}
              handleBlur={handleBlur}
              validationSchema={validationSchema}
              touched={touched?.mainInsured?.consentTd}
              value={mainInsured?.consentTd}
              error={
                errors?.mainInsured?.consentTd && touched?.mainInsured?.consentTd
                  ? errors?.mainInsured?.consentTd
                  : ''
              }
              label={t('data_entry_step_3.consent_accordion_value')}
              classesLabel="m-0 font-16 d-flex align-items-baseline"
            />
          </div>
        </div>
        <div className="pt-2 font-15-semibold">{t('commons.required_field')}</div>
      </div>
    )

    const DiscountField = (
      <div>
        <div className="d-block position-relative">
          <div className="col-md-7 pe-md-5">
            <InputText
              name="mainInsured.discount"
              handleChange={handleChange}
              handleBlur={handleBlur}
              validationSchema={validationSchema}
              touched={touched?.mainInsured?.discount}
              value={mainInsured?.discount?.toUpperCase()}
              error={errors?.mainInsured?.discount}
              label={t('data_entry_step_3.discount_label')}
              placeholder={t('data_entry_step_3.discount_placeholder')}
            />
          </div>
          <div className="col-md-2 position-discount-button">
            <Button
              flavor="accent"
              text={t('data_entry_step_3.discount_apply_btn')}
              classes="d-flex justify-content-center w-100"
              type="button"
              classesText="font-14-semibold text-uppercase"
              disabled={
                !mainInsured.discount ||
                (!!errors?.mainInsured?.discount && !!touched?.mainInsured?.discount)
              }
              onPress={updateTotalQuote}
            />
          </div>
        </div>
        {mainInsured?.discount && discountError && (
          <div className="font-14 c-862633">{discountError}</div>
        )}
      </div>
    )

    const Total = (
      <>
        <div className="d-block d-md-flex mb-3">
          <div className="col-12 mb-3 mb-md-0">
            <div className="d-md-block mt-4 mt-md-0">
              {quote?.quoteValue && (
                <div className="d-flex justify-content-center justify-content-md-start align-items-center">
                  <div>
                    <div className="font-18-semibold c-7f7f7f">{t('data_entry_step_3.total')}</div>
                    <div className="font-30-semibold">
                      {price === 'DISCOUNT' ? (
                        <div className="d-flex align-items-center">
                          <div className="line-over me-3">{`${oldQuote} €`}</div>
                          {`${newQuote} €`}
                        </div>
                      ) : (
                        <div>{price}</div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {warning && <div className="font-15 mt-3">{t('data_entry_step_3.choose_season')}</div>}
      </>
    )

    return (
      <div className="ps-md-3 pe-md-3">
        <ScrollTopRoot />
        <Recaptcha />
        <div className="border border-1 rounded-3 d-flex justify-content-center container-fluid bg-ffffff pt-4 mb-4">
          <div className="col-12 p-md-3">
            <div className="font-28-semibold mb-4">{t('data_entry_step_3.title')}</div>
            <div className="d-block">
              <div className="mb-4">{MainInsured}</div>
              <div className="mt-3 mb-3 border-top" />
              {/* <Accordion
                title={'Vedi di più'}
                classesTitle="m-0 p-0 font-16 me-3"
                classes="w-100"
                classesHead="m-0 p-0 pb-2 mt-3 d-flex justify-content-between align-items-center justify-content-md-start flex-nowrap"
              >
              </Accordion> */}
              <div className="mb-4">{OtherInsureds}</div>
              <div className="mt-3 mb-3 border-top" />
              <div className="mb-3">{InfoCompany}</div>
            </div>
          </div>
        </div>

        <div>
          <div>{Conditions}</div>
          <div className="mt-3 mb-3 border-top" />
          {quoting ? (
            <Loader classes="m-auto" />
          ) : (
            <>
              {group?.length === 0 && quote?.showDiscountField && DiscountField}
              <div className="mb-4">{Total}</div>
            </>
          )}
        </div>
      </div>
    )
  }
)
