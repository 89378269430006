import { Container } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { typedMemo } from 'src/types'

import { useCookie } from './cookie-policy.hook'

export const CookiePolicy = typedMemo(() => {
  const { t, templatesStart, templatesEnd } = useCookie()

  return (
    <div>
      <Helmet>
        <title>{t('meta.cookie.title')}</title>
      </Helmet>
      <Container fluid>
        <div
          className="mt-5"
          dangerouslySetInnerHTML={{
            __html: templatesStart || '',
          }}
        />
        <div id="ot-sdk-cookie-policy" />
        <button id="ot-sdk-btn" className="ot-sdk-show-settings">
          {t('commons.cookie_label')}
        </button>
        <div
          className="mt-5 mb-5"
          dangerouslySetInnerHTML={{
            __html: templatesEnd || '',
          }}
        />
      </Container>
    </div>
  )
})
