import './sliders.scss'

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Logo } from 'src/types'
export const useSliders = ({ logos }: { logos: Logo[] }) => {
  const { t } = useTranslation()

  const [isMobile, setIsMobile] = useState(window.innerWidth < 810)

  const half = Math.ceil(logos.length / 2)
  const firstHalf = logos.slice(0, half)
  const secondHalf = logos.slice(half)

  const commonSettings = {
    dots: false,
    infinite: true,
    slidesToShow: isMobile ? 2 : 6,
    slidesToScroll: 1,
    autoplay: true,
    speed: 9000,
    autoplaySpeed: 0,
    cssEase: 'linear',
  }

  const firstHalfSettings = {
    ...commonSettings,
    rtl: false,
  }

  const secondHalfSettings = {
    ...commonSettings,
    rtl: true,
  }

  useEffect(() => {
    window.addEventListener(
      'resize',
      () => {
        const ismobile = window.innerWidth < 810
        if (ismobile !== isMobile) setIsMobile(ismobile)
      },
      false
    )
  }, [isMobile])

  return {
    t,
    firstHalf,
    secondHalf,
    firstHalfSettings,
    secondHalfSettings,
  }
}
