export const regexDate = /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/

export const regexPhone = /^(\+\d{1,2}\s)??\d{3}?[\s-]?\d{3}[\s-]?\d{0,9}$/

export const regexAccents = /^[a-zA-ZáàâãéèêíïòóôõöúçñùÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]{2,}$/

export const regexAgencyCode = /^[a-zA-Z0-9]{4}$/

export const regexTaxCode =
  /^([A-Z]{6}[0-9LMNPQRSTUV]{2}[ABCDEHLMPRST]{1}[0-9LMNPQRSTUV]{2}[A-Z]{1}[0-9LMNPQRSTUV]{3}[A-Z]{1})$/gim

export const regexDigit = /^[0-9]*$/

export const regexBirthDate = /^(0[1-9]|[12][0-9]|3[01])[/](0[1-9]|1[012])[/](19|20)\d\d$/
