import React from 'react'

export type Optional<T> = T | undefined

export type Voidable<T> = T | ''

export type Callbacks<T = () => void> = Record<string, T>

export const typedMemo: <T>(c: T) => T = React.memo

export type FiscalCode = {
  name: string
  surname: string
  birthDate: string
  gender: 'M' | 'F'
  birthPlace: string
}

export type ReactSelectOption = {
  value: string
  label: string
}

export type Multilanguage = {
  it: string
  en: string
  de: string
}

export type ReactSelectMultilanguageOption = {
  value: string
  language: Multilanguage
}

/**
 * Logo
 */
export interface Logo {
  id?: string
  logoDesktop?: string
  logoMobile?: string
  logoUrl?: string
}

export interface seasonal_t {
  seasonal_end_date?: string
}

export interface evaluate_premium_t {
  premium?: {
    quoteValue: string
    oldQuoteValue: string
  }
}
