import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router'
import { useSetRecoilState } from 'recoil'
import { Paths } from 'src/navigation'
import { usePostSendEmailClaim } from 'src/networking/terminals/claim/claim.gates'
import {
  atomClaimBackPath,
  atomClaimData,
  atomClaimQuote,
  atomProduct,
  atomProgressBar,
} from 'src/storage'

export const useClaimIsOpen = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const name = new URLSearchParams(useLocation().search).get('name')?.toString() || ''
  const code = new URLSearchParams(useLocation().search).get('code')?.toString() || ''
  const isOpen = new URLSearchParams(useLocation().search).get('open')?.toString() || ''
  const urlContact = new URLSearchParams(useLocation().search).get('contact')?.toString() || ''

  const [contact] = useState({
    type: urlContact?.split('_')[0],
    value: urlContact?.split('_')[1],
  })

  const { crossGate: postSendEmailClaim } = usePostSendEmailClaim()

  const setClaimQuote = useSetRecoilState(atomClaimQuote)
  const setClaimBackPath = useSetRecoilState(atomClaimBackPath)
  const setClaimData = useSetRecoilState(atomClaimData)
  const setProgressBar = useSetRecoilState(atomProgressBar)
  const setProduct = useSetRecoilState(atomProduct)

  useEffect(() => {
    postSendEmailClaim(
      {
        body: {
          claimCode: code,
          isOpen: isOpen === 'true',
        },
      },
      {
        onSuccess: (response: { outcome: boolean }) => {
          if (response?.outcome) {
            setClaimQuote(undefined)
            setClaimBackPath(undefined)
            setClaimData(undefined)
            setProgressBar(undefined)
            setProduct(undefined)
          } else {
            navigate(Paths.ClaimReportError)
          }
        },
        onFailure: () => {
          navigate(Paths.ClaimReportError)
        },
        onPending: () => {},
      }
    )
  }, [])

  return { t, name, contact }
}
