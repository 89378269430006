import { Image } from 'react-bootstrap'
import { SuccessIcon } from 'src/assets'
import i18n from 'src/i18n'
import { ModalSuccessProps, typedMemo } from 'src/types'
import { Button, Modal } from 'src/ui/widgets'

export const ModalSuccess = typedMemo(
  ({
    visible,
    icon = SuccessIcon,
    title,
    description,
    onClose,
    button = {
      flavor: 'accentNext',
      text: i18n.t('commons.close'),
      onPress: onClose,
    },
  }: ModalSuccessProps) => {
    return (
      <Modal visible={visible} onClose={onClose} classes="d-flex justify-content-center">
        <div className="d-flex align-items-center justify-content-center p-1">
          <div className="d-inline text-center">
            {icon && <Image src={icon} />}
            {title && <p className="mt-5 font-16-semibold text-uppercase">{title}</p>}
            {description && <p className="mt-4 font-16 pre-line">{description}</p>}
            {button?.onPress && (
              <div className="mt-4 mb-2 d-flex align-items-center justify-content-center">
                <Button {...button} />
              </div>
            )}
          </div>
        </div>
      </Modal>
    )
  }
)
