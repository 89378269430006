import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { MAX_FILE, MAX_FILE_MB } from 'src/config'
import { Paths } from 'src/navigation'
import { usePostUploadFileClaim } from 'src/networking/terminals/claim/claim.gates'
import { atomClaimBackPath, atomClaimData, atomClaimQuote, atomProgressBar } from 'src/storage'
import { CostsFileModule, FormikClaimReportRefound } from 'src/types'
import { ClaimCostFileLabelUpload, ClaimFile, CostFileRefounds } from 'src/types/pages/claim.types'
import { fromBase64ToBlob, getDifferenceInDays, yupArrayRequired } from 'src/utils'
import * as Yup from 'yup'

import { PROGRESS_BAR_STEP } from '../../progress-bar-step'

export const UseClaimReportRcInjuryRefound = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const inputFileAccept = '.pdf,.jpg,.png,.tif'

  const claimQuote = useRecoilValue(atomClaimQuote)

  const [claimData, setClaimData] = useRecoilState(atomClaimData)
  const setClaimBackPath = useSetRecoilState(atomClaimBackPath)
  const [progressBar, setProgressBar] = useRecoilState(atomProgressBar)

  const { crossGate: postUploadFileClaim } = usePostUploadFileClaim()

  const isMultiDays = getDifferenceInDays(claimQuote?.from, claimQuote?.to) + 1 >= 3

  const optionCosts = [
    {
      value: '1',
      label: t('claim_report_rc_injury_refound.costs_option_medical_costs'),
    },
    ...(isMultiDays
      ? [
          {
            value: '2',
            label: t('claim_report_rc_injury_refound.costs_option_skipass'),
          },
          {
            value: '3',
            label: t('claim_report_rc_injury_refound.costs_option_rental'),
          },
          {
            value: '4',
            label: t('claim_report_rc_injury_refound.costs_option_lessons'),
          },
        ]
      : []),
  ]

  const costsfilesUpload: Record<string, ClaimCostFileLabelUpload[]> = {
    '1': [
      {
        id: 0,
        value: t('claim_report_rc_injury_refound.file_medical_costs_1'),
      },
    ],
    '2': [
      { id: 0, value: t('claim_report_rc_injury_refound.file_skipass_1') },
      { id: 1, value: t('claim_report_rc_injury_refound.file_skipass_2') },
      { id: 2, value: t('claim_report_rc_injury_refound.file_skipass_3') },
    ],
    '3': [
      { id: 0, value: t('claim_report_rc_injury_refound.file_rental_1') },
      { id: 1, value: t('claim_report_rc_injury_refound.file_rental_2') },
      { id: 2, value: t('claim_report_rc_injury_refound.file_rental_3') },
    ],
    '4': [
      { id: 0, value: t('claim_report_rc_injury_refound.file_lessons_1') },
      { id: 1, value: t('claim_report_rc_injury_refound.file_lessons_2') },
      { id: 2, value: t('claim_report_rc_injury_refound.file_lessons_3') },
    ],
  }

  const getInitialFormattedFile = (files_: ClaimFile[]): (File | undefined)[] => {
    const formattedFile_: (File | undefined)[] = []
    if (files_?.length > 0) {
      files_?.map((file) => {
        if (file) {
          if (file && file !== null) {
            formattedFile_?.push(
              new File([fromBase64ToBlob(file.data || '')], file?.name, {
                type: file?.type,
                lastModified: file?.lastModified,
              })
            )
          } else {
            formattedFile_?.push(undefined)
          }
        }
      })
    }

    return formattedFile_
  }

  const getInitialFormattedValues = (): CostsFileModule[] => {
    const initialFormattedValues_: CostsFileModule[] = []

    if (claimData?.other?.request?.refound?.costFileRefounds) {
      claimData?.other?.request?.refound?.costFileRefounds?.map((item) => {
        if (item?.cost && item?.files && item?.files?.length > 0) {
          initialFormattedValues_?.push({
            refoundCosts: item?.cost,
            files: getInitialFormattedFile(item?.files),
          })
        }
      })
    }

    return initialFormattedValues_?.length > 0
      ? initialFormattedValues_
      : [
          {
            refoundCosts: undefined,
            files: undefined,
          },
        ]
  }

  const [initialValues] = useState<FormikClaimReportRefound>({
    costFileRefounds: getInitialFormattedValues(),
  })

  const validationSchema: Yup.AnyObjectSchema = Yup.object().shape({
    costFileRefounds: yupArrayRequired,
  })

  const getOtherInitialFiles = (): File | undefined => {
    if (!claimData?.other?.request?.refound?.otherFile) return undefined
    const files_ = claimData?.other?.request?.refound?.otherFile
    return new File([fromBase64ToBlob(files_[0]?.data || '')], files_?.[0]?.name, {
      type: files_?.[0]?.type,
    })
  }

  //////////////////////////////////////////////////////////////////////////////
  const [otherFile, setOtherFile] = useState<File | undefined>(getOtherInitialFiles())
  const [isMobile, setIsMobile] = useState(window.innerWidth < 810)
  const [isOutOfSizeFile, setIsOutOfSizeFile] = useState(false)
  const [showUploadOtherFileModal, setShowUploadOtherFileModal] = useState(false)
  const [showUploadFileModal, setShowUploadFileModal] = useState(false)
  const [filteredOptions, setFilteredOptions] = useState(optionCosts)
  const [startUpdate, setStartUpdate] = useState(true)
  const [idFile, setIdFile] = useState('')
  const [showCheckError, setShowCheckError] = useState(false)
  const [loadingFile, setLoadingFile] = useState(false)

  //////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    setTimeout(() => {
      setProgressBar(PROGRESS_BAR_STEP.claim_report_rc_injury_refound)
    }, 100)
  }, [])

  useEffect(() => {
    if (!claimQuote) {
      navigate(Paths.ClaimReport)
    }
  }, [claimQuote])

  useEffect(() => {
    window.addEventListener(
      'resize',
      () => {
        const ismobile = window.innerWidth < 810
        if (ismobile !== isMobile) setIsMobile(ismobile)
      },
      false
    )
  }, [isMobile])

  const formatFile = async (id: string, file: File): Promise<ClaimFile> => {
    const formattedFile: ClaimFile = await new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = async () => {
        resolve({
          name: `${id}-${file?.name?.replaceAll(' ', '')}` || '',
          size: file?.size || 0,
          data: reader.result?.toString() || '',
          type: file?.type || '',
          lastModified: file?.lastModified || 0,
        })
      }
      reader.onerror = reject
    })

    return formattedFile
  }

  // eslint-disable-next-line complexity
  const checkFormik = (values: FormikClaimReportRefound) => {
    if (!values?.costFileRefounds) return false
    let isValid = true
    for (let i = 0; i < values?.costFileRefounds?.length; i++) {
      const item = values?.costFileRefounds[i]
      if (!otherFile || !otherFile?.name || otherFile?.name === '') {
        isValid = false
        break
      } else if (!item?.refoundCosts) {
        isValid = false
        break
      } else if (
        item?.refoundCosts?.value &&
        item?.files?.length !== costsfilesUpload[item?.refoundCosts?.value]?.length
      ) {
        isValid = false
        break
      } else if (item?.files) {
        let tempIndex = 0
        for (let j = 0; j < item?.files?.length; j++) {
          const file = item?.files[j]
          tempIndex += 1
          if (!file || !file?.name || file?.name === '') {
            isValid = false
            break
          }
        }
        if (tempIndex !== item?.files?.length) {
          isValid = false
          break
        }
      }
    }

    return isValid
  }

  const getOutputFiles = async (item: CostsFileModule): Promise<CostFileRefounds | undefined> => {
    let updateFiles_: (ClaimFile | undefined)[] = []
    if (item?.files && item?.files?.length > 0) {
      updateFiles_ = await Promise.all(
        item?.files?.map(async (file): Promise<ClaimFile | undefined> => {
          if (file) {
            const file_ = await formatFile(item?.refoundCosts?.value || '', file)
            let updatedFile: ClaimFile | undefined = undefined

            await postUploadFileClaim(
              {
                body: {
                  shopId: claimQuote?.contractId || '',
                  ...file_,
                },
              },
              {
                onSuccess: (response) => {
                  if (response?.fileName) {
                    updatedFile = {
                      ...file_,
                      reference: response?.fileName,
                    }
                  }
                },
                onFailure: () => {
                  setLoadingFile(false)
                  navigate(Paths.ClaimReportError)
                },
                onPending: () => {},
              }
            )

            return updatedFile
          }
          return undefined
        })
      )
    }

    const cleanFiles = updateFiles_?.filter((element) => {
      return element !== undefined
    }) as ClaimFile[] | undefined

    return {
      cost: item?.refoundCosts,
      files: cleanFiles,
    }
  }

  const getOutputOtherFile = async (): Promise<ClaimFile | undefined> => {
    let otherFile_: ClaimFile | undefined = undefined
    if (otherFile) {
      setLoadingFile(true)

      const file_ = await formatFile('other', otherFile)
      await postUploadFileClaim(
        {
          body: {
            shopId: claimQuote?.contractId || '',
            ...file_,
          },
        },
        {
          onSuccess: (response) => {
            if (response?.fileName) {
              otherFile_ = {
                ...file_,
                reference: response?.fileName,
              }
            }
          },
          onFailure: () => {
            setLoadingFile(false)
            navigate(Paths.ClaimReportError)
          },
          onPending: () => {},
        }
      )
    }
    return otherFile_
  }

  const formik = useFormik<FormikClaimReportRefound>({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      let costFileRefounds_: CostFileRefounds[] = []
      let otherFiles_: ClaimFile | undefined = undefined
      if (checkFormik(values)) {
        setLoadingFile(true)
        setShowCheckError(false)

        setClaimData({
          ...claimData,
          other: {
            ...claimData?.other,
            request: {
              ...claimData?.other?.request,
              refound: undefined,
            },
          },
        })

        if (values?.costFileRefounds) {
          costFileRefounds_ = await Promise.all(
            values?.costFileRefounds?.map(async (item) => (await getOutputFiles(item)) || {})
          )
        }

        if (otherFile) {
          otherFiles_ = await getOutputOtherFile()
        }

        setClaimData({
          ...claimData,
          other: {
            ...claimData?.other,
            request: {
              ...claimData?.other?.request,
              refound: {
                ...claimData?.other?.request?.refound,
                costFileRefounds: costFileRefounds_,
                otherFile: otherFiles_ ? [otherFiles_] : undefined,
              },
            },
          },
        })

        setLoadingFile(false)
        setClaimBackPath(Paths.ClaimReportRcInjuryRefound)
        navigate(Paths.ClaimReportOpen)
      } else {
        setShowCheckError(true)
      }
    },
  })

  useEffect(() => {
    if (startUpdate) {
      setStartUpdate(false)
      const filteredOption_ = optionCosts?.filter((optionCost) => {
        return formik?.values?.costFileRefounds?.every((refoundCost) => {
          return refoundCost.refoundCosts?.value !== optionCost.value
        })
      })

      setFilteredOptions(filteredOption_)
    }
  }, [startUpdate, formik])

  useEffect(() => {
    if (formik?.values) {
      setShowCheckError(false)
    }
  }, [formik?.values])
  //////////////////////////////////////////////////////////////////////////////

  const goBack = () => {
    navigate(Paths.ClaimReportRcInjuryRequestReason)
    setClaimData({
      ...claimData,
      other: {
        ...claimData?.other,
        request: {},
      },
    })
  }

  const uploadOtherDocument = (file: File) => {
    if (!file) return

    const fileSize = file.size / MAX_FILE

    if (fileSize > MAX_FILE_MB) {
      setIsOutOfSizeFile(true)
      setShowUploadOtherFileModal(false)
    } else {
      setOtherFile(file)
      setShowUploadOtherFileModal(false)
    }
  }

  const deleteOtherDocument = () => {
    setOtherFile(undefined)
  }

  const uploadDocument = (file: File, index: number, indexLabel: number) => {
    if (!file) return

    const fileSize = file.size / MAX_FILE

    if (fileSize > MAX_FILE_MB) {
      setIsOutOfSizeFile(true)
      setShowUploadFileModal(false)
    } else {
      //TODO ADD CHECK IF NOT EXISTS SO ADD
      formik?.setFieldValue(`costFileRefounds.${index}.files.${indexLabel}`, file)
      setShowUploadFileModal(false)
    }
  }

  const deleteDocument = (index: number, indexLabel: number) => {
    formik?.setFieldValue(`costFileRefounds.${index}.files.${indexLabel}`, undefined)
  }

  const updateCostsFiles = () => {
    setStartUpdate(true)
  }

  return {
    t,
    progressBar,
    formik,
    isMobile,
    otherFile,
    optionCosts,
    filteredOptions,
    isOutOfSizeFile,
    inputFileAccept,
    costsfilesUpload,
    showUploadFileModal,
    showUploadOtherFileModal,
    showCheckError,
    idFile,
    loadingFile,
    setIdFile,
    updateCostsFiles,
    setIsOutOfSizeFile,
    setShowUploadFileModal,
    setShowUploadOtherFileModal,
    uploadDocument,
    deleteDocument,
    uploadOtherDocument,
    deleteOtherDocument,
    goBack,
  }
}
