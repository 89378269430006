import { useEffect, useState } from 'react'
import { Route, Routes as ReactRoutes, useLocation } from 'react-router'
import { NotLandingPaths, Routes } from 'src/navigation'
import { useGetProduct } from 'src/networking/terminals'
import { PrivateRoute } from 'src/ui/fragments'

export const NavigationRoot = () => {
  const { pending: pendingProduct } = useGetProduct()
  const { pathname } = useLocation()
  const isNotLanding = NotLandingPaths.includes(pathname)
  const [showItem, setShowItem] = useState(false)

  useEffect(() => {
    if (isNotLanding && pendingProduct) {
      setShowItem(false)
    } else {
      setShowItem(true)
    }
  }, [isNotLanding, pendingProduct])

  return (
    <ReactRoutes>
      {Object.values(Routes).map(({ path, element, private: pvt }, index) => (
        <Route
          key={index}
          path={path}
          element={
            pvt ? (
              <PrivateRoute element={element} />
            ) : isNotLanding && pendingProduct ? (
              <></>
            ) : (
              showItem && <div key={`element_${index}`}>{element}</div>
            )
          }
        />
      ))}
    </ReactRoutes>
  )
}
