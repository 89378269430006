import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import DE from './locales/de.locale.json'
import EN from './locales/en.locale.json'
import IT from './locales/it.locale.json'

const resources = {
  de: {
    translation: DE,
  },
  it: {
    translation: IT,
  },
  en: {
    translation: EN,
  },
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      resources,
      fallbackLng: 'it',
      debug: true,
      interpolation: {
        escapeValue: false,
      },
    }
    // () => {
    //   i18n.changeLanguage('it')
    // }
  )

export type UpperLanguage = 'IT' | 'DE' | 'EN'
export type LowerLanguage = 'it' | 'de' | 'en'

export const country = i18n.language.slice(0, 2).toUpperCase()
export const upperLanguage = country === 'DE' || country === 'EN' ? country : 'IT'
export const lowerLanguage = upperLanguage.toLowerCase() as LowerLanguage

export default i18n
