import { ProgressBar } from 'react-bootstrap'
import { Trans } from 'react-i18next'
import { ScrollTopRoot } from 'src/roots'
import { Button, InputDate } from 'src/ui/widgets'

import { UseClaimReportRcInjuryDateHour } from './claim-report-rc-injury-date-hour.hook'

export const ClaimReportRcInjuryDateHour = () => {
  const { t, formik, isValidData, validationSchema, progressBar, goBack } =
    UseClaimReportRcInjuryDateHour()

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
  } = formik

  const Topbar = (
    <Button
      flavor="transparentAccentBack"
      text={t('commons.back')}
      classes="px-0 pt-0"
      classesText="text-uppercase font-14-semibold"
      onPress={goBack}
    />
  )

  const Title = (
    <div className="font-28-semibold c-292929">{t('claim_report_rc_injury_date_hour.title')}</div>
  )

  const Subtitle = (
    <div className="mt-2 font-14-semibold c-292929 text-uppercase">
      {t('claim_report_rc_injury_date_hour.subtitle')}
    </div>
  )

  const InsertLabel = (
    <div className="mt-3 font-16 c-292929">
      <Trans i18nKey={'claim_report_rc_injury_date_hour.insert_label'} />
    </div>
  )

  const DateForm = (
    <div className="mt-4 mt-md-0 col-md-6 pe-md-4">
      <InputDate
        name="date"
        handleChange={handleChange}
        handleBlur={handleBlur}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
        validationSchema={validationSchema}
        touched={touched.date}
        value={values.date}
        error={touched.date && errors.date ? errors.date : ''}
        label={`${t('claim_report_rc_injury_date_hour.date')}`}
        placeholder={t('claim_report_rc_injury_date_hour.date_placeholder')}
        disabledTo={new Date()}
        classes="m-0 p-0 mb-3"
      />
    </div>
  )

  const HourForm = (
    <div className="col-md-6 ps-md-4">
      <div className="font-medium-s">{t('claim_report_rc_injury_date_hour.hour')}*</div>
      <div className="claim-form-control-hour">
        <input
          name="hour"
          type="time"
          className="form-control"
          placeholder={t('claim_report_rc_injury_date_hour.hour_placeholder')}
          value={values?.hour}
          required
          onChange={(e) => {
            setFieldValue('hour', e.target.value)
          }}
        />
      </div>
    </div>
  )

  const ErrorLabel = (
    <div className="font-14-semibold c-7c2d35 white-space-pre text-center">
      {t('claim_report_rc_injury_date_hour.error_date_hour')}
    </div>
  )

  const ButtonForm = (
    <div className="mt-3 d-flex justify-content-md-end">
      <div className="col-12 col-md-4">
        <Button
          flavor="accentNext"
          type="submit"
          text={t('commons.continue')}
          disabled={
            values?.date === '' || values?.hour === '' || Object?.keys(errors)?.length !== 0
          }
          classes="ps-4 ps-md-3 pe-4 pe-md-3 m-auto m-md-0 w-100 d-flex justify-content-between"
          classesText="font-14-semibold text-uppercase"
        />
      </div>
    </div>
  )

  return (
    <div className="mb-md-5 pb-md-5 ps-md-3 pe-md-3 d-md-flex justify-content-md-center container-fluid">
      <div className="col-12 col-lg-7">
        <div className="mt-4">
          <ScrollTopRoot />
          {Topbar}
          <div className="claim-progress">
            <ProgressBar now={progressBar} className="claim-progress-bar" />
          </div>
          <div className="p-4 ps-3 pe-3 p-md-5 bg-ffffff">
            {Title}
            {Subtitle}
            {InsertLabel}
            <form onSubmit={handleSubmit}>
              <div className="mt-md-4 d-md-flex align-items-start">
                {DateForm}
                {HourForm}
              </div>
              <div className={`mt-5 pt-5 mt-md-1 pt-md-4 pb-md-5 mb-md-5 ${isValidData && 'pb-5'}`}>
                {!isValidData && ErrorLabel}
              </div>
              {ButtonForm}
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
