import './header.scss'

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { useRecoilState, useResetRecoilState } from 'recoil'
import i18n, { LowerLanguage, lowerLanguage } from 'src/i18n'
import { Paths } from 'src/navigation'
import { useGetProduct, useGetUserTransaction } from 'src/networking/terminals'
import { atomFormikMainInsured, atomProduct, atomUserQuote } from 'src/storage'

export const useHeader = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const urlSearchParams = new URLSearchParams(useLocation().search)
  const notifierId = urlSearchParams.get('quote_id') || undefined
  const agencyId = urlSearchParams.get('agency_id') || undefined

  const { crossGate: getProduct } = useGetProduct()
  const { crossGate: getUserTransaction } = useGetUserTransaction()

  const [product, saveProduct] = useRecoilState(atomProduct)
  const [userQuote, saveUserQuote] = useRecoilState(atomUserQuote)

  const [radioLanguage, setRadioLanguage] = useState(lowerLanguage)

  const resetFormikMainInsured = useResetRecoilState(atomFormikMainInsured)

  useEffect(() => {
    if (notifierId && notifierId !== userQuote?.notifierId && agencyId) {
      if (!product || product?.agency?.id?.toUpperCase() !== agencyId?.toUpperCase()) {
        getProduct(
          {
            params: {
              agencyCode: agencyId?.toUpperCase(),
            },
          },
          {
            onSuccess: (product) => {
              saveProduct(product)
            },
          }
        )
      }

      getUserTransaction(
        {
          body: {
            agencyId: agencyId?.toUpperCase(),
            notifierId: notifierId,
          },
        },
        {
          onSuccess: (quoteUserTransaction) => {
            if (quoteUserTransaction) {
              resetFormikMainInsured()
              saveUserQuote({ ...quoteUserTransaction, notifierId })

              const newLanguage = quoteUserTransaction?.language?.toLowerCase() as LowerLanguage
              setRadioLanguage(newLanguage)
              i18n.changeLanguage(newLanguage)

              setTimeout(() => {
                setTimeout(() => window.location.reload())
              }, 100)
            }
          },
        }
      )
    }
  }, [notifierId, userQuote])

  /**
   * Functions
   */
  const onChange = (language: LowerLanguage) => {
    setRadioLanguage(language)
    i18n.changeLanguage(language)
    setTimeout(() => window.location.reload())
  }

  const setIT = () => onChange('it')
  const setDE = () => onChange('de')
  const setEN = () => onChange('en')

  const goToDataEntry = () => {
    navigate({
      pathname: Paths.DataEntry1,
      search: `?${new URLSearchParams({
        base: 'true',
        seasonal: 'false',
      }).toString()}`,
    })
  }

  const goToLanding = () => navigate(Paths.Landing)

  return { t, radioLanguage, setIT, setDE, setEN, goToDataEntry, goToLanding }
}
