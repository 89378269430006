/* eslint-disable complexity */
import { Image } from 'react-component-library'
import { CrossIcon, ItasMinusIcon, ItasPlusIcon, RightArrowIcon, TickIcon } from 'src/assets/'
import LandingAccordion from 'src/ui/fragments/landing-accordion/Accordion'
import { Button } from 'src/ui/widgets'

import { useSection2 } from './section2.hook'

export const Section2 = () => {
  const {
    t,
    PACKAGE_BASE_PRICE,
    PACKAGE_PLUS_PRICE,
    selectedPeriod,
    selectedPackage,
    setSelectedPeriod,
    setSelectedPackage,
    goToDataEntry,
    openInformationSetFile,
  } = useSection2()

  const nextPage = (fromButton: string) =>
    goToDataEntry(
      fromButton === 'base' ? true : false,
      selectedPeriod === 'id_daily' ? false : true
    )

  const Title = (
    <h2 className="pt-4 mb-4 mb-md-5 ps-1 pe-1 section2-dynamic-size-title d-flex align-item-center justify-content-center text-uppercase">
      <div className="d-inline-block text-center">
        <div className="display-contents">{t('landing.section_2_title_1')}</div>
        <div className="ms-2 c-2727ab display-contents">{t('landing.section_2_title_2')}</div>
        <div className="display-contents">{t('landing.section_2_title_3')}</div>
      </div>
    </h2>
  )

  const Subtitle = (
    <div className="mb-5 section2-dynamic-size-subtitle d-flex align-items-center justify-content-center text-center">
      {t('landing.section_2_subtitle')}
    </div>
  )

  const PackageTitle = (
    <div className="mb-3 d-block d-md-none font-16-ag-400">
      {t('landing.section_2_package_title')}
    </div>
  )

  const Periods = (
    <div>
      <div>{PackageTitle}</div>
      <div className="mb-5 d-md-flex align-items-center justify-content-center">
        <div className="periods-border d-md-flex align-items-center justify-content-center">
          <div className="d-flex align-items-center justify-content-between">
            <div className="pe-3 pe-md-0 pb-md-0 col-6 col-md-auto">
              <div
                className={`ms-md-3 me-md-3 ps-md-3 pe-md-3 pt-3 pb-3 col-md-auto d-flex align-items-center justify-content-center font-16-ag-500 cursor-pointer ${
                  selectedPeriod === 'id_daily'
                    ? 'selected-period-border'
                    : 'unselected-period-border'
                }`}
                onClick={() => setSelectedPeriod('id_daily')}
              >
                {t('landing.section_2_period_daily')}
              </div>
            </div>
            <div className="ps-3 pe-md-0 pb-md-0 col-6 col-md-auto">
              <div
                className={`ms-md-3 me-md-3 ps-md-3 pe-md-3 pt-3 pb-3 col-md-auto d-flex align-items-center justify-content-center font-16-ag-500 cursor-pointer ${
                  selectedPeriod === 'id_seasonal'
                    ? 'selected-period-border'
                    : 'unselected-period-border'
                }`}
                onClick={() => setSelectedPeriod('id_seasonal')}
              >
                {t('landing.section_2_period_seasonal')}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  //Desktop

  const GuaranteesCard = (
    <div className="pt-4">
      <div className="font-24-age-500 section2-space-0">
        <div className="mb-4 ps-4 pe-4 font-24-age-500 white-space-pre">
          {t('landing.section_2_guarantees_title')}
        </div>
      </div>
      <div className="ps-4 pe-4 d-flex align-items-center section2-space-1">
        <div>
          <div className="pb-2 font-20-ag-400">{t('landing.section_2_civil_liability_title')}</div>
          <div>
            <ul className="m-0 p-0 ps-4 pe-3">
              <li className="font-14-ag-400 label-opacity-grey">
                {t('landing.section_2_civil_liability_info_1')}
              </li>
              <li className="font-14-ag-400 label-opacity-grey">
                {t('landing.section_2_civil_liability_info_2')}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="ps-4 pe-4 d-flex align-items-center section2-space-2">
        <div>
          <div className="pb-2 font-20-ag-400">{t('landing.section_2_reimbursement_title')}</div>
          <div>
            <ul className="m-0 p-0 ps-4 pe-3">
              <li className="font-14-ag-400 label-opacity-grey">
                {t('landing.section_2_reimbursement_info_1')}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="ps-4 pe-4 d-flex align-items-center section2-space-2">
        <div>
          <div className="pb-2 font-20-ag-400">{t('landing.section_2_injury_title')}</div>
          <div>
            <ul className="m-0 p-0 ps-4 pe-3">
              <li className="font-14-ag-400 label-opacity-grey">
                {t('landing.section_2_injury_info_1')}
              </li>
              <li className="font-14-ag-400 label-opacity-grey">
                {t('landing.section_2_injury_info_2')}
              </li>
              <li className="font-14-ag-400 label-opacity-grey">
                {t('landing.section_2_injury_info_3')}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="ps-4 pe-4 d-flex align-items-center section2-space-3">
        <div>
          <div className="pb-2 font-20-ag-400">
            {t('landing.section_2_reimbursement_title_other')}
          </div>
          <div className="pb-2 font-14-ag-500">
            {t('landing.section_2_reimbursement_info_1_other')}
          </div>
          <div>
            <ul className="m-0 p-0 ps-4 pe-3">
              <li className="font-14-ag-400 label-opacity-grey">
                {t('landing.section_2_reimbursement_info_2_other')}
              </li>
              <li className="font-14-ag-400 label-opacity-grey">
                {t('landing.section_2_reimbursement_info_3_other')}
              </li>
              <li className="font-14-ag-400 label-opacity-grey">
                {t('landing.section_2_reimbursement_info_4_other')}
              </li>
              <li className="font-14-ag-400 label-opacity-grey">
                {t('landing.section_2_reimbursement_info_5_other')}
              </li>
              <li className="font-14-ag-400 label-opacity-grey">
                {t('landing.section_2_reimbursement_info_6_other')}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )

  const BaseCardDesktop = (
    <div className="pt-4">
      <div className="font-24-age-500 section2-space-0">
        <div className="mb-4 ps-4 pe-4 font-24-age-500">{t('landing.section_2_base_title')}</div>
        <div className="mb-4 ps-4 pe-4 font-36-age-700">{PACKAGE_BASE_PRICE[selectedPeriod]}</div>
        <div className="mb-4 ps-4 pe-4">
          <Button
            text={t('commons.active_now_button')}
            iconRight={RightArrowIcon}
            classes="p-2 ps-3 pe-3 w-100 d-flex align-items-center justify-content-between bg-2727ab c-ffffff"
            classesText="font-14-ag-500"
            classesIconRight="filter-ffffff"
            onPress={() => nextPage('base')}
          />
        </div>
      </div>

      <div className="ps-4 pe-4 d-flex align-items-center justify-content-center section2-space-1">
        <Image src={TickIcon} width={20} height={20} />
      </div>

      <div className="ps-4 pe-4 d-flex align-items-center justify-content-center section2-space-2">
        <Image src={TickIcon} width={20} height={20} />
      </div>

      <div className="ps-4 pe-4 d-flex align-items-center justify-content-center section2-space-2">
        <Image src={CrossIcon} width={20} height={20} />
      </div>

      <div className="ps-4 pe-4 d-flex align-items-center justify-content-center section2-space-3">
        <Image src={TickIcon} width={20} height={20} />
      </div>
    </div>
  )

  const PlusCardDesktop = (
    <div className="pt-4">
      <div className="font-24-age-500 section2-space-0">
        <div className="mb-4 ps-4 pe-4 font-24-age-500">{t('landing.section_2_plus_title')}</div>
        <div className="mb-4 ps-4 pe-4 font-36-age-700">{PACKAGE_PLUS_PRICE[selectedPeriod]}</div>
        <div className="mb-4 ps-4 pe-4">
          <Button
            text={t('commons.active_now_button')}
            iconRight={RightArrowIcon}
            classes="p-2 ps-3 pe-3 w-100 d-flex align-items-center justify-content-between bg-2727ab c-ffffff"
            classesText="font-14-ag-500"
            classesIconRight="filter-ffffff"
            onPress={() => nextPage('plus')}
          />
        </div>
      </div>

      <div className="ps-4 pe-4 d-flex align-items-center justify-content-center section2-space-1">
        <Image src={TickIcon} width={20} height={20} />
      </div>

      <div className="ps-4 pe-4 d-flex align-items-center justify-content-center section2-space-2">
        <Image src={TickIcon} width={20} height={20} />
      </div>

      <div className="ps-4 pe-4 d-flex align-items-center justify-content-center section2-space-2">
        <Image src={TickIcon} width={20} height={20} />
      </div>

      <div className="ps-4 pe-4 d-flex align-items-center justify-content-center section2-space-3">
        <Image src={TickIcon} width={20} height={20} />
      </div>
    </div>
  )

  const PackagesDesktop = (
    <div className="d-none d-md-block">
      <div className="ms-lg-4 me-lg-4 mb-4 d-flex align-items-center justify-content-center">
        <div className="col-md-12 row gx-4 m-0 p-0">
          <div className="col-md-4">
            <div className="section2-card">{GuaranteesCard}</div>
          </div>
          <div className="col-md-4" onClick={() => setSelectedPackage('base')}>
            <div
              className={`cursor-pointer ${
                selectedPackage === 'base' ? 'selected-package' : 'section2-card'
              }`}
            >
              {BaseCardDesktop}
            </div>
          </div>
          <div className="col-md-4" onClick={() => setSelectedPackage('plus')}>
            <div
              className={`cursor-pointer ${
                selectedPackage === 'plus' ? 'selected-package' : 'section2-card'
              }`}
            >
              {PlusCardDesktop}
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  //Mobile

  const BaseCardMobile = (
    <div className="pt-4 pb-4">
      <div className="ps-4 pe-4 font-20-age-500">{t('landing.section_2_base_title')}</div>
      <div className="ps-4 pe-4 font-24-age-700">{PACKAGE_BASE_PRICE[selectedPeriod]}</div>
      <div className="mt-3 ps-4 pe-4">
        <Button
          text={t('commons.active_now_button')}
          iconRight={RightArrowIcon}
          classes="p-2 ps-3 pe-3 w-100 d-flex align-items-center justify-content-between bg-2727ab c-ffffff"
          classesText="font-14-ag-500"
          classesIconRight="filter-ffffff"
          onPress={() => nextPage('base')}
        />
      </div>

      <div className="mt-4 mb-4 ps-4 pe-4">
        <LandingAccordion
          icon_size={24}
          icon_open={ItasMinusIcon}
          icon_close={ItasPlusIcon}
          show_close={true}
          button_read={false}
          classes="ps-md-3 pe-md-3"
          classes_icon_open="section2-open-icon"
          head={
            <div className="font-18-ag-400 d-flex align-items-center">
              <Image src={TickIcon} width={20} height={20} />
              <div className="ms-2">{t('landing.section_2_civil_liability_title')}</div>
            </div>
          }
        >
          <ul className="pt-2 ps-4 pe-3">
            <li className="font-14-ag-400 label-opacity-grey">
              {t('landing.section_2_civil_liability_info_1')}
            </li>
            <li className="font-14-ag-400 label-opacity-grey">
              {t('landing.section_2_civil_liability_info_2')}
            </li>
          </ul>
        </LandingAccordion>
      </div>

      <hr className="ms-4 me-4 section2-separator" />

      <div className="mt-4 mb-4 ps-4 pe-4">
        <LandingAccordion
          icon_size={24}
          icon_open={ItasMinusIcon}
          icon_close={ItasPlusIcon}
          show_close={true}
          button_read={false}
          classes="ps-md-3 pe-md-3"
          classes_icon_open="section2-open-icon"
          classes_head="m-0 p-0 d-flex align-items-center justify-content-between flex-nowrap"
          head={
            <div className="font-18-ag-400 d-flex align-items-center">
              <Image src={TickIcon} width={20} height={20} />
              <div className="ms-2">{t('landing.section_2_reimbursement_title')}</div>
            </div>
          }
        >
          <ul className="pt-2 ps-4 pe-3">
            <li className="font-14-ag-400 label-opacity-grey">
              {t('landing.section_2_reimbursement_info_1')}
            </li>
          </ul>
        </LandingAccordion>
      </div>

      <hr className="ms-4 me-4 section2-separator" />

      <div className="mt-4 mb-4 ps-4 pe-4">
        <LandingAccordion
          icon_size={24}
          icon_open={ItasMinusIcon}
          icon_close={ItasPlusIcon}
          show_close={true}
          button_read={false}
          classes="ps-md-3 pe-md-3"
          classes_icon_open="section2-open-icon"
          classes_head="m-0 p-0 d-flex align-items-center justify-content-between flex-nowrap"
          head={
            <div className="font-18-ag-400 d-flex align-items-center">
              <Image src={CrossIcon} width={20} height={20} />
              <div className="ms-2">{t('landing.section_2_injury_title')}</div>
            </div>
          }
        >
          <ul className="m-0 p-0 ps-4 pe-3">
            <li className="font-14-ag-400 label-opacity-grey">
              {t('landing.section_2_injury_info_1')}
            </li>
            <li className="font-14-ag-400 label-opacity-grey">
              {t('landing.section_2_injury_info_2')}
            </li>
            <li className="font-14-ag-400 label-opacity-grey">
              {t('landing.section_2_injury_info_3')}
            </li>
          </ul>
        </LandingAccordion>
      </div>

      <hr className="ms-4 me-4 section2-separator" />

      <div className="mt-4 mb-4 ps-4 pe-4">
        <LandingAccordion
          icon_size={24}
          icon_open={ItasMinusIcon}
          icon_close={ItasPlusIcon}
          show_close={true}
          button_read={false}
          classes="ps-md-3 pe-md-3"
          classes_icon_open="section2-open-icon"
          classes_head="m-0 p-0 d-flex align-items-center justify-content-between flex-nowrap"
          head={
            <div className="font-18-ag-400 d-flex align-items-center">
              <Image src={TickIcon} width={20} height={20} />
              <div className="ms-2">{t('landing.section_2_reimbursement_title_other')}</div>
            </div>
          }
        >
          <div>
            <div className="pt-3 pb-2 font-14-ag-500">
              {t('landing.section_2_reimbursement_info_1_other')}
            </div>
            <ul className="m-0 p-0 ps-4 pe-3">
              <li className="font-14-ag-400 label-opacity-grey">
                {t('landing.section_2_reimbursement_info_2_other')}
              </li>
              <li className="font-14-ag-400 label-opacity-grey">
                {t('landing.section_2_reimbursement_info_3_other')}
              </li>
              <li className="font-14-ag-400 label-opacity-grey">
                {t('landing.section_2_reimbursement_info_4_other')}
              </li>
              <li className="font-14-ag-400 label-opacity-grey">
                {t('landing.section_2_reimbursement_info_5_other')}
              </li>
              <li className="font-14-ag-400 label-opacity-grey">
                {t('landing.section_2_reimbursement_info_6_other')}
              </li>
            </ul>
          </div>
        </LandingAccordion>
      </div>
    </div>
  )

  const PlusCardMobile = (
    <div className="pt-4 pb-4">
      <div className="ps-4 pe-4 font-20-age-500">{t('landing.section_2_plus_title')}</div>
      <div className="ps-4 pe-4 font-24-age-700">{PACKAGE_PLUS_PRICE[selectedPeriod]}</div>
      <div className="mt-3 ps-4 pe-4">
        <Button
          text={t('commons.active_now_button')}
          iconRight={RightArrowIcon}
          classes="p-2 ps-3 pe-3 w-100 d-flex align-items-center justify-content-between bg-2727ab c-ffffff"
          classesText="font-14-ag-500"
          classesIconRight="filter-ffffff"
          onPress={() => nextPage('plus')}
        />
      </div>

      <div className="mt-4 mb-4 ps-4 pe-4">
        <LandingAccordion
          icon_size={24}
          icon_open={ItasMinusIcon}
          icon_close={ItasPlusIcon}
          show_close={true}
          button_read={false}
          classes="ps-md-3 pe-md-3"
          classes_icon_open="section2-open-icon"
          head={
            <div className="font-18-ag-400 d-flex align-items-center">
              <Image src={TickIcon} width={20} height={20} />
              <div className="ms-2">{t('landing.section_2_civil_liability_title')}</div>
            </div>
          }
        >
          <ul className="pt-2 ps-4 pe-3">
            <li className="font-14-ag-400 label-opacity-grey">
              {t('landing.section_2_civil_liability_info_1')}
            </li>
            <li className="font-14-ag-400 label-opacity-grey">
              {t('landing.section_2_civil_liability_info_2')}
            </li>
          </ul>
        </LandingAccordion>
      </div>

      <hr className="ms-4 me-4 section2-separator" />

      <div className="mt-4 mb-4 ps-4 pe-4">
        <LandingAccordion
          icon_size={24}
          icon_open={ItasMinusIcon}
          icon_close={ItasPlusIcon}
          show_close={true}
          button_read={false}
          classes="ps-md-3 pe-md-3"
          classes_icon_open="section2-open-icon"
          head={
            <div className="font-18-ag-400 d-flex align-items-center">
              <Image src={TickIcon} width={20} height={20} />
              <div className="ms-2">{t('landing.section_2_reimbursement_title')}</div>
            </div>
          }
        >
          <ul className="pt-2 ps-4 pe-3">
            <li className="font-14-ag-400 label-opacity-grey">
              {t('landing.section_2_reimbursement_info_1')}
            </li>
          </ul>
        </LandingAccordion>
      </div>

      <hr className="ms-4 me-4 section2-separator" />

      <div className="mt-4 mb-4 ps-4 pe-4">
        <LandingAccordion
          icon_size={24}
          icon_open={ItasMinusIcon}
          icon_close={ItasPlusIcon}
          show_close={true}
          button_read={false}
          classes="ps-md-3 pe-md-3"
          classes_icon_open="section2-open-icon"
          head={
            <div className="font-18-ag-400 d-flex align-items-center">
              <Image src={TickIcon} width={20} height={20} />
              <div className="ms-2">{t('landing.section_2_injury_title')}</div>
            </div>
          }
        >
          <ul className="pt-2 ps-4 pe-3">
            <li className="font-14-ag-400 label-opacity-grey">
              {t('landing.section_2_injury_info_1')}
            </li>
            <li className="font-14-ag-400 label-opacity-grey">
              {t('landing.section_2_injury_info_2')}
            </li>
            <li className="font-14-ag-400 label-opacity-grey">
              {t('landing.section_2_injury_info_3')}
            </li>
          </ul>
        </LandingAccordion>
      </div>

      <hr className="ms-4 me-4 section2-separator" />

      <div className="mt-4 mb-4 ps-4 pe-4">
        <LandingAccordion
          icon_size={24}
          icon_open={ItasMinusIcon}
          icon_close={ItasPlusIcon}
          show_close={true}
          button_read={false}
          classes="ps-md-3 pe-md-3"
          classes_icon_open="section2-open-icon"
          classes_head="m-0 p-0 d-flex align-items-center justify-content-between flex-nowrap"
          head={
            <div className="font-18-ag-400 d-flex align-items-center">
              <Image src={TickIcon} width={20} height={20} />
              <div className="ms-2">{t('landing.section_2_reimbursement_title_other')}</div>
            </div>
          }
        >
          <div>
            <div className="pt-3 pb-2 font-14-ag-500">
              {t('landing.section_2_reimbursement_info_1_other')}
            </div>
            <ul className="m-0 p-0 ps-4 pe-3">
              <li className="font-14-ag-400 label-opacity-grey">
                {t('landing.section_2_reimbursement_info_2_other')}
              </li>
              <li className="font-14-ag-400 label-opacity-grey">
                {t('landing.section_2_reimbursement_info_3_other')}
              </li>
              <li className="font-14-ag-400 label-opacity-grey">
                {t('landing.section_2_reimbursement_info_4_other')}
              </li>
              <li className="font-14-ag-400 label-opacity-grey">
                {t('landing.section_2_reimbursement_info_5_other')}
              </li>
              <li className="font-14-ag-400 label-opacity-grey">
                {t('landing.section_2_reimbursement_info_6_other')}
              </li>
            </ul>
          </div>
        </LandingAccordion>
      </div>
    </div>
  )

  const PackagesMobile = (
    <div className="d-block d-md-none">
      <div>
        <div className="col-md-12 row gx-4">
          <div className="col-md-4" onClick={() => setSelectedPackage('base')}>
            <div
              className={`cursor-pointer ${
                selectedPackage === 'base' ? 'selected-package' : 'section2-card'
              }`}
            >
              {BaseCardMobile}
            </div>
          </div>

          <div className="mt-4 col-md-4" onClick={() => setSelectedPackage('plus')}>
            <div
              className={`cursor-pointer ${
                selectedPackage === 'plus' ? 'selected-package' : 'section2-card'
              }`}
            >
              {PlusCardMobile}
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  //Information set
  const InformationSet = (
    <div className="text-center text-md-start ms-md-4 me-md-4 mt-3 ps-md-3 label-opacity-grey d-inline-block">
      <div className="font-14-ag-500 display-contents">
        {t('landing.section_2_information_set_label_1')}
      </div>
      <Button
        text={t('landing.section_2_information_set_link')}
        classes="py-md-2 ms-1 me-1 display-contents"
        classesText="font-14-ag-500 d-inline text-underline-grey"
        flavor="transparentAccent"
        onPress={openInformationSetFile}
      />
      <div className="font-14-ag-500 display-contents">
        {t('landing.section_2_information_set_label_2')}
      </div>
    </div>
  )

  return (
    <div>
      {Title}
      {Subtitle}
      {Periods}
      {PackagesDesktop}
      {PackagesMobile}
      {InformationSet}
    </div>
  )
}
