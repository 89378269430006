import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useRecoilState, useRecoilValue } from 'recoil'
import { Paths } from 'src/navigation'
import { atomClaimQuote, atomProgressBar } from 'src/storage'

import { PROGRESS_BAR_STEP } from '../../progress-bar-step'

export const UseClaimReportSummary = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const claimQuote = useRecoilValue(atomClaimQuote)
  const [progressBar, setProgressBar] = useRecoilState(atomProgressBar)

  const COVERAGE_TYPE: Record<string | symbol, string> = {
    '1': t('claim_report_summary.base'),
    '2': t('claim_report_summary.plus'),
  }

  //////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    setTimeout(() => {
      setProgressBar(PROGRESS_BAR_STEP.claim_report_summary)
    }, 100)
  }, [])

  useEffect(() => {
    if (!claimQuote) {
      navigate(Paths.ClaimReport)
    }
  }, [claimQuote])

  //////////////////////////////////////////////////////////////////////////////

  const goNext = () => {
    navigate(Paths.ClaimReportType)
  }

  return {
    t,
    progressBar,
    claimQuote,
    COVERAGE_TYPE,
    goNext,
  }
}
