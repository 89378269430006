import './section2.scss'

import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { PERIOD_MAPPER } from 'src/config'
import { lowerLanguage } from 'src/i18n'
import { Paths } from 'src/navigation'
import { selectorCoverages, selectorDocuments } from 'src/storage'

export const useSection2 = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const documents = useRecoilValue(selectorDocuments)
  const coverages = useRecoilValue(selectorCoverages)

  const PACKAGE_BASE_PRICE: Record<string, string> = {
    id_daily: `${coverages?.['1']?.basePremium} €`,
    id_seasonal: `${coverages?.['1']?.seasonalBasePremium} €`,
  }

  const PACKAGE_PLUS_PRICE: Record<string, string> = {
    id_daily: `${coverages?.['2']?.basePremium} €`,
    id_seasonal: `${coverages?.['2']?.seasonalBasePremium} €`,
  }

  const [selectedPeriod, setSelectedPeriod] = useState('id_seasonal')
  const [selectedPackage, setSelectedPackage] = useState('base')
  const informativePersonalDataLink = documents?.find(({ id }) => id === '1')

  const goToDataEntry = (base: boolean, seasonal: boolean) => {
    navigate({
      pathname: Paths.DataEntry1,
      search: `?${new URLSearchParams({
        landing: 'true',
        base: `${base}`,
        seasonal: `${seasonal}`,
        utm_source: `${PERIOD_MAPPER[`${base}_${seasonal}`?.toString()]}`,
        utm_medium: 'home',
      }).toString()}`,
    })
  }

  const openInformationSetFile = () => {
    const file = informativePersonalDataLink?.file?.[lowerLanguage]
    if (file) {
      window.open(file.replaceAll('"', ''), '_blank')
    }
  }

  return {
    t,
    PACKAGE_BASE_PRICE,
    PACKAGE_PLUS_PRICE,
    selectedPeriod,
    selectedPackage,
    setSelectedPeriod,
    setSelectedPackage,
    goToDataEntry,
    openInformationSetFile,
  }
}
