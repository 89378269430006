/* eslint-disable complexity */
import { ProgressBar } from 'react-bootstrap'
import { ScrollTopRoot } from 'src/roots'
import { Button } from 'src/ui/widgets'
import { extactTimeFromDateString, extractDateFromDateString } from 'src/utils'

import { UseClaimReportSummary } from './claim-report-summary.hook'

export const ClaimReportSummary = () => {
  const { t, progressBar, claimQuote, COVERAGE_TYPE, goNext } = UseClaimReportSummary()

  const Title = <div className="font-28-semibold c-292929">{t('claim_report_summary.title')}</div>

  const Subtitle = (
    <div className="mt-2 font-14-semibold c-292929 text-uppercase">
      {t('claim_report_summary.subtitle')}
    </div>
  )

  const Payer = (
    <div className="mt-4 font-14 c-292929 d-md-flex">
      <div className="col-md-4">{t('claim_report_summary.payer')}</div>
      <div className="d-flex align-items-center">
        <div className="me-3 font-14 c-7f7f7f">{claimQuote?.contractor?.surname || '-'}</div>
        <div className="font-14 c-7f7f7f">{claimQuote?.contractor?.name || '-'}</div>
      </div>
    </div>
  )

  const ProductType = (
    <div className="mt-4 font-14 c-292929 d-md-flex">
      <div className="col-md-4"> {t('claim_report_summary.product_type')}</div>
      <div className="d-flex justify-content-center align-items-center ps-4 pe-4 font-14 c-ffffff border-tag">
        {t('claim_report_summary.product_type_value')}
      </div>
    </div>
  )

  const CertificateNumber = (
    <div className="mt-4 font-14 c-292929 d-md-flex">
      <div className="col-md-4">{t('claim_report_summary.certificate_number')}</div>
      <div className="font-14 c-7f7f7f">{claimQuote?.contractId || '-'}</div>
    </div>
  )

  const CoveragePeriod = (
    <div className="d-md-flex">
      <div className="mt-4 mt-md-0 col-md-4 font-14 c-292929">
        {t('claim_report_summary.coverage_period')}
      </div>
      <div>
        <div className="font-14 c-292929 d-flex">
          <div className="col-3 col-md-5">{t('claim_report_summary.coverage_from')}</div>
          <div className="pe-2 col-3 col-md-5 font-14 text-end c-7f7f7f">
            {extactTimeFromDateString(claimQuote?.from) || '-'}
          </div>
          <div className="">{t('claim_report_summary.coverage_of')}</div>
          <div className="ps-2 font-14 c-7f7f7f">
            {extractDateFromDateString(claimQuote?.from) || '-'}
          </div>
        </div>
        <div className="font-14 c-292929 d-flex">
          <div className="col-3 col-md-5">{t('claim_report_summary.coverage_to')}</div>
          <div className="pe-2 col-3 col-md-5 font-14 text-end c-7f7f7f">
            {extactTimeFromDateString(claimQuote?.to) || '-'}
          </div>
          <div className="">{t('claim_report_summary.coverage_of')}</div>
          <div className="ps-2 font-14 c-7f7f7f">
            {extractDateFromDateString(claimQuote?.to) || '-'}
          </div>
        </div>
      </div>
    </div>
  )

  const CoverageType = (
    <div className="mt-4 font-14 c-292929 d-md-flex">
      <div className="col-md-4"> {t('claim_report_summary.coverage_type')} </div>
      <div className="font-14 c-7f7f7f">{COVERAGE_TYPE?.[claimQuote?.coverage || '-'] || '-'}</div>
    </div>
  )

  const InsuredList = (
    <div className="mt-4 font-14 c-292929 d-md-flex">
      <div className="col-md-4">{t('claim_report_summary.insured_list_title')}</div>
      <div>
        <div className="d-flex align-items-center">
          <div className="me-3 font-14 c-7f7f7f">{claimQuote?.contractor?.surname || '-'}</div>
          <div className="font-14 c-7f7f7f">{claimQuote?.contractor?.name || '-'}</div>
        </div>
        {claimQuote?.assets?.map((insured, index) => (
          <div key={index} className="d-flex align-items-center">
            <div className="me-3 font-14 c-7f7f7f">{insured?.surname || '-'}</div>
            <div className="font-14 c-7f7f7f">{insured?.name || '-'}</div>
          </div>
        ))}
      </div>
    </div>
  )

  const ButtonForm = (
    <div className="mt-4 pt-2 d-flex justify-content-md-end">
      <div className="col-12 col-md-4">
        <Button
          flavor="accentNext"
          text={t('commons.continue')}
          classes="ps-4 ps-md-3 pe-4 pe-md-3 m-auto m-md-0 w-100 d-flex justify-content-between"
          classesText="font-14-semibold text-uppercase"
          onPress={goNext}
        />
      </div>
    </div>
  )

  return (
    <div className="mb-md-5 pb-md-5 ps-md-3 pe-md-3 d-md-flex justify-content-md-center container-fluid">
      <div className="col-12 col-lg-7">
        <div className="mt-4">
          <ScrollTopRoot />
          <div className="pt-3 pb-3 mt-2 mb-1" />
          <div className="claim-progress">
            <ProgressBar now={progressBar} className="claim-progress-bar" />
          </div>
          <div className="p-4 ps-3 pe-3 p-md-5 bg-ffffff">
            {Title}
            {Subtitle}
            <div className="col-md-12">
              {Payer}
              {ProductType}
              {CertificateNumber}
            </div>
            <div className="col-md-12 mt-md-3">
              {CoveragePeriod}
              {CoverageType}
              {claimQuote?.assets && claimQuote?.assets?.length > 0 && InsuredList}
            </div>
            {ButtonForm}
          </div>
        </div>
      </div>
    </div>
  )
}
