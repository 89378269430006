import './landing.scss'

import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useResetRecoilState } from 'recoil'
import {
  atomCurrentStep,
  atomFormikMainInsured,
  atomFormikMainInsuredGroup,
  atomIsGroup,
  atomQuote,
  atomUserQuote,
} from 'src/storage'

export const useLanding = () => {
  const resetFormikMainInsured = useResetRecoilState(atomFormikMainInsured)
  const resetFormikMainGroup = useResetRecoilState(atomFormikMainInsuredGroup)
  const resetQuote = useResetRecoilState(atomQuote)
  const resetUserQuote = useResetRecoilState(atomUserQuote)
  const resetCurrentStep = useResetRecoilState(atomCurrentStep)
  const resetIsGroup = useResetRecoilState(atomIsGroup)

  const { t } = useTranslation()

  useEffect(() => {
    resetFormikMainInsured()
    resetCurrentStep()
    resetUserQuote()
    resetQuote()

    // Group
    resetFormikMainGroup()
    resetIsGroup()
  }, [])

  return { t }
}
