import { Image } from 'react-component-library'
import { ItasEmailIcon, ItasErrorMan, ItasPhoneIcon } from 'src/assets'
import { Button } from 'src/ui/widgets'

import { UseClaimeReportWarning } from './claim-report-warning.hook'

export const ClaimReportWarning = () => {
  const { t, goBack } = UseClaimeReportWarning()

  const ImageSection = (
    <div>
      <Image src={ItasErrorMan} width={350} height={150} />
    </div>
  )

  const Title = (
    <div className="mt-4 font-28-semibold white-space-pre text-uppercase">
      {t('claim_report_warning.title')}
    </div>
  )

  const SubTitle = (
    <div className="mt-3 font-14 white-space-pre text-center">
      {t('claim_report_warning.subtitle')}
    </div>
  )

  const Email = (
    <div className="mt-3 font-14 d-flex align-items-center">
      <div className="me-2">
        <Image src={ItasEmailIcon} width={24} height={24} />
      </div>
      <div
        className="cursor-pointer display-contents font-14 c-0067de"
        onClick={() => window.open(`mailto:${t('claim_report_warning.email')}`)}
      >
        <div className="text-underline">{t('claim_report_warning.email')}</div>
      </div>
    </div>
  )

  const Phone = (
    <div className="mt-2 font-14 d-flex align-items-center">
      <div className="me-2">
        <Image src={ItasPhoneIcon} width={24} height={24} />
      </div>
      <div
        className="cursor-pointer display-contents font-14"
        onClick={() => window.open(`tel:${t('claim_report_warning.phone')}`)}
      >
        {t('claim_report_warning.phone_title')} {t('claim_report_warning.phone')}
      </div>
    </div>
  )

  const ButtonBack = (
    <Button flavor="accent" text={t('commons.back')} classes="mt-4 ps-5 pe-5" onPress={goBack} />
  )

  return (
    <div className="m-4 p-4 ps-3 pe-3 vh-100 bg-ffffff d-md-flex flex-column justify-content-center align-items-center">
      <div className="d-flex justify-content-center align-items-center">{ImageSection}</div>
      {Title}
      {SubTitle}
      <div>
        {Email}
        {Phone}
      </div>
      <div className="d-flex justify-content-center">{ButtonBack}</div>
    </div>
  )
}
