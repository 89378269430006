import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useRecoilState, useRecoilValue } from 'recoil'
import { Paths } from 'src/navigation'
import { atomClaimData, atomClaimQuote, atomProgressBar } from 'src/storage'
import { FormikClaimInformation } from 'src/types'
import {
  compareFullDates,
  isValidDate,
  reverseDate,
  yupDateRequired,
  yupStrRequired,
} from 'src/utils'
import * as Yup from 'yup'

import { PROGRESS_BAR_STEP } from '../../progress-bar-step'

export const UseClaimReportWeatherInformation = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const claimQuote = useRecoilValue(atomClaimQuote)
  const [claimData, setClaimData] = useRecoilState(atomClaimData)
  const [progressBar, setProgressBar] = useRecoilState(atomProgressBar)

  const [isValidData, setIsValidData] = useState(true)

  const [initialValues] = useState<FormikClaimInformation>({
    date: claimData?.weather?.date || '',
    hour: claimData?.weather?.hour || '',
    skiResort: claimData?.weather?.skiResort || '',
  })

  const validationSchema = Yup.object().shape({
    date: yupDateRequired.test(
      'check_date',
      t('claim_report_weather_information.error_date'),
      (value) => {
        return (
          compareFullDates(new Date(reverseDate(value)), new Date()) <= 0 &&
          isValidDate(reverseDate(value))
        )
      }
    ),
    hour: yupStrRequired,
    skiResort: yupStrRequired,
  })

  //////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    setTimeout(() => {
      setProgressBar(PROGRESS_BAR_STEP.claim_report_weather_information)
    }, 100)
  }, [])

  useEffect(() => {
    if (!claimQuote) {
      navigate(Paths.ClaimReport)
    }
  }, [claimQuote])

  //////////////////////////////////////////////////////////////////////////////

  const formik = useFormik<FormikClaimInformation>({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (claimQuote) {
        const checkDate = new Date(values?.date?.split('/').reverse().join('/'))
        const hours = values?.hour?.split(':')[0]
        const minutes = values?.hour?.split(':')[1]

        checkDate.setHours(Number(hours), Number(minutes))

        const fromDate = new Date(claimQuote?.from || '')
        const toDate = new Date(claimQuote?.to || '')

        const isValidFrom = compareFullDates(fromDate, checkDate)
        const isValidTo = compareFullDates(checkDate, toDate)

        const isFullValid =
          (isValidFrom === -1 || isValidFrom === 0) && (isValidTo === -1 || isValidTo === 0)

        if (isFullValid) {
          setIsValidData(true)
          setClaimData({
            ...claimData,
            weather: {
              ...claimData?.weather,
              date: values?.date,
              hour: values?.hour,
              skiResort: values?.skiResort,
            },
          })
          navigate(Paths.ClaimReportWeatherDocument)
        } else {
          setIsValidData(false)
        }
      }
    },
  })

  //////////////////////////////////////////////////////////////////////////////

  const goBack = () => {
    navigate(Paths.ClaimReportWeatherInsuredPerson)
    if (claimQuote?.assets?.length === 0) {
      navigate(Paths.ClaimReportWeatherDeclaration)
    } else {
      navigate(Paths.ClaimReportWeatherInsuredPerson)
    }
  }

  return {
    t,
    formik,
    isValidData,
    validationSchema,
    progressBar,
    goBack,
  }
}
