import { Image } from 'react-component-library'
import { BannerLandingImage, BannerLandingMobileImage, RightArrowIcon } from 'src/assets'
import { Button } from 'src/ui/widgets'

import { useSection3 } from './section3.hook'

export const Section3 = () => {
  const { t, toInsertPage } = useSection3()

  return (
    <div className="position-relative">
      <Image
        src={BannerLandingImage}
        alt="Itasnow"
        width="100%"
        height="100%"
        className="sliders-image-max-height d-none d-md-block"
      />
      <Image
        src={BannerLandingMobileImage}
        alt="ItasnowMobile"
        width="100%"
        height="100%"
        className="sliders-image-max-height d-block d-md-none"
      />

      <div className="section3-align-item">
        <h2 className="white-space-pre section3-dynamic-size-title c-ffffff text-uppercase text-center">
          {t('landing.section_3_title')}
        </h2>
        <div className="mt-4 d-flex align-items-center justify-content-center">
          <Button
            text={t('commons.buy_now_button')}
            iconRight={RightArrowIcon}
            iconSize={18}
            classes="p-2 ps-3 pe-3 d-flex justify-content-center"
            classesText="font-14-ag-500"
            onPress={toInsertPage}
          />
        </div>
      </div>
    </div>
  )
}
