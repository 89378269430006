/* eslint-disable complexity */
import { RightArrowIcon } from 'src/assets'
import { Button, Loader } from 'src/ui/widgets'

import { useDataEntryGroup } from './data-entry-group.hook'
import { EntryGroupStep2 } from './manage-group/entry-group-step2/entry-group-step2'
import { EntryGroupStep3 } from './manage-group/entry-group-step3/entry-group-step3'
import { EntryGroupStep4 } from './manage-group/entry-group-step4/entry-group-step4'
import { EntryGroupStep5 } from './manage-group/entry-group-step5/entry-group-step5'

export const DataEntryGroup = (props: { step: number }) => {
  const {
    t,
    formik,
    isDisabled,
    currentStep,
    discountError,
    evaluatedPremium,
    formikMainInsured,
    validationSchemas,
    pendingEvaluatePremium,
    goBack,
    evaluatePremium,
    setDiscountError,
    triggerFormikCheck,
  } = useDataEntryGroup(props?.step)

  const { handleSubmit, isSubmitting } = formik

  const Topbar = (
    <Button
      flavor="transparentAccentBack"
      text={t('commons.back')}
      classes="mt-3 px-0 ps-md-5 ms-md-2"
      classesText="text-uppercase font-14-semibold"
      onPress={goBack}
    />
  )

  const Title = (
    <div className="px-md-3 mt-1">
      <div className="mb-2 font-24-bold">{t('data_entry.title')}</div>
      <div className="mb-3 font-16">
        {t('data_entry.step')} {currentStep + 1} {t('data_entry.of')} 5
      </div>
      <div className="mt-3 mb-3 border-top" />
    </div>
  )

  const Form = (
    <>
      {currentStep === 1 && <EntryGroupStep2 formikFather={formik} />}
      {currentStep === 2 && (
        <EntryGroupStep3 fatherFormik={formik} validationSchema={validationSchemas[currentStep]} />
      )}
      {currentStep === 3 && (
        <EntryGroupStep4
          formikFather={formik}
          formikMainInsured={formikMainInsured}
          validationSchema={validationSchemas[currentStep]}
          discountError={discountError}
          setDiscountError={setDiscountError}
        />
      )}
      {currentStep === 4 && <EntryGroupStep5 />}
    </>
  )

  const Total = currentStep <= 1 && (
    <div className="col-12 px-md-3 pl-md-3 mb-2 pt-md-4 pt-md-0 mb-md-1">
      <div className={`d-block d-md-flex mb-3`}>
        <div className="col-12 mb-3 mb-md-0">
          <div className="d-md-block">
            {pendingEvaluatePremium ? (
              <div className="m-auto d-flex justify-content-center mb-3">
                <Loader overlay />
              </div>
            ) : (
              evaluatedPremium?.premium?.quoteValue && (
                <div className="d-flex justify-content-center justify-content-md-start align-items-center">
                  <div>
                    <div className="font-18-semibold c-7f7f7f">{t('data_entry_step_3.total')}</div>
                    <div className="font-30-semibold">
                      <div>{evaluatedPremium?.premium?.quoteValue?.replace('.', ',')} €</div>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  )

  const SubmitButton = (
    <div className="col-12 px-md-3 pl-md-3 mb-2 pt-4 pt-md-0 mb-md-1">
      <div className="d-md-flex justify-content-center justify-content-md-between">
        {(currentStep === 1 || currentStep === 2) && (
          <>
            {currentStep === 1 && (
              <div>
                <div className="pe-md-4 mb-4 mb-md-0 d-flex d-md-block justify-content-center">
                  <Button
                    flavor="accent"
                    classes="ps-5 pe-5 d-flex justify-content-center button-submit m-md-0"
                    classesText="font-14-semibold text-uppercase"
                    onPress={evaluatePremium}
                    text={t('data_entry_step_1.evaluate')}
                    disabled={isDisabled}
                  />
                </div>
              </div>
            )}
            <div />
            <div>
              <Button
                type="submit"
                flavor="accent"
                text={t('commons.continue')}
                classes="ps-5 pe-5 d-flex justify-content-center button-submit m-md-0"
                classesText="font-14-semibold text-uppercase"
                iconSize={20}
                iconLeft={RightArrowIcon}
                classesIconLeft="me-2 filter-ffffff"
                disabled={currentStep === 1 && isDisabled}
                onPress={triggerFormikCheck}
              />
            </div>
          </>
        )}
      </div>

      <div>
        {currentStep === 3 && (
          <div className="d-md-flex justify-content-end">
            <div className="font-13 col-md-3 mb-4 mb-md-0 text-md-start me-md-3 click-height">
              {t('data_entry_step_3.click_label')}
            </div>
            <Button
              type="submit"
              flavor="accent"
              text={t('data_entry.payment_label')}
              classes="ps-5 pe-5 d-flex justify-content-center button-submit m-md-0"
              classesText="font-14-semibold text-uppercase"
              disabled={isSubmitting}
            />
          </div>
        )}
      </div>
    </div>
  )

  return (
    <div className="ps-3 pe-3 d-flex justify-content-center container-fluid">
      <div className="col-lg-11">
        <form onSubmit={handleSubmit} className="row justify-content-center m-0 p-0">
          <div className="col-lg-11">
            {Topbar}
            <div className="mx-lg-4 col-sm-12 p-0 ps-lg-3 pe-lg-3 pb-lg-3">
              {Title}
              {Form}
              {Total}
              <div className="px-md-3">
                <div className="mt-3 mb-3 border-top" />
              </div>
              {SubmitButton}
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
