import { FieldArray, FieldArrayRenderProps, FormikProvider } from 'formik'
import { ProgressBar } from 'react-bootstrap'
import { Image } from 'react-component-library'
import Select, { components, NoticeProps } from 'react-select'
import { ItasCloseIcon, PlusIcon } from 'src/assets'
import { ScrollTopRoot } from 'src/roots'
import { reactSelectStyles } from 'src/styles/react-select.styles'
import { CostsFileModule, ReactSelectOption } from 'src/types'
import { ClaimCostModule, ClaimFileModule } from 'src/types/pages/claim.types'
import {
  ClaimUploadDocumentInfoFile,
  ClaimUploadDocumentModalError,
  ClaimUploadDocumentModalUpload,
  ClaimUploadDocumentTooltipInfo,
  ClaimUploadDocumentUploadFile,
} from 'src/ui/fragments/claim-upload-document/claim-upload-document'
import { Button, Loader } from 'src/ui/widgets'

import { UseClaimReportRcInjuryRefound } from './claim-report-rc-injury-refound.hook'

export const ClaimReportRcInjuryRefound = () => {
  const {
    t,
    progressBar,
    formik,
    isMobile,
    otherFile,
    optionCosts,
    filteredOptions,
    isOutOfSizeFile,
    inputFileAccept,
    costsfilesUpload,
    showUploadFileModal,
    showUploadOtherFileModal,
    idFile,
    showCheckError,
    loadingFile,
    setIdFile,
    updateCostsFiles,
    setIsOutOfSizeFile,
    setShowUploadFileModal,
    setShowUploadOtherFileModal,
    uploadDocument,
    deleteDocument,
    uploadOtherDocument,
    deleteOtherDocument,
    goBack,
  } = UseClaimReportRcInjuryRefound()

  const { values, setFieldValue, handleSubmit } = formik

  const Topbar = (
    <Button
      flavor="transparentAccentBack"
      text={t('commons.back')}
      classes="px-0 pt-0"
      classesText="text-uppercase font-14-semibold"
      onPress={goBack}
    />
  )

  const Title = (
    <div className="font-28-semibold c-292929">{t('claim_report_rc_injury_refound.title')}</div>
  )

  const Subtitle = (
    <div className="mt-2 font-14-semibold c-292929 text-uppercase">
      {t('claim_report_rc_injury_refound.subtitle')}
    </div>
  )

  const NoOptionsMessage = (props: NoticeProps) => {
    return (
      <components.NoOptionsMessage {...props}>
        {t('claim_report_rc_injury_refound.select_no_options')}
      </components.NoOptionsMessage>
    )
  }

  const CostModule = ({ fieldArray, item, index }: ClaimCostModule) => (
    <div>
      <div className="font-12">{t('claim_report_rc_injury_refound.costs_label')}</div>
      <div className="mb-3 d-flex align-items-center col-md-6">
        <Select
          className="w-100"
          name={`costFileRefounds.${index}.refoundCosts`}
          options={filteredOptions}
          defaultValue={item?.refoundCosts}
          styles={reactSelectStyles}
          components={{ NoOptionsMessage }}
          placeholder={t('claim_report_rc_injury_refound.costs_placeholder')}
          onChange={(e) => {
            updateCostsFiles()
            setFieldValue(`costFileRefounds.${index}.refoundCosts`, e as ReactSelectOption)
          }}
        />
        {values?.costFileRefounds && values?.costFileRefounds?.length > 1 && (
          <Image
            src={ItasCloseIcon}
            width={24}
            height={24}
            className="ms-2 p-1"
            onClick={() => {
              updateCostsFiles()
              fieldArray.remove(index)
            }}
          />
        )}
      </div>
    </div>
  )
  const FileModule = ({ item, index }: ClaimFileModule) => (
    <div>
      {item?.refoundCosts?.value &&
        costsfilesUpload[item?.refoundCosts?.value]?.map(({ id, value }) => {
          return (
            <div key={`${index}_${item.refoundCosts?.value}_${id}`}>
              <div
                className={`p-2 mt-2 bg-f5e9da ${
                  item?.files?.[id] ? 'claim-document-border-green' : ''
                }`}
              >
                <ClaimUploadDocumentTooltipInfo t={t} tooltipLabel={value} />
                <ClaimUploadDocumentUploadFile
                  t={t}
                  id={`${index}_${id}`}
                  setIdFile={setIdFile}
                  isMobile={isMobile}
                  file={item?.files?.[id]}
                  inputFileAccept={inputFileAccept}
                  uploadDocument={(file) => uploadDocument(file, index, id)}
                  setShowUploadModal={setShowUploadFileModal}
                />

                {item?.files?.[id] && (
                  <ClaimUploadDocumentInfoFile
                    file={item?.files?.[id]}
                    deleteDocument={() => deleteDocument(index, id)}
                  />
                )}
              </div>
              {showUploadFileModal && `${index}_${id}` === idFile && (
                <ClaimUploadDocumentModalUpload
                  t={t}
                  id={`${index}_${id}`}
                  inputFileAccept={inputFileAccept}
                  uploadDocument={(file) => {
                    uploadDocument(
                      file,
                      Number(idFile?.split('_')[0]),
                      Number(idFile?.split('_')[1])
                    )
                  }}
                  setShowUploadModal={setShowUploadFileModal}
                />
              )}
            </div>
          )
        })}
    </div>
  )

  const CostFileModule = (
    fieldArray: FieldArrayRenderProps,
    item: CostsFileModule,
    index: number
  ) => {
    return (
      <div className="mt-4">
        <CostModule fieldArray={fieldArray} item={item} index={index} />
        <FileModule item={item} index={index} />
      </div>
    )
  }

  const UploadOtherDocumentsForm = (
    <div>
      <div className="font-16-semibold c-7c2d35">
        {t('claim_report_rc_injury_refound.other_documents_title')?.toUpperCase()}
      </div>
      <div className={`p-2 mt-3 bg-f5e9da ${otherFile ? 'claim-document-border-green' : ''}`}>
        <ClaimUploadDocumentTooltipInfo
          t={t}
          tooltipLabel={t('claim_report_rc_injury_refound.other_documents_label')}
        />
        <ClaimUploadDocumentUploadFile
          t={t}
          id={'other_file'}
          isMobile={isMobile}
          file={otherFile}
          setIdFile={setIdFile}
          inputFileAccept={inputFileAccept}
          uploadDocument={uploadOtherDocument}
          setShowUploadModal={setShowUploadOtherFileModal}
        />
        {otherFile && (
          <ClaimUploadDocumentInfoFile file={otherFile} deleteDocument={deleteOtherDocument} />
        )}
      </div>
    </div>
  )

  const CheckError = (
    <div className="mt-5 input-text--error">{t('claim_report_rc_injury_refound.error')}</div>
  )

  const ButtonForm = (
    <div className="pt-2 d-flex justify-content-md-end">
      <div className="col-12 col-md-4">
        <Button
          flavor="accentNext"
          text={t('commons.continue')}
          type="submit"
          classes="ps-4 ps-md-3 pe-4 pe-md-3 m-auto m-md-0 w-100 d-flex justify-content-between"
          classesText="font-14-semibold text-uppercase"
        />
      </div>
    </div>
  )

  return (
    <div className="mb-md-5 pb-md-5 ps-md-3 pe-md-3 d-md-flex justify-content-md-center container-fluid">
      <div className="col-12 col-lg-7">
        <div className="mt-4">
          <ScrollTopRoot />
          {Topbar}
          <div className="claim-progress">
            <ProgressBar now={progressBar} className="claim-progress-bar" />
          </div>
          <div className="mb-4 p-4 ps-3 pe-3 p-md-5 bg-ffffff">
            {Title}
            {Subtitle}
            <form className="mt-4" onSubmit={handleSubmit}>
              <FormikProvider value={formik}>
                <FieldArray
                  name="costFileRefounds"
                  render={(fieldArray: FieldArrayRenderProps) => (
                    <div>
                      {values.costFileRefounds?.map((item, index) => (
                        <div key={`${index}_${item.refoundCosts?.value}`}>
                          {CostFileModule(fieldArray, item, index)}
                        </div>
                      ))}

                      {filteredOptions?.length >= 1 &&
                        values?.costFileRefounds &&
                        values?.costFileRefounds?.length < optionCosts?.length && (
                          <div className="mt-4 d-flex align-items-center">
                            <div
                              className="ms-2 font-14-semibold c-059881"
                              onClick={() => {
                                fieldArray.push({
                                  refoundCosts: undefined,
                                  files: undefined,
                                })
                              }}
                            >
                              <Image
                                src={PlusIcon}
                                width={16}
                                height={16}
                                className="filter-059881 me-2"
                              />
                              {t('claim_report_rc_injury_refound.add_costs')?.toUpperCase()}
                            </div>
                          </div>
                        )}
                    </div>
                  )}
                />
              </FormikProvider>
              <div className="mt-3 mb-3 border-bottom-green" />
              {UploadOtherDocumentsForm}
              {showCheckError && CheckError}
              <div className={showCheckError ? 'mt-2 pt-3' : 'mt-5 pt-5'}>{ButtonForm}</div>
            </form>
          </div>
          {showUploadOtherFileModal && (
            <ClaimUploadDocumentModalUpload
              t={t}
              id={'other_file_upload'}
              inputFileAccept={inputFileAccept}
              uploadDocument={uploadOtherDocument}
              setShowUploadModal={setShowUploadOtherFileModal}
            />
          )}
          {isOutOfSizeFile && (
            <ClaimUploadDocumentModalError
              t={t}
              isOutOfSizeFile={isOutOfSizeFile}
              setIsOutOfSizeFile={setIsOutOfSizeFile}
            />
          )}
        </div>
      </div>
      {loadingFile && <Loader overlay />}
    </div>
  )
}
