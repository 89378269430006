/* eslint-disable complexity */
import './Accordion.scss'

import React, { useState } from 'react'
import { Collapse } from 'react-bootstrap'
import { Image } from 'react-component-library'
import { AccordionPropsCustom } from 'react-component-library/build/types'

interface AccordionProps extends AccordionPropsCustom {
  icon_open?: string
  icon_close?: string
  classes_icon_open?: string
  classes_icon_close?: string
  show_close?: boolean
  button_read?: boolean
  button_read_classes?: string
  button_read_more_label?: string
  button_read_less_label?: string
}

const LandingAccordion: React.FC<AccordionProps> = ({
  title,
  head,
  children,
  icon_open,
  icon_close,
  separator,
  default_expanded = false,
  icon_size = 16,
  show_close,
  classes,
  classes_head = 'm-0 p-0 d-flex justify-content-between flex-nowrap',
  classes_title = 'm-0 p-0',
  classes_icon_open = '',
  classes_icon_close = '',
  button_read,
  button_read_classes,
  button_read_more_label,
  button_read_less_label,
  ...props
}: AccordionProps) => {
  /**
   * UseState
   */
  const [open, set_open] = useState(default_expanded)

  return (
    <main className={classes}>
      {/* Head */}
      <div className={classes_head} role="button" onClick={() => set_open(!open)}>
        {head || <div className={classes_title}>{title}</div>}

        {button_read ? (
          <Image
            src={icon_close}
            width={icon_size}
            height={icon_size}
            className={`icon-accordion ${classes_icon_close}`}
          />
        ) : open ? (
          <Image
            src={icon_open}
            width={icon_size}
            height={icon_size}
            className={`icon-accordion rotate-icon-accordion ${classes_icon_open}`}
          />
        ) : show_close ? (
          <Image
            src={icon_close}
            width={icon_size}
            height={icon_size}
            className={`icon-accordion rotate-icon-accordion ${classes_icon_close}`}
          />
        ) : (
          <></>
        )}
      </div>

      {/* Separator */}
      {separator && !button_read && <hr className={separator} />}

      {/* Collapse */}
      <Collapse in={open} {...props}>
        <div className="m-0 p-0">{children}</div>
      </Collapse>

      {button_read && (
        <div role="button" onClick={() => set_open(!open)}>
          {open ? (
            <div className="d-flex align-items-center">
              <div className={button_read_classes}>{button_read_less_label}</div>
              <Image
                src={icon_open}
                icon="minus"
                width={icon_size}
                height={icon_size}
                className={`icon-accordion ${classes_icon_open}`}
              />
            </div>
          ) : (
            <div className="d-flex align-items-center">
              <div className={button_read_classes}>{button_read_more_label}</div>
              <Image
                src={icon_close}
                icon="plus"
                width={icon_size}
                height={icon_size}
                className={`icon-accordion ${classes_icon_close}`}
              />
            </div>
          )}
        </div>
      )}
      {separator && button_read && <hr className={separator} />}
    </main>
  )
}

export default LandingAccordion
