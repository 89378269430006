import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { Paths } from 'src/navigation'
import { atomClaimBackPath, atomClaimData, atomClaimQuote, atomProgressBar } from 'src/storage'
import { FormikClaimReportInjuryStep1, InputRadioOption } from 'src/types'
import { yupStrRequired } from 'src/utils'
import * as Yup from 'yup'

import { PROGRESS_BAR_STEP } from '../../../progress-bar-step'

export const UseClaimReportRcInjuryReportInjuryStep1 = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const optionItems = [
    t('claim_report_rc_injury_report_injury_step1.radio_item_1'),
    t('claim_report_rc_injury_report_injury_step1.radio_item_2'),
    t('claim_report_rc_injury_report_injury_step1.radio_item_3'),
  ]

  const claimQuote = useRecoilValue(atomClaimQuote)
  const setClaimBackPath = useSetRecoilState(atomClaimBackPath)
  const [claimData, setClaimData] = useRecoilState(atomClaimData)
  const [progressBar, setProgressBar] = useRecoilState(atomProgressBar)

  const [injuryOptions, setInjuryOptions] = useState<InputRadioOption[]>()

  const [initialValues] = useState<FormikClaimReportInjuryStep1>({
    type: claimData?.other?.request?.injury?.type || '',
  })

  const validationSchema = Yup.object().shape({
    type: yupStrRequired,
  })

  //////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    setTimeout(() => {
      setProgressBar(PROGRESS_BAR_STEP.claim_report_rc_injury_report_injury)
    }, 100)
  }, [])

  useEffect(() => {
    if (!claimQuote) {
      navigate(Paths.ClaimReport)
    }
  }, [claimQuote])

  useEffect(() => {
    const options_: InputRadioOption[] = []

    optionItems?.map((item, index) => {
      options_?.push({
        id: index?.toString(),
        value: item,
      })
    })

    setInjuryOptions(options_)
  }, [])

  //////////////////////////////////////////////////////////////////////////////

  const formik = useFormik<FormikClaimReportInjuryStep1>({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setClaimData({
        ...claimData,
        other: {
          ...claimData?.other,
          request: {
            ...claimData?.other?.request,
            injury: { type: values?.type },
          },
        },
      })
      if (values?.type === '0') {
        setClaimBackPath(Paths.ClaimReportRcInjuryReportInjuryStep1)
        navigate(Paths.ClaimReportOpen)
      } else if (values?.type === '1' || values?.type === '2') {
        navigate(Paths.ClaimReportRcInjuryReportInjuryStep2)
      }
    },
  })

  //////////////////////////////////////////////////////////////////////////////

  const goBack = () => {
    navigate(Paths.ClaimReportRcInjuryRequestReason)
    setClaimData({
      ...claimData,
      other: {
        ...claimData?.other,
        request: {},
      },
    })
  }

  return {
    t,
    formik,
    injuryOptions,
    validationSchema,
    progressBar,
    goBack,
  }
}
