/* eslint-disable complexity */
import {
  ClaimDataInsured,
  ClaimDataRequest,
  ClaimFile,
  ClaimOtherData,
  CostFileRefounds,
  DamageRequest,
  DamageRequestThings,
  InjuryRequest,
  InjuryRequestSections,
  RefoundRequest,
} from 'src/types/pages/claim.types'

const getFile = (file: ClaimFile | undefined): ClaimFile | undefined => {
  if (!file) return undefined

  return {
    name: file?.name,
    size: file?.size,
    type: file?.type,
    lastModified: file?.lastModified,
    reference: file?.reference,
    data: '',
  }
}

const getDamage = (damage: DamageRequest | undefined): DamageRequest => {
  const personalData_: ClaimDataInsured = {
    name: damage?.personalData?.name || undefined,
    surname: damage?.personalData?.surname || undefined,
    fiscalCode: damage?.personalData?.fiscalCode || undefined,
    residence: damage?.personalData?.residence || undefined,
    mobile: damage?.personalData?.mobile || undefined,
    email: damage?.personalData?.email || undefined,
  }

  const damageRequestThings_: DamageRequestThings = {
    whatDamage: damage?.damageRequestThings?.whatDamage || undefined,
    damageValue: damage?.damageRequestThings?.damageValue || undefined,
  }

  return {
    injuredPersonKnown: damage?.injuredPersonKnown || undefined,
    personalData: personalData_ || undefined,
    damageDoneType: damage?.damageDoneType || undefined,
    damageRequestThings: damageRequestThings_ || undefined,
    file: getFile(damage?.file || undefined) || undefined,
  }
}

const getRefound = (refound: RefoundRequest | undefined): RefoundRequest => {
  const costFileRefounds_: CostFileRefounds[] = []

  refound?.costFileRefounds?.map((item) => {
    const files_: ClaimFile[] | undefined = []
    item?.files?.map((file) => {
      files_?.push({
        ...file,
        data: '',
      })
    })

    costFileRefounds_.push({
      cost: item?.cost,
      files: files_,
    })
  })

  const otherFile_: ClaimFile | undefined = getFile(refound?.otherFile?.[0] || undefined)

  return {
    costFileRefounds: costFileRefounds_,
    otherFile: otherFile_ ? [otherFile_] : undefined,
  }
}

const getInjurySection = (injurySections: InjuryRequestSections[]): InjuryRequestSections[] => {
  const injurySections_: InjuryRequestSections[] = []

  injurySections?.map((item) => {
    injurySections_?.push({
      bodyParts: item?.bodyParts,
      nature: item?.nature,
      showAccordion: item?.showAccordion,
      showMenu: item?.showMenu,
    })
  })

  return injurySections_ || []
}

const getInjury = (injury: InjuryRequest | undefined): InjuryRequest => {
  return {
    type: injury?.type || undefined,
    extentDamage: injury?.extentDamage || undefined,
    damageDescription: injury?.damageDescription || undefined,
    injurySections: getInjurySection(injury?.injurySections || []),
  }
}

const getRequest = (
  request: ClaimDataRequest | undefined,
  requestType: string | undefined
): ClaimDataRequest => {
  return {
    injury: requestType === 'injury' ? getInjury(request?.injury || undefined) : undefined,
    damage: requestType === 'damage' ? getDamage(request?.damage || undefined) : undefined,
    refound: requestType === 'refound' ? getRefound(request?.refound || undefined) : undefined,
  }
}

export const getOtherRequest = (otherData: ClaimOtherData | undefined): ClaimOtherData => {
  return {
    file: getFile(otherData?.file),
    date: otherData?.date || undefined,
    hour: otherData?.hour || undefined,
    insuredType: otherData?.insuredType || undefined,
    requestType: otherData?.requestType || undefined,
    request: getRequest(otherData?.request || undefined, otherData?.requestType),
    personalData: otherData?.personalData || undefined,
  }
}
