import { useTranslation } from 'react-i18next'
import { useRecoilValue } from 'recoil'
import { atomFromApp } from 'src/storage'
import { Certificate, ReactNativeWindow } from 'src/types'

export const usePdf = () => {
  const { t } = useTranslation()

  const fromApp = useRecoilValue(atomFromApp)

  const base64Arraybuffer = async (data: Uint8Array) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const base64url: any = await new Promise((r) => {
      const reader = new FileReader()
      reader.onload = () => r(reader.result)
      reader.readAsDataURL(new Blob([data]))
    })
    return base64url?.split(',', 2)[1]
  }

  const showPdfMobile = (data: number[]) => {
    const reactNativeWindow: ReactNativeWindow = window
    base64Arraybuffer(new Uint8Array(data)).then((base64) => {
      reactNativeWindow?.ReactNativeWebView?.postMessage?.(`PDF_BASE64:${base64}`)
    })
  }

  const showPdfDesktop = (data: number[]) => {
    const arr = new Uint8Array(data)
    const blob = new Blob([arr], { type: 'application/pdf' })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = t('payment.pdf_title')
    a.click()
    a.remove()
  }

  const showPdf = (certificate: Certificate) => {
    if (fromApp) {
      showPdfMobile(certificate?.data)
    } else {
      showPdfDesktop(certificate?.data)
    }
  }

  return { showPdf }
}
