import { ChangeEvent } from 'react'
import { ProgressBar } from 'react-bootstrap'
import { Trans } from 'react-i18next'
import { ScrollTopRoot } from 'src/roots'
import { Button, InputRadio } from 'src/ui/widgets'

import { UseClaimReportRcInjuryRequestReason } from './claim-report-rc-injury-request-reason.hook'

export const ClaimReportRcInjuryRequestReason = () => {
  const { t, formik, optionItems, validationSchema, progressBar, goBack } =
    UseClaimReportRcInjuryRequestReason()

  const { values, touched, errors, handleBlur, handleSubmit, handleChange, setFieldValue } = formik

  const Topbar = (
    <Button
      flavor="transparentAccentBack"
      text={t('commons.back')}
      classes="px-0 pt-0"
      classesText="text-uppercase font-14-semibold"
      onPress={goBack}
    />
  )

  const Title = (
    <div className="font-28-semibold c-292929">
      {t('claim_report_rc_injury_request_reason.title')}
    </div>
  )

  const Subtitle = (
    <div className="mt-2 font-14-semibold c-292929 text-uppercase">
      {t('claim_report_rc_injury_request_reason.subtitle')}
    </div>
  )

  const SelectLabel = (
    <div className="mt-3 font-16 c-292929">
      <Trans i18nKey={'claim_report_rc_injury_request_reason.select_label'} />
    </div>
  )

  const RadioForm = (
    <div>
      <InputRadio
        name="request"
        handleChange={(event: ChangeEvent<HTMLInputElement>) => {
          setFieldValue('request', event?.target?.value)
          handleChange(event)
        }}
        handleBlur={handleBlur}
        options={optionItems || []}
        validationSchema={validationSchema}
        touched={touched.request}
        value={values.request}
        error={errors.request}
        vertically
        label={t('claim_report_rc_injury_request_reason.radio_label')}
        classes="m-0 p-0 mt-4 mb-3"
        classesLabel="m-0 p-0 font-12 d-flex align-items-center mb-3"
        classesOption="pb-3"
      />
    </div>
  )

  const ButtonForm = (
    <div className="mt-5 pt-2 d-flex justify-content-md-end">
      <div className="col-12 col-md-4">
        <Button
          flavor="accentNext"
          type="submit"
          text={t('commons.continue')}
          disabled={!values?.request}
          classes="ps-4 ps-md-3 pe-4 pe-md-3 m-auto m-md-0 w-100 d-flex justify-content-between"
          classesText="font-14-semibold text-uppercase"
        />
      </div>
    </div>
  )

  return (
    <div className="mb-md-5 pb-md-5 ps-md-3 pe-md-3 d-md-flex justify-content-md-center container-fluid">
      <div className="col-12 col-lg-7">
        <div className="mt-4">
          <ScrollTopRoot />
          {Topbar}
          <div className="claim-progress">
            <ProgressBar now={progressBar} className="claim-progress-bar" />
          </div>
          <div className="p-4 ps-3 pe-3 p-md-5 bg-ffffff">
            {Title}
            {Subtitle}
            {SelectLabel}

            <form onSubmit={handleSubmit}>
              {RadioForm}
              <div className="pt-5">{ButtonForm}</div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
