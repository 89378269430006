export const postQuoteCodes = {
  '500100': 'TODO - Error1',
  '500101': 'TODO - Error2',
  '500102': 'TODO - Error3',
  '500103': 'TODO - Error4',
}

export const postPaymentCodes = {
  // 🚧 TODO: add your backend codes
}

export const postInitTransactionCodes = {
  // 🚧 TODO: add your backend codes
}

export const postPdfCodes = {
  // 🚧 TODO: add your backend codes
}

export const postCertificateCodes = {
  // 🚧 TODO: add your backend codes
}
