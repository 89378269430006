import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useRecoilValue } from 'recoil'
import { PERIOD_MAPPER } from 'src/config'
import { Paths } from 'src/navigation'
import { selectorCoverages } from 'src/storage'
import { LandingCardProps } from 'src/types'

export const useLandingCard = ({ base, seasonal }: LandingCardProps) => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const coverages = useRecoilValue(selectorCoverages)

  const basePrice = coverages?.['1']?.basePremium
  const plusPrice = coverages?.['2']?.basePremium
  const seasonalBasePrice = coverages?.['1']?.seasonalBasePremium
  const seasonalPlusPrice = coverages?.['2']?.seasonalBasePremium

  const buttonDisabled = !basePrice || !plusPrice || !seasonalBasePrice || !seasonalPlusPrice

  const goToDataEntry = () => {
    navigate({
      pathname: Paths.DataEntry1,
      search: `?${new URLSearchParams({
        base: `${base}`,
        seasonal: `${seasonal}`,
        utm_source: `${PERIOD_MAPPER[`${base}_${seasonal}`?.toString()]}`,
        utm_medium: 'home',
      }).toString()}`,
    })
  }

  return {
    t,
    basePrice,
    plusPrice,
    seasonalBasePrice,
    seasonalPlusPrice,
    buttonDisabled,
    goToDataEntry,
  }
}
