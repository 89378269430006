import { MockendError } from 'src/types'
import { delay } from 'src/utils'

import { ALL_DISABLED, ALL_ENABLED, CONFIG, TIMING } from './mockend.config'

export const useMockend = () => {
  const isMocked = (gateId: string) => {
    const configs = {
      ['ALL_DISABLED=true, ALL_ENABLED=true']: false,
      ['ALL_DISABLED=true, ALL_ENABLED=false']: false,
      ['ALL_DISABLED=false, ALL_ENABLED=true']: true,
      ['ALL_DISABLED=false, ALL_ENABLED=false']: CONFIG[gateId]?.enabled,
    }
    return configs[`ALL_DISABLED=${ALL_DISABLED}, ALL_ENABLED=${ALL_ENABLED}`]
  }

  const getMock = async (gateId: string) => {
    const { status, mocks } = CONFIG[gateId]

    await delay(TIMING)

    const response = {
      status,
      data: mocks[status],
    }

    if (status < 200 || status > 299) {
      throw new MockendError(gateId, response)
    }
    return response
  }

  return { isMocked, getMock }
}
