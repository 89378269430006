import { InputDigitProps } from 'src/types'
import { PropsClaimCode } from 'src/types/pages/claim.types'

export const useClaimCode = ({ setCode }: PropsClaimCode) => {
  const handleDigitInputsChange = (value: InputDigitProps) => {
    setCode(value.asString.slice(0, 8))
  }

  return {
    handleDigitInputsChange,
  }
}
