import './footer.scss'

import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useRootOutcomes } from 'src/storage'

export const useFooter = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const { topRootOutcome, removeRootOutcomes } = useRootOutcomes()

  const errorCode = topRootOutcome?.awsGateError?.errorCode
  const errorDesc = topRootOutcome?.awsGateError?.errorDescription

  useEffect(() => {
    removeRootOutcomes()
  }, [])

  return {
    t,
    navigate,
    removeRootOutcomes,
    errorCode,
    errorDesc,
  }
}
