import { ProgressBar } from 'react-bootstrap'
import { Trans } from 'react-i18next'
import { ScrollTopRoot } from 'src/roots'
import { Button, InputText } from 'src/ui/widgets'

import { UseClaimReportRcInjuryPersonalData } from './claim-report-rc-injury-personal-data.hook'

export const ClaimReportRcInjuryPersonalData = () => {
  const { t, formik, validationSchema, progressBar, goBack } = UseClaimReportRcInjuryPersonalData()

  const { values, touched, errors, handleBlur, handleChange, handleSubmit } = formik

  const Topbar = (
    <Button
      flavor="transparentAccentBack"
      text={t('commons.back')}
      classes="px-0 pt-0"
      classesText="text-uppercase font-14-semibold"
      onPress={goBack}
    />
  )

  const Title = (
    <div className="font-28-semibold c-292929">
      {t('claim_report_rc_injury_personal_data.title')}
    </div>
  )

  const Subtitle = (
    <div className="mt-2 font-14-semibold c-292929 text-uppercase">
      {t('claim_report_rc_injury_personal_data.subtitle')}
    </div>
  )

  const SelectLabel = (
    <div className="mt-3 font-16 c-292929">
      <Trans i18nKey={'claim_report_rc_injury_personal_data.select_label'} />
    </div>
  )

  const InsuredForm = (
    <div className="mt-4 col-12">
      <div className="d-block d-md-flex">
        <div className="col-md-6 ps-md-0 pe-md-4">
          <InputText
            name="name"
            handleChange={handleChange}
            handleBlur={handleBlur}
            validationSchema={validationSchema}
            touched={touched.name}
            value={values.name}
            error={errors.name}
            disabled={true}
            label={t('claim_report_rc_injury_personal_data.name')}
            placeholder={t('claim_report_rc_injury_personal_data.name_placeholder')}
          />
        </div>
        <div className="col-md-6 pe-md-0 ps-md-4">
          <InputText
            name="surname"
            handleChange={handleChange}
            handleBlur={handleBlur}
            validationSchema={validationSchema}
            touched={touched.surname}
            value={values.surname}
            error={errors.surname}
            disabled={true}
            label={t('claim_report_rc_injury_personal_data.surname')}
            placeholder={t('claim_report_rc_injury_personal_data.surname_placeholder')}
          />
        </div>
      </div>

      <div className="d-block d-md-flex">
        <div className="col-md-6 ps-md-0 pe-md-4">
          <InputText
            name="fiscalCode"
            handleChange={handleChange}
            handleBlur={handleBlur}
            validationSchema={validationSchema}
            touched={touched.fiscalCode}
            value={values.fiscalCode?.toUpperCase()}
            error={errors.fiscalCode}
            disabled={true}
            label={t('claim_report_rc_injury_personal_data.fiscal_code')}
            placeholder={t('claim_report_rc_injury_personal_data.fiscal_code_placeholder')}
          />
        </div>
        <div className="col-md-6 pe-md-0 ps-md-4">
          <InputText
            name="residence"
            handleChange={handleChange}
            handleBlur={handleBlur}
            validationSchema={validationSchema}
            touched={touched.residence}
            value={values.residence}
            error={errors.residence}
            label={t('claim_report_rc_injury_personal_data.residence')}
            placeholder={t('claim_report_rc_injury_personal_data.residence_placeholder')}
          />
        </div>
      </div>

      <div className="d-block d-md-flex">
        <div className="col-md-6 ps-md-0 pe-md-4">
          <InputText
            name="mobile"
            handleChange={handleChange}
            handleBlur={handleBlur}
            validationSchema={validationSchema}
            touched={touched.mobile}
            value={values.mobile}
            error={errors.mobile}
            label={t('claim_report_rc_injury_personal_data.mobile')}
            placeholder={t('claim_report_rc_injury_personal_data.mobile_placeholder')}
          />
        </div>
        <div className="col-md-6 pe-md-0 ps-md-4">
          <InputText
            name="email"
            handleChange={handleChange}
            handleBlur={handleBlur}
            validationSchema={validationSchema}
            touched={touched.email}
            value={values.email}
            disabled={true}
            error={errors.email}
            label={t('claim_report_rc_injury_personal_data.email')}
            placeholder={t('claim_report_rc_injury_personal_data.email_placeholder')}
          />
        </div>
      </div>
    </div>
  )

  const ButtonForm = (
    <div className="mt-5 pt-2 d-flex justify-content-md-end">
      <div className="col-12 col-md-4">
        <Button
          flavor="accentNext"
          type="submit"
          text={t('commons.continue')}
          classes="ps-4 ps-md-3 pe-4 pe-md-3 m-auto m-md-0 w-100 d-flex justify-content-between"
          classesText="font-14-semibold text-uppercase"
        />
      </div>
    </div>
  )

  return (
    <div className="mb-4 mb-md-5 pb-md-5 ps-md-3 pe-md-3 d-md-flex justify-content-md-center container-fluid">
      <div className="col-12 col-lg-7">
        <div className="mt-4">
          <ScrollTopRoot />
          {Topbar}
          <div className="claim-progress">
            <ProgressBar now={progressBar} className="claim-progress-bar" />
          </div>
          <div className="p-4 ps-3 pe-3 p-md-5 bg-ffffff">
            {Title}
            {Subtitle}
            {SelectLabel}
            <form onSubmit={handleSubmit}>
              {InsuredForm}
              <div className="pt-2">{ButtonForm}</div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
