import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil'
import { upperLanguage } from 'src/i18n'
import { Paths } from 'src/navigation'
import {
  toEvaluatePremiumRequestGroup,
  usePostEvaluatePremium,
  usePostPayment,
} from 'src/networking/terminals'
import {
  atomCurrentStep,
  atomEvaluatePremium,
  atomFormikMainInsured,
  atomFormikMainInsuredGroup,
  atomGoogleEcommerce,
  atomIsGroup,
  atomPaymentData,
  atomPaymentResponse,
  atomQuote,
  selectorAgency,
} from 'src/storage'
import { GoogleWindow } from 'src/types'
import { FormikMainInsuredGroup } from 'src/types/networking/terminals/group.types'
import { submitCaptcha } from 'src/ui/widgets'

import { usePrice } from './handlers/price.hook'
import { useScrollToErrorGroup } from './handlers/scroll-to-error.hook'
import { useValidationSchemasGroup } from './handlers/validation-schemas-group.hook'

export const useDataEntryGroup = (step: number) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  // Formiks
  const formikMainInsured = useRecoilValue(atomFormikMainInsured)
  const [formikMainInsuredGroup, saveFormikMainInsuredGroup] = useRecoilState(
    atomFormikMainInsuredGroup
  )

  // Recoils
  const [quote] = useRecoilState(atomQuote)
  const resetQuote = useResetRecoilState(atomQuote)
  const agency = useRecoilValue(selectorAgency)
  const setIsGroup = useSetRecoilState(atomIsGroup)
  const saveGoogleEcommerce = useSetRecoilState(atomGoogleEcommerce)
  const [paymentData, setPaymentData] = useRecoilState(atomPaymentData)
  const resetEvaluatePremium = useResetRecoilState(atomEvaluatePremium)
  const [currentStep, saveCurrentStep] = useRecoilState(atomCurrentStep)
  const [paymentResponse, setPaymentResponse] = useRecoilState(atomPaymentResponse)
  const [evaluatedPremium, saveEvaluatePremium] = useRecoilState(atomEvaluatePremium)

  // States
  const [isDisabled, setIsDisabled] = useState(true)
  const [discountError, setDiscountError] = useState('')
  const [updatePremium, setUpdatePremium] = useState(false)
  const [scrollEvaluated, setScrollEvaluated] = useState(true)

  // CrossGates
  const { crossGate: postEvaluatePremium, pending: pendingEvaluatePremium } =
    usePostEvaluatePremium()
  const price = usePrice(discountError)
  const { crossGate: postPayment } = usePostPayment()

  ////////////////////////////////////////////////////////////////

  const trackPurchase = () => {
    const googleWindow: GoogleWindow = window
    googleWindow.dataLayer = googleWindow?.dataLayer || []
    googleWindow.dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.
    const ecommerce = {
      value: +(quote?.quoteValue || 0),
      currency: 'EUR',
      items: [
        {
          item_category: formikMainInsured?.insured_days,
          item_category2: formikMainInsured?.coverage_type_radios === '1' ? 'Base' : 'Plus',
        },
      ],
    }
    saveGoogleEcommerce(ecommerce)
    googleWindow.dataLayer.push({ event: 'begin_checkout', ecommerce })
  }

  /**
   * Event Navigation Payment
   */
  window.addEventListener('message', function (event) {
    // console.log('[EXTERNAL event]', event)
    if (event.data === 'Axepta3dsClosed') {
      window.addEventListener('message', function (event) {
        // console.log('[event]', event)

        if (event.data === 'Axepta3dsClosed') {
          navigate({
            pathname: Paths.EntryGroup4,
          })
        }

        if (event.origin !== process.env?.REACT_APP_BNL_SDK_URL) {
          return
        }

        if (event.data === 'axepta_SUCCESS_message') {
          setPaymentResponse('OK')
        } else if (event.data === 'axepta_FAILURE_message') {
          setPaymentResponse('KO')
        }
      })
      navigate({
        pathname: Paths.EntryGroup4,
      })
    }

    // if (event.origin !== process.env?.REACT_APP_BNL_SDK_URL) {
    //   return
    // }

    if (event.data === 'axepta_SUCCESS_message') {
      setPaymentResponse('OK')
    } else if (event.data === 'axepta_FAILURE_message') {
      setPaymentResponse('KO')
    }
  })

  // UseEffect
  useEffect(() => {
    if (paymentData?.paymentId && paymentResponse) {
      if (paymentResponse === 'OK') {
        setPaymentResponse(undefined)
        window.open(paymentData?.successUrl, '_self')
      } else if (paymentResponse === 'KO') {
        setPaymentResponse(undefined)
        window.open(paymentData?.failureUrl, '_self')
      }
      setPaymentData(undefined)
    }
  }, [paymentData, paymentResponse])

  useEffect(() => {
    setIsGroup(true)
  }, [])

  useEffect(() => {
    if (currentStep !== 0) {
      window?.scrollTo(0, 0)
    }
    if (evaluatedPremium && scrollEvaluated && currentStep === 1) {
      setScrollEvaluated(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [evaluatedPremium, scrollEvaluated, currentStep])

  useEffect(() => {
    if (!isNaN(step)) {
      saveCurrentStep(step)
    }
  }, [step])

  useEffect(() => {
    if (updatePremium) {
      doPostEvaluatePremium()
      setUpdatePremium(false)
    }
  }, [
    updatePremium,
    currentStep,
    formikMainInsured?.coverage_type_radios,
    formikMainInsured?.insured_number,
    formikMainInsured?.insured_number_under14,
    formikMainInsured?.insured_days,
    formikMainInsured?.is_family,
  ])

  // Formik
  const validationSchemas = useValidationSchemasGroup()

  const formik = useFormik<FormikMainInsuredGroup>({
    initialValues: formikMainInsuredGroup,
    validationSchema: validationSchemas[currentStep],
    enableReinitialize: true,
    onSubmit: (values, { setTouched, setSubmitting }) => {
      saveCurrentStep((x) => Math.min(4, x + 1))

      setTouched({})

      if (currentStep + 1 === 2) {
        values.originGroup = values?.group
      }

      saveFormikMainInsuredGroup(values)
      setValues(values)

      if (currentStep + 1 === 2) {
        navigate({
          pathname: Paths.EntryGroup3,
        })
      } else if (currentStep + 1 === 3) {
        const tempGroup = values?.group || []

        if (tempGroup && values?.mainInsured?.isInsured === 'id_yes') {
          const newGroup = tempGroup?.filter((item) => item?.id !== values?.mainInsured?.id)
          saveFormikMainInsuredGroup({ ...values, group: newGroup })
        }

        navigate({
          pathname: Paths.EntryGroup4,
        })
      } else {
        doPostPayment()
      }

      setSubmitting(false)
      setPaymentData(undefined)
      setPaymentResponse(undefined)
    },
  })

  const { values, setValues } = formik

  const { triggerFormikCheck } = useScrollToErrorGroup(formik)

  useEffect(() => {
    if (formik?.values?.group?.length > 0) {
      const hasErrors = formik?.values?.group.some(
        (asset) => asset.errors && Object.keys(asset.errors).length > 0
      )
      setIsDisabled(hasErrors)
    } else {
      setIsDisabled(true)
    }
  }, [formik?.values?.group])

  useEffect(() => {
    if (formik?.values?.mainInsured?.discount === '') {
      setDiscountError('')
    }
  }, [formik?.values?.mainInsured?.discount])

  // Functions
  const proceedToPayment = (paymentId: string) => {
    saveCurrentStep((x) => x + 1)
    navigate({
      pathname: Paths.EntryGroup5,
      search: `?paymentId=${paymentId}`,
    })
  }

  const doPostPayment = () => {
    submitCaptcha().then((token) => {
      postPayment(
        {
          body: {
            token: token,
            agencyId: agency?.id,
            quoteId: quote?.quoteId,
            language: upperLanguage,
          },
        },
        {
          onSuccess: (response) => {
            if (response?.paymentId) {
              setPaymentData(response)
              setTimeout(() => {
                trackPurchase()
                proceedToPayment(response?.paymentId)
              }, 500)
            }
          },
        }
      )
    })
  }

  const doPostEvaluatePremium = () => {
    const body_ = toEvaluatePremiumRequestGroup(
      formik?.values?.group,
      formikMainInsured,
      currentStep
    )
    if (body_) {
      postEvaluatePremium(
        {
          body: body_,
        },
        {
          onSuccess: saveEvaluatePremium,
        }
      )
    }
  }

  const evaluatePremium = () => {
    setUpdatePremium(true)
  }

  // Go back
  const goBack = () => {
    resetQuote()
    resetEvaluatePremium()
    if (currentStep > 0) {
      setValues({ ...values })
      saveCurrentStep((x) => Math.max(0, x - 1))
      if (currentStep - 1 === 0) {
        resetEvaluatePremium()
        setIsGroup(false)
        navigate({
          pathname: Paths.DataEntry1,
        })
      } else if (currentStep - 1 === 1) {
        saveFormikMainInsuredGroup(values)
        navigate({
          pathname: Paths.EntryGroup2,
        })
      } else if (currentStep - 1 === 2) {
        const updatedValues = { ...values, group: values.originGroup }
        saveFormikMainInsuredGroup(updatedValues)
        navigate({
          pathname: Paths.EntryGroup3,
        })
      } else if (currentStep - 1 === 3) {
        navigate({
          pathname: Paths.EntryGroup4,
        })
      }
    }
  }

  return {
    t,
    price,
    formik,
    isDisabled,
    currentStep,
    discountError,
    evaluatedPremium,
    formikMainInsured,
    validationSchemas,
    pendingEvaluatePremium,
    goBack,
    evaluatePremium,
    setDiscountError,
    triggerFormikCheck,
  }
}
