import './entry-group-step4.scss'

import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilState, useRecoilValue } from 'recoil'
import { postQuoteCodes, toQuoteRequestGroup, usePostQuote } from 'src/networking/terminals'
import { atomProduct, atomQuote, selectorAgency, selectorCoverages } from 'src/storage'
import { Formik, FormikMainInsured } from 'src/types'
import { FormikMainInsuredGroup } from 'src/types/networking/terminals/group.types'
import { getDifferenceInDays, useBoolean } from 'src/utils'

import { usePrice } from '../../handlers/price.hook'

export const useEntryGroupStep4 = ({
  formikFather,
  formikMainInsured,
  discountError = '',
  setDiscountError,
}: {
  formikFather: Formik<FormikMainInsuredGroup>
  formikMainInsured: FormikMainInsured
  discountError?: string
  setDiscountError: Dispatch<SetStateAction<string>>
}) => {
  const { t } = useTranslation()

  const { mainInsured } = formikFather?.values ?? {}

  // Constants
  const isMultiDays =
    getDifferenceInDays(
      formikMainInsured.coverage_start_date,
      formikMainInsured.coverage_end_date
    ) +
      1 >=
    3

  const price = usePrice(discountError, ' ')

  // Recoils
  const coverages = useRecoilValue(selectorCoverages)
  const coverage = coverages?.[formikMainInsured.coverage_type_radios || '']

  const product = useRecoilValue(atomProduct)

  const agency = useRecoilValue(selectorAgency)
  const [quote, saveQuote] = useRecoilState(atomQuote)

  // States
  const [policyCode, setPolicyCode] = useState(coverage?.policyCode || '')

  // Booleans
  const [quoting, turnOnQuoting, turnOffQuoting] = useBoolean()

  // CrossGates
  const { crossGate: postQuote } = usePostQuote()

  // Special
  const oldQuote = quote?.oldQuoteValue?.replace('.', ',')
  const newQuote = quote?.quoteValue?.replace('.', ',')

  const seasonalBase = product?.configuration?.coverages?.['1']?.seasonalBasePremium
  const seasonalPlus = product?.configuration?.coverages?.['2']?.seasonalBasePremium
  const quoteValue = Number(quote?.quoteValue?.replace(',', '.'))

  const warning =
    formikMainInsured.insured_days === 'id_period' &&
    ((seasonalBase &&
      formikMainInsured.coverage_type_radios === '1' &&
      quoteValue >= seasonalBase) ||
      (seasonalPlus &&
        formikMainInsured.coverage_type_radios === '2' &&
        quoteValue >= seasonalPlus))

  // UseEffect
  useEffect(() => {
    if (agency?.isCustomNow) {
      if (formikMainInsured.coverage_type_radios === '1') {
        setPolicyCode(agency?.policyNumberBase || '')
      } else if (formikMainInsured.coverage_type_radios === '2') {
        setPolicyCode(agency?.policyNumberPlus || '')
      }
    }
  }, [])

  useEffect(() => {
    if (!quote) {
      doPostQuote()
    }
  }, [quote])

  useEffect(() => {
    if (mainInsured?.discount === '') {
      setDiscountError('')
    }
  }, [mainInsured?.discount])

  ////////////////////////////////////////////////////////////////////////////////////////////

  // Functions
  const doPostQuote = () => {
    postQuote(
      {
        body: toQuoteRequestGroup(
          formikFather?.values?.group,
          formikFather?.values?.mainInsured,
          formikMainInsured,
          agency,
          quote?.quoteId
        ),
      },
      {
        onSuccess: saveQuote,
        onFailure: (error) => {
          const code = error?.errorCode || ''
          const desc = error?.errorDescription || ''
          if (code in postQuoteCodes) {
            setDiscountError(desc)
          }
        },
        onPending: turnOnQuoting,
        onIdle: turnOffQuoting,
      }
    )
  }

  const updateTotalQuote = () => {
    doPostQuote()
    setDiscountError('')
  }

  return {
    t,
    quote,
    price,
    agency,
    warning,
    quoting,
    coverage,
    newQuote,
    oldQuote,
    coverages,
    policyCode,
    isMultiDays,
    updateTotalQuote,
  }
}
