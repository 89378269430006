import './section4.scss'

import { useTranslation } from 'react-i18next'
export const useSection4 = () => {
  const { t } = useTranslation()

  return {
    t,
  }
}
