import './App.scss'

import { BrowserRouter } from 'react-router-dom'
import { RecoilRoot } from 'recoil'

import {
  FromAppRoot,
  LoaderRoot,
  NavigationRoot,
  OutcomeRoot,
  ScrollTopRoot,
  WrapperRoot,
} from './roots'

const App = () => {
  return (
    <BrowserRouter>
      <RecoilRoot>
        <WrapperRoot>
          <FromAppRoot />
          <ScrollTopRoot />
          <LoaderRoot />
          <OutcomeRoot />
          <NavigationRoot />
        </WrapperRoot>
      </RecoilRoot>
    </BrowserRouter>
  )
}

export default App
