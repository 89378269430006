import { useAws } from 'src/networking/aws'
import { AwsGateCallbacks, AwsGateRequest, GetProduct } from 'src/types'

export const useGetProduct = <
  T extends GetProduct['request'],
  D extends GetProduct['response']
>() => {
  const hook = useAws<T, D>('getProduct')
  return {
    ...hook,
    crossGate: (request: AwsGateRequest<T>, callbacks: AwsGateCallbacks<D>) => {
      return hook.crossGate(
        {
          method: 'get',
          path: `/product/${request.params.agencyCode}`,
          ...request,
        },
        callbacks
      )
    },
  }
}
