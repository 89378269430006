import React from 'react'
import { Image } from 'react-component-library'
import { useTranslation } from 'react-i18next'
import Select, { components } from 'react-select'
import { LeftIcon } from 'src/assets'
import { reactSelectStyles } from 'src/styles/react-select.styles'
import { ReactSelectOption } from 'src/types'
import { InputCheckbox } from 'src/ui/widgets'

export const OptionsOutsideSelect = (props: any) => {
  const { id, isMulti, outsideValues, onChange } = props

  const { t } = useTranslation()

  const handleRemoveValue = (buttonName: string) => {
    if (!onChange) return
    const removedValue = outsideValues?.find((val: ReactSelectOption) => val.value === buttonName)
    if (!removedValue) return
    onChange(
      outsideValues.filter((val: ReactSelectOption) => val.value !== buttonName),
      { action: 'remove-value', removedValue }
    )
  }

  const Option = (props: any) => {
    return (
      <div>
        <components.Option {...props}>
          <InputCheckbox
            name={`injurySections.${id}.bodyParts.${props?.data?.value}`}
            label={props?.data?.label}
            value={outsideValues?.find(
              (item: ReactSelectOption) => item?.value === props?.data?.value
            )}
            handleChange={() => {
              ;('')
            }}
            classesLabel="font-14 d-flex align-items-center"
          />
        </components.Option>
      </div>
    )
  }

  const SeeForm = (
    <div>
      <div
        className="mt-4 d-flex flex-wrap justify-content-between"
        id={`injurySections.${id}.bodyParts.optionsInjuryId`}
        style={{ maxHeight: '95px', overflow: 'hidden' }}
      >
        {outsideValues?.map((val: ReactSelectOption) => (
          <div
            key={`injurySections.${id}.bodyParts.${val.value}`}
            className="mt-1 mb-1 d-flex align-items-center justify-content-between option-claim-item"
          >
            <div className="font-14">{val.label}</div>

            <Image
              icon="close"
              width={27}
              height={27}
              className="ms-3 ps-2 pe-2 filter-ffffff cursor-pointer"
              onClick={() => handleRemoveValue(val.value)}
            />
          </div>
        ))}
      </div>
      {Number(
        (
          document?.getElementById(`injurySections.${id}.bodyParts.optionsInjuryId`)
            ?.offsetHeight || 0
        )
          ?.toString()
          ?.replace('px', '')
      ) >= 95 && (
        <div>
          <div
            id={`injurySections.${id}.bodyParts.showLess`}
            style={{ display: 'none' }}
            className="align-items-center cursor-pointer"
          >
            <div
              onClick={() => {
                const el = document.getElementById(`injurySections.${id}.bodyParts.optionsInjuryId`)
                if (el?.style.maxHeight && el?.style.overflow) {
                  el.style.overflow = 'hidden'
                  el.style.maxHeight = '95px'
                }

                const elShowMore = document.getElementById(
                  `injurySections.${id}.bodyParts.showMore`
                )

                if (elShowMore?.style?.display) {
                  elShowMore.style.display = 'flex'
                }

                const elShowLess = document.getElementById(
                  `injurySections.${id}.bodyParts.showLess`
                )

                if (elShowLess?.style?.display) {
                  elShowLess.style.display = 'none'
                }
              }}
              className="font-14-semibold c-7c2d35"
            >
              {t('commons.see_less')}
            </div>
            <Image src={LeftIcon} width={15} height={15} className="ms-2 icon filter-7c2d35" />
          </div>
          <div
            id={`injurySections.${id}.bodyParts.showMore`}
            style={{ display: 'flex' }}
            className="align-items-center cursor-pointer"
          >
            <div
              onClick={() => {
                const el = document.getElementById(`injurySections.${id}.bodyParts.optionsInjuryId`)
                if (el?.style.maxHeight && el?.style.overflow) {
                  el.style.overflow = 'unset'
                  el.style.maxHeight = 'unset'
                }

                const elShowMore = document.getElementById(
                  `injurySections.${id}.bodyParts.showMore`
                )

                if (elShowMore?.style?.display) {
                  elShowMore.style.display = 'none'
                }

                const elShowLess = document.getElementById(
                  `injurySections.${id}.bodyParts.showLess`
                )

                if (elShowLess?.style?.display) {
                  elShowLess.style.display = 'flex'
                }
              }}
              className="font-14-semibold c-7c2d35"
            >
              {t('commons.see_more')}
            </div>
            <Image
              src={LeftIcon}
              width={15}
              height={15}
              className="ms-2 icon rotate filter-7c2d35"
            />
          </div>
        </div>
      )}
    </div>
  )

  return (
    <div>
      <Select
        {...props}
        instanceId={`injurySections.${id}.bodyParts.select`}
        controlShouldRenderValue={!isMulti}
        // closeMenuOnSelect={false}
        // closeMenuOnScroll={false}
        // blurInputOnSelect={false}
        isMulti={true}
        components={{
          Option,
        }}
        styles={reactSelectStyles}
        value={outsideValues}
      />
      <div>{isMulti ? SeeForm : null}</div>
    </div>
  )
}
