import { useEffect, useState } from 'react'
import { INPUT_DIGITS_KEY_CODES } from 'src/config'
import { regexDigit } from 'src/regex'

export const useInputDigits = ({ onDigitsChange }: any) => {
  const [values, setValues] = useState<any>({})
  const [focusedIndex, setFocusedIndex] = useState<any>(null)
  const [isMobile, setIsMobile] = useState(window.innerWidth < 810)

  useEffect(() => {
    window.addEventListener(
      'resize',
      () => {
        const ismobile = window.innerWidth < 810
        if (ismobile !== isMobile) setIsMobile(ismobile)
      },
      false
    )
  }, [isMobile])

  useEffect(() => {
    const asString = Object.values(values).join('')
    const asNumber = Number(asString)
    onDigitsChange({
      asNumber,
      asString,
      asObject: values,
    })
  }, [values])

  const handleDigitChange = (index: number, value: string) => {
    let index_ = index
    if (value?.length === 1) {
      setValues({
        ...values,
        [index_]: value,
      })
    } else {
      let tempIndex = index_
      let tempValues = {}
      value?.split('')?.map((item) => {
        tempValues = { ...tempValues, [tempIndex]: item }
        tempIndex++
      })
      setValues({
        ...values,
        ...tempValues,
      })

      index_ = tempIndex - 1
    }

    setFocusedIndex(regexDigit.test(value) ? index_ + 1 : null)
  }

  const changeFocus = (keyCode: any) => {
    if (isNaN(focusedIndex)) return
    if (keyCode === INPUT_DIGITS_KEY_CODES.ARROW_LEFT) setFocusedIndex(focusedIndex - 1)
    if (keyCode === INPUT_DIGITS_KEY_CODES.ARROW_RIGHT) setFocusedIndex(focusedIndex + 1)
  }

  return {
    values,
    isMobile,
    focusedIndex,
    handleDigitChange,
    changeFocus,
  }
}
