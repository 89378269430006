import './entry-group-step5.scss'

import { useEffect } from 'react'
import { useLocation } from 'react-router'
import { useResetRecoilState } from 'recoil'
import { atomQuote } from 'src/storage'
import { AxeptaWindow } from 'src/types'

export const useEntryGroupStep5 = () => {
  const resetQuote = useResetRecoilState(atomQuote)
  const urlSearchParams = new URLSearchParams(useLocation().search)
  const paymentId = urlSearchParams.get('paymentId') || ''

  useEffect(() => {
    if (paymentId) {
      const axeptaWindow: AxeptaWindow = window
      axeptaWindow?.callAxeptaClient?.()?.preparePayment?.(paymentId)
      resetQuote()
    }
  }, [paymentId])

  return {}
}
