import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { MAX_FILE, MAX_FILE_MB } from 'src/config'
import { Paths } from 'src/navigation'
import { usePostUploadFileClaim } from 'src/networking/terminals/claim/claim.gates'
import { atomClaimBackPath, atomClaimData, atomClaimQuote, atomProgressBar } from 'src/storage'
import { ClaimFile } from 'src/types/pages/claim.types'
import { fromBase64ToBlob } from 'src/utils'

import { PROGRESS_BAR_STEP } from '../../progress-bar-step'

export const UseClaimReportRcInjuryDocument = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const inputFileAccept = '.pdf,.jpg,.png,.tif'

  const claimQuote = useRecoilValue(atomClaimQuote)
  const [progressBar, setProgressBar] = useRecoilState(atomProgressBar)
  const setClaimBackPath = useSetRecoilState(atomClaimBackPath)
  const [claimData, setClaimData] = useRecoilState(atomClaimData)
  const { crossGate: postUploadFileClaim } = usePostUploadFileClaim()

  const [isMobile, setIsMobile] = useState(window.innerWidth < 810)
  const [file, setFile] = useState<File | undefined>(
    claimData?.weather?.file
      ? new File(
          [fromBase64ToBlob(claimData?.weather?.file?.data || '')],
          claimData?.weather?.file?.name,
          {
            type: claimData?.weather?.file?.type,
          }
        )
      : undefined
  )
  const [isOutOfSizeFile, setIsOutOfSizeFile] = useState(false)
  const [showUploadModal, setShowUploadModal] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [loadingFile, setLoadingFile] = useState(false)

  //////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    setTimeout(() => {
      setProgressBar(PROGRESS_BAR_STEP.claim_report_weather_document)
    }, 100)
  }, [])

  // useEffect(() => {
  //   if (file) {
  //     const reader = new FileReader()

  //     reader.readAsDataURL(file)
  //     reader.onload = function () {
  //       const file_: ClaimFile = {
  //         name: file?.name,
  //         size: file?.size,
  //         data: JSON.stringify(reader.result) || '',
  //         type: file?.type,
  //         lastModified: file?.lastModified,
  //       }

  //       setClaimData({
  //         ...claimData,
  //         weather: {
  //           ...claimData?.weather,
  //           file: file_,
  //         },
  //       })
  //     }
  //   }

  // }, [file])

  //Open File
  // useEffect(() => {
  //   if (claimData && claimData?.file !== '') {
  //     window.open(window.URL.createObjectURL(fromBase64ToBlob(claimData?.file || '')))
  //   }
  // }, [claimData])

  useEffect(() => {
    window.addEventListener(
      'resize',
      () => {
        const ismobile = window.innerWidth < 810
        if (ismobile !== isMobile) setIsMobile(ismobile)
      },
      false
    )
  }, [isMobile])

  //////////////////////////////////////////////////////////////////////////////

  const goBack = () => {
    navigate(Paths.ClaimReportWeatherInformation)
  }

  const goNext = () => {
    const reader = new FileReader()

    if (file) {
      setLoadingFile(true)
      reader.readAsDataURL(file)
      reader.onload = function () {
        const file_: ClaimFile = {
          name: file?.name,
          size: file?.size,
          data: reader.result?.toString() || '',
          type: file?.type,
          lastModified: file?.lastModified,
        }

        postUploadFileClaim(
          {
            body: {
              shopId: claimQuote?.contractId || '',
              ...file_,
            },
          },
          {
            onSuccess: (response) => {
              if (response?.fileName) {
                setClaimData({
                  ...claimData,
                  weather: {
                    ...claimData?.weather,
                    file: { ...file_, reference: response?.fileName },
                  },
                })
                setLoadingFile(false)

                setClaimBackPath(Paths.ClaimReportWeatherDocument)
                navigate(Paths.ClaimReportOpen)
              }
            },
            onFailure: () => {
              setLoadingFile(false)
              navigate(Paths.ClaimReportError)
            },
            onPending: () => {},
          }
        )
      }
    }
  }

  const uploadDocument = (file: File) => {
    if (!file) return

    const fileSize = file.size / MAX_FILE

    if (fileSize > MAX_FILE_MB) {
      setIsOutOfSizeFile(true)
      setShowUploadModal(false)
    } else {
      setFile(file)
      setIsLoaded(true)
      setShowUploadModal(false)
    }
  }

  const deleteDocument = () => {
    setFile(undefined)
    setClaimData({
      ...claimData,
      weather: {
        ...claimData?.weather,
        file: undefined,
      },
    })
    setIsLoaded(false)
  }

  return {
    t,
    file,
    showUploadModal,
    isOutOfSizeFile,
    inputFileAccept,
    isMobile,
    progressBar,
    isLoaded,
    loadingFile,
    goBack,
    goNext,
    uploadDocument,
    setIsOutOfSizeFile,
    setShowUploadModal,
    deleteDocument,
  }
}
