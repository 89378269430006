import { AwsGateCallbacks, AwsGateError, AwsGateRequest } from 'src/types'

import { useAwsGateError } from './aws-gate-error.hook'
import { useAwsGateLoader } from './aws-gate-loader.hook'
import { useAwsGateRequest } from './aws-gate-request.hook'

export const useAws = <T, D>(gateId: string) => {
  const { doRequest } = useAwsGateRequest<T, D>(gateId)
  const { gateError, saveGateError, removeGateError } = useAwsGateError(gateId)
  const { idle, pending, toIdle, toPending } = useAwsGateLoader(gateId)

  const crossGate = async (
    request: AwsGateRequest<T>,
    {
      onSuccess,
      onFailure = saveGateError,
      onIdle = toIdle,
      onPending = toPending,
    }: AwsGateCallbacks<D>
  ) => {
    try {
      onPending?.()
      onSuccess?.(await doRequest(request))
    } catch (err) {
      onFailure?.(err as AwsGateError)
    } finally {
      onIdle?.()
    }
  }

  return { gateError, removeGateError, idle, pending, crossGate }
}
