import './recaptcha.scss'

import { useEffect } from 'react'
import { SITE_KEY } from 'src/config'
import { RecaptchaWindow, typedMemo } from 'src/types'

export const Recaptcha = typedMemo(() => {
  useEffect(() => {
    const id = 'recaptcha-key'
    if (!document.getElementById(id)) {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`
      script.id = 'recaptcha-key'
      document.body.appendChild(script)
    }
  }, [])

  return <></>
})

export const submitCaptcha = (): Promise<string> => {
  return new Promise<string>((resolve) => {
    const recaptcha: RecaptchaWindow = window
    const gRecaptcha = recaptcha?.grecaptcha
    if (gRecaptcha) {
      gRecaptcha?.ready(() => {
        resolve(gRecaptcha?.execute(SITE_KEY || '', { action: 'submit' }))
      })
    }
  })
}
