import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { MAX_FILE, MAX_FILE_MB } from 'src/config'
import { Paths } from 'src/navigation'
import { usePostUploadFileClaim } from 'src/networking/terminals/claim/claim.gates'
import { atomClaimBackPath, atomClaimData, atomClaimQuote, atomProgressBar } from 'src/storage'
import { FormikClaimReportDamageStep2 } from 'src/types'
import { ClaimFile } from 'src/types/pages/claim.types'
import { fromBase64ToBlob, yupBool, yupStr } from 'src/utils'
import * as Yup from 'yup'

import { PROGRESS_BAR_STEP } from '../../../progress-bar-step'

export const UseClaimReportRcInjuryReportDamageStep2 = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const inputFileAccept = '.pdf,.jpg,.png,.tif'

  const claimQuote = useRecoilValue(atomClaimQuote)

  const [claimData, setClaimData] = useRecoilState(atomClaimData)
  const [progressBar, setProgressBar] = useRecoilState(atomProgressBar)
  const setClaimBackPath = useSetRecoilState(atomClaimBackPath)

  const { crossGate: postUploadFileClaim } = usePostUploadFileClaim()

  const [isMobile, setIsMobile] = useState(window.innerWidth < 810)

  const [file, setFile] = useState<File | undefined>(
    claimData?.other?.request?.damage?.file
      ? new File(
          [fromBase64ToBlob(claimData?.other?.request?.damage?.file?.data || '')],
          claimData?.other?.request?.damage?.file?.name,
          {
            type: claimData?.other?.request?.damage?.file?.type,
          }
        )
      : undefined
  )
  const [isOutOfSizeFile, setIsOutOfSizeFile] = useState(false)
  const [showUploadModal, setShowUploadModal] = useState(false)
  const [loadingFile, setLoadingFile] = useState(false)

  const [initialValues] = useState<FormikClaimReportDamageStep2>({
    damageDoneInjury:
      !!claimData?.other?.request?.damage?.damageDoneType?.find(
        (item) => item === 'damage_injury_id'
      ) || false,
    damageDoneThings:
      !!claimData?.other?.request?.damage?.damageDoneType?.find(
        (item) => item === 'damage_things_id'
      ) || false,
    whatDamage: claimData?.other?.request?.damage?.damageRequestThings?.whatDamage || '',
    damageValue: claimData?.other?.request?.damage?.damageRequestThings?.damageValue || '',
  })

  const validationSchema = Yup.object().shape({
    damageDoneInjury: yupBool,
    damageDoneThings: yupBool,
    whatDamage: yupStr.when('damageDoneThings', (item, schema) => {
      return item === true ? schema.required(t('commons.required')) : schema
    }),
    damageValue: yupStr.when('damageDoneThings', (item, schema) => {
      return item === true ? schema.required(t('commons.required')) : schema
    }),
  })

  //////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    setTimeout(() => {
      setProgressBar(PROGRESS_BAR_STEP.claim_report_rc_injury_report_damage_step2)
    }, 100)
  }, [])

  useEffect(() => {
    if (!claimQuote) {
      navigate(Paths.ClaimReport)
    }
  }, [claimQuote])

  useEffect(() => {
    window.addEventListener(
      'resize',
      () => {
        const ismobile = window.innerWidth < 810
        if (ismobile !== isMobile) setIsMobile(ismobile)
      },
      false
    )
  }, [isMobile])

  //////////////////////////////////////////////////////////////////////////////

  const formik = useFormik<FormikClaimReportDamageStep2>({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const damageDoneType_: string[] = []

      if (values?.damageDoneInjury) {
        damageDoneType_?.push('damage_injury_id')
      }

      if (values?.damageDoneThings) {
        damageDoneType_?.push('damage_things_id')
      }

      const reader = new FileReader()

      if (file && values?.damageDoneThings) {
        setLoadingFile(true)
        reader.readAsDataURL(file)
        reader.onload = function () {
          const file_: ClaimFile = {
            name: file?.name,
            size: file?.size,
            data: reader.result?.toString() || '',
            type: file?.type,
            lastModified: file?.lastModified,
          }

          postUploadFileClaim(
            {
              body: {
                shopId: claimQuote?.contractId || '',
                ...file_,
              },
            },
            {
              onSuccess: (response) => {
                if (response?.fileName) {
                  setLoadingFile(false)
                  setClaimData({
                    ...claimData,
                    other: {
                      ...claimData?.other,
                      request: {
                        ...claimData?.other?.request,
                        damage: {
                          ...claimData?.other?.request?.damage,
                          file: {
                            ...file_,
                            reference: response?.fileName,
                          },
                          damageDoneType: damageDoneType_,
                          ...(values?.damageDoneThings
                            ? {
                                damageRequestThings: {
                                  whatDamage: values?.whatDamage,
                                  damageValue: values?.damageValue,
                                },
                              }
                            : { damageRequestThings: undefined }),
                        },
                      },
                    },
                  })

                  setClaimBackPath(Paths.ClaimReportRcInjuryReportDamageStep2)
                  navigate(Paths.ClaimReportOpen)
                }
              },
              onFailure: () => {
                setLoadingFile(false)
                navigate(Paths.ClaimReportError)
              },
              onPending: () => {},
            }
          )
        }
      } else {
        setClaimData({
          ...claimData,
          other: {
            ...claimData?.other,
            request: {
              ...claimData?.other?.request,
              damage: {
                ...claimData?.other?.request?.damage,
                damageDoneType: damageDoneType_,
                damageRequestThings: undefined,
                file: undefined,
              },
            },
          },
        })
        setClaimBackPath(Paths.ClaimReportRcInjuryReportDamageStep2)
        navigate(Paths.ClaimReportOpen)
      }
    },
  })

  //////////////////////////////////////////////////////////////////////////////

  const goBack = () => {
    navigate(Paths.ClaimReportRcInjuryReportDamageStep1)
  }

  const uploadDocument = (file: File) => {
    if (!file) return

    const fileSize = file.size / MAX_FILE

    if (fileSize > MAX_FILE_MB) {
      setIsOutOfSizeFile(true)
      setShowUploadModal(false)
    } else {
      setFile(file)
      setShowUploadModal(false)
    }
  }

  const deleteDocument = () => {
    setFile(undefined)
    setClaimData({
      ...claimData,
      other: {
        ...claimData?.other,
        request: {
          ...claimData?.other?.request,
          damage: {
            ...claimData?.other?.request?.damage,
            file: undefined,
          },
        },
      },
    })
  }

  const checkDisabled = () => {
    if (
      formik?.values?.damageDoneThings &&
      (!formik?.values?.whatDamage || !formik?.values?.damageValue)
    ) {
      return true
    }

    if (!formik?.values?.damageDoneInjury && !formik?.values?.damageDoneThings) return true

    return false
  }

  return {
    t,
    progressBar,
    formik,
    validationSchema,
    file,
    isMobile,
    showUploadModal,
    isOutOfSizeFile,
    inputFileAccept,
    loadingFile,
    goBack,
    uploadDocument,
    setIsOutOfSizeFile,
    setShowUploadModal,
    deleteDocument,
    checkDisabled,
  }
}
