import { useRecoilValue } from 'recoil'
import { atomQuote } from 'src/storage'

export const usePrice = (discountError: string, discount?: string) => {
  const quote = useRecoilValue(atomQuote)

  const oldQuote = quote?.oldQuoteValue?.replace('.', ',')
  const newQuote = quote?.quoteValue?.replace('.', ',')

  if (discountError !== '' || !discount) {
    return `${oldQuote} €`
  }
  if (quote?.discount) {
    return 'DISCOUNT'
  }
  if (newQuote && newQuote !== '0') {
    return `${newQuote} €`
  }
  return '-'
}
