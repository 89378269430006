import './tooltip.scss'

import { useRef } from 'react'
import { Image, Overlay, Row, Tooltip as RBTooltip } from 'react-bootstrap'
import { InfoIcon } from 'src/assets'
import { TooltipProps, typedMemo } from 'src/types'
import { useBoolean } from 'src/utils'

export const Tooltip = typedMemo(
  ({
    label,
    children,
    placement = 'bottom',
    widthPercentage = 0.7,
    widthMax = 400,
    iconSize = 16,
    classes = '',
    classesLabel = 'm-0 p-0 font-16',
    classesIcon = '',
    classesOverIcon = 'm-0 p-0 d-flex justify-content-center align-items-center',
    classesOverRow = 'm-0 p-0 cursor-pointer h-100',
  }: TooltipProps) => {
    const width = Math.min(widthPercentage * window.innerWidth, widthMax)

    const target = useRef(null)
    const [visible, show, hide] = useBoolean()

    const Icon = (
      <Row className={classesOverRow}>
        {label && <p className={classesLabel}>{label}</p>}
        <div className={classesOverIcon} ref={target} onMouseEnter={show} onMouseLeave={hide}>
          <Image
            src={InfoIcon}
            width={iconSize}
            height={iconSize}
            className={classesIcon}
            alt="i"
          />
        </div>
      </Row>
    )

    const Content = (
      <Overlay target={target.current} show={visible} placement={placement}>
        {(props) => (
          <RBTooltip id="tooltip-wrapper" {...props}>
            <div style={{ width }}>{children}</div>
          </RBTooltip>
        )}
      </Overlay>
    )

    return (
      <main className={classes}>
        {Icon}
        {Content}
      </main>
    )
  }
)
