/* eslint-disable complexity */
import './entry-group-step3.scss'

import { ChangeEvent, useEffect, useState } from 'react'
import { Option } from 'react-bootstrap-typeahead/types/types'
import { useTranslation } from 'react-i18next'
import { CountryData } from 'react-phone-input-2'
import { useRecoilValue } from 'recoil'
import { lowerLanguage } from 'src/i18n'
import { selectorDocuments } from 'src/storage'
import { Asset, Formik, Gender, InputRadioOption, Nationality } from 'src/types'
import {
  FormikMainInsuredGroup,
  GetGroupExtraInfo,
} from 'src/types/networking/terminals/group.types'

export const useEntryGroupStep3 = ({
  fatherFormik,
}: {
  fatherFormik: Formik<FormikMainInsuredGroup>
}) => {
  const { t } = useTranslation()

  const { setTouched } = fatherFormik
  const { mainInsured } = fatherFormik?.values || {}

  // Constants
  const nationalityOptions = [
    { id: 'IT', value: t('data_entry_step_2.insured_nationality_italy') },
    { id: 'EXT', value: t('data_entry_step_2.insured_nationality_foreign') },
  ]

  const fcQuestionOptions = [
    { id: 'yes_id', value: t('data_entry_step_2.insured_fc_question_yes') },
    { id: 'no_id', value: t('data_entry_step_2.insured_fc_question_no') },
  ]

  const genderOptions: InputRadioOption[] = [
    {
      id: 'M',
      value: t('claim_report_open.male'),
    },
    {
      id: 'F',
      value: t('claim_report_open.female'),
    },
  ]

  const insuredOptions: InputRadioOption[] = [
    {
      id: 'id_yes',
      value: t('entry_group_step_3.radio_item_1'),
    },
    {
      id: 'id_no',
      value: t('entry_group_step_3.radio_item_2'),
    },
  ]

  // Recoils
  const documents = useRecoilValue(selectorDocuments)

  // States
  const [isSelectedInsured, setIsSelectedInsured] = useState<Asset | undefined>(
    mainInsured?.fiscalCode
      ? {
          name: mainInsured?.name || '',
          surname: mainInsured?.surname || '',
          nationality: (mainInsured?.nationality as Nationality) || '',
          fiscalCode: mainInsured?.fiscalCode || '',
          birthDate: mainInsured?.birthDate || '',
          birthNation: mainInsured?.birthNation || '',
          birthPlace: mainInsured?.birthPlace || '',
          gender: (mainInsured?.gender as Gender) || '',
          fiscalCodeQuestion: mainInsured?.fiscalCodeQuestion || '',
        }
      : undefined
  )
  const [isMobile, setIsMobile] = useState(window.innerWidth < 810)

  // UseEffect
  useEffect(() => {
    window.addEventListener(
      'resize',
      () => {
        const ismobile = window.innerWidth < 810
        if (ismobile !== isMobile) setIsMobile(ismobile)
      },
      false
    )
  }, [isMobile])

  // Functions
  const onSelectedInsured = (event: Option[]) => {
    const selectedInsured = (event as GetGroupExtraInfo)[0]

    setIsSelectedInsured(selectedInsured)

    fatherFormik?.setFieldValue('mainInsured.id', selectedInsured?.id)
    fatherFormik?.setFieldValue('mainInsured.name', selectedInsured?.name || '')
    fatherFormik?.setFieldValue('mainInsured.surname', selectedInsured?.surname || '')
    fatherFormik?.setFieldValue(
      'mainInsured.fiscalCodeQuestion',
      selectedInsured?.fiscalCode ? 'yes_id' : 'no_id'
    )
    fatherFormik?.setFieldValue('mainInsured.nationality', selectedInsured?.nationality || '')
    fatherFormik?.setFieldValue('mainInsured.birthDate', selectedInsured?.birthDate || '')
    fatherFormik?.setFieldValue('mainInsured.birthNation', selectedInsured?.birthNation || '')
    fatherFormik?.setFieldValue('mainInsured.birthPlace', selectedInsured?.birthPlace || '')
    fatherFormik?.setFieldValue('mainInsured.gender', selectedInsured?.gender || '')
    fatherFormik?.setFieldValue('mainInsured.fiscalCode', selectedInsured?.fiscalCode || '')

    const tempGroup = fatherFormik?.values?.originGroup || []

    const newGroup = tempGroup.filter((item) => item?.id !== selectedInsured?.id)
    fatherFormik?.setFieldValue('group', newGroup)
  }

  const onResetInsured = () => {
    setIsSelectedInsured(undefined)
    setTouched({})
    fatherFormik?.setFieldValue('mainInsured.name', '')
    fatherFormik?.setFieldValue('mainInsured.surname', '')
    fatherFormik?.setFieldValue('mainInsured.email', '')
    fatherFormik?.setFieldValue('mainInsured.emailConfirm', '')
    fatherFormik?.setFieldValue('mainInsured.phone', '')
    fatherFormik?.setFieldValue('mainInsured.cap', '')
    fatherFormik?.setFieldValue('mainInsured.nationality', '')
    fatherFormik?.setFieldValue('mainInsured.fiscalCodeQuestion', '')
    fatherFormik?.setFieldValue('mainInsured.fiscalCode', '')
    fatherFormik?.setFieldValue('mainInsured.birthDate', '')
    fatherFormik?.setFieldValue('mainInsured.birthNation', '')
    fatherFormik?.setFieldValue('mainInsured.birthPlace', '')
    fatherFormik?.setFieldValue('mainInsured.gender', '')
    fatherFormik?.setFieldValue('group', fatherFormik?.values?.originGroup || [])
  }

  const onChangePhone = (
    value: string,
    data: CountryData,
    _: ChangeEvent<HTMLInputElement>,
    formattedValue: string
  ) => {
    fatherFormik?.setFieldValue('mainInsured.phone', {
      country: data,
      formattedPhone: value === (data as CountryData)?.dialCode ? value : formattedValue,
      value: formattedValue,
    })
  }

  const openInformativePersonalDataLink = () => {
    const informativePersonalDataLink = documents?.find(({ id }) => id === '6')
    const file = informativePersonalDataLink?.file?.[lowerLanguage]
    if (file) {
      window.open(file.replaceAll('"', ''), '_blank')
    }
  }

  return {
    t,
    isMobile,
    genderOptions,
    insuredOptions,
    isSelectedInsured,
    fcQuestionOptions,
    nationalityOptions,
    onChangePhone,
    onResetInsured,
    onSelectedInsured,
    openInformativePersonalDataLink,
  }
}
