import { ProgressBar } from 'react-bootstrap'
import { ScrollTopRoot } from 'src/roots'
import { Button, InputCheckbox, Loader } from 'src/ui/widgets'

import { UseClaimOpenSummary } from './claim-open-summary.hook'

// eslint-disable-next-line complexity
export const ClaimOpenSummary = () => {
  const {
    t,
    progressBar,
    formik,
    validationSchema,
    openClaimSummary,
    loadClaim,
    pendingCreateClaim,
    isUploadingData,
    goBack,
    openInformativePersonalDataLink,
  } = UseClaimOpenSummary()
  const { values, touched, errors, handleChange, handleBlur, handleSubmit } = formik

  const Topbar = (
    <Button
      flavor="transparentAccentBack"
      text={t('commons.back')}
      classes="px-0 pt-0"
      classesText="text-uppercase font-14-semibold"
      onPress={goBack}
    />
  )

  const Title = (
    <div className="mb-2 font-28-semibold c-292929">{t('claim_report_open_summary.title')}</div>
  )

  const Subtitle = (
    <div className="mb-3 font-14-semibold c-292929 text-uppercase">
      {t('claim_report_open_summary.subtitle')}
    </div>
  )

  const CheckLabel = (
    <div className="mb-4 font-16">{t('claim_report_open_summary.check_label')}</div>
  )

  const ClaimDetails = (
    <div className="mb-4">
      <div className="mb-2 font-16-semibold c-7c2d35 text-uppercase">
        {t('claim_report_open_summary.claim_details_title')}
      </div>
      <div className="font-14 c-292929 d-md-flex">
        <div className="col-md-4">{t('claim_report_open_summary.data_hour')}</div>
        <div className="mb-3 font-16 c-7f7f7f">
          {openClaimSummary?.claimDetails?.date} - {openClaimSummary?.claimDetails?.hour}
        </div>
      </div>

      <div className="font-14 c-292929 d-md-flex">
        <div className="col-md-4"> {t('claim_report_open_summary.insured_involved')}</div>
        <div className="mb-3 font-16 c-7f7f7f">
          {openClaimSummary?.claimDetails?.surname} {openClaimSummary?.claimDetails?.name}
        </div>
      </div>

      <div className="font-14 c-292929 d-md-flex">
        <div className="col-md-4">{t('claim_report_open_summary.motivation')}</div>
        <div className="mb-3 font-16 c-7f7f7f">{openClaimSummary?.claimDetails?.motivation}</div>
      </div>

      {openClaimSummary?.claimDetails?.skyResort && (
        <div className="font-14 c-292929 d-md-flex">
          <div className="col-md-4">{t('claim_report_open_summary.ski_resort')}</div>
          <div className="mb-3 font-16 c-7f7f7f">{openClaimSummary?.claimDetails?.skyResort}</div>
        </div>
      )}
    </div>
  )

  const InjuryPersonalData = (
    <div className="mb-4">
      <div className="mb-2 font-16-semibold c-7c2d35 text-uppercase">
        {t('claim_report_open_summary.injury_personal_data_details_title')}
      </div>

      {openClaimSummary?.injuryPersonalData?.isAvailable ? (
        <div>
          <div className="font-14 d-md-flex">
            <div className="col-md-4">{t('claim_report_open_summary.name_surname')}</div>
            <div className="mb-3 font-16 c-7f7f7f">
              {openClaimSummary?.injuryPersonalData?.name}{' '}
              {openClaimSummary?.injuryPersonalData?.surname}
            </div>
          </div>

          <div className="font-14 d-md-flex">
            <div className="col-md-4">{t('claim_report_open_summary.fiscal_code')}</div>
            <div className="mb-3 font-16 c-7f7f7f">
              {openClaimSummary?.injuryPersonalData?.fiscalCode}
            </div>
          </div>

          <div className="font-14 d-md-flex">
            <div className="col-md-4">{t('claim_report_open_summary.residence')}</div>
            <div className="mb-3 font-16 c-7f7f7f">
              {openClaimSummary?.injuryPersonalData?.residence}
            </div>
          </div>

          <div className="font-14 d-md-flex">
            <div className="col-md-4">{t('claim_report_open_summary.email')}</div>
            <div className="mb-3 font-16 c-7f7f7f">
              {openClaimSummary?.injuryPersonalData?.email}
            </div>
          </div>

          <div className="font-14 d-md-flex">
            <div className="col-md-4">{t('claim_report_open_summary.mobile')}</div>
            <div className="mb-3 font-16 c-7f7f7f">
              {openClaimSummary?.injuryPersonalData?.mobile}
            </div>
          </div>
        </div>
      ) : (
        <div className="font-14 c-292929">{t('claim_report_open_summary.not_available')}</div>
      )}
    </div>
  )

  const DamageDetails = (
    <div className="mb-4">
      <div className="mb-2 font-16-semibold c-7c2d35 text-uppercase">
        {t('claim_report_open_summary.damage_details_title')}
      </div>
      {openClaimSummary?.damageDetails?.type && (
        <div className="font-14 c-292929 d-md-flex">
          <div className="col-md-4">{t('claim_report_open_summary.damage_type')}</div>
          <div className="mb-3 font-16 c-7f7f7f">{openClaimSummary?.damageDetails?.type}</div>
        </div>
      )}

      <div className="col-12 d-flex d-md-block">
        {openClaimSummary?.damageDetails?.damageCaused &&
          openClaimSummary?.damageDetails?.damageCaused?.length > 0 && (
            <div className="col-6 col-md-12">
              <div className="mb-2">
                <div className="font-14 c-292929 d-md-flex">
                  <div className="col-md-4">
                    {t('claim_report_open_summary.injury_damage_caused')}
                  </div>
                  {openClaimSummary?.damageDetails?.damageCaused?.map((item, index) => (
                    <div key={index} className="font-16 c-7f7f7f">
                      {item}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        {openClaimSummary?.damageDetails?.extentDamage && (
          <div className="col-6 col-md-12 mt-md-3">
            <div className="font-14 c-292929 d-md-flex">
              <div className="col-md-4">{t('claim_report_open_summary.extent_damage')}</div>
              <div className="mb-3 font-16 c-7f7f7f">
                {openClaimSummary?.damageDetails?.extentDamage}
              </div>
            </div>
          </div>
        )}
      </div>
      <div>
        {openClaimSummary?.damageDetails?.injurySections?.map((item, index) => (
          <div key={index}>
            <div className="d-md-flex">
              <div className="col-md-4">
                <div className="d-flex align-items-center">
                  <div className="font-14 c-059881">{index + 1}°</div>
                  <div className="font-14 c-292929">
                    - {t('claim_report_open_summary.injury_nature')}
                  </div>
                </div>
              </div>
              <div className="mb-3 mb-md-0 font-16 c-7f7f7f">{item?.nature?.label}</div>
            </div>

            <div>
              {item?.bodyParts && item?.bodyParts?.length > 0 && (
                <div>
                  <div className="font-14 c-292929 d-md-flex">
                    <div className="col-md-4">{t('claim_report_open_summary.body_parts')}</div>
                    <div className="mb-3 font-16 c-7f7f7f d-flex align-items-center">
                      {item?.bodyParts?.map((body, indexBody) => (
                        <div key={indexBody}>
                          {item?.bodyParts && indexBody === item?.bodyParts?.length - 1 ? (
                            <div key={body?.value}>{body?.label} </div>
                          ) : (
                            <div key={body?.value} className="me-2">
                              {body?.label} -
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>

      <div>
        {openClaimSummary?.damageDetails?.damageObject && (
          <div>
            <div className="font-14 c-292929 d-md-flex">
              <div className="col-md-4">{t('claim_report_open_summary.damage_object')}</div>
              <div className="mb-3 font-16 c-7f7f7f">
                {openClaimSummary?.damageDetails?.damageObject}
              </div>
            </div>
          </div>
        )}

        {openClaimSummary?.damageDetails?.damageDescription && (
          <div>
            <div className="font-14 c-292929 d-md-flex">
              <div className="col-md-4">{t('claim_report_open_summary.damage_description')}</div>
              <div className="mb-3 font-16 c-7f7f7f">
                {openClaimSummary?.damageDetails?.damageDescription}
              </div>
            </div>
          </div>
        )}

        {openClaimSummary?.damageDetails?.damageEstimation && (
          <div>
            <div className="font-14 c-292929 d-md-flex">
              <div className="col-md-4">{t('claim_report_open_summary.damage_estimation')}</div>
              <div className="mb-3 font-16 c-7f7f7f">
                {openClaimSummary?.damageDetails?.damageEstimation}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )

  const RequestDetails = (
    <div className="mb-4">
      <div className="mb-2 font-16-semibold c-7c2d35 text-uppercase">
        {t('claim_report_open_summary.request_details_title')}
      </div>

      <div className="font-14 c-292929  d-md-flex">
        <div className="col-md-4">{t('claim_report_open_summary.request_motivation')}</div>
        {openClaimSummary?.requestDetails?.map((item) => (
          <div key={item?.value} className="font-16 c-7f7f7f">
            {item?.label}
          </div>
        ))}
      </div>
    </div>
  )

  const OpenDetails = (
    <div className="mb-4">
      <div className="mb-2 font-16-semibold c-7c2d35 text-uppercase">
        {t('claim_report_open_summary.open_details_title')}
      </div>
      <div className="font-14 c-292929 d-md-flex">
        <div className="col-md-4">{t('claim_report_open_summary.reporting')}</div>
        <div className="mb-3 font-16 c-7f7f7f">
          {openClaimSummary?.claimOpeningConfirmation?.reportingData}
        </div>
      </div>
      {openClaimSummary?.claimOpeningConfirmation?.name &&
        openClaimSummary?.claimOpeningConfirmation?.surname && (
          <div>
            <div className="font-14 c-292929 d-md-flex">
              <div className="col-md-4">{t('claim_report_open_summary.name_surname')}</div>
              <div className="mb-3 font-16 c-7f7f7f">
                {openClaimSummary?.claimOpeningConfirmation?.name}{' '}
                {openClaimSummary?.claimOpeningConfirmation?.surname}
              </div>
            </div>
          </div>
        )}
      {!openClaimSummary?.claimOpeningConfirmation?.fiscalCode && (
        <div className="col-12 d-flex">
          <div className="col-6">
            <div className="font-14 c-292929 d-md-flex">
              <div className="col-md-4">{t('claim_report_open_summary.birth')}</div>
              <div className="mb-3 font-16 c-7f7f7f">
                {openClaimSummary?.claimOpeningConfirmation?.birth}
              </div>
            </div>
          </div>

          <div className="col-6">
            <div className="font-14 c-292929 d-md-flex">
              <div className="col-md-4">{t('claim_report_open_summary.gender')}</div>
              <div className="mb-3 font-16 c-7f7f7f">
                {openClaimSummary?.claimOpeningConfirmation?.gender}
              </div>
            </div>
          </div>
        </div>
      )}
      {openClaimSummary?.claimOpeningConfirmation?.fiscalCode && (
        <div>
          <div className="font-14 c-292929 d-md-flex">
            <div className="col-md-4">{t('claim_report_open_summary.fiscal_code')}</div>
            <div className="mb-3 font-16 c-7f7f7f">
              {openClaimSummary?.claimOpeningConfirmation?.fiscalCode}
            </div>
          </div>
        </div>
      )}
      {!openClaimSummary?.claimOpeningConfirmation?.fiscalCode && (
        <div>
          <div className="font-14 c-292929 d-md-flex">
            <div className="col-md-4">{t('claim_report_open_summary.birth_nation')}</div>
            <div className="mb-3 font-16 c-7f7f7f">
              {openClaimSummary?.claimOpeningConfirmation?.birthNation}
            </div>
          </div>

          <div className="font-14 c-292929 d-md-flex">
            <div className="col-md-4">{t('claim_report_open_summary.birth_place')}</div>
            <div className="mb-3 font-16 c-7f7f7f">
              {openClaimSummary?.claimOpeningConfirmation?.birthPlace}
            </div>
          </div>
        </div>
      )}
      <div className="font-14 c-292929 d-md-flex">
        <div className="col-md-4">{t('claim_report_open_summary.residence')}</div>
        <div className="mb-3 font-16 c-7f7f7f">
          {openClaimSummary?.claimOpeningConfirmation?.residence}
        </div>
      </div>
      <div className="font-14 c-292929 d-md-flex">
        <div className="col-md-4">{t('claim_report_open_summary.contact_mode')}</div>
        <div>
          <div className="mb-2 font-16 c-7f7f7f">
            {openClaimSummary?.claimOpeningConfirmation?.contact}
          </div>
          <div>
            {openClaimSummary?.claimOpeningConfirmation?.timeSlots &&
              openClaimSummary?.claimOpeningConfirmation?.timeSlots?.length > 0 && (
                <div className="mb-2 d-flex align-items-center justify-content-start flex-wrap">
                  {openClaimSummary?.claimOpeningConfirmation?.timeSlots?.map((timeSlot, index) => (
                    <div
                      key={index}
                      style={{ width: '130px' }}
                      className="mb-3 p-1 font-16 me-2 claim-document-border-green c-059881 text-center"
                    >
                      {timeSlot}
                    </div>
                  ))}
                </div>
              )}
          </div>
        </div>
      </div>

      {openClaimSummary?.claimOpeningConfirmation?.otherInformation && (
        <div>
          <div className="font-14 c-292929 d-md-flex">
            <div className="col-md-4">{t('claim_report_open_summary.special_needs')}</div>
            <div className="mb-3 font-16 c-7f7f7f">
              {openClaimSummary?.claimOpeningConfirmation?.otherInformation}
            </div>
          </div>
        </div>
      )}
    </div>
  )

  const Sections = (
    <div>
      {ClaimDetails}
      <div className="mb-3 claim-border-bottom-green" />
      <div>
        {openClaimSummary?.injuryPersonalData && (
          <div>
            {InjuryPersonalData}
            <div className="mb-3 claim-border-bottom-green" />
          </div>
        )}
        {openClaimSummary?.damageDetails && (
          <div>
            {DamageDetails}
            <div className="mb-3 claim-border-bottom-green" />
          </div>
        )}
        {openClaimSummary?.requestDetails && openClaimSummary?.requestDetails?.length > 0 && (
          <div>
            {RequestDetails}
            <div className="mb-3 claim-border-bottom-green" />
          </div>
        )}
      </div>
      {OpenDetails}
    </div>
  )

  const Consents = (
    <div className="mt-2 p-2 mb-4 pb-2 bg-f5e9da">
      <InputCheckbox
        name="digitalConsent"
        handleChange={handleChange}
        handleBlur={handleBlur}
        validationSchema={validationSchema}
        touched={touched?.digitalConsent}
        value={values?.digitalConsent}
        error={touched?.digitalConsent && errors.digitalConsent ? errors.digitalConsent : ''}
        label={t('claim_report_open_summary.accordion_value')}
        classesLabel="m-0 mb-2 font-16 d-flex align-items-baseline align-items-md-center"
      />

      <InputCheckbox
        name="privacyConsent"
        handleChange={handleChange}
        handleBlur={handleBlur}
        validationSchema={validationSchema}
        touched={touched?.privacyConsent}
        value={values?.privacyConsent}
        error={touched?.privacyConsent && errors.privacyConsent ? errors.privacyConsent : ''}
        label={t('claim_report_open_summary.privacy_consent')}
        classesLabel="m-0 mb-3 mb-2 font-16 d-flex align-items-baseline align-items-md-center"
      />

      <div className="d-inline-block">
        <div className="py-md-2 font-16 display-contents">
          {t('claim_report_open_summary.declaration_1')}
        </div>
        <div className="py-md-2 display-contents c-891124 cursor-pointer">
          <Button
            flavor="transparentPrimary"
            text={t('claim_report_open_summary.declaration_link')}
            classes="p-0 display-contents"
            classesText="font-16 d-inline border-underline-link"
            onPress={openInformativePersonalDataLink}
          />
        </div>
        <div className="py-md-2 font-16 display-contents">
          {t('claim_report_open_summary.declaration_2')}
        </div>
      </div>
    </div>
  )

  const InfoSend = (
    <div className="font-14-semibold c-7c2d35 text-center text-md-start">
      {t('claim_report_open_summary.info_send')} {openClaimSummary?.claimOpeningConfirmation?.email}
    </div>
  )

  const ButtonForm = (
    <div className="d-flex justify-content-md-end">
      <div className="col-12">
        <Button
          flavor="accentNext"
          text={t('claim_report_open_summary.send')}
          type="submit"
          disabled={pendingCreateClaim || isUploadingData}
          classes="ps-4 ps-md-3 pe-4 pe-md-3 m-auto m-md-0 w-100 d-flex justify-content-between"
          classesText="font-14-semibold text-uppercase"
        />
      </div>
    </div>
  )

  return (
    <div className="mb-md-5 pb-md-5 ps-md-3 pe-md-3 d-md-flex justify-content-md-center container-fluid">
      <div className="col-12 col-lg-7">
        <div className="mt-4">
          <ScrollTopRoot />
          {Topbar}
          <div className="claim-progress">
            <ProgressBar now={progressBar} className="claim-progress-bar" />
          </div>

          <div className="mb-4 p-4 ps-3 pe-3 p-md-5 bg-ffffff">
            {Title}
            {Subtitle}
            {CheckLabel}
            <form onSubmit={handleSubmit}>
              {Sections}
              {Consents}
              <div className="d-md-flex align-items-center">
                <div className="col-md-7">{InfoSend}</div>
                <div className="pt-3 col-md-5">{ButtonForm}</div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {(pendingCreateClaim || loadClaim) && <Loader overlay />}
    </div>
  )
}
