import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import { Image } from 'react-component-library'
import Slider from 'react-slick'
import { Logo } from 'src/types'

import { useSliders } from './sliders.hook'

export const Sliders = ({ logos }: { logos: Logo[] }) => {
  const { t, firstHalf, secondHalf, firstHalfSettings, secondHalfSettings } = useSliders({ logos })

  return (
    <div>
      <div className="mb-5 section6-dynamic-size-partners-title d-flex align-items-center justify-content-center">
        {t('landing.section_6_partners_title')}
      </div>
      <div>
        <div className="slider-logos-container m-0 p-0">
          <Slider {...firstHalfSettings} className="mb-5">
            {firstHalf.map((logo) => (
              <div key={logo?.id}>
                <Image
                  src={logo?.logoDesktop}
                  width="200px"
                  height="100px"
                  className="d-none d-md-block logo-box"
                />
                <Image
                  src={logo?.logoMobile}
                  width="100px"
                  height="auto"
                  className="d-block d-md-none m-2 logo-box"
                />
              </div>
            ))}
          </Slider>
          <Slider {...secondHalfSettings}>
            {secondHalf.map((logo) => (
              <div key={logo?.id}>
                <Image
                  src={logo?.logoDesktop}
                  width="200px"
                  height="100px"
                  className="d-none d-md-block logo-box"
                />
                <Image
                  src={logo?.logoMobile}
                  width="100px"
                  height="auto"
                  className="d-block d-md-none m-2 logo-box"
                />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  )
}
