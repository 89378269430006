import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useRecoilState, useRecoilValue } from 'recoil'
import { MAX_FILE, MAX_FILE_MB } from 'src/config'
import { Paths } from 'src/navigation'
import { usePostUploadFileClaim } from 'src/networking/terminals/claim/claim.gates'
import { atomClaimData, atomClaimQuote, atomProgressBar } from 'src/storage'
import { ClaimFile } from 'src/types/pages/claim.types'
import { fromBase64ToBlob } from 'src/utils'

import { PROGRESS_BAR_STEP } from '../../progress-bar-step'

export const UseClaimReportRcInjuryDocument = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const inputFileAccept = '.pdf,.jpg,.png,.tif'

  const claimQuote = useRecoilValue(atomClaimQuote)
  const [progressBar, setProgressBar] = useRecoilState(atomProgressBar)
  const [claimData, setClaimData] = useRecoilState(atomClaimData)
  const { crossGate: postUploadFileClaim } = usePostUploadFileClaim()

  const [isMobile, setIsMobile] = useState(window.innerWidth < 810)
  const [file, setFile] = useState<File | undefined>(
    claimData?.other?.file
      ? new File(
          [fromBase64ToBlob(claimData?.other?.file?.data || '')],
          claimData?.other?.file?.name,
          {
            type: claimData?.other?.file?.type,
          }
        )
      : undefined
  )
  const [isOutOfSizeFile, setIsOutOfSizeFile] = useState(false)
  const [showUploadModal, setShowUploadModal] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [loadingFile, setLoadingFile] = useState(false)

  //////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    setTimeout(() => {
      setProgressBar(PROGRESS_BAR_STEP.claim_report_rc_injury_document)
    }, 100)
  }, [])

  useEffect(() => {
    window.addEventListener(
      'resize',
      () => {
        const ismobile = window.innerWidth < 810
        if (ismobile !== isMobile) setIsMobile(ismobile)
      },
      false
    )
  }, [isMobile])

  //////////////////////////////////////////////////////////////////////////////

  const goBack = () => {
    navigate(Paths.ClaimReportType)
  }

  const goNext = () => {
    const reader = new FileReader()

    if (file) {
      setLoadingFile(true)
      reader.readAsDataURL(file)
      reader.onload = function () {
        const file_: ClaimFile = {
          name: file?.name,
          size: file?.size,
          data: reader.result?.toString() || '',
          type: file?.type,
          lastModified: file?.lastModified,
        }

        postUploadFileClaim(
          {
            body: {
              shopId: claimQuote?.contractId || '',
              ...file_,
            },
          },
          {
            onSuccess: (response) => {
              if (response?.fileName) {
                setClaimData({
                  ...claimData,
                  other: {
                    ...claimData?.other,
                    file: {
                      ...file_,
                      reference: response?.fileName,
                    },
                  },
                })
                setLoadingFile(false)
                navigate(Paths.ClaimReportRcInjuryDateHour)
              }
            },
            onFailure: () => {
              setLoadingFile(false)
              navigate(Paths.ClaimReportError)
            },
            onPending: () => {},
          }
        )
      }
    }
  }

  const uploadDocument = (file: File) => {
    if (!file) return

    const fileSize = file.size / MAX_FILE

    if (fileSize > MAX_FILE_MB) {
      setIsOutOfSizeFile(true)
      setShowUploadModal(false)
    } else {
      setFile(file)
      setIsLoaded(true)
      setShowUploadModal(false)
    }
  }

  const deleteDocument = () => {
    setFile(undefined)
    setClaimData({
      ...claimData,
      other: {
        ...claimData?.other,
        file: undefined,
      },
    })
    setIsLoaded(false)
  }

  return {
    t,
    file,
    showUploadModal,
    isOutOfSizeFile,
    inputFileAccept,
    isMobile,
    progressBar,
    isLoaded,
    loadingFile,
    goBack,
    goNext,
    uploadDocument,
    setIsOutOfSizeFile,
    setShowUploadModal,
    deleteDocument,
  }
}
