import './section5.scss'

import { useTranslation } from 'react-i18next'
export const useSection5 = () => {
  const { t } = useTranslation()

  return {
    t,
  }
}
