import { ProgressBar } from 'react-bootstrap'
import { ScrollTopRoot } from 'src/roots'
import { Button, InputCheckbox } from 'src/ui/widgets'

import { UseClaimReportWeatherDeclaration } from './claim-report-weather-declaration.hook'

export const ClaimReportWeatherDeclaration = () => {
  const { t, progressBar, formik, validationSchema, goBack } = UseClaimReportWeatherDeclaration()

  const { values, touched, handleChange, handleBlur, handleSubmit } = formik

  const Topbar = (
    <Button
      flavor="transparentAccentBack"
      text={t('commons.back')}
      classes="px-0 pt-0"
      classesText="text-uppercase font-14-semibold"
      onPress={goBack}
    />
  )

  const Title = (
    <div className="font-28-semibold c-292929">{t('claim_report_weather_declaration.title')}</div>
  )

  const Subtitle = (
    <div className="mt-2 font-14-semibold c-292929 text-uppercase">
      {t('claim_report_weather_declaration.subtitle')}
    </div>
  )

  const CheckBoxForm = (
    <div className="mt-4">
      <InputCheckbox
        name="isDeclarationChecked"
        handleChange={handleChange}
        handleBlur={handleBlur}
        validationSchema={validationSchema}
        touched={touched.isDeclarationChecked}
        value={values?.isDeclarationChecked}
        label={t('claim_report_weather_declaration.check_condition')}
        classesLabel="m-0 mb-2 font-14 d-flex align-items-baseline align-items-md-center"
      />
    </div>
  )

  const ButtonForm = (
    <div className="mt-5 pt-2 d-flex justify-content-md-end">
      <div className="col-12 col-md-4">
        <Button
          flavor="accentNext"
          text={t('commons.continue')}
          type="submit"
          disabled={!values?.isDeclarationChecked}
          classes="ps-4 ps-md-3 pe-4 pe-md-3 m-auto m-md-0 w-100 d-flex justify-content-between"
          classesText="font-14-semibold text-uppercase"
        />
      </div>
    </div>
  )
  return (
    <div className="mb-md-5 pb-md-5 ps-md-3 pe-md-3 d-md-flex justify-content-md-center container-fluid">
      <div className="col-12 col-lg-7">
        <div className="mt-4">
          <ScrollTopRoot />
          {Topbar}
          <div className="claim-progress">
            <ProgressBar now={progressBar} className="claim-progress-bar" />
          </div>

          <div className="mb-4 p-4 ps-3 pe-3 p-md-5 bg-ffffff">
            {Title}
            {Subtitle}
            <form onSubmit={handleSubmit}>
              {CheckBoxForm}
              <div className="pt-5">{ButtonForm}</div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
