/* eslint-disable complexity */
import { ClaimFile, ClaimOpen } from 'src/types/pages/claim.types'

export const getOpenInformations = (openInformations: ClaimOpen | undefined): ClaimOpen => {
  let optionalFiles_: Record<string, ClaimFile> | undefined = undefined

  Object?.entries(openInformations?.optionalFiles || {})?.map(([key, file]) => {
    optionalFiles_ = {
      ...optionalFiles_,
      [key]: {
        name: file?.name,
        size: file?.size,
        type: file?.type,
        lastModified: file?.lastModified,
        reference: file?.reference,
        data: '',
      },
    }
  })

  return {
    insuredType: openInformations?.insuredType || undefined,
    name: openInformations?.name || undefined,
    surname: openInformations?.surname || undefined,
    nationality: openInformations?.nationality || undefined,
    birth: openInformations?.birth || undefined,
    birthNation: openInformations?.birthNation || undefined,
    birthPlace: openInformations?.birthPlace || undefined,
    gender: openInformations?.gender || undefined,
    fiscalCode: openInformations?.fiscalCode || undefined,
    residence: openInformations?.residence || undefined,
    contact: openInformations?.contact || undefined,
    otherInformation: openInformations?.otherInformation || undefined,
    optionalFiles: optionalFiles_,
  }
}
