import { Helmet } from 'react-helmet'
import { Button, Loader, Recaptcha } from 'src/ui/widgets'

import { usePaymentOk } from './payment-ok.hook'

export const PaymentOk = () => {
  const { t, contractId, securityHash, email, loading, generateRecaptcha } = usePaymentOk()

  return (
    <div className="col-12 font-27 mx-auto my-auto text-center white-space-pre p-4">
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <div>
        <Recaptcha />
        <div className="font-27-semibold mb-3 text-uppercase">{t('payment.success_thanks')}</div>
        <div className="mb-4">{t('payment.success_title')}</div>

        {loading ? (
          <div className="d-flex justify-content-center mb-3">
            <Loader />
          </div>
        ) : contractId && securityHash ? (
          <div className="d-flex justify-content-center">
            <Button
              flavor="accent"
              text={t('payment.save_certificate')}
              classesText="text-uppercase font-14-semibold"
              classes="mt-2 ps-5 pe-5 mb-4"
              onPress={generateRecaptcha}
            />
          </div>
        ) : (
          <></>
        )}

        <div className="mb-4">
          {t('payment.success_subtitle_1')}
          <div className="text-center mt-3 mt-md-1 font-25">{email}</div>
        </div>
        <div className="mb-3 font-20">{t('payment.success_subtitle_2')}</div>
      </div>
    </div>
  )
}
