/* eslint-disable complexity */
import { memo } from 'react'

import { useDigit } from './digit.hook'
import styles from './Digit.module.css'

export const Digit = memo(
  ({
    className,
    error,
    index,
    value,
    onDigitChange,
    changeFocus,
    focused,
    hidden,
    disabled,
    success,
    style,
  }: any) => {
    const { digitRef, handleChange, handleKeyUpPress, handleFocus } = useDigit({
      index,
      onDigitChange,
      changeFocus,
      focused,
    })

    return (
      <>
        <input
          className={
            success
              ? `${className || styles.digit} ${styles.success_digit}`
              : error
              ? `${className || styles.digit} ${styles.error_digit}`
              : disabled
              ? `${className || styles.digit} ${styles.disabled_digit}`
              : className || styles.digit
          }
          style={style}
          name={`digit-index-${index}`}
          type={hidden ? 'password' : 'text'}
          autoComplete="off"
          value={value || ''}
          ref={digitRef}
          // maxLength={1}
          disabled={disabled || success}
          onChange={handleChange}
          pattern="[0-9]*"
          inputMode="tel"
          onKeyPress={handleKeyUpPress}
          onKeyUp={handleKeyUpPress}
          onFocus={handleFocus}
        />
      </>
    )
  }
)
