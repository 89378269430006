import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilState, useRecoilValue } from 'recoil'
import { lowerLanguage } from 'src/i18n'
import { useGetCookie } from 'src/networking/terminals'
import { atomReloadCookie, selectorCookieTemplats } from 'src/storage'

export const useCookie = () => {
  const { t } = useTranslation()

  const { crossGate: getCookie } = useGetCookie()

  const cookieTemplates = useRecoilValue(selectorCookieTemplats)

  const [templatesStart, setTemplatesStart] = useState('')
  const [templatesEnd, setTemplatesEnd] = useState('')
  const [isReloaded, setReloaded] = useRecoilState(atomReloadCookie)

  useEffect(() => {
    if (!isReloaded) {
      setReloaded(true)
      location.reload()
    }
  }, [isReloaded])

  useEffect(() => {
    if (cookieTemplates) {
      getCookie(
        {
          baseURL: cookieTemplates?.startTemplate?.[lowerLanguage] || '',
        },
        {
          onSuccess: (template) => {
            setTemplatesStart(template)
          },
        }
      )

      getCookie(
        {
          baseURL: cookieTemplates?.endTemplate?.[lowerLanguage] || '',
        },
        {
          onSuccess: (template) => {
            setTemplatesEnd(template)
          },
        }
      )
    }
  }, [cookieTemplates, lowerLanguage])

  return { t, templatesStart, templatesEnd }
}
