/* eslint-disable complexity */

import { typedMemo } from 'src/types'

import { useEntryGroupStep5 } from './entry-group-step5.hook'

export const EntryGroupStep5 = typedMemo(() => {
  const {} = useEntryGroupStep5()

  return (
    <div className="ps-md-3 pe-md-3">
      <div className="d-flex justify-content-center">
        <div id="my-axepta-sdk-pg" />
      </div>
    </div>
  )
})
