import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useRecoilState, useRecoilValue } from 'recoil'
import { Paths } from 'src/navigation'
import { atomClaimData, atomClaimQuote, atomProgressBar } from 'src/storage'
import { getDifferenceInDays } from 'src/utils'

import { PROGRESS_BAR_STEP } from '../../progress-bar-step'

export const UseClaimReportType = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const CARD_TYPE = {
    0: 'weather',
    1: 'other',
  }

  const COVERAGE_RC_CARD_TYPE: Record<string | symbol, string> = {
    '1': t('claim_report_type.card_1_title_injury'),
    '2': t('claim_report_type.card_1_title_refound_injury'),
  }

  const claimQuote = useRecoilValue(atomClaimQuote)
  const [claimData, setClaimData] = useRecoilState(atomClaimData)

  const [progressBar, setProgressBar] = useRecoilState(atomProgressBar)

  const [selectedCard, setSelectedCard] = useState(
    claimData?.selectedType ? claimData?.selectedType : ''
  )
  const [nextSection, setNextSection] = useState(false)

  //////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    setTimeout(() => {
      setProgressBar(PROGRESS_BAR_STEP.claim_report_type)
    }, 100)
  }, [])

  useEffect(() => {
    if (!claimQuote) {
      navigate(Paths.ClaimReport)
    }
  }, [claimQuote])

  useEffect(() => {
    if (nextSection) {
      if (selectedCard === CARD_TYPE[0]) {
        if (getDifferenceInDays(claimQuote?.from, claimQuote?.to) + 1 >= 3) {
          navigate(Paths.ClaimReportWeather)
          setClaimData({
            weather: {},
            selectedType: selectedCard,
          })
        } else {
          navigate(Paths.ClaimReportWarning)
        }
      } else if (selectedCard === CARD_TYPE[1]) {
        setClaimData({
          other: {
            date: '',
            hour: '',
            insuredType: '',
            request: {},
            requestType: '',
          },
          selectedType: selectedCard,
        })
        navigate(Paths.ClaimReportRcInjuryDocument)
      }
    }
  }, [nextSection])

  //////////////////////////////////////////////////////////////////////////////

  const goBack = () => {
    navigate(Paths?.ClaimReport)
    setClaimData({
      selectedType: '',
    })
  }

  const selectCard = (key: string) => {
    setSelectedCard(key)
  }

  const goNext = () => {
    setNextSection(true)
  }

  return {
    t,
    CARD_TYPE,
    COVERAGE_RC_CARD_TYPE,
    claimQuote,
    selectedCard,
    progressBar,
    selectCard,
    goBack,
    goNext,
  }
}
