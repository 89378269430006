import { ChangeEvent } from 'react'
import { ProgressBar } from 'react-bootstrap'
import { Image } from 'react-component-library'
import { ConfirmedBoldIcon, DeleteIcon, ItasCamera, ItasGallery, Upload } from 'src/assets'
import { ScrollTopRoot } from 'src/roots'
import { ClaimUploadDocumentModalError } from 'src/ui/fragments/claim-upload-document/claim-upload-document'
import { Button, InputDate, InputRadio, InputText, Loader, Tooltip } from 'src/ui/widgets'

import { UseClaimReportOpen } from './claim-report-open.hook'

// eslint-disable-next-line complexity
export const ClaimReportOpen = () => {
  const {
    t,
    progressBar,
    formik,
    validationSchema,
    isMobile,
    timeSlots,
    claimQuote,
    genderOptions,
    insuredOptions,
    nationalityOptions,
    inputFileAccept,
    isOutOfSizeFile,
    showUploadFileModal,
    loadingFile,
    selectTimeSlot,
    deleteDocument,
    uploadDocuments,
    setUpdateInsured,
    setIsOutOfSizeFile,
    setShowUploadFileModal,
    goBack,
  } = UseClaimReportOpen()

  const {
    values,
    touched,
    errors,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    handleChange,
    handleSubmit,
  } = formik

  const Topbar = (
    <Button
      flavor="transparentAccentBack"
      text={t('commons.back')}
      classes="px-0 pt-0"
      classesText="text-uppercase font-14-semibold"
      onPress={goBack}
    />
  )

  const Title = <div className="font-28-semibold c-292929">{t('claim_report_open.title')}</div>

  const Subtitle = (
    <div className="mt-2 font-14-semibold c-292929 text-uppercase">
      {t('claim_report_open.subtitle')}
    </div>
  )

  const RadioForm = (
    <div>
      <InputRadio
        name="insuredType"
        handleChange={(event: ChangeEvent<HTMLInputElement>) => {
          setFieldValue('insuredType', event?.target?.value)
          handleChange(event)
          setUpdateInsured(true)
        }}
        handleBlur={handleBlur}
        options={insuredOptions || []}
        validationSchema={validationSchema}
        touched={touched.insuredType}
        value={values.insuredType}
        error={touched.insuredType && errors.insuredType ? errors.insuredType : ''}
        vertically
        label={t('claim_report_open.radio_label')}
        classes="m-0 p-0 mt-4 mb-3"
        classesLabel="m-0 p-0 font-12 d-flex align-items-center mb-3"
        classesOption="pb-3"
      />
    </div>
  )

  const NationalityDataIt = (
    <div className="d-md-flex align-items-start">
      <InputText
        name="fiscalCode"
        handleChange={handleChange}
        handleBlur={handleBlur}
        validationSchema={validationSchema}
        touched={touched.fiscalCode}
        disabled={values?.insuredType !== 'third_person'}
        value={values.fiscalCode?.toUpperCase()}
        error={touched.fiscalCode && errors.fiscalCode ? errors.fiscalCode : ''}
        label={t('claim_report_open.fiscal_code')}
        placeholder={t('claim_report_open.fiscal_code_placeholder')}
        classes="mb-4 col-md-6 pe-md-2"
      />
      <InputText
        name="residence"
        handleChange={handleChange}
        handleBlur={handleBlur}
        validationSchema={validationSchema}
        touched={touched.residence}
        value={values.residence}
        error={touched.residence && errors.residence ? errors.residence : ''}
        label={t('claim_report_open.residence')}
        placeholder={t('claim_report_open.residence_placeholder')}
        classes="mb-4 col-md-6 ps-md-2"
      />
    </div>
  )

  const NationalityDataExt = (
    <div>
      <div className="d-md-flex align-items-start">
        <InputDate
          name="birth"
          handleChange={handleChange}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          validationSchema={validationSchema}
          touched={touched.birth}
          value={values.birth}
          error={touched.birth && errors.birth ? errors.birth : ''}
          label={t('claim_report_open.birth')}
          placeholder={t('claim_report_open.birth_placeholder')}
          disabledTo={new Date()}
          classes="m-0 p-0 mb-4 col-md-6 pe-md-2"
        />
        <InputText
          name="birthNation"
          handleChange={handleChange}
          handleBlur={handleBlur}
          validationSchema={validationSchema}
          touched={touched.birthNation}
          value={values.birthNation}
          error={touched.birthNation && errors.birthNation ? errors.birthNation : ''}
          label={t('claim_report_open.birth_nation')}
          placeholder={t('claim_report_open.birth_nation_placeholder')}
          classes="mb-4 col-md-6 ps-md-2"
        />
      </div>
      <div className="d-md-flex align-items-start">
        <InputText
          name="birthPlace"
          handleChange={handleChange}
          handleBlur={handleBlur}
          validationSchema={validationSchema}
          touched={touched.birthPlace}
          value={values.birthPlace}
          error={touched.birthPlace && errors.birthPlace ? errors.birthPlace : ''}
          label={t('claim_report_open.birth_place')}
          placeholder={t('claim_report_open.birth_place_placeholder')}
          classes="mb-4 col-md-6 pe-md-2"
        />
        <InputText
          name="residence"
          handleChange={handleChange}
          handleBlur={handleBlur}
          validationSchema={validationSchema}
          touched={touched.residence}
          value={values.residence}
          error={touched.residence && errors.residence ? errors.residence : ''}
          label={t('claim_report_open.residence')}
          placeholder={t('claim_report_open.residence_placeholder')}
          classes="mb-4 col-md-6 ps-md-2"
        />
      </div>
      <InputRadio
        name="gender"
        handleChange={(event: ChangeEvent<HTMLInputElement>) => {
          setFieldValue('gender', event?.target?.value)
          handleChange(event)
        }}
        handleBlur={handleBlur}
        options={genderOptions || []}
        validationSchema={validationSchema}
        touched={touched.gender}
        value={values.gender}
        error={touched.gender && errors.gender ? errors.gender : ''}
        vertically
        label={t('claim_report_open.gender')}
        classes="m-0 p-0 mt-4"
        classesLabel="m-0 p-0 font-12 d-flex align-items-center mb-3"
        classesOption="pb-3"
      />
    </div>
  )

  const UserForm = (
    <div>
      <div className="d-md-flex align-items-start">
        <InputText
          name="name"
          handleChange={handleChange}
          handleBlur={handleBlur}
          validationSchema={validationSchema}
          touched={touched.name}
          value={values.name}
          disabled={values?.insuredType !== 'third_person'}
          error={touched.name && errors.name ? errors.name : ''}
          label={t('claim_report_open.name')}
          placeholder={t('claim_report_open.name_placeholder')}
          classes="mb-4 col-md-6 pe-md-2"
        />
        <InputText
          name="surname"
          handleChange={handleChange}
          handleBlur={handleBlur}
          validationSchema={validationSchema}
          touched={touched.surname}
          value={values.surname}
          disabled={values?.insuredType !== 'third_person'}
          error={touched.surname && errors.surname ? errors.surname : ''}
          label={t('claim_report_open.surname')}
          placeholder={t('claim_report_open.surname_placeholder')}
          classes="mb-4 col-md-6 ps-md-2"
        />
      </div>
      <div>
        <InputRadio
          name="nationality"
          handleChange={(event: ChangeEvent<HTMLInputElement>) => {
            if (values?.insuredType === 'third_person') {
              setFieldValue('nationality', event?.target?.value)
              handleChange(event)
            }
          }}
          handleBlur={handleBlur}
          options={nationalityOptions || []}
          validationSchema={validationSchema}
          touched={touched.nationality}
          value={values.nationality}
          disabled={values?.insuredType !== 'third_person'}
          error={touched.nationality && errors.nationality ? errors.nationality : ''}
          vertically
          label={t('claim_report_open.nationality')}
          classes="m-0 p-0 mt-2 mb-3"
          classesLabel="m-0 p-0 font-12 d-flex align-items-center mb-3"
          classesOption="pb-3"
        />
      </div>
      {values?.nationality === 'IT' ? NationalityDataIt : NationalityDataExt}
    </div>
  )

  const TimeSlots = (
    <div>
      <div className="font-medium-s mb-2">{t('claim_report_open.time_slot')}</div>
      {timeSlots?.map((_, rowIndex) => {
        return (
          <div key={`row-${rowIndex}`} className="mb-2 d-flex align-items-center">
            {timeSlots[rowIndex]?.map((item, colIndex) => {
              return (
                <div key={`col-${rowIndex}-${colIndex}`} className="col-4">
                  <div
                    className={`cursor-pointer shadow-time-slot font-12 text-center p-1 ${
                      item?.isSelected ? 'border-time-slot font-12-semibold' : ''
                    } ${colIndex === 1 ? 'ms-1 me-1' : ''}`}
                    onClick={() => selectTimeSlot(rowIndex, colIndex)}
                  >
                    {item?.value}
                  </div>
                </div>
              )
            })}
          </div>
        )
      })}
    </div>
  )

  const MobileForm = (
    <div className="mb-3">
      <div className="d-flex align-items-start col-md-6">
        <InputText
          name="contactMobile"
          handleChange={handleChange}
          handleBlur={handleBlur}
          validationSchema={validationSchema}
          touched={touched.contactMobile}
          value={values.contactMobile}
          error={touched.contactMobile && errors.contactMobile ? errors.contactMobile : ''}
          label={t('claim_report_open.mobile_label')}
          placeholder={t('claim_report_open.mobile_placeholder')}
          classes="mb-4 w-100"
        />
      </div>
      <div>{TimeSlots}</div>
    </div>
  )

  const EmailForm = (
    <div className="mb-3">
      <InputText
        name="contactEmail"
        handleChange={handleChange}
        handleBlur={handleBlur}
        validationSchema={validationSchema}
        touched={touched.contactEmail}
        value={values.contactEmail}
        error={touched.contactEmail && errors.contactEmail ? errors.contactEmail : ''}
        label={t('claim_report_open.email_label')}
        placeholder={t('claim_report_open.email_placeholder')}
        classes="mb-4 w-100"
      />
      <InputText
        name="contactConfirmEmail"
        handleChange={handleChange}
        handleBlur={handleBlur}
        validationSchema={validationSchema}
        touched={touched.contactConfirmEmail}
        value={values.contactConfirmEmail}
        error={
          touched.contactConfirmEmail && errors.contactConfirmEmail
            ? errors.contactConfirmEmail
            : ''
        }
        label={t('claim_report_open.confirm_email_label')}
        placeholder={t('claim_report_open.email_placeholder')}
        classes="mb-4 w-100"
      />
    </div>
  )

  const ContactForm = (
    <div>
      <div className="mb-3 font-12">{t('claim_report_open.contact_label')}</div>
      <InputRadio
        name="contact"
        handleChange={(event: ChangeEvent<HTMLInputElement>) => {
          setFieldValue('contact', event?.target?.value)
          handleChange(event)
        }}
        handleBlur={handleBlur}
        options={
          [
            {
              id: 'mobile',
              value: t('claim_report_open.mobile'),
            },
          ] || []
        }
        validationSchema={validationSchema}
        touched={touched.contact}
        value={values.contact}
        error={''}
        classes="m-0 p-0 mb-3"
        classesLabel="d-none"
      />
      <div>{values.contact === 'mobile' && MobileForm}</div>
      <InputRadio
        name="contact"
        handleChange={(event: ChangeEvent<HTMLInputElement>) => {
          setFieldValue('contact', event?.target?.value)
          handleChange(event)
        }}
        handleBlur={handleBlur}
        options={
          [
            {
              id: 'email',
              value: t('claim_report_open.email'),
            },
          ] || []
        }
        validationSchema={validationSchema}
        touched={touched.contact}
        value={values.contact}
        error={''}
        classes="m-0 p-0 mb-3"
        classesLabel="d-none"
      />
      <div>{values.contact === 'email' && values?.insuredType !== 'contractor' && EmailForm}</div>
    </div>
  )

  const OtherInformation = (
    <InputText
      name="otherInformation"
      handleChange={handleChange}
      handleBlur={handleBlur}
      textarea
      validationSchema={validationSchema}
      touched={touched.otherInformation}
      value={values.otherInformation}
      error={
        touched.otherInformation && errors.otherInformation ? errors.otherInformation : undefined
      }
      classesLabel="font-medium-s mb-2"
      label={t('claim_report_open.other_information')}
      placeholder={t('claim_report_open.other_information_placeholder')}
    />
  )

  const TooltipInfo = (
    <div className="d-flex align-items-center w-100 text-md-center">
      <div className="font-12">{t('claim_report_open.upload_file')}</div>
      <div className="ps-2">
        <Tooltip placement="top" iconSize={15}>
          <div className="font-14 c-292929 d-flex flex-column align-items-start">
            <div>{t('commons.tooltip_info_file_1')}</div>
            <div>{t('commons.tooltip_info_file_2')}</div>
          </div>
        </Tooltip>
      </div>
    </div>
  )

  const ModalUpload = (
    <div>
      <div className="claim-modal-backgroud-upload" />
      <div className="claim-modal-upload">
        <div className="p-3 bg-ffffff h-100">
          <div className="d-flex justify-content-center align-items-center font-16-semibold">
            {t('commons.upload_title')}
          </div>

          <div className={'mt-4 custom-input-upload custom-label-pointer'}>
            <label htmlFor="upload-input-gallery">
              <div className="d-flex align-items-center">
                <Image src={ItasGallery} width={24} height={24} />
                <div className="ms-3 font-16 c-7f7f7f">{t('commons.upload_gallery')}</div>
              </div>
            </label>
            <input
              onChange={(e) => {
                if (e?.target?.files) {
                  uploadDocuments(e?.target?.files)
                }
              }}
              multiple
              type="file"
              accept={inputFileAccept}
              capture="environment"
              className="d-none"
              id="upload-input-gallery"
            />
          </div>

          <div className={'mt-4 custom-input-upload custom-label-pointer'}>
            <label htmlFor="upload-input-photo">
              <div className="d-flex align-items-center">
                <Image src={ItasCamera} width={24} height={24} />
                <div className="ms-3 font-16 c-7f7f7f">
                  {t('claim_report_rc_injury_document.upload_photo')}
                </div>
              </div>
            </label>
            <input
              onChange={(e) => {
                if (e?.target?.files) {
                  uploadDocuments(e?.target?.files)
                }
              }}
              multiple
              type="file"
              accept={inputFileAccept}
              capture="environment"
              className="d-none"
              id="upload-input-photo"
            />
          </div>

          <Button
            flavor="accent"
            text={t('commons.cancel')}
            classes="mt-4 ps-4 pe-4 d-flex justify-content-center m-auto w-100"
            classesText="font-14-semibold text-uppercase"
            onPress={() => setShowUploadFileModal(false)}
          />
        </div>
      </div>
    </div>
  )

  const UploadFromMobile = (
    <div className="mt-3 d-flex align-items-center">
      <Image src={Upload} width={20} height={20} />
      <div
        className="ms-2 font-14-semibold text-underline c-7c2d35"
        onClick={() => setShowUploadFileModal(true)}
      >
        {t('claim_report_rc_injury_report_damage_step2.upload_document')?.toUpperCase()}
      </div>
    </div>
  )

  const UploadFromDesktop = (
    <div className="mt-3 d-flex align-items-center">
      <Image src={Upload} width={20} height={20} />
      <div>
        <input
          onChange={(e) => {
            if (e?.target?.files) {
              uploadDocuments(e?.target?.files)
            }
          }}
          type="file"
          accept={inputFileAccept}
          multiple
          className="d-none"
          id="open-claim-upload-input"
        />
      </div>
      <label
        htmlFor="open-claim-upload-input"
        className={`ms-2 font-14-semibold c-7c2d35 text-underline cursor-pointer`}
      >
        {t('claim_report_rc_injury_report_damage_step2.upload_document')?.toUpperCase()}
      </label>
    </div>
  )

  const UploadLabel = (
    <div
      className={`bg-f5e9da ${
        Object?.keys(values?.optionalFiles)?.length > 0 ? 'claim-document-border-green' : ''
      }`}
    >
      <div className="p-2">
        {TooltipInfo}
        {isMobile ? UploadFromMobile : UploadFromDesktop}
      </div>
      {Object?.keys(values?.optionalFiles)?.map((fileKey, index) => (
        <div key={index}>
          <div className="p-2 bg-f5e9da col-12 col-md-4 d-flex align-items-center">
            <Image src={ConfirmedBoldIcon} width={20} height={20} />
            <div className="ps-3 col-10 font-16-semibold">
              {values?.optionalFiles[fileKey]?.name}
            </div>
            <div className="col-1 d-md-flex justify-content-end cursor-pointer">
              <Image
                src={DeleteIcon}
                width={20}
                height={20}
                onClick={() => deleteDocument(fileKey)}
              />
            </div>
          </div>
          <div className="border-bottom" />
        </div>
      ))}
    </div>
  )

  const ButtonForm = (
    <div className="mt-5 pt-2 d-flex justify-content-md-end">
      <div className="col-12 col-md-4">
        <Button
          flavor="accentNext"
          text={t('claim_report_open.continue')}
          type="submit"
          classes="ps-4 ps-md-3 pe-4 pe-md-3 m-auto m-md-0 w-100 d-flex justify-content-between"
          classesText="font-14-semibold text-uppercase"
        />
      </div>
    </div>
  )

  return (
    <div className="mb-md-5 pb-md-5 ps-md-3 pe-md-3 d-md-flex justify-content-md-center container-fluid">
      <div className="col-12 col-lg-7">
        <div className="mt-4">
          <ScrollTopRoot />
          {Topbar}
          <div className="claim-progress">
            <ProgressBar now={progressBar} className="claim-progress-bar" />
          </div>
          <div className="mb-4 p-4 ps-3 pe-3 p-md-5 bg-ffffff">
            {Title}
            {Subtitle}
            <form onSubmit={handleSubmit}>
              {claimQuote?.assets?.length !== 0 && RadioForm}
              {UserForm}
              {ContactForm}
              {OtherInformation}
              {UploadLabel}
              <div className="pt-5">{ButtonForm}</div>
            </form>
            {showUploadFileModal && ModalUpload}
            {isOutOfSizeFile && (
              <ClaimUploadDocumentModalError
                t={t}
                isOutOfSizeFile={isOutOfSizeFile}
                setIsOutOfSizeFile={setIsOutOfSizeFile}
              />
            )}
          </div>
        </div>
      </div>
      {loadingFile && <Loader overlay />}
    </div>
  )
}
