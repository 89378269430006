import './input-date.scss'

import { Button, Image } from 'react-bootstrap'
import { CalendarIcon, LeftIcon, RightIcon } from 'src/assets'
import { InputDateProps, typedMemo } from 'src/types'
import { compareDates, concatClasses } from 'src/utils'

import { InputText } from '../input-text/input-text'
import { useInputDate } from './input-date.hook'
import { DAYS, MONTHS } from './input-date.utils'

export const InputDate = typedMemo((props: InputDateProps) => {
  const {
    days,
    expandedPicker,
    currentDate,
    fullYear,
    fullMonth,
    dayOfWeek,
    updateDate,
    togglePicker,
    prevYear,
    nextYear,
    prevTenYear,
    nextTenYear,
    prevMonth,
    nextMonth,
  } = useInputDate(props)

  const {
    name,
    value: datestr,
    disabled,
    disabledFrom,
    disabledTo,
    classes,
    ...inputTextProps
  } = props

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  const Input = (
    <InputText
      name={name}
      value={datestr}
      placeholder="DD / MM / YYYY"
      icon={CalendarIcon}
      iconValid=""
      disabled={disabled}
      onPressIcon={togglePicker}
      {...inputTextProps}
    />
  )

  const Years = (
    <div className="input-date--picker-selector">
      <Image src={LeftIcon} onClick={prevYear} />
      <div onClick={prevTenYear}>«</div>
      <p>{fullYear}</p>
      <div onClick={nextTenYear}>»</div>
      <Image src={RightIcon} onClick={nextYear} />
    </div>
  )

  const Months = (
    <div className="input-date--picker-selector">
      <Image src={LeftIcon} onClick={prevMonth} />
      <p>{MONTHS?.[fullMonth]}</p>
      <Image src={RightIcon} onClick={nextMonth} />
    </div>
  )

  const Days = (
    <div className="mt-4 d-flex flex-wrap">
      {DAYS.map((day) => (
        <div key={day} className="input-date--picker-day">
          <small>{day}</small>
        </div>
      ))}
    </div>
  )

  const PickerItem = (date: Date) => {
    const highlight = datestr && compareDates(date, currentDate) === 0

    const disabledF = compareDates(date, disabledFrom) === -1
    const disabledT = compareDates(date, disabledTo) === 1
    const disabled = disabledF || disabledT

    const variant = highlight ? 'day-selected c-ffffff' : disabled ? 'day-disabled' : ''

    return (
      <div key={date.getTime()} className="input-date--picker-day">
        <Button
          onClick={() => updateDate(date)}
          className="input-date--picker-day-button rounded-circle d-flex justify-content-center align-items-center"
          variant={variant}
          disabled={disabled}
        >
          {date.getDate()}
        </Button>
      </div>
    )
  }

  const Picker = (
    <div className="d-flex flex-wrap">
      <div style={{ width: `${dayOfWeek * 14.28}%` }} />
      {days.map(PickerItem)}
    </div>
  )

  return (
    <main className={classes}>
      {Input}
      <div
        className={concatClasses(
          'input-date--picker-container',
          expandedPicker ? 'd-block mb-3' : 'd-none'
        )}
      >
        {Years}
        {Months}
        {Days}
        {Picker}
      </div>
    </main>
  )
})
