import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useRecoilState, useRecoilValue } from 'recoil'
import { Paths } from 'src/navigation'
import { atomClaimData, atomClaimQuote, atomProgressBar } from 'src/storage'
import { FormikClaimDateHour } from 'src/types'
import {
  compareFullDates,
  isValidDate,
  reverseDate,
  yupDateRequired,
  yupStrRequired,
} from 'src/utils'
import * as Yup from 'yup'

import { PROGRESS_BAR_STEP } from '../../progress-bar-step'

export const UseClaimReportRcInjuryDateHour = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const claimQuote = useRecoilValue(atomClaimQuote)
  const [claimData, setClaimData] = useRecoilState(atomClaimData)
  const [progressBar, setProgressBar] = useRecoilState(atomProgressBar)

  const [isValidData, setIsValidData] = useState(true)

  const [initialValues] = useState<FormikClaimDateHour>({
    date: claimData?.other?.date || '',
    hour: claimData?.other?.hour || '',
  })

  const validationSchema = Yup.object().shape({
    date: yupDateRequired.test(
      'check_date',
      t('claim_report_rc_injury_date_hour.error_date'),
      (value) => {
        return (
          compareFullDates(new Date(reverseDate(value)), new Date()) <= 0 &&
          isValidDate(reverseDate(value))
        )
      }
    ),
    hour: yupStrRequired,
  })

  //////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    setTimeout(() => {
      setProgressBar(PROGRESS_BAR_STEP.claim_report_rc_injury_date_hour)
    }, 100)
  }, [])

  useEffect(() => {
    if (!claimQuote) {
      navigate(Paths.ClaimReport)
    }
  }, [claimQuote])

  //////////////////////////////////////////////////////////////////////////////

  const formik = useFormik<FormikClaimDateHour>({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (claimQuote) {
        const checkDate = new Date(values?.date.split('/').reverse().join('/'))
        const hours = values?.hour?.split(':')[0]
        const minutes = values?.hour?.split(':')[1]

        checkDate.setHours(Number(hours), Number(minutes))

        const fromDate = new Date(claimQuote?.from || '')
        const toDate = new Date(claimQuote?.to || '')

        const isValidFrom = compareFullDates(fromDate, checkDate)
        const isValidTo = compareFullDates(checkDate, toDate)

        const isFullValid =
          (isValidFrom === -1 || isValidFrom === 0) && (isValidTo === -1 || isValidTo === 0)

        if (isFullValid) {
          setIsValidData(true)
          if (claimQuote?.assets?.length === 0) {
            setClaimData({
              ...claimData,
              other: {
                ...claimData?.other,
                date: values?.date,
                hour: values?.hour,
                insuredType: 'contractor',
              },
            })
            navigate(Paths.ClaimReportRcInjuryPersonalData)
          } else {
            setClaimData({
              ...claimData,
              other: {
                ...claimData?.other,
                date: values?.date,
                hour: values?.hour,
              },
            })
            navigate(Paths.ClaimReportRcInjuryInsuredPerson)
          }
        } else {
          setIsValidData(false)
        }
      }
    },
  })

  //////////////////////////////////////////////////////////////////////////////

  const goBack = () => {
    navigate(Paths.ClaimReportRcInjuryDocument)
  }

  return {
    t,
    formik,
    isValidData,
    validationSchema,
    progressBar,
    goBack,
  }
}
