import './section2-special.scss'

import { useTranslation } from 'react-i18next'
import { useRecoilValue } from 'recoil'
import { atomProduct } from 'src/storage'

export const useSection2Special = () => {
  const { t } = useTranslation()

  const product = useRecoilValue(atomProduct)

  return {
    t,
    product,
  }
}
