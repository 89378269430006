import { Button } from 'src/ui/widgets'

import { usePaymentKo } from './payment-ko.hook'

export const PaymentKo = () => {
  const { t, goToDataEntry } = usePaymentKo()

  return (
    <div className="col-12 font-35 mx-auto my-auto text-center white-space-pre p-4">
      <div>
        <div className="mb-3">{t('payment.failure_title')}</div>
        <div className="mb-5">{t('payment.failure_sub_title')}</div>
        <div className="d-flex justify-content-center">
          <Button
            flavor="accent"
            text={t('commons.back')}
            classes="mt-2 ps-5 pe-5"
            onPress={goToDataEntry}
          />
        </div>
      </div>
    </div>
  )
}
