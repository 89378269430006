import { ClaimFile, ClaimWeatherData } from 'src/types/pages/claim.types'

const getFile = (file: ClaimFile | undefined): ClaimFile | undefined => {
  if (!file) return undefined

  return {
    name: file?.name,
    size: file?.size,
    type: file?.type,
    lastModified: file?.lastModified,
    reference: file?.reference,
    data: '',
  }
}

export const getWeatherRequest = (weatherData: ClaimWeatherData | undefined): ClaimWeatherData => {
  return {
    isDeclarationChecked: weatherData?.isDeclarationChecked || false,
    insuredType: weatherData?.insuredType || undefined,
    date: weatherData?.date || undefined,
    hour: weatherData?.hour || undefined,
    skiResort: weatherData?.skiResort || undefined,
    file: getFile(weatherData?.file) || undefined,
  }
}
