import './wrapper.root.scss'

import { ReactNode } from 'react'
import { Card } from 'react-bootstrap'
import { Footer, Header } from 'src/ui/fragments'

export const WrapperRoot = ({ children }: { children: ReactNode }) => {
  return (
    <Card>
      <div className="wrapper--header">
        <Header />
      </div>
      <div className="wrapper--body">{children}</div>
      <div className="layout--footer">{<Footer />}</div>
    </Card>
  )
}
