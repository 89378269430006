/* eslint-disable complexity */
import './footer.scss'

import { useRecoilValue, useSetRecoilState } from 'recoil'
import { lowerLanguage, upperLanguage } from 'src/i18n'
import { Paths } from 'src/navigation'
import { atomReloadCookie, selectorAgency, selectorDocuments } from 'src/storage'
import { AgencyCompleteAddress, typedMemo } from 'src/types'
import { capitalizeCase } from 'src/utils'

import { useFooter } from './footer.hook'

const MAPS_LINK = 'http://maps.google.com/?q='

export const Footer = typedMemo(() => {
  const { t, navigate } = useFooter()

  const documents = useRecoilValue(selectorDocuments)
  const agency = useRecoilValue(selectorAgency)
  const setReloaded = useSetRecoilState(atomReloadCookie)

  const informativeWebLink = documents?.find(({ id }) => id === '7')?.file?.[lowerLanguage]
  const claimLink = documents?.find(({ id }) => id === '8')?.file?.[lowerLanguage]

  const renderCustomAddress = (agencyAddress: AgencyCompleteAddress) => {
    if (!agencyAddress || Object?.keys(agencyAddress)?.length === 0) return ''

    const formattedAddress = `${capitalizeCase(agencyAddress?.address || '')}, ${capitalizeCase(
      agencyAddress?.location || ''
    )}, ${
      (agencyAddress?.province?.includes('-')
        ? `${capitalizeCase(agencyAddress?.province?.split('-')[0])} - ${agencyAddress?.province
            ?.split('-')[1]
            ?.toUpperCase()}`
        : capitalizeCase(agencyAddress?.province || '')) || ''
    }`

    return (
      <div
        className="font-15 c-ffffffbf ms-xl-2 cursor-pointer"
        onClick={() => window.open(MAPS_LINK + formattedAddress)}
      >
        {formattedAddress}
      </div>
    )
  }

  const FooterTop = (
    <div className="bg-000000E6 d-md-flex justify-content-md-between py-4 px-3 py-md-4 px-md-5">
      <div className="d-lg-flex">
        <div>
          <div>
            <div className="ml-auto font-12 c-ffffffbf text-uppercase">{t('footer.title_1')}</div>

            <div className="footer-line mt-2 mb-3" />

            <div className="mt-2">
              <div className="d-flex d-md-block d-xl-flex">
                <div className="font-15 c-ffffffbf">{t('footer.green_number_title')}</div>
                <div
                  className="font-15 c-ffffffbf ms-2 ms-md-0 ms-xl-2 cursor-pointer"
                  onClick={() => window.open(`tel:${t('footer.green_number')}`)}
                >
                  {t('footer.green_number')}
                </div>
              </div>

              <div className="d-flex d-md-block d-xl-flex">
                <div className="font-15 c-ffffffbf">{t('footer.email_title')}</div>
                <div
                  className="font-15 c-ffffffbf ms-2 ms-md-0 ms-xl-2 cursor-pointer"
                  onClick={() => window.open(`mailto:${t('footer.email')}`)}
                >
                  {t('footer.email')}
                </div>
              </div>

              <div className="font-15 c-ffffffbf mt-3 white-space-pre">
                {t('footer.customer_service_info_2')}
              </div>

              <div className="font-15 c-ffffffbf mt-3 white-space-pre">
                {t('footer.customer_service_info')}
              </div>
            </div>
          </div>
          <div>
            <div className="ml-auto font-12 c-ffffffbf text-uppercase mt-4">
              {t('footer.claim_report')}
            </div>

            <div className="footer-line mt-2 mb-3" />

            <div className="mt-2">
              <div className="d-flex d-md-block d-xl-flex">
                <div className="font-15 c-ffffffbf">{t('footer.claim_email_title')}</div>
                <div
                  className="font-15 c-ffffffbf ms-2 ms-md-0 ms-xl-2 cursor-pointer"
                  onClick={() => window.open(`mailto:${t('footer.claim_email')}`)}
                >
                  {t('footer.claim_email')}
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="ml-auto font-12 c-ffffffbf text-uppercase mt-4">
              {t('footer.open_claims')}
            </div>

            <div className="footer-line mt-2 mb-3" />

            <div className="mt-2">
              <div className="d-flex d-md-block d-xl-flex">
                <div className="font-15 c-ffffffbf">{t('footer.claim_number_title')}</div>
                <div
                  className="font-15 c-ffffffbf ms-2 ms-md-0 ms-xl-2 cursor-pointer"
                  onClick={() => window.open(`tel:${t('footer.claim_number')}`)}
                >
                  {t('footer.claim_number')}
                </div>
              </div>

              <div className="font-15 c-ffffffbf">{t('footer.claim_date')}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-5 mt-md-0">
        <div className="ml-auto font-12 c-ffffffbf text-uppercase">
          {agency?.isCustomNow ? agency?.denomination : t('footer.title_3')}
        </div>

        <div className="footer-line mt-2 mb-3" />

        <div className="font-15 c-ffffffbf white-space-pre">
          {t('footer.srl_label_1')}
          {agency?.isCustomNow ? agency?.ruiNumber : t('footer.srl_label_number')}
          {t('footer.srl_label_2')}
        </div>

        <div className="d-block d-md-flex">
          <div className="font-15 c-ffffffbf">{t('footer.ivass_value_2')}</div>
          <div
            className="font-15 c-ffffffbf ms-md-2 cursor-pointer"
            onClick={() => window.open(t('footer.ivass_link'))}
          >
            {t('footer.ivass_link')}
          </div>
        </div>

        <div className="d-block d-xl-flex">
          <div className="font-15 c-ffffffbf">{t('footer.registered_office_title')}</div>
          {agency?.isCustomNow ? (
            renderCustomAddress(agency?.registeredOfficeCompleteAddress || {})
          ) : (
            <div
              className="font-15 c-ffffffbf ms-xl-2 cursor-pointer"
              onClick={() => window.open(MAPS_LINK + t('footer.registered_office'))}
            >
              {t('footer.registered_office')}
            </div>
          )}
        </div>

        <div className="d-block d-xl-flex">
          <div className="font-15 c-ffffffbf">{t('footer.operational_offices_title')}</div>
          {agency?.isCustomNow ? (
            renderCustomAddress(agency?.operationalHeadquartersCompleteAddress || {})
          ) : (
            <div
              className="font-15 c-ffffffbf ms-xl-2 cursor-pointer"
              onClick={() => window.open(MAPS_LINK + t('footer.operational_offices'))}
            >
              {t('footer.operational_offices')}
            </div>
          )}
        </div>

        <div className="d-flex">
          <div className="font-15 c-ffffffbf">{t('footer.phone_title')}</div>
          {agency?.isCustomNow && agency?.phoneNumber ? (
            <div
              className="font-15 c-ffffffbf ms-2 cursor-pointer"
              onClick={() => window.open(`tel:${agency?.phoneNumber}`)}
            >
              {agency?.phoneNumber}
            </div>
          ) : (
            <div
              className="font-15 c-ffffffbf ms-2 cursor-pointer"
              onClick={() => window.open(`tel:${t('footer.phone')}`)}
            >
              {t('footer.phone')}
            </div>
          )}
        </div>

        <div className="d-flex">
          <div className="font-15 c-ffffffbf">{t('footer.mail_title')}</div>
          {agency?.isCustomNow && agency?.mail ? (
            <div
              className="font-15 c-ffffffbf ms-2 cursor-pointer"
              onClick={() => window.open(`mailto:${agency?.mail}`)}
            >
              {agency?.mail}
            </div>
          ) : (
            <div
              className="font-15 c-ffffffbf ms-2 cursor-pointer"
              onClick={() => window.open(`mailto:${t('footer.mail')}`)}
            >
              {t('footer.mail')}
            </div>
          )}
        </div>

        <div className={upperLanguage === 'IT' ? 'd-flex' : 'd-block d-xl-flex'}>
          <div className="font-15 c-ffffffbf">{t('footer.pec_title')}</div>
          {agency?.isCustomNow && agency?.pecMail ? (
            <div
              className={
                upperLanguage === 'IT'
                  ? 'font-15 c-ffffffbf ms-2 cursor-pointer'
                  : 'font-15 c-ffffffbf cursor-pointer'
              }
              onClick={() => window.open(`mailto:${agency?.pecMail}`)}
            >
              {agency?.pecMail}
            </div>
          ) : (
            <div
              className={
                upperLanguage === 'IT'
                  ? 'font-15 c-ffffffbf ms-2 cursor-pointer'
                  : 'font-15 c-ffffffbf cursor-pointer'
              }
              onClick={() => window.open(`mailto:${t('footer.pec')}`)}
            >
              {t('footer.pec')}
            </div>
          )}
        </div>
      </div>
    </div>
  )

  const FooterBottom = (
    <div className="bg-000000 py-4 px-3 py-md-4 px-md-5">
      <div className="d-block white-space-pre">
        <div
          className="font-15 c-ffffff cursor-pointer display-contents"
          onClick={() => {
            setReloaded(false)
            navigate(Paths.CookiePolicy)
          }}
        >
          {t('footer.privacy_title_1')}
        </div>
        <div
          className="font-15 c-ffffff cursor-pointer display-contents"
          onClick={() => {
            if (informativeWebLink) {
              window.open(informativeWebLink, '_blank')
            }
          }}
        >
          {t('footer.privacy_title_2')}
        </div>
        <div
          className="font-15 c-ffffff cursor-pointer display-contents"
          onClick={() => {
            if (claimLink) {
              window.open(claimLink, '_blank')
            }
          }}
        >
          {t('footer.privacy_title_3')}
        </div>
        <div
          className="font-15 c-ffffff cursor-pointer display-contents"
          onClick={() => navigate(Paths.Accessibility)}
        >
          {t('footer.privacy_title_4')}
        </div>
      </div>

      <div className="font-13 c-ffffffbf mt-2">{t('footer.privacy_subtitle')}</div>
      <br />
      <br />
    </div>
  )

  return (
    <div className="footer">
      {FooterTop}
      {FooterBottom}
    </div>
  )
})
