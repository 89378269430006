import './accordion.scss'

import { useState } from 'react'
import { Collapse, Image } from 'react-bootstrap'
import { LeftIcon } from 'src/assets'
import { AccordionProps, typedMemo } from 'src/types'
import { concatClasses } from 'src/utils'

export const Accordion = typedMemo(
  ({
    title,
    head,
    children,
    flavor = 'base',
    defaultExpanded = false,
    classes = 'd-flex justify-content-between',
    classesHead = 'm-0 p-2 d-flex justify-content-between flex-nowrap',
    classesTitle = 'm-0 p-0 font-medium-m',
    classesIcon = '',
  }: AccordionProps) => {
    const [expanded, setExpanded] = useState(defaultExpanded)

    const Head = (
      <div className={classesHead} role="button" onClick={() => setExpanded((x) => !x)}>
        {head || <div className={classesTitle}>{title}</div>}
        <Image
          src={LeftIcon}
          width={18}
          height={18}
          className={concatClasses(
            'icon',
            classesIcon,
            expanded ? '' : 'rotate',
            flavor === 'green' ? 'filter-159881' : ''
          )}
        />
      </div>
    )

    const Separator = (
      <>
        {flavor === 'green' && <hr className="separator separator-green" />}
        {flavor === 'grey' && <hr className="separator separator-grey" />}
      </>
    )

    const Collapsible = (
      <Collapse in={expanded}>
        <div className="m-0 p-0">{children}</div>
      </Collapse>
    )

    return (
      <main className={classes}>
        {Head}
        {Separator}
        {Collapsible}
      </main>
    )
  }
)
