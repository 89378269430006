import { useClaimOpenPending } from './claim-open-pending.hook'

export const ClaimOpenPending = () => {
  const { t, email } = useClaimOpenPending()

  return (
    <div className="col-12 font-27 mx-auto my-auto text-center p-4">
      <div className="mb-4 font-27-semibold">{t('claim_report_open_pending.title')}</div>
      <div className="mb-4 white-space-pre">
        {t('claim_report_open_pending.subtitle')}
        <div className="text-center mt-3 mt-md-1 font-25-semibold">{email}</div>
      </div>
    </div>
  )
}
