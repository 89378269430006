import { selectorFamily, useRecoilState, useSetRecoilState } from 'recoil'
import { Optional, RootOutcome } from 'src/types'
import { deleteKey } from 'src/utils'

import { atomRootOutcomes } from './root-outcomes.atoms'

const selectorOutcome = selectorFamily<Optional<RootOutcome>, string>({
  key: 'rootOutcome',

  get:
    (outcomeId: string) =>
    ({ get }) =>
      get(atomRootOutcomes)?.[outcomeId],

  set:
    (outcomeId: string) =>
    ({ get, set }, rootOutcome) => {
      set(atomRootOutcomes, { ...get(atomRootOutcomes), [outcomeId]: rootOutcome as RootOutcome })
    },
})

export const useRootOutcome = (errorId: string) => {
  const saveRootOutcomes = useSetRecoilState(atomRootOutcomes)

  const [rootOutcome, saveRootOutcome] = useRecoilState(selectorOutcome(errorId))

  const removeRootOutcome = () => {
    saveRootOutcomes((rootOutcomes) => deleteKey(rootOutcomes, errorId))
  }

  return { rootOutcome, saveRootOutcome, removeRootOutcome }
}
