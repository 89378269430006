import { useEffect, useRef } from 'react'
import { INPUT_DIGITS_KEY_CODES } from 'src/config'
import { regexDigit } from 'src/regex'

export default function useFocus(isFocused: boolean) {
  const ref = useRef<any>(null)

  useEffect(() => {
    isFocused && ref?.current?.focus()
  })

  return ref
}

export const useDigit = (props: any) => {
  const { focused, onDigitChange, index, changeFocus } = props

  const digitRef = useFocus(focused)

  const handleChange = (event: any) => {
    onDigitChange(index, event.target.value)
  }

  const handleKeyUpPress = (event: any) => {
    const { ARROW_LEFT, ARROW_RIGHT } = INPUT_DIGITS_KEY_CODES

    if ([ARROW_LEFT, ARROW_RIGHT].includes(event.keyCode)) {
      changeFocus(event.keyCode)
    }

    if (!regexDigit.test(event.key)) {
      event.preventDefault()
    }
  }

  const handleFocus = (event: any) => {
    event.target.setSelectionRange(0, 1)
  }

  return {
    digitRef,
    handleChange,
    handleKeyUpPress,
    handleFocus,
  }
}
