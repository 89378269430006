import './section1.scss'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { Paths } from 'src/navigation'
import { selectorCoverages } from 'src/storage'

export const useSection1 = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const coverages = useRecoilValue(selectorCoverages)

  /**
   * Functions
   */
  const goToDataEntry = () => {
    navigate({
      pathname: Paths.DataEntry1,
      search: `?${new URLSearchParams({
        landing: 'true',
        base: 'true',
        seasonal: 'false',
      }).toString()}`,
    })
  }

  return {
    t,
    coverages,
    goToDataEntry,
  }
}
