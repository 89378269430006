import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

export const UseClaimeReportWarning = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const goBack = () => {
    navigate(-1)
  }

  return {
    t,
    goBack,
  }
}
