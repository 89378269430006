import { ChangeEvent, DragEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { InputUploadFileProps } from 'src/types'

export const useInputUploadFile = ({
  selectedFiles,
  multiple = true,
  maxFileSizeMB = 1,
  onFilesSelected,
  onRemove,
}: InputUploadFileProps) => {
  const { t } = useTranslation()
  const maxFileSizeBytes = maxFileSizeMB * 1024 * 1024

  const [isShowAlert, setIsShowAlert] = useState<number | undefined>()
  const [files, setFiles] = useState<File[]>(selectedFiles || [])
  const [isOutOfSizeFile, setIsOutOfSizeFile] = useState(false)

  useEffect(() => {
    onFilesSelected(files)
  }, [files, onFilesSelected])

  const getIsOverSizeFiles = (files: FileList) => {
    const filesSize = Array.from(files).reduce((total, file) => total + file.size, 0)
    if (filesSize > maxFileSizeBytes) {
      setIsOutOfSizeFile(true)
      return true
    }
    return false
  }

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files
    if (selectedFiles && getIsOverSizeFiles(selectedFiles)) return

    if (selectedFiles && selectedFiles.length > 0) {
      const newFiles: File[] = Array.from(selectedFiles)
      setFiles((prevFiles) => [...prevFiles, ...newFiles])
    }
  }

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()

    const droppedFiles = event.dataTransfer.files
    if (getIsOverSizeFiles(droppedFiles)) return

    if (droppedFiles.length > 0) {
      const newFiles: File[] = Array.from(droppedFiles).filter((file) =>
        file.name.endsWith('.xlsx')
      )

      if (multiple) {
        setFiles((prevFiles) => [...prevFiles, ...newFiles])
      } else if (newFiles[0]?.name) {
        setFiles([newFiles[0]])
      }
    }
  }

  const handleRemoveFile = (index: number | undefined) => {
    if (index !== undefined) {
      setFiles((prevFiles) => prevFiles?.filter((_, i) => i !== index))
      setIsShowAlert(undefined)
    }
  }

  const confirmAction = () => {
    handleRemoveFile(isShowAlert)
    if (onRemove) {
      onRemove()
    }
  }

  return {
    t,
    files,
    isOutOfSizeFile,
    isShowAlert,
    setIsShowAlert,
    setIsOutOfSizeFile,
    handleDrop,
    handleFileChange,
    confirmAction,
  }
}
