/* eslint-disable complexity */
import { lowerLanguage } from 'src/i18n'
import {
  Agency,
  Asset,
  Contractor,
  CoverageId,
  EvaluatePremiumRequest,
  FormikMainInsured,
  FormikOtherInsured,
  Gender,
  Nationality,
  PeriodType,
  QuoteRequest,
  Structure,
  Voidable,
} from 'src/types'
import { GetGroupExtraInfo, MainIsuredGroup } from 'src/types/networking/terminals/group.types'
import { deleteEmptyKeys, generateFiscalCode, getDateFromFiscalCode, reverseDate } from 'src/utils'

export const toStructureOptions = (structures: Structure[]) => {
  const structures_ = structures
    .filter(({ id, name }) => id && name)
    .map(({ id, name }) => ({
      id: id,
      value: name?.[lowerLanguage] || '',
    }))
  return structures_
}

export const toPeriodType = (coverageId: Voidable<CoverageId>) => {
  const periodTypes: Record<string, PeriodType> = {
    id_daily: '1',
    id_seasonal: '2',
    id_period: '3',
    id_weekly: '4',
    id_monthly: '5',
    id_quarterly: '6',
  }
  return periodTypes[coverageId]
}

export const toContractor = ({
  insured_name,
  insured_surname,
  insured_nationality,
  insured_cap,
  insured_fc_question,
  insured_fc,
  insured_birth,
  insured_birth_nation,
  insured_birth_place,
  insured_gender,
  insured_email,
  insured_phone,
  consent_td,
  consent_privacy,
  consent_marketing,
  consent_profiling,
  consent_communication,
}: FormikMainInsured): Contractor => {
  return deleteEmptyKeys({
    contractor: true,
    name: insured_name?.trim(),
    surname: insured_surname?.trim(),
    nationality: insured_nationality,
    cap: insured_cap,
    fiscalCode:
      insured_fc_question === 'yes_id'
        ? insured_fc?.toUpperCase()
        : generateFiscalCode({
            name: insured_name,
            surname: insured_surname,
            birthDate: insured_birth,
            birthPlace: insured_birth_place,
            gender: insured_gender === 'M' ? 'M' : 'F',
          }),
    birthDate:
      insured_fc_question === 'no_id'
        ? reverseDate(insured_birth)
        : getDateFromFiscalCode(insured_fc?.toUpperCase()),
    birthNation: insured_fc_question === 'no_id' ? insured_birth_nation : '',
    birthPlace: insured_fc_question === 'no_id' ? insured_birth_place : '',
    gender: insured_fc_question === 'no_id' ? insured_gender : '',
    mainContact: {
      type: 'email',
      value: insured_email?.trim(),
    },
    otherContacts: insured_phone?.formattedPhone
      ? [{ type: 'mobile', value: insured_phone?.formattedPhone }]
      : [],
    tdConsent: consent_td,
    privacyConsent: consent_privacy,
    marketingConsent: consent_marketing,
    profilingConsent: consent_profiling,
    communicationConsent: consent_communication,
  })
}

export const toAssets = (otherInsureds: FormikOtherInsured[]): Asset[] => {
  return otherInsureds.map(
    ({
      name,
      surname,
      nationality,
      fc_question,
      fc,
      birth,
      birth_nation,
      birth_place,
      gender,
    }: FormikOtherInsured) => {
      return deleteEmptyKeys({
        name: name?.trim(),
        surname: surname?.trim(),
        nationality: nationality,
        fiscalCode:
          fc_question === 'yes_id'
            ? fc?.toUpperCase()
            : generateFiscalCode({
                name,
                surname,
                birthDate: birth,
                birthPlace: birth_place,
                gender: gender === 'M' ? 'M' : 'F',
              }),
        birthDate:
          fc_question === 'no_id' ? reverseDate(birth) : getDateFromFiscalCode(fc?.toUpperCase()),
        birthNation: fc_question === 'no_id' ? birth_nation : '',
        birthPlace: fc_question === 'no_id' ? birth_place : '',
        gender: fc_question === 'no_id' ? gender : '',
      })
    }
  )
}

export const toQuoteRequest = (
  values: FormikMainInsured,
  agency?: Agency,
  quoteId?: string
): QuoteRequest => {
  const structure = agency?.structures?.find(({ id }) => id === values.convention)
  const structureName = structure?.name?.[lowerLanguage] || ''

  const agencyWithStructure = deleteEmptyKeys({
    id: agency?.id,
    name: agency?.name,
    structure: values.convention,
    structureName,
    isCustomNow: agency?.isCustomNow || false,
  })

  const period = {
    type: toPeriodType(values.insured_days),
    from: reverseDate(values.coverage_start_date, '/', '-'),
    to: reverseDate(values.coverage_end_date, '/', '-'),
  }

  return deleteEmptyKeys({
    agency: agencyWithStructure,
    period,
    quoteId,
    contractor: toContractor(values),
    assets: toAssets(values.insured_list),
    sections: [],
    language: lowerLanguage,
    coverage: values.coverage_type_radios,
    discount: values.discount?.toUpperCase(),
    token: 'token',
  })
}

export const toContractorGroup = ({
  name,
  surname,
  email,
  emailConfirm,
  isInsured,
  phone,
  cap,
  nationality,
  fiscalCodeQuestion,
  birthDate,
  birthNation,
  birthPlace,
  gender,
  fiscalCode,
  consentTd,
  consentPrivacy,
  consentMarketing,
  consentProfiling,
  consentCommunication,
}: MainIsuredGroup): Contractor => {
  return deleteEmptyKeys({
    contractor: true,
    isInsured: isInsured === 'id_yes',
    name: name?.trim() || '',
    surname: surname?.trim() || '',
    nationality: (nationality as Nationality) || 'IT',
    cap: cap || '',
    fiscalCode: fiscalCode?.toUpperCase() || '',
    birthDate:
      fiscalCodeQuestion === 'no_id'
        ? reverseDate(birthDate) || ''
        : getDateFromFiscalCode(fiscalCode?.toUpperCase() || ''),
    birthNation: fiscalCodeQuestion === 'no_id' ? birthNation || '' : '',
    birthPlace: fiscalCodeQuestion === 'no_id' ? birthPlace || '' : '',
    gender: fiscalCodeQuestion === 'no_id' ? (gender as Gender) || 'M' : 'M',
    mainContact: {
      type: 'email',
      value: email?.trim() || '',
    },
    otherContacts: phone?.formattedPhone ? [{ type: 'mobile', value: phone?.formattedPhone }] : [],
    tdConsent: consentTd || true,
    privacyConsent: consentPrivacy || false,
    marketingConsent: consentMarketing || false,
    profilingConsent: consentProfiling || false,
    communicationConsent: consentCommunication || false,
  })
}

export const toAssetsGroup = (otherInsureds: Asset[]): Asset[] => {
  return otherInsureds.map(
    ({
      name,
      surname,
      nationality,
      fiscalCode,
      birthDate,
      birthNation,
      birthPlace,
      fiscalCodeQuestion,
      gender,
    }: Asset) => {
      return deleteEmptyKeys({
        name: name?.trim(),
        surname: surname?.trim(),
        nationality: nationality,
        fiscalCode:
          fiscalCodeQuestion === 'yes_id'
            ? fiscalCode?.toUpperCase()
            : generateFiscalCode({
                name,
                surname,
                birthDate: birthDate,
                birthPlace: birthPlace,
                gender: gender === 'M' ? 'M' : 'F',
              }),
        birthDate:
          fiscalCodeQuestion === 'no_id'
            ? reverseDate(birthDate)
            : getDateFromFiscalCode(fiscalCode?.toUpperCase()),
        birthNation: fiscalCodeQuestion === 'no_id' ? birthNation : '',
        birthPlace: fiscalCodeQuestion === 'no_id' ? birthPlace : '',
        gender: fiscalCodeQuestion === 'no_id' ? gender : '',
      })
    }
  )
}

export const toQuoteRequestGroup = (
  group: GetGroupExtraInfo,
  mainInsured: MainIsuredGroup,
  formikMainInsured: FormikMainInsured,
  agency?: Agency,
  quoteId?: string
): QuoteRequest => {
  const structureName = ''

  const agencyWithStructure = deleteEmptyKeys({
    id: agency?.id,
    name: agency?.name,
    structure: '',
    structureName,
    isCustomNow: agency?.isCustomNow || false,
  })

  const period = {
    type: toPeriodType(formikMainInsured.insured_days),
    from: reverseDate(formikMainInsured.coverage_start_date, '/', '-'),
    to: reverseDate(formikMainInsured.coverage_end_date, '/', '-'),
  }

  return deleteEmptyKeys({
    agency: agencyWithStructure,
    period,
    quoteId,
    contractor: toContractorGroup(mainInsured),
    assets: toAssetsGroup(group),
    sections: [],
    language: lowerLanguage,
    coverage: formikMainInsured.coverage_type_radios,
    discount: formikMainInsured.discount?.toUpperCase(),
    token: 'token',
  })
}

//Evaluate premium

const calculateAge = (birthDate: string): number => {
  const birth = new Date(birthDate)
  const today = new Date()
  let age = today.getFullYear() - birth.getFullYear()
  const monthDifference = today.getMonth() - birth.getMonth()
  if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birth.getDate())) {
    age--
  }
  return age
}

const getInsuredNumbers = (values: FormikMainInsured, currentStep: number) => {
  let insuredNumber_ = Number(values.insured_number)
  let insuredNumberUnder14_ = Number(values.insured_number_under14)

  if (currentStep === 1) {
    insuredNumber_ = 0
    insuredNumberUnder14_ = 0

    const processInsured = (fcQuestion: string, fc: string, birth: string) => {
      const age =
        fcQuestion === 'yes_id'
          ? calculateAge(getDateFromFiscalCode(fc))
          : calculateAge(reverseDate(birth))

      if (age < 14) {
        insuredNumberUnder14_++
      } else if (age >= 14) {
        insuredNumber_++
      }
    }

    processInsured(values?.insured_fc_question, values?.insured_fc, values?.insured_birth)

    if (values?.insured_list?.length > 0) {
      values.insured_list.forEach((insured) => {
        processInsured(insured?.fc_question, insured?.fc, insured?.birth)
      })
    }
  }

  return {
    insuredNumber: insuredNumber_,
    insuredNumberUnder14: insuredNumberUnder14_,
  }
}

export const toEvaluatePremiumRequest = (
  values: FormikMainInsured,
  currentStep: number
): EvaluatePremiumRequest | undefined => {
  const period = toPeriodType(values.insured_days)
  const from = reverseDate(values.coverage_start_date, '/', '-')
  const to = reverseDate(values.coverage_end_date, '/', '-')

  const { insuredNumber, insuredNumberUnder14 } = getInsuredNumbers(values, currentStep)

  if (insuredNumber === 0 && insuredNumberUnder14 === 0) {
    return undefined
  }

  return deleteEmptyKeys({
    coverage: values.coverage_type_radios,
    insuredNumber: insuredNumber,
    insuredNumberUnder14: insuredNumberUnder14,
    from,
    to,
    period,
  })
}

const getInsuredNumbersGroup = (values: GetGroupExtraInfo, currentStep: number) => {
  let insuredNumber_ = 0
  let insuredNumberUnder14_ = 0

  if (currentStep === 1) {
    const processInsured = (fcQuestion: string, fc: string, birth: string) => {
      const age =
        fcQuestion === 'yes_id'
          ? calculateAge(getDateFromFiscalCode(fc))
          : calculateAge(reverseDate(birth))

      if (age < 14) {
        insuredNumberUnder14_++
      } else if (age >= 14) {
        insuredNumber_++
      }
    }

    if (values?.length > 0) {
      values.forEach((insured) => {
        processInsured(insured?.fiscalCodeQuestion || '', insured?.fiscalCode, insured?.birthDate)
      })
    }
  }

  return {
    insuredNumber: insuredNumber_,
    insuredNumberUnder14: insuredNumberUnder14_,
  }
}
export const toEvaluatePremiumRequestGroup = (
  values: GetGroupExtraInfo,
  formikMainInsured: FormikMainInsured,
  currentStep: number
): EvaluatePremiumRequest | undefined => {
  const period = toPeriodType(formikMainInsured.insured_days)
  const from = reverseDate(formikMainInsured.coverage_start_date, '/', '-')
  const to = reverseDate(formikMainInsured.coverage_end_date, '/', '-')

  const { insuredNumber, insuredNumberUnder14 } = getInsuredNumbersGroup(values, currentStep)

  if (insuredNumber === 0 && insuredNumberUnder14 === 0) {
    return undefined
  }

  return deleteEmptyKeys({
    coverage: formikMainInsured.coverage_type_radios,
    insuredNumber: insuredNumber,
    insuredNumberUnder14: insuredNumberUnder14,
    from,
    to,
    period,
  })
}
