import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { lowerLanguage } from 'src/i18n'
import { Paths } from 'src/navigation'
import { useGetProduct } from 'src/networking/terminals'
import { usePostCreateClaim } from 'src/networking/terminals/claim/claim.gates'
import {
  atomClaimData,
  atomClaimId,
  atomClaimQuote,
  atomProduct,
  atomProgressBar,
  selectorDocuments,
} from 'src/storage'
import { FormikClaimOpenSummary } from 'src/types'
import { ClaimOtherData, ClaimSummarySections, ClaimWeatherData } from 'src/types/pages/claim.types'
import { yupBoolRequired } from 'src/utils'
import * as Yup from 'yup'

import { PROGRESS_BAR_STEP } from '../../progress-bar-step'
import {
  formatClaimPostData,
  getClaimDetails,
  getClaimOpeningConfirmation,
  getDamageDetails,
  getInjuryPersonalData,
  getRequestDetails,
} from './claim-open-summary-get-data'

export const UseClaimOpenSummary = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const claimQuote = useRecoilValue(atomClaimQuote)
  const claimData = useRecoilValue(atomClaimData)
  const claimId = useRecoilValue(atomClaimId)
  const { crossGate: getProduct } = useGetProduct()

  const { pending: pendingCreateClaim, crossGate: postCreateClaim } = usePostCreateClaim()
  const [isUploadingData, setIsUploadingData] = useState(false)
  const [loadClaim, setLoadClaim] = useState(false)

  // eslint-disable-next-line complexity
  const getClaimSummary = () => {
    type ObjectClaimKey = keyof typeof claimData

    const claimRequestObject: ClaimOtherData | ClaimWeatherData =
      claimData?.[claimData?.selectedType as ObjectClaimKey] || {}

    const openClaimSummary_: ClaimSummarySections = {
      claimDetails:
        getClaimDetails(claimRequestObject, claimData?.selectedType || '', claimQuote) || undefined,
      injuryPersonalData:
        getInjuryPersonalData((claimRequestObject as ClaimOtherData)?.request?.damage) || undefined,
      damageDetails: getDamageDetails(claimRequestObject as ClaimOtherData) || undefined,
      requestDetails:
        getRequestDetails(
          (claimRequestObject as ClaimOtherData)?.request?.refound?.costFileRefounds
        ) || undefined,
      claimOpeningConfirmation:
        getClaimOpeningConfirmation(
          claimData?.openInformations,
          claimQuote?.contractor?.mainContact?.value || ''
        ) || undefined,
    }

    return openClaimSummary_ || {}
  }

  const saveProduct = useSetRecoilState(atomProduct)
  const documents = useRecoilValue(selectorDocuments)

  const informativePersonalDataLink = documents?.find(({ id }) => id === '6')

  const openInformativePersonalDataLink = () => {
    const file = informativePersonalDataLink?.file?.[lowerLanguage]
    if (file) {
      window.open(file.replaceAll('"', ''), '_blank')
    }
  }

  //////////////////////////////////////////////////////////////////////////////

  const [openClaimSummary] = useState(getClaimSummary() || {})
  const [progressBar, setProgressBar] = useRecoilState(atomProgressBar)

  const [initialValues] = useState<FormikClaimOpenSummary>({
    digitalConsent: true,
    privacyConsent: false,
  })

  const validationSchema = Yup.object().shape({
    digitalConsent: yupBoolRequired,
    privacyConsent: yupBoolRequired,
  })

  //////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    setTimeout(() => {
      setProgressBar(PROGRESS_BAR_STEP.claim_report_open_summary)
    }, 100)
  }, [])

  useEffect(() => {
    if (!informativePersonalDataLink) {
      getProduct(
        { params: { agencyCode: claimQuote?.agency?.id || '' } },
        {
          onSuccess: (product) => {
            saveProduct(product)
          },
        }
      )
    }
  }, [])

  useEffect(() => {
    if (!claimQuote) {
      navigate(-1)
    }
  }, [claimQuote])

  //////////////////////////////////////////////////////////////////////////////

  const formik = useFormik<FormikClaimOpenSummary>({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (claimQuote?.contractId) {
        setLoadClaim(true)
        setIsUploadingData(true)
        postCreateClaim(
          {
            body: {
              id: claimId || '',
              claimData: formatClaimPostData(claimData),
              digitalConsent: values?.digitalConsent || false,
              privacyConsent: values?.privacyConsent || false,
              coverage: claimQuote?.coverage || '',
            },
          },
          {
            onSuccess: (response: { outcome: boolean }) => {
              setLoadClaim(false)
              setIsUploadingData(false)
              if (response?.outcome) {
                navigate(Paths.ClaimOpenPending)
              } else {
                navigate(Paths.ClaimReportError)
              }
            },
            onFailure: () => {
              setLoadClaim(false)
              navigate(Paths.ClaimReportError)
              setIsUploadingData(false)
            },
            onPending: () => {},
          }
        )
      } else {
        navigate(Paths.ClaimReportError)
      }
    },
  })

  //////////////////////////////////////////////////////////////////////////////

  const goBack = () => {
    navigate(Paths.ClaimReportOpen)
  }

  return {
    t,
    progressBar,
    formik,
    validationSchema,
    openClaimSummary,
    loadClaim,
    pendingCreateClaim,
    isUploadingData,
    goBack,
    openInformativePersonalDataLink,
  }
}
